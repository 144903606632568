import {
    Get,
    Post,
    GetSelfOrganizationApi,
    LeaveOrganizationApi,
    GetOrganizationMembersApi,
    CreateOrganizationApi, Del
} from '../common';
import {message} from 'antd';

// 获取 LocalStorage 中存储的组织信息
export function GetLocalStorageOrg() {
    const org = window.localStorage.getItem('org')
    let orgUuid, orgName = ''
    if (org !== null) {
        const orgMap = JSON.parse(org)
        orgUuid = orgMap['value']
        orgName = orgMap['label']
    }
    return [orgUuid, orgName]
}


export const [OrgUuid, OrgName] = (() => {
    const org = window.localStorage.getItem('org')
    let orgUuid, orgName = ''
    if (org !== null) {
        const orgMap = JSON.parse(org)
        orgUuid = orgMap['value']
        orgName = orgMap['label']
    }
    return [orgUuid, orgName]
})();

export function VerifyOrgUuid() {
    if (OrgUuid === undefined || OrgUuid === "") {
        message.warning("请选择对应的 [ 组织 ]")
        console.log("请选择对应的 [ 组织 ]")
        return false
    }
    return true
}

export function VerifyOrgProductUuid() {
    if (OrgProductUuid === undefined || OrgProductUuid === "") {
        message.warning("请选择对应的 [ 组织产品 ]")
        console.log("请选择对应的 [ 组织产品 ]")
        return false
    }
    return true
}

export function VerifyOrgUuidAndOrgProductUuid() {
    if (!VerifyOrgUuid() || !VerifyOrgProductUuid()) {
        return false
    }
    return true
}

// 获取 LocalStorage 中存储的产品信息
export function GetLocalStorageOrgProduct() {
    const orgProduct = window.localStorage.getItem('orgProduct')
    let orgProductUuid, orgProductName = ''
    if (orgProduct !== null) {
        const orgMap = JSON.parse(orgProduct)
        orgProductUuid = orgMap['value']
        orgProductName = orgMap['label']
    }
    return [orgProductUuid, orgProductName]
}

export const [OrgProductUuid, OrgProductName] = (() => {
    const orgProduct = window.localStorage.getItem('orgProduct')
    let orgProductUuid, orgProductName = ''
    if (orgProduct !== null) {
        const orgMap = JSON.parse(orgProduct)
        orgProductUuid = orgMap['value']
        orgProductName = orgMap['label']
    }
    return [orgProductUuid, orgProductName]
})()


// 获取用户所在组织列表
export function GetSelfOrg(props) {
    return Get({
        url: GetSelfOrganizationApi,
    })
}

// 创建组织
export function CreateOrg(props) {
    let params = {
        name: props.name
    }
    return Post({
        url: CreateOrganizationApi,
        params: params,
    })
}

export function LeaveOrganization(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid
    });

    const leaveOrganizationUrl = `${LeaveOrganizationApi}?${params}`
    return Del({
        url: leaveOrganizationUrl,
    })
}

export function GetOrganizationMembers(props) {
    const params = new URLSearchParams({orgUuid: props.orgUuid});
    const getOrganizationMembersApiUrl = `${GetOrganizationMembersApi}?${params}`

    return Get({
        url: getOrganizationMembersApiUrl,
    })
}