import {Button, Input, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {ConvertTimestamp} from '../common/date';
import {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common'
import {
    AddKubernetesCluster,
    AddKubernetesEdge,
    DelKubernetesCluster,
    DelKubernetesEdge,
    ListKubernetesCluster
} from '../../apis/product/k8s';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';
import {KubernetesEventColumns} from './common';
import {CopyOutlined} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import {GetMessageGroupOptions} from '../message/messageGroup';
import {
    CreateKubernetesEventReceiver,
    DelKubernetesEventReceiver,
    ListKubernetesEventReceiver
} from '../../apis/kubernetes/messageReceiver';

export function K8SClusterRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['cluster']}></LeftSider>
                    <IndexRightBody>
                        <ListK8SClusterContent></ListK8SClusterContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export default function ListK8SClusterContent() {
    const [kubernetesEventDataSource, setKubernetesEventDataSource] = useState([])
    const [kubernetesEventOpenModal, setKubernetesEventOpenModal] = useState(false)
    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const [k8sClusterDataSource, setK8SClusterDataSource] = useState([])
    // 列出K8S集群
    const listK8SClusterHandler = () => {
        if (env === '') {
            return;
        }
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListKubernetesCluster({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const clusters = data.cluster
                if (errCode === 0) {
                    if (clusters === null) {
                        setK8SClusterDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const c of clusters) {
                        tmp.push({
                            key: c.uuid,
                            uuid: c.uuid,
                            k8sClusterUuid: c.uuid,
                            name: c.name,
                            orgUuid: c.orgUuid,
                            productUuid: c.productUuid,
                            productEnvUuid: c.productEnvUuid,
                            upToken: c.upToken,
                            apiService: c.apiService,
                            describe: c.describe,
                            createTime: c.createTime,
                            kubeEdgeGrpc: c.kubeEdgeGrpc,
                            token: c.token
                        })
                    }
                    setK8SClusterDataSource(tmp)
                    return;
                }
                message.error(`列出K8S集群失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出K8S集群失败`)
            }
        )
    }
    //
    useEffect(() => {
        listK8SClusterHandler()
    }, [env])
    //
    const k8sClusterColumns = [
        {
            title: '集群名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'KubeEdge',
            dataIndex: 'kubeEdgeGrpc',
            key: 'kubeEdgeGrpc'
        },
        {
            title: '上报参数信息',
            dataIndex: 'key',
            key: 'key',
            render: (_, record) => {
                return (<>
                    <pre>orgUuid: {record.orgUuid}</pre>
                    <pre>productUuid: {record.productUuid}</pre>
                    <pre>productEnvUuid: {record.productEnvUuid}</pre>
                    <pre>k8sClusterUuid: {record.k8sClusterUuid}</pre>
                    <pre>upToken: {record.upToken}</pre>
                </>)
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '拷贝参数',
            dataIndex: 'copyParams',
            key: 'copyParams',
            render: (_, record) => {
                const params = `orgUuid=${record.orgUuid}&orgProductUuid=${record.productUuid}&orgProductEnvUuid=${record.productEnvUuid}&k8SClusterUuid=${record.key}&token=${record.upToken}`
                return (
                    <>
                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => copy(params)}>复制</Button>
                    </>
                );
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Space>
                            <Popconfirm
                                title={'删除K8S集群'}
                                description={'您确定要删除该K8S集群？'}
                                onConfirm={() => delK8sClusterOnClick(record)}
                            >
                                <Button type={'primary'} danger={true}>删除</Button>
                            </Popconfirm>

                            <Popconfirm
                                title={'删除K8S KubeEdge'}
                                onConfirm={() => delK8SClusterKubeEdgeOnClick(record)}
                            >
                                <Button type={'primary'} danger={true}>删除KubeEdge</Button>
                            </Popconfirm>

                        </Space>


                        <Space>
                            <Button type={'primary'}
                                    onClick={() => createK8SClusterKubeEdgeOnClick(record)}>添加KubeEdge</Button>

                            <Button type={'primary'}
                                    href={'/kubernetes/event'}
                            >事件</Button>
                        </Space>


                        <Space>
                            <Button type={'primary'} onClick={() => bindMessageGroupOnClick(record.uuid)}>绑定消息组</Button>
                            <Button type={'primary'} onClick={() => getMessageGroupOnClick(record.uuid)}>查看消息组</Button>
                        </Space>

                    </>
                );
            }
        }
    ]

    //
    const [bindMessageGroupClusterUuid, setBindMessageGroupClusterUuid] = useState('')
    const [bindMessageGroupModalOpen, setBindMessageGroupModalOpen] = useState(false)
    const bindMessageGroupOnClick = (k8sClusterUuid) => {
        setBindMessageGroupClusterUuid(k8sClusterUuid)
        setBindMessageGroupModalOpen(true)
    }
    const bindMessageGroupModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return;
        }

        if (messageGroupDefaultValue === undefined || messageGroupDefaultValue === "") {
            message.error(`未发现消息组Uuid`)
            return
        }

        if (bindMessageGroupClusterUuid === undefined || bindMessageGroupClusterUuid === "") {
            message.error(`未发现集群Uuid`)
            return;
        }

        console.log(`OrgUuid: ${OrgUuid}, OrgProductUuid: ${OrgProductUuid}, bindMessageGroupClusterUuid: ${bindMessageGroupClusterUuid}, messageGroupDefaultValue: ${messageGroupDefaultValue}.`)
        CreateKubernetesEventReceiver({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            k8SClusterUuid: bindMessageGroupClusterUuid,
            messageGroupUuid: messageGroupDefaultValue,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setBindMessageGroupModalOpen(false)
                    message.info(`绑定消息组成功`)
                    return
                }
                message.error(`绑定消息组失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`绑定消息组失败 ${err}`)
            }
        )
    }
    const bindMessageGroupModalOnCancel = () => {
        setBindMessageGroupModalOpen(false)
    }

    const messageGroupColumns = [
        {
            title: '消息组',
            dataIndex: 'messageGroupUuid',
            key: 'messageGroupUuid'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            render: (_, record) => {
                return (<>
                    <Button type={'primary'} danger={true} onClick={() => delMessageGroupOnClick(record.k8sClusterUuid, record.uuid)}>删除</Button>
                </>)
            }
        }
    ]



    const messageGroupOptions = GetMessageGroupOptions()
    const [messageGroupDefaultValue, setMessageGroupDefaultValue] = useState('')
    const messageGroupOptionsSelectOnHandler = (value) => {
        setMessageGroupDefaultValue(value)
    }

    const [getMessageGroupModalOpen, setGetMessageGroupModalOpen] = useState(false)
    const [messageGroupDataSource, setMessageGroupDataSource] = useState([])
    const getMessageGroupOnClick = (k8sClusterUuid) => {
        ListKubernetesEventReceiver(
            {
                orgUuid: OrgUuid,
                productUuid: OrgProductUuid,
                k8sClusterUuid: k8sClusterUuid,
            }
        ).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setMessageGroupDataSource([])
                        setGetMessageGroupModalOpen(true)
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push(
                            {
                                key: dd.uuid,
                                uuid: dd.uuid,
                                k8sClusterUuid: dd.k8SClusterUuid,
                                messageGroupUuid: dd.messageGroupUuid,
                                createTime: dd.createTime,
                            }
                        )
                    }
                    setGetMessageGroupModalOpen(true)
                    setMessageGroupDataSource(tmp)
                    return

                }
                message.error(`获取消息组失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取消息组失败 ${err}`)
            }
        )
    }
    const getMessageGroupModalOnOk = () => {
        setGetMessageGroupModalOpen(false)
    }
    const getMessageGroupModalOnCancel = () => {
        setGetMessageGroupModalOpen(false)
    }

    const delMessageGroupOnClick = (k8sClusterUuid, receiverUuid) => {
        DelKubernetesEventReceiver({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            k8sClusterUuid: k8sClusterUuid,
            receiverUuid: receiverUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除消息组成功`)
                    getMessageGroupOnClick(k8sClusterUuid)
                    return
                }

                message.error(`删除消息组失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除消息组失败 ${err}`)
            }
        )
    }

    //
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }
    //
    const delK8sClusterOnClick = (record) => {
        DelKubernetesCluster({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            clusterUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除K8S集群成功`)
                    listK8SClusterHandler()
                    return
                }
                message.error(`删除K8S集群失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除K8S集群失败`)
            }
        )
    }
    //
    const delK8SClusterKubeEdgeOnClick = (record) => {
        DelKubernetesEdge({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除K8S KubeEdge成功`)
                    listK8SClusterHandler()
                    return
                }
                message.error(`删除K8S KubeEdge失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除K8S KubeEdge失败`)
            }
        )
    }
    //
    const [createK8SClusterOpenModal, setCreateK8SClusterOpenModal] = useState(false)
    //
    const createK8SClusterOnClick = () => {
        setCreateK8SClusterOpenModal(true)
    }
    const createK8SClusterModalOnCancel = () => {
        setCreateK8SClusterOpenModal(false)
    }
    const createK8SClusterModalOnOk = () => {
        const name = document.getElementById('name').value
        const apiService = document.getElementById('apiService').value
        const describe = document.getElementById('describe').value

        AddKubernetesCluster({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            name: name,
            apiService: apiService,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加K8S集群成功`)
                    setCreateK8SClusterOpenModal(false)
                    listK8SClusterHandler()
                    return
                }
                message.error(`添加K8S集群失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加K8S集群失败 ${err}`)
            }
        )
    }

    // 添加 k8s kubeEdge
    const [createK8SClusterKubeEdgeOpenModal, setCreateK8SClusterKubeEdgeOpenModal] = useState(false)
    const [k8sClusterUuidDefaultValue, setK8SClusterUuidDefaultValue] = useState('')
    const createK8SClusterKubeEdgeOnClick = (record) => {
        setK8SClusterUuidDefaultValue(record.key)
        setCreateK8SClusterKubeEdgeOpenModal(true)
    }
    const createK8SClusterKubeEdgeModalOnCancel = () => {
        setCreateK8SClusterKubeEdgeOpenModal(false)
    }
    const createK8SClusterKubeEdgeModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return;
        }
        const kubeEdgeGrpc = document.getElementById('kubeEdgeGrpc').value
        const token = document.getElementById('token').value
        const k8sClusterUuid = document.getElementById('k8sClusterUuid').value
        if (kubeEdgeGrpc === '' || token === '' || k8sClusterUuid === '') {
            message.error(`请输入对应的参数`)
            return
        }
        AddKubernetesEdge({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: k8sClusterUuid,
            kubeEdgeGrpc: kubeEdgeGrpc,
            token: token
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加K8S集群KubeEdge成功`)
                    setCreateK8SClusterKubeEdgeOpenModal(false)
                    listK8SClusterHandler()
                    return
                }
                message.error(`添加K8S集群KubeEdge失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加K8S集群KubeEdge失败`)
            }
        )
    }

    //
    const kubernetesEventModalOnOk = () => {
        setKubernetesEventOpenModal(false)
    }
    const kubernetesEventModalOnCancel = () => {
        setKubernetesEventOpenModal(false)
    }

    return (
        <>
            <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>

            <Space size={4}>
                <Button type={'primary'} onClick={listK8SClusterHandler}>查询</Button>
                <Button type={'primary'} onClick={createK8SClusterOnClick}>添加K8S集群</Button>
            </Space>
            <Table columns={k8sClusterColumns} dataSource={k8sClusterDataSource}/>

            <Modal
                title={'添加K8S集群'}
                open={createK8SClusterOpenModal}
                onCancel={createK8SClusterModalOnCancel}
                onOk={createK8SClusterModalOnOk}
                destroyOnClose={true}
            >
                <span>名字</span><Input id={'name'}/>
                <span>APIService</span><Input id={'apiService'}/>
                <span>描述</span><Input id={'describe'}/>
            </Modal>

            <Modal
                open={createK8SClusterKubeEdgeOpenModal}
                onCancel={createK8SClusterKubeEdgeModalOnCancel}
                onOk={createK8SClusterKubeEdgeModalOnOk}
                title={'添加K8S集群KubeEdge'}
                destroyOnClose={true}
            >
                <span>k8sClusterUuid</span><Input disabled={true} defaultValue={k8sClusterUuidDefaultValue}
                                                  id={'k8sClusterUuid'}/>
                <span>kubeEdgeGrpc</span><Input id={'kubeEdgeGrpc'}/>
                <span>token</span><Input id={'token'}/>
            </Modal>


            <Modal
                open={kubernetesEventOpenModal}
                onOk={kubernetesEventModalOnOk}
                onCancel={kubernetesEventModalOnCancel}
                title={'K8S集群Events'}
                width={'100%'}
                destroyOnClose={true}
            >
                <div style={{height: '400px', overflow: 'auto'}}>
                    <Table columns={KubernetesEventColumns} dataSource={kubernetesEventDataSource}/>
                </div>
            </Modal>


            <Modal
                title={'绑定K8S集群消息组'}
                open={bindMessageGroupModalOpen}
                onOk={bindMessageGroupModalOnOk}
                onCancel={bindMessageGroupModalOnCancel}
                destroyOnClose={true}
            >
                <span>消息组</span>
                <Select
                    style={{
                        width: '100%',
                    }}
                    value={messageGroupDefaultValue}
                    options={messageGroupOptions}
                    onSelect={messageGroupOptionsSelectOnHandler}
                />
            </Modal>


            <Modal
                title={'K8S集群消息组'}
                open={getMessageGroupModalOpen}
                onOk={getMessageGroupModalOnOk}
                onCancel={getMessageGroupModalOnCancel}
            >
                <Table columns={messageGroupColumns} dataSource={messageGroupDataSource}/>
            </Modal>
        </>
    );
}