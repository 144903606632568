import {CiCopyContainerImageApi, Del, Get, Post} from '../common';


export function CopyContainerImage(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        srcImageRegistryUuid: props.srcImageRegistryUuid,
        srcImage: props.srcImage,
        destImageRegistryUuid: props.destImageRegistryUuid,
        destImage: props.destImage,
        timeout: props.timeout,
    }
    return Post({
        url: CiCopyContainerImageApi,
        params: params
    })
}


export function CopyContainerImageTaskHis(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${CiCopyContainerImageApi}?${params}`,
    })
}

