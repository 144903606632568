import {Get, Del, Post, ProductK8SClusterNameSpaceApi} from '../common';

export function CreateNameSpace(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterNameSpaceApi}`,
        params: params,
    })
}

export function ListNameSpace(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
    })
    return Get({
        url: `${ProductK8SClusterNameSpaceApi}?${params}`
    })
}

export function DelNameSpace(props) {
    const params = new URLSearchParams({

    })
    return Del({
        url: `${ProductK8SClusterNameSpaceApi}?${params}`
    })
}