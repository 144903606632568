import {Button, ConfigProvider, Empty, Input, Layout, message, Modal, Select, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useEffect, useState} from 'react';
import {
    GetCloudPlatformAccountOptions, GetCloudPlatformSubAccountAccessKeyOptions,
    GetCloudPlatformSubAccountOptions,
    GetOrgProductCloudPlatformOptions
} from './common';
import {
    CreateObjectStorageGenerator, DelObjectStorageGenerator, GetObjectStorageBucketSecurityToken,
    ListObjectStorageBucket,
    ListObjectStorageGenerator
} from '../../apis/cloud/objectStorage';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {GetCloudPlatform} from '../../apis/common';
import React from 'react';

export function ObjectStorageService() {
    return (<ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider></LeftSider>
                    <IndexRightBody>
                        <Empty/>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>);
}

export function ObjectStorageServiceRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudOSS']}></LeftSider>
                    <IndexRightBody>
                        <ObjectStorageServiceContent></ObjectStorageServiceContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function ObjectStorageServiceContent() {
    //
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState('')
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountValue, setCloudPlatformSubAccountValue] = useState('')
    const cloudPlatformSubAccountOptions = GetCloudPlatformSubAccountOptions(cloudPlatformAccountValue, setCloudPlatformSubAccountValue)
    const cloudPlatformSubAccountOnSelectHandler = (value) => {
        setCloudPlatformSubAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountAccessKeyValue, setCloudPlatformSubAccountAccessKeyValue] = useState('')
    const cloudPlatformSubAccountAccessKeyOptions = GetCloudPlatformSubAccountAccessKeyOptions(cloudPlatformSubAccountValue, setCloudPlatformSubAccountAccessKeyValue)
    const cloudPlatformSubAccountAccessKeyHandler = (value) => {
        setCloudPlatformSubAccountAccessKeyValue(value)
    }
    //
    const [cloudOSSDataSource, setCloudOSSDataSource] = useState([])
    const ossColumns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            width: '20%'
        },
        {
            title: 'location',
            dataIndex: 'location',
            key: 'location',
            width: '15%'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: 'storageClass',
            dataIndex: 'storageClass',
            key: 'storageClass',
            width: '10%'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'}
                                onClick={() => createCloudOSSGeneratorOnClickHandler(record)}>添加STS生成者</Button>
                    </>
                );
            }
        }
    ]

    //
    const listCloudOSSOnClickHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListObjectStorageBucket({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const buckets = data.bucket
                    if (buckets === null) {
                        setCloudOSSDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const bucket of buckets) {
                        tmp.push({
                            key: bucket.name,
                            name: bucket.name,
                            location: bucket.location,
                            createTime: bucket.createTime,
                            storageClass: bucket.storageClass
                        })
                    }
                    setCloudOSSDataSource(tmp)
                    return
                }
                message.error(`列出云平台对象存储桶失败 ${errMsg}`)
                return
            }
        ).catch(
            err => {
                message.error(`列出云平台对象存储桶失败`)
                console.log(`列出云平台对象存储桶失败 ${err}`)
            }
        )
    }
    //
    const [cloudOSSGeneratorDataSource, setCloudOSSGeneratorDataSource] = useState([])
    const ossGeneratorColumns = [
        {
            title: '云平台',
            dataIndex: 'platformId',
            key: 'platformId',
            width: '10%',
            render: (text) => {
                return GetCloudPlatform(text)
            }
        },
        {
            title: 'bucket',
            dataIndex: 'bucketName',
            key: 'bucketName',
            width: '10%'
        },
        {
            title: 'endpoint',
            dataIndex: 'endpoint',
            key: 'endpoint',
            width: '10%'
        },
        {
            title: 'arn',
            dataIndex: 'arn',
            key: 'arn',
            width: '10%'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime',
            width: '10%'
        },
        {
            title: 'operator',
            dataIndex: 'operator',
            key: 'operator',
            width: '10%',
            render: (_, record) => {
                return <>
                    <Button type={'primary'} danger={true}
                            onClick={() => delCloudOSSGeneratorOnClickHandler(record)}>删除</Button>
                    <Button type={'primary'} onClick={() => getCloudOSSSTSByGeneratorOnClick(record)}>获取STS</Button>
                </>
            }
        }
    ]
    //
    const listCloudOSSGeneratorOnClickHandler = () => {
        ListObjectStorageGenerator({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const generators = data.generator
                    if (generators === null) {
                        setCloudOSSGeneratorDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const generator of generators) {
                        tmp.push({
                            key: generator.uuid,
                            platformId: generator.platformId,
                            bucketName: generator.bucketName,
                            endpoint: generator.endpoint,
                            arn: generator.arn,
                            createTime: generator.createTime,
                            platformName: generator.platformName
                        })
                    }
                    setCloudOSSGeneratorDataSource(tmp)
                    return;
                }
                message.error(`列出OSS Generator失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                message.error(`列出对象存储 Generator失败`)
                console.log(`列出对象存储 Generator失败 ${err}`)
            }
        )
    }

    useEffect(() => {
        listCloudOSSGeneratorOnClickHandler()
    }, [])
    //
    const [bucketValue, setBucketValue] = useState('')
    const [openCreateCloudOSSGenerator, setOpenCreateCloudOSSGenerator] = useState(false)
    const createCloudOSSGeneratorOnClickHandler = (record) => {
        setBucketValue(record.name)
        setOpenCreateCloudOSSGenerator(true)
    }
    const createCloudOSSGeneratorOnCancel = () => {
        setOpenCreateCloudOSSGenerator(false)
    }
    const createCloudOSSGeneratorOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        const bucket = document.getElementById('bucket').value
        const endpoint = document.getElementById('endpoint').value
        const arn = document.getElementById('arn').value

        CreateObjectStorageGenerator({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            bucket: bucket,
            endpoint: endpoint,
            arn: arn
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建对象存储 Generator 成功`)
                    setOpenCreateCloudOSSGenerator(false)
                    return;
                }
                message.error(`创建对象存储 Generator 失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                message.error(`创建对象存储 Generator 失败`)
                console.log(`创建对象存储 Generator 失败 ${err}`)
            }
        )
    }
    //
    const delCloudOSSGeneratorOnClickHandler = (record) => {
        DelObjectStorageGenerator({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            generatorUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除对象存储STS生成者成功`)
                    listCloudOSSGeneratorOnClickHandler()
                    return;
                }
                message.error(`删除对象存储STS生成者失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除对象存储STS生成者失败`)
                console.log(`删除对象存储STS生成者失败 ${err}`)
            }
        )
    }
    //
    const [generatorUuidValue, setGeneratorUuidValue] = useState('')
    const [generatorBucketValue, setGeneratorBucketValue] = useState('')
    const [generatorPlatformIdValue, setGeneratorPlatformIdValue] = useState('')
    const [generatorPlatformValue, setGeneratorPlatformValue] = useState('')

    const [openGetCloudOSSSTSByGenerator, setOpenGetCloudOSSSTSByGenerator] = useState(false)
    const [sts, setSts] = useState('')

    const getCloudOSSSTSByGeneratorModalOnOk = () => {
        const generatorUuid = document.getElementById('generatorUuid').value
        const relativeId = document.getElementById('relativeId').value

        GetObjectStorageBucketSecurityToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            generatorUuid: generatorUuid,
            relativeId: relativeId
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const accessKeyId = data.accessKeyId
                    const accessKeySecret = data.accessKeySecret
                    const securityToken = data.securityToken
                    const endpoint = data.endpoint
                    const expiration = data.expiration
                    const stsCommand = `
expiration: ${expiration}\n

推荐工具:
    linux:
        http://repo.startops.com.cn/tools/upload/linux/upload
    mac:
        http://repo.startops.com.cn/tools/upload/mac/upload

./upload \\
-localFilePath 请替换成需要上传的本地文件路径  \\
-platformId ${generatorPlatformIdValue} \\
-endpoint ${endpoint} \\
-bucket ${generatorBucketValue} \\
-objectPath ${relativeId} \\
-accessKeyId ${accessKeyId} \\
-accessKeySecret ${accessKeySecret} \\
-securityToken ${securityToken}
`
                    //const sts = `endpoint: ${endpoint}\naccessKeyId: ${accessKeyId}\naccessKeySecret: ${accessKeySecret}\nsecurityToken: ${securityToken}\nexpiration: ${expiration}`
                    setSts(stsCommand)
                    return;
                }
                message.error(`获取对象存储STS失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取对象存储STS失败`)
                console.log(`获取对象存储STS失败 ${err}`)
            }
        )
    }
    const getCloudOSSSTSByGeneratorModalOnCancel = () => {
        setOpenGetCloudOSSSTSByGenerator(false)
    }
    const getCloudOSSSTSByGeneratorOnClick = (record) => {
        setGeneratorUuidValue(record.key)
        setGeneratorBucketValue(record.bucketName)
        setGeneratorPlatformIdValue(record.platformId)
        setGeneratorPlatformValue(record.platformName)
        setOpenGetCloudOSSSTSByGenerator(true)
    }
    //
    const tabOnChange = (activeKey) => {
        switch (activeKey) {
            case 'bucketStsGenerator':
                listCloudOSSGeneratorOnClickHandler()
                break
            default:
                console.log(`activeKey: ${activeKey}`)
                break
        }
    }

    return (
        <>
            <span>云平台</span>
            <Select style={{width: 120}}
                    options={orgProductCloudPlatformOptions}
                    value={cloudPlatformId}
                    onSelect={cloudPlatformOnSelectHandler}/>
            <span>云平台账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformAccountOptions}
                    value={cloudPlatformAccountValue}
                    onSelect={cloudPlatformAccountOnSelectHandler}/>

            <span>子账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountOptions}
                    value={cloudPlatformSubAccountValue}
                    onSelect={cloudPlatformSubAccountOnSelectHandler}/>

            <span>AccessKey</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountAccessKeyOptions}
                    value={cloudPlatformSubAccountAccessKeyValue}
                    onSelect={cloudPlatformSubAccountAccessKeyHandler}/>
            <Space size={4}>
                <Button type={'primary'} onClick={listCloudOSSOnClickHandler}>查询</Button>
            </Space>

            <Tabs
                //defaultActiveKey={"1"}
                items={[
                    {
                        label: 'bucket列表',
                        key: 'bucket',
                        children: <Table columns={ossColumns} dataSource={cloudOSSDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'bucketStsGenerator',
                        key: 'bucketStsGenerator',
                        children: <Table columns={ossGeneratorColumns} dataSource={cloudOSSGeneratorDataSource}
                                         scroll={{x: true, y: 400}}/>
                    }
                ]}
                onChange={tabOnChange}
            />

            <Modal
                title={'添加对象存储Bucket STS生成者'}
                open={openCreateCloudOSSGenerator}
                onOk={createCloudOSSGeneratorOnOk}
                onCancel={createCloudOSSGeneratorOnCancel}
                okText={'添加'}
            >
                <span>bucket</span><Input disabled={true} id={'bucket'} value={bucketValue}/>
                <span>endpoint(参考文档)</span><Input id={'endpoint'}/>
                <span>arn(role中查找-非标准)</span><Input id={'arn'}/>
            </Modal>

            <Modal
                title={'获取STS'}
                open={openGetCloudOSSSTSByGenerator}
                onOk={getCloudOSSSTSByGeneratorModalOnOk}
                onCancel={getCloudOSSSTSByGeneratorModalOnCancel}
                okText={'生成'}
            >

                <span>云平台ID</span><Input disabled={true} id={'platformId'} value={generatorPlatformIdValue}/>
                <span>云平台</span><Input disabled={true} id={'platformId'} value={generatorPlatformValue}/>
                <span>GeneratorUuid</span><Input disabled={true} id={'generatorUuid'} value={generatorUuidValue}/>
                <span>GeneratorBucket</span><Input disabled={true} id={'generatorBucket'} value={generatorBucketValue}/>
                <span>relativeId(对象存储路径)</span><Input id={'relativeId'}/>
                <pre>{sts}</pre>
            </Modal>
        </>
    );
}