import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {
    AddWebServiceResource,
    DelWebServiceResource,
    ListWebServiceResource
} from '../../apis/product/webServiceResource';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';

export function WebServiceResourceRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['webServiceResource']}></LeftSider>
                    <IndexRightBody>
                        <WebServiceResourceContent></WebServiceResourceContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function WebServiceResourceContent() {
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)

    const envSelectOnChange = (value) => {
        setEnv(value)
        //getServiceUrlOnClick()
    }
    //
    const [protocolValue, setProtocolValue] = useState(2)
    const protocolOptions = [
        {
            label: 'http',
            value: 1
        },
        {
            label: 'https',
            value: 2
        }
    ]

    const protocolOnChange = (value) => {
        console.log(`value: ${value}`)
        setProtocolValue(value)
    }

    //
    const webServiceResourceColumns = [
        {
            title: 'protocol',
            dataIndex: 'protocol',
            key: 'protocol',
            render: (text) => {
                switch (text) {
                    case 1:
                        return "http"
                    case 2:
                        return "https"
                    default:
                        return "unKnow"
                }
            }
        },
        {
            title: 'serverName',
            dataIndex: 'serverName',
            key: 'serverName'
        },
        {
            title: 'healthCheckUri',
            dataIndex: 'healthCheckUri',
            key: 'healthCheckUri'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: 'ipPool',
            dataIndex: 'ipPool',
            key: 'ipPool'
        },
        {
            title: 'operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (<>

                    <Popconfirm
                        title={'是否要删除该web资源'}
                        onConfirm={() => delServiceResourceModalOnClick(record.key)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>)
            }
        }
    ]
    const [webServiceResourceDataSource, setWebServiceResourceDataSource] = useState([])

    //
    const listServiceResourceOnClick = () => {
        ListWebServiceResource({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const bizData = data.data
                    if (bizData === null) {
                        setWebServiceResourceDataSource([])
                        return;
                    }

                    const tmp = []
                    for (const d of bizData) {
                        tmp.push({
                            key: d.uuid,
                            protocol: d.protocol,
                            serverName: d.serverName,
                            ipPool: d.ipPool,
                            healthCheckUri: d.healthCheckUri,
                            describe: d.describe,
                            createTime: d.createTime,
                        })
                    }
                    setWebServiceResourceDataSource(tmp)
                    return
                }
                setWebServiceResourceDataSource([])
                message.error(`列出Web服务资源失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setWebServiceResourceDataSource([])
                message.error(`列出Web服务资源失败，${err}`)
            }
        )
    }

    useEffect(() => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        if (env === '') {
            return;
        }
        listServiceResourceOnClick()
    }, [env])


    //
    const [addServerResourceOpenModal, setAddServerResourceOpenModal] = useState(false)
    const addServiceResourceOnClick = () => {
        setAddServerResourceOpenModal(true)
    }

    const addServiceResourceModalOnOk = () => {

        const serverName = document.getElementById('serverName').value
        const ipPool = document.getElementById('ipPool').value
        const healthCheckUri = document.getElementById('healthCheckUri').value
        const describe = document.getElementById('describe').value

        AddWebServiceResource({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            protocol: protocolValue,
            serverName: serverName,
            ipPool: ipPool.split(','),
            healthCheckUri: healthCheckUri,
            describe: describe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setAddServerResourceOpenModal(false)
                    listServiceResourceOnClick()
                    return
                }
                message.error(`添加Web服务资源失败  ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加Web服务资源失败  ${err}`)
            }
        )

    }

    const addServiceResourceModalOnCancel = () => {
        setAddServerResourceOpenModal(false)
    }

    //
    const delServiceResourceModalOnClick = (uuid) => {
        DelWebServiceResource({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            uuid: uuid,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除 Web Service 资源成功`)
                    listServiceResourceOnClick()
                    return
                }
                message.error(`删除 Web Service 资源失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 Web Service 资源失败 ${err}`)
            }
        )
    }
    return (<>
        <span>https 资源会每日验证证书是否失效</span>
        <br/>
        <span>自动生成 Prometheus Http Discovery</span>

        <br/>
        <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChange}/>
        <Space size={4}>
            <Button onClick={listServiceResourceOnClick} type={'primary'}>查询</Button>
            <Button onClick={addServiceResourceOnClick} type={'primary'}>添加</Button>
            <Button type={'primary'}>Prometheus Http Discovery</Button>
        </Space>

        <Table columns={webServiceResourceColumns} dataSource={webServiceResourceDataSource}/>

        <Modal
            title={'添加Web服务资源'}
            open={addServerResourceOpenModal}
            onOk={addServiceResourceModalOnOk}
            onCancel={addServiceResourceModalOnCancel}
        >
            <span>协议</span>
            <Select
                options={protocolOptions}
                onChange={protocolOnChange}
                defaultValue={protocolValue}
                style={{
                    width: 400,
                }}
            />
            <br/>
            <span>主机名</span><Input id={'serverName'}/>
            <span>IP池</span><Input id={'ipPool'} placeholder={'使用英文逗号(,)进行分割'}/>
            <span>健康检查地址</span><Input id={'healthCheckUri'}/>
            <span>描述</span><Input id={'describe'}/>
        </Modal>
    </>)
}