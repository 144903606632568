import {
    CloudPlatformAccountApi,
    CloudPlatformSubAccountAccessKeyApi,
    CloudPlatformSubAccountApi,
    Del,
    Get,
    Post
} from '../common';

export function AddCloudAccount(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        name: `${props.name !== undefined ? props.name : ""}`,
        accountName: `${props.accountName !== undefined ? props.accountName : ""}`,
        email: `${props.email !== undefined ? props.email : ""}`,
        phoneNumber: props.phoneNumber,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: CloudPlatformAccountApi,
        params: params
    })
}

export function ListCloudAccount(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: `${props.platformId !== undefined ? props.platformId : ""}`,
    })
    return Get({
        url: `${CloudPlatformAccountApi}?${params}`
    })
}

export function DelCloudAccount(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        accountUuid: `${props.accountUuid !== undefined ? props.accountUuid : ""}`,
    })
    return Del({
        url: `${CloudPlatformAccountApi}?${params}`
    })
}

export function AddCloudSubAccount(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        accountUuid: `${props.accountUuid !== undefined ? props.accountUuid : ""}`,
        username: `${props.username !== undefined ? props.username : ""}`,
        phoneNumber: props.phoneNumber,
        email: `${props.email !== undefined ? props.email : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
            url: CloudPlatformSubAccountApi,
            params: params
        })
}

export function ListCloudSubAccount(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        accountUuid: `${props.accountUuid !== undefined ? props.accountUuid : ""}`,
    })
    return Get({
        url: `${CloudPlatformSubAccountApi}?${params}`,
    })
}

export function DelCloudSubAccount(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        subAccountUuid: `${props.subAccountUuid !== undefined ? props.subAccountUuid : ""}`,
    })
    return Del({
        url: `${CloudPlatformSubAccountApi}?${params}`,
    })
}

export function AddCloudSubAccountAccessKey(props) {
    const params = {
        subAccountUuid: props.subAccountUuid,
        accessKey: `${props.accessKey !== undefined ? props.accessKey : ""}`,
        secretKey: `${props.secretKey !== undefined ? props.secretKey : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: CloudPlatformSubAccountAccessKeyApi,
        params: params
    })
}

export function ListCloudSubAccountAccessKey(props) {
    const parmas = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        subAccountUuid: props.subAccountUuid,
    })

    return Get({
        url: `${CloudPlatformSubAccountAccessKeyApi}?${parmas}`,
    })
}

export function DelCloudSubAccountAccessKey(props) {
    const parmas = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        subAccountUuid: props.subAccountUuid,
        accessKeyUuid: props.accessKeyUuid,
    })
    return Del({
        url: `${CloudPlatformSubAccountAccessKeyApi}?${parmas}`,
    })
}