import {
    Del,
    Get,
    Post,
    ProductCodeRepoContainerImage,
    ProductCodeRepoSelfGitlabContainerImage,
    ProductCodeRepoGithubContainerImage, ProductCodeRepoGithubCiTemplate, ProductCodeRepoSelfGitlabCiTemplate
} from '../common';

export function ListCodeRepoContainerImage(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
    })
    return Get({
        url: `${ProductCodeRepoContainerImage}?${params}`,
    })
}

export function SelfGitlabCodeRepoContainerImage(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
        registryUuid: props.registryUuid,
        dockerfile: props.dockerfile,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabContainerImage,
        params: params
    })
}

export function GithubCodeRepoContainerImage(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
        registryUuid: props.registryUuid,
        dockerfile: props.dockerfile,
    }
    return Post({
        url: ProductCodeRepoGithubContainerImage,
        params: params
    })
}


export function GithubCodeRepoExecCiTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
        templateUuid: props.templateUuid,
    }
    return Post({
        url: ProductCodeRepoGithubCiTemplate,
        params: params
    })
}


export function SelfGitlabCodeRepoExecCiTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
        templateUuid: props.templateUuid,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabCiTemplate,
        params: params
    })
}