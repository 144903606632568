import {Button, Input, message, Modal, Select, Space, Table} from 'antd';
import {
    AddCloudAccount,
    AddCloudSubAccount,
    AddCloudSubAccountAccessKey, DelCloudAccount,
    DelCloudSubAccount, DelCloudSubAccountAccessKey,
    ListCloudAccount
} from '../../apis/cloud/account';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {useEffect, useState} from 'react';
import {CloudPlatformOptions, GetCloudPlatform} from '../../apis/common';
import {ConvertTimestamp} from '../common/date';
import {
    GetCloudPlatformAccountOptions,
    GetOrgProductCloudPlatformOptions,
    SetCloudPlatformSubAccountDataSource,
    SetListSubAccountAccessKeyDataSource
} from './common';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

const spanStyle = {
    width: 80,
    display: 'block'
}

const inputStyle = {
    width: 300
}

export function SubAccountRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudSubAccount']}></LeftSider>
                    <IndexRightBody>
                        <SubAccountContent></SubAccountContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function SubAccountContent() {
    const searchParams = new URLSearchParams(window.location.search);
    //const platformId = searchParams.get('platformId');
    const accountUuid = searchParams.get('accountUuid');
    // 云平台 Select Options
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    // 子账户 Columns
    const [subAccountDataSource, setSubAccountDataSource] = useState([]);
    const subAccountColumns = [
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: '手机号',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Space size={4}>
                            <Button type={'primary'}
                                    onClick={() => addSubAccountAccessKeyOnClickHandler(record)}
                            >
                                添加AccessKey</Button>
                            <Button type={'primary'}
                                    onClick={() => listSubAccountAccessKeyOnClickHandler(record)}
                            >
                                查看AccessKey</Button>
                            <Button
                                type={'primary'}
                                danger={true}
                                onClick={() => delSubAccountOnClickHandler(record)}
                            >
                                删除子账户</Button>
                        </Space>
                    </>
                );
            }
        }
    ]
    // 删除子账号 AccessKey
    const delSubAccountAccessKeyOnClickHandler = (record) => {
        console.log(`record key: ${record.key}`)
        DelCloudSubAccountAccessKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            subAccountUuid: record.subAccountUuid,
            accessKeyUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除子账号AccessKey成功`)
                    //listCloudSubAccountAccessKey(record.subAccountUuid)
                    SetListSubAccountAccessKeyDataSource(record.subAccountUuid, setListSubAccountAccessKeyDataSource)
                    return
                }
                message.error(`删除子账号AccessKey失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除子账号AccessKey失败`)
                console.log(`删除子账号AccessKey失败 ${err}`)
            }
        )
    }

    // 列出子账户 AccessKey
    const [subAccountAccessKeyOpenModal, setSubAccountAccessKeyOpenModal] = useState(false)
    const [listSubAccountAccessKeyDataSource, setListSubAccountAccessKeyDataSource] = useState([])
    const listSubAccountAccessKeyColumns = [
        {
            title: 'accessKey',
            dataIndex: 'accessKey',
            key: 'accessKey'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Button
                            type={'primary'}
                            danger={true}
                            onClick={() => delSubAccountAccessKeyOnClickHandler(record)}
                        >删除</Button>
                    </>
                );
            }
        }
    ]
    const listSubAccountAccessKeyOnClickHandler = (record) => {
        setSubAccountAccessKeyOpenModal(true)
        SetListSubAccountAccessKeyDataSource(record.key, setListSubAccountAccessKeyDataSource)
    }
    const listSubAccountAccessKeyModalOnCancel = () => {
        setSubAccountAccessKeyOpenModal(false)
    }
    const listSubAccountAccessKeyModalOnOk = () => {
        setSubAccountAccessKeyOpenModal(false)
    }

    // 云平台账户
    //const [cloudPlatformAccountOptions, setCloudPlatformAccountOptions] = useState([])
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState(accountUuid)
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)


    // 删除云平台子账户
    const delSubAccountOnClickHandler = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        DelCloudSubAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            subAccountUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    listCloudPlatformSubAccountOnClickHandler()
                    message.info(`删除子账户成功`)
                    return
                }
                message.error(`删除子账户失败， ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除子账户失败`)
                console.log(`删除子账户失败, ${err}`)
            }
        )
    }

    // 列出云平台子账户
    const listCloudPlatformSubAccountOnClickHandler = () => {
        SetCloudPlatformSubAccountDataSource(cloudPlatformAccountValue, setSubAccountDataSource)
    }
    useEffect(() => {
        SetCloudPlatformSubAccountDataSource(cloudPlatformAccountValue, setSubAccountDataSource)
    }, [cloudPlatformAccountValue])
    // 添加云平台子账户 AccessKey
    const [addSubAccountAccessKeySubAccountUuid, setAddSubAccountAccessKeySubAccountUuid] = useState('')
    const [addSubAccountAccessKeyOpenModal, setAddSubAccountAccessKeyOpenModal] = useState(false)
    const addSubAccountAccessKeyModalOnCancel = () => {
        setAddSubAccountAccessKeyOpenModal(false)
    }
    const addSubAccountAccessKeyOnClickHandler = (record) => {
        setAddSubAccountAccessKeySubAccountUuid(record.key)
        setAddSubAccountAccessKeyOpenModal(true)
    }
    const addSubAccountAccessKeyModalOnOk = () => {
        const subAccountUuid = document.getElementById('subAccountUuid').value
        const accessKey = document.getElementById('accessKey').value
        const secretKey = document.getElementById('secretKey').value
        const describe = document.getElementById('describe').value

        AddCloudSubAccountAccessKey({
            subAccountUuid: subAccountUuid,
            accessKey: accessKey,
            secretKey: secretKey,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加子账户AccessKey成功`)
                    setAddSubAccountAccessKeyOpenModal(false)
                    return
                }
                message.error(`添加子账户AccessKey失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加子账户AccessKey失败`)
                console.log(`添加子账户AccessKey失败 ${err}`)
            }
        )
    }

    return (
        <>
            <span>云平台</span>
            <span>云平台</span>
            <Select style={{width: 120}}
                    options={orgProductCloudPlatformOptions}
                    value={cloudPlatformId}
                    onSelect={cloudPlatformOnSelectHandler}/>

            <span>云平台账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformAccountOptions}
                    value={cloudPlatformAccountValue}
                    onSelect={cloudPlatformAccountOnSelectHandler}/>
            <Button type={'primary'} onClick={listCloudPlatformSubAccountOnClickHandler}>查询</Button>

            <Table columns={subAccountColumns} dataSource={subAccountDataSource}/>

            <Modal
                title={'添加子账户AccessKey'}
                open={addSubAccountAccessKeyOpenModal}
                onOk={addSubAccountAccessKeyModalOnOk}
                onCancel={addSubAccountAccessKeyModalOnCancel}
                destroyOnClose={true}
                maskClosable={false}
            >
                <span>SubAccountUuid</span>
                <Input id={'subAccountUuid'}
                       defaultValue={addSubAccountAccessKeySubAccountUuid}
                       disabled={true}
                />
                <span>AccessKey</span>
                <Input id={'accessKey'}/>
                <span>AccessSecretKey</span>
                <Input id={'secretKey'}/>
                <span>描述</span>
                <Input id={'describe'}/>
            </Modal>

            <Modal
                title={'子账户AccessKey信息'}
                open={subAccountAccessKeyOpenModal}
                onOk={listSubAccountAccessKeyModalOnOk}
                onCancel={listSubAccountAccessKeyModalOnCancel}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Table
                    columns={listSubAccountAccessKeyColumns}
                    dataSource={listSubAccountAccessKeyDataSource}
                    scroll={{x: true, y: 400}}
                />
            </Modal>
        </>
    )
}

export function CloudAccountRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudAccount']}></LeftSider>
                    <IndexRightBody>
                        <AccountContent></AccountContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function AccountContent() {
    const accountColumns = [
        {
            title: '云平台',
            dataIndex: 'platform',
            key: 'platform',
            render: function (text) {
                return GetCloudPlatform(text)
            }
        },
        {
            title: '账户名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Button
                            type={'primary'}
                            size={'small'}
                            danger={true}
                            onClick={() => delCloudPlatformAccountOnClickHandler(record)}>删除</Button>
                        <Button
                            type={'primary'}
                            size={'small'}
                            onClick={() => addCloudPlatformSubAccountOnClickHandler(record)}
                        >添加子账户</Button>
                        <Button
                            type={'primary'}
                            size={'small'}
                            href={`/cloud/cloudSubAccount`}
                        >查看子账户</Button>
                    </>
                );
            }
        }
    ]
    // 删除云平台账户
    const delCloudPlatformAccountOnClickHandler = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        DelCloudAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            accountUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除云平台账户成功`)
                    listCloudPlatformAccountHandler({})
                    return
                }
                message.error(`删除云平台账户失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除云平台账户失败`)
                console.log(`删除云平台账户失败 ${err}`)
            }
        )
    }
    // 添加云平台子账户
    const [addSubAccountByAccountUuid, setAddSubAccountByAccountUuid] = useState('')
    const [addCloudPlatformSubAccountOpenModal, setAddCloudPlatformSubAccountOpenModal] = useState(false)
    const addCloudPlatformSubAccountOnClickHandler = (record) => {
        setAddSubAccountByAccountUuid(record.key)
        setAddCloudPlatformSubAccountOpenModal(true)
    }
    const addCloudPlatformSubAccountModalOnCancelHandler = () => {
        setAddCloudPlatformSubAccountOpenModal(false)
    }
    const addCloudPlatformSubAccountModalOnOkHandler = () => {
        const accountUuid = document.getElementById('accountUuid').value
        const username = document.getElementById('username').value
        const phoneNumber = document.getElementById('phoneNumber').value
        const email = document.getElementById('email').value
        const describe = document.getElementById('describe').value
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        AddCloudSubAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            accountUuid: accountUuid,
            username: username,
            phoneNumber: parseInt(phoneNumber, 10),
            email: email,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加子账户成功`)
                    setAddCloudPlatformSubAccountOpenModal(false)
                    return
                }
                message.error(`添加子账户失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加子账户失败`)
                console.log(`添加子账户失败, ${err}`)
                return
            }
        )
    }

    // 添加云平台账户
    const [addCloudPlatformAccountPlatformId, setAddCloudPlatformAccountPlatformId] = useState(CloudPlatformOptions[0].value)
    const [addCloudPlatformAccountOpenModal, setAddCloudPlatformAccountOpenModal] = useState(false)
    const addCloudPlatformAccountPlatformIdOnSelect = (value) => {
        setAddCloudPlatformAccountPlatformId(value)
    }
    const addCloudPlatformAccountOnClickHandler = () => {
        setAddCloudPlatformAccountOpenModal(true)
    }
    const addCloudPlatformAccountModalOnCancelHandler = () => {
        setAddCloudPlatformAccountOpenModal(false)
    }
    const addCloudPlatformAccountModalOnOkHandler = () => {
        const name = document.getElementById('name').value
        const accountName = document.getElementById('accountName').value
        const email = document.getElementById('email').value
        const phoneNumber = document.getElementById('phoneNumber').value
        const describe = document.getElementById('describe').value

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        AddCloudAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: parseInt(addCloudPlatformAccountPlatformId, 10),
            name: name,
            accountName: accountName,
            email: email,
            phoneNumber: parseInt(phoneNumber, 10),
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加账户成功`)
                    listCloudPlatformAccountHandler({})
                    setAddCloudPlatformAccountOpenModal(false)
                    return
                }
                message.error(`添加账户失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加账户失败`)
            }
        )
    }

    // 列出云平台账户
    const [cloudPlatformAccountDataSource, setCloudPlatformAccountDataSource] = useState([])
    const listCloudPlatformAccountHandler = (props) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListCloudAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: props.platformId
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const accounts = data.account
                    if (accounts === null) {
                        return;
                    }
                    let tempAccountDataSource = []
                    for (const account of accounts) {
                        tempAccountDataSource.push({
                            key: account.uuid,
                            orgUuid: account.orgUuid,
                            productUuid: account.productUuid,
                            ownerUuid: account.ownerUuid,
                            name: account.name,
                            platform: account.platformId,
                            account_name: account.accountName,
                            email: account.email,
                            phone_number: account.phoneNumber,
                            describe: account.describe,
                            createTime: account.createTime
                        })
                    }
                    setCloudPlatformAccountDataSource(tempAccountDataSource)
                    return
                }
                message.error(`列出云平台账号失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error('列出云平台账户失败')
                console.log(`列出云平台账户失败 ${err}`)
            }
        )
    }
    useEffect(() => {
        listCloudPlatformAccountHandler({})
    }, [])

    //
    return (
        <>
            <Button type={'primary'} onClick={addCloudPlatformAccountOnClickHandler}>添加云账号</Button>
            <Table columns={accountColumns} dataSource={cloudPlatformAccountDataSource}/>

            <Modal
                title={'添加云平台子账户'}
                maskClosable={true}
                destroyOnClose={true}
                open={addCloudPlatformSubAccountOpenModal}
                onOk={addCloudPlatformSubAccountModalOnOkHandler}
                onCancel={addCloudPlatformSubAccountModalOnCancelHandler}
            >
                <Space>
                    <span style={spanStyle}>账户Uuid</span>
                    <Input id={'accountUuid'} style={inputStyle} disabled={true}
                           defaultValue={addSubAccountByAccountUuid}/>
                </Space>
                <Space>
                    <span style={spanStyle}>子账户名</span>
                    <Input id={'username'} style={inputStyle}/>
                </Space>
                <Space>
                    <span style={spanStyle}>手机号</span>
                    <Input type={'number'} id={'phoneNumber'} style={inputStyle}/>
                </Space>
                <Space>
                    <span style={spanStyle}>邮箱</span>
                    <Input id={'email'} style={inputStyle}/>
                </Space>
                <Space>
                    <span style={spanStyle}>描述</span>
                    <Input id={'describe'} style={inputStyle}/>
                </Space>
            </Modal>

            <Modal
                title="添加云平台账号"
                maskClosable={true}
                destroyOnClose={true}
                open={addCloudPlatformAccountOpenModal}
                onOk={addCloudPlatformAccountModalOnOkHandler}
                onCancel={addCloudPlatformAccountModalOnCancelHandler}
            >
                <Space>
                    <span style={spanStyle}>云平台:</span>
                    <Select
                        style={{width: 240}}
                        options={CloudPlatformOptions}
                        value={addCloudPlatformAccountPlatformId}
                        onSelect={addCloudPlatformAccountPlatformIdOnSelect}
                    />
                </Space>
                <Space>
                    <span style={spanStyle}>名称(别名):</span>
                    <Input style={{width: 240}} id={'name'}/>
                </Space>
                <Space>
                    <span style={spanStyle}>账户名:</span>
                    <Input style={{width: 240}} id={'accountName'}/>
                </Space>
                <Space>
                    <span style={spanStyle}>Email:</span>
                    <Input style={{width: 240}} id={'email'}/>
                </Space>
                <Space>
                    <span style={spanStyle}>手机号:</span>
                    <Input style={{width: 240}} id={'phoneNumber'} type={'number'}/>
                </Space>
                <Space>
                    <span style={spanStyle}>描述:</span>
                    <Input style={{width: 240}} id={'describe'}/>
                </Space>
            </Modal>
        </>
    )
}
