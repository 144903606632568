import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, message, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';
import {ListService} from '../../apis/kubernetes/service';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';

export function K8SServiceRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['service']}></LeftSider>
                    <IndexRightBody>
                        <ServiceContent></ServiceContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function ServiceContent() {
    const k8sServiceColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'clusterIP',
            dataIndex: 'clusterIP',
            key: 'clusterIP'
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: () => {
                return <>
                <Button type={'primary'}>更新</Button>
                    <Button type={'primary'} danger={true}>删除</Button>
                </>
            }
        },
    ]
    const [k8sServiceDataSource, setK8sServiceDataSource] = useState([])


    // env
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    // cluster
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    // namespace
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === "") {
            return
        }
        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])


    //
    const getKubernetesServiceOnClick = () => {
        ListService({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const services = data.service
                    if (services === null) {
                        setK8sServiceDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const s of services) {
                        tmp.push({
                            key: s.resourceVersion,
                            name: s.name,
                            namespace: s.namespace,
                            resourceVersion: s.resourceVersion,
                            annotations: s.annotations,
                            clusterIP: s.clusterIP,
                            sessionAffinity: s.sessionAffinity,
                            type: s.type,
                            labels: s.labels,
                            ports: s.ports,
                        })
                    }
                    setK8sServiceDataSource(tmp)
                    return
                }
                message.error(`列出 K8S 集群 Service 失败 ${errMsg}`)
                setK8sServiceDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出 K8S 集群 Service 失败`)
                console.log(`列出 K8S 集群 Service 失败 ${err}`)
                setK8sServiceDataSource([])
            }
        )
    }

    //
    const delKubernetesServiceOnClick = (namespace, name) => {

    }

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={getKubernetesServiceOnClick}>查询</Button>
            </Space>

            <Table columns={k8sServiceColumns} dataSource={k8sServiceDataSource}/>
        </>
    );
}