import {Get, Post, Del, WorkSpaceFaultApi, WorkSpaceFaultStreamApi} from '../common';

export function CreateFault(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        title: props.title,
        level: props.level,
        startTime: props.startTime,
        endTime: props.endTime,
        summary: props.summary,
        reason: props.reason,
        optimize: props.optimize,
    }
    return Post({
        url: `${WorkSpaceFaultApi}`,
        params: params,
    })
}

export function ListFault(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        titleKeyword: props.titleKeyword,
        searchStartTime: props.searchStartTime,
        searchEndTime: props.searchEndTime,
    })
    return Get({
        url: `${WorkSpaceFaultApi}?${params}`,
    })
}

export function AddFaultStream(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        faultUuid: props.faultUuid,
        startTime: props.startTime,
        endTime: props.endTime,
        do: props.do,
    }
    return Post({
        url: `${WorkSpaceFaultStreamApi}`,
        params: params,
    })
}

export function ListFaultStream(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        faultUuid: props.faultUuid,
    })
    return Get({
        url: `${WorkSpaceFaultStreamApi}?${params}`,
    })
}

export function DelFaultStream(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        faultUuid: props.faultUuid,
        faultStreamUuid: props.faultStreamUuid,
    })
    return Del({
        url: `${WorkSpaceFaultStreamApi}?${params}`,
    })
}
