import zhCN from 'antd/locale/zh_CN';
import {
    Button,
    ConfigProvider,
    Input,
    Layout,
    Modal,
    Select,
    Space,
    DatePicker,
    message,
    Table,
    Popconfirm
} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {CloseTodo, CreateTodo, DelTodo, ListTodo} from '../../apis/workSpace/todoList';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {ConvertTimestamp} from '../common/date';
import React from 'react';

const spanStyle = {
    display: 'inline-block',
    width: 80
}

export default function TodoListRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['workSpace']} defaultSelectedKeys={['todo']}></LeftSider>
                    <IndexRightBody>
                        <TodoListContent></TodoListContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


export function TodoListContent() {
    //
    const todoListColumns = [
        {
            title: 'priority',
            dataIndex: 'priority',
            key: 'priority',
            render: (text) => {
                switch (text) {
                    case 1:
                        return '高'
                    case 2:
                        return '中'
                    case 3:
                        return '低'
                    default:
                        return '未知'
                }
            }
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'content'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                switch (text) {
                    case 1:
                        return '已完成'
                    case -1:
                        return '执行中'
                    default:
                        return '未知'
                }
            }
        },
        {
            title: '结束时间',
            dataIndex: 'overTime',
            key: 'overTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe',
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {

                return <>

                    {
                        record.status === -1 && (
                            <Space>
                                <Popconfirm
                                    title={'删除待办事宜'}
                                    description={'确认是否删除待办事宜'}
                                    onConfirm={() => delTodo(record)}
                                >
                                    <Button type={'primary'}>删除</Button>
                                </Popconfirm>
                                <Button type={'primary'} onClick={() => closeTodoOnClick(record)}>关闭</Button>
                            </Space>
                        )
                    }
                </>
            }
        }
    ]
    const [todoListDataSource, setTodoListDataSource] = useState([])
    //
    const todoStatusOptions = [
        {
            value: 0,
            label: '所有状态'
        },
        {
            value: 1,
            label: '已完成'
        },
        {
            value: -1,
            label: '执行中'
        }
    ]
    const [todoStatus, setTodoStatus] = useState(-1)
    const todoStatusOnSelectHandler = (value) => {
        setTodoStatus(value)
    }

    const [todoPriority, setTodoPriority] = useState(2)
    const todoPriorityOptions = [
        {
            value: 1,
            label: '高'
        },
        {
            value: 2,
            label: '中'
        },
        {
            value: 3,
            label: '低'
        }
    ]
    const todoPriorityOnSelectHandler = (value) => {
        setTodoPriority(value)
    }

    const listTodo = () => {
        ListTodo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            status: todoStatus
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const todos = data.todo
                    if (todos === null) {
                        setTodoListDataSource([])
                        return
                    }
                    let tmp = []
                    for (const todo of todos) {
                        tmp.push({
                            key: todo.uuid,
                            priority: todo.priority,
                            content: todo.content,
                            status: todo.status,
                            overTime: todo.overTime,
                            createTime: todo.createTime,
                            describe: todo.describe,
                        })
                    }
                    setTodoListDataSource(tmp)
                    return
                }
                message.error(`列出待办事宜失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setTodoListDataSource([])
                console.log(`列出待办事宜失败 ${err}`)
                message.error(`列出待办事宜失败`)
            }
        )
    }

    //
    const [createTodoDeadlineTime, setCreateTodoDeadlineTime] = useState(dayjs().add(+3, 'd'))
    const createTodoDeadlineTimeOnChange = (value, dateString) => {
        setCreateTodoDeadlineTime(value)
    };
    const createTodoDeadlineTimeOnOk = (value) => {
        setCreateTodoDeadlineTime(value)
    };
    const [createTodoOpenModal, setCreateTodoOpenModal] = useState(false)
    const createTodoOnClick = () => {
        setCreateTodoOpenModal(true)
    }
    const createTodoModalOnOk = () => {
        const deadlineTime = Math.floor(createTodoDeadlineTime.toDate().getTime() / 1000)
        const content = document.getElementById('content').value
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        CreateTodo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            priority: todoPriority,
            overTime: deadlineTime,
            content: content
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateTodoOpenModal(false)
                    listTodo()
                    return
                }
                message.error(`创建待办事宜失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`创建待办事宜失败 ${err}`)
                message.error(`创建待办事宜失败`)
            }
        )
    }
    const createTodoModalOnCancel = () => {
        setCreateTodoOpenModal(false)
    }
    //
    const [closeTodoUuid, setCloseTodoUuid] = useState('')
    const [closeTodoOpenModal, setCloseTodoOpenModal] = useState(false)
    const closeTodoOnClick = (record) => {
        setCloseTodoUuid(record.key)
        setCloseTodoOpenModal(true)
    }
    const closeTodoOnOk = () => {
        const describe = document.getElementById('describe').value
        const todoUuid = document.getElementById('todoUuid').value

        if (! VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        CloseTodo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            todoUuid: todoUuid,
            describe: describe,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setCloseTodoOpenModal(false)
                    listTodo()
                    message.info(`关闭待办事宜成功`)
                    return
                }
                message.error(`关闭待办事宜失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`关闭待办事宜失败 ${err}`)
                message.error(`关闭待办事宜失败`)
            }
        )
    }
    const closeTodoOnCancel = () => {
        setCloseTodoOpenModal(false)
    }
    //
    const delTodo = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        DelTodo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            todoUuid: record.key,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除待办事宜成功`)
                    listTodo()
                    return
                }
                message.error(`删除待办事宜失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`删除待办事宜失败 ${err}`)
                message.error(`删除待办事宜失败`)
            }
        )
    }

    //
    useEffect(() => {
        listTodo()
    }, [])

    return (
        <>
            <Space>
                <span>工单状态</span>
                <Select
                    options={todoStatusOptions}
                    value={todoStatus}
                    onSelect={todoStatusOnSelectHandler}
                />

                <Button type={'primary'} onClick={listTodo}>
                    查询
                </Button>

                <Button type={'primary'} onClick={createTodoOnClick}>
                    添加待办
                </Button>
            </Space>

            <Table columns={todoListColumns} dataSource={todoListDataSource}/>

            <Modal
                title={'添加待办事宜'}
                open={createTodoOpenModal}
                onOk={createTodoModalOnOk}
                onCancel={createTodoModalOnCancel}
            >

                <div style={{display: 'flex'}}>
                    <span style={spanStyle}>优先级</span>
                    <Select
                        options={todoPriorityOptions}
                        value={todoPriority}
                        onSelect={todoPriorityOnSelectHandler}
                    />
                </div>

                <div style={{display: 'flex'}}>
                    <span style={spanStyle}>最后时间</span>

                    <DatePicker showTime onChange={createTodoDeadlineTimeOnChange} onOk={createTodoDeadlineTimeOnOk}
                                value={createTodoDeadlineTime}/>
                </div>

                <div style={{display: 'flex'}}>
                    <span style={spanStyle}>待办内容</span>
                </div>
                <Input.TextArea id={'content'}/>
            </Modal>

            <Modal
            title={'关闭待办事宜'}
            open={closeTodoOpenModal}
            onOk={closeTodoOnOk}
            onCancel={closeTodoOnCancel}
            >
                <span>Uuid</span><Input disabled={true} id={'todoUuid'} value={closeTodoUuid}/>
                <span>描述</span><Input.TextArea id={'describe'}/>
            </Modal>
        </>
    );
}
