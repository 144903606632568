import {Del, Get, Post, ProductContainerImageApi} from '../common';

export function AddContainerImage(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: props.name,
        imageUrl: props.imageUrl,
        describe: props.describe,
    }
    return Post({
        url: ProductContainerImageApi,
        params: params,
    })
}

export function ListContainerImage(props) {
    const params = new URLSearchParams({
            orgUuid: props.orgUuid,
            productUuid: props.productUuid,
            keyword: `${props.keyword !== undefined ? props.keyword : ""}`,
        })
    return Get({
        url: `${ProductContainerImageApi}?${params}`,
    })
}

export function DelContainerImage(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        containerImageUuid: `${props.containerImageUuid !== undefined ? props.containerImageUuid : ""}`,
    })
    return Del({
        url: `${ProductContainerImageApi}?${params}`,
    })
}