import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {DelMonitorToken, GenerateMonitorToken, ListMonitorToken} from '../../apis/monitor/token';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {CopyOutlined} from '@ant-design/icons';
import copy from 'copy-to-clipboard';


export function MonitorTokenRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['monitor']} defaultSelectedKeys={['monitorToken']}></LeftSider>
                    <IndexRightBody>
                        <MonitorTokenContent></MonitorTokenContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function MonitorTokenContent() {
    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)

    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }
    //
    const monitorTokenColumns = [
        {
            title: 'token',
            dataIndex: 'token',
            key: 'token'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: '拷贝参数',
            dataIndex: 'copyParams',
            key: 'copyParams',
            render: (_, record) => {
                const params = `orgUuid=${OrgUuid}&productUuid=${OrgProductUuid}&productEnvUuid=${env}&token=${record.token}`
                return (
                    <>
                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => copy(params)}>复制</Button>
                    </>
                );
            },
        },
        {
            title: '参数',
            dataIndex: 'params',
            key: 'params',
            render: (_, record) => {
                return (
                    <>
                        <pre>token: {record.token}</pre>
                        <pre>orgUuid: {OrgUuid}</pre>
                        <pre>orgProductUuid: {OrgProductUuid}</pre>
                        <pre>orgProductEnvUuid: {env}</pre>
                    </>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (<>
                    <Popconfirm
                        title={"删除该监控token"}
                        onConfirm={() => delMonitorAccessToken(record.key)}>
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>)
            }
        }
    ]
    const [monitorTokenDataSource, setMonitorTokenDataSource] = useState([])
    //
    const listMonitorAccessTokenOnClick = () => {
        ListMonitorToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {

                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        return;
                    }

                    let tmp = []
                    for (const i of d) {
                        tmp.push({
                            key: i.uuid,
                            token: i.token,
                            createTime: i.createTime,
                            describe: i.describe,
                        })
                    }
                    setMonitorTokenDataSource(tmp)
                    return
                }
                setMonitorTokenDataSource([])
                message.error(`列出监控失败， ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出监控Token失败, ${err}`)
                setMonitorTokenDataSource([])
            }
        )
    }

    //

    const [generateMonitorAccessTokenOpenModal, setGenerateMonitorAccessTokenOpenModal] = useState(false)
    const generateMonitorAccessTokenOnClick = () => {
        setGenerateMonitorAccessTokenOpenModal(true)
    }
    const generateMonitorAccessTokenOnOk = () => {
        const describe = document.getElementById('describe').value
        GenerateMonitorToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setGenerateMonitorAccessTokenOpenModal(false)
                    message.info(`生成监控访问Token成功`)
                    listMonitorAccessTokenOnClick()
                    return
                }
                message.error(`生成监控访问Token失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`生成监控访问Token失败 ${err}`)
            }
        )
    }
    const generateMonitorAccessTokenOnCancel = () => {
        setGenerateMonitorAccessTokenOpenModal(false)
    }

    //
    const delMonitorAccessToken = (uuid) => {
        DelMonitorToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            uuid: uuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除监控Token成功`)
                    listMonitorAccessTokenOnClick()
                    return
                }
                message.error(`删除监控Token失败 ${errMsg}`)

            }
        ).catch(
            err => {
                message.error(`删除监控Token失败 ${err}`)
            }
        )
    }

    useEffect(() => {
        if (env === '') {
            return
        }
        listMonitorAccessTokenOnClick()
    }, [env])
    return (
        <>
            <Space>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={listMonitorAccessTokenOnClick}>列出Token</Button>
                <Button type={'primary'} onClick={generateMonitorAccessTokenOnClick}>生成Token</Button>
            </Space>

            <Table columns={monitorTokenColumns} dataSource={monitorTokenDataSource}/>

            <Modal
                title={'生成监控访问Token'}
                open={generateMonitorAccessTokenOpenModal}
                onOk={generateMonitorAccessTokenOnOk}
                onCancel={generateMonitorAccessTokenOnCancel}
                destroyOnClose={true}
            >
                <span>描述</span><Input id={'describe'}/>
            </Modal>
        </>
    );
}