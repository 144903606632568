import {CloudPlatformRegionApi, Del, Get, Post} from '../common';

export function AddOrgCloudPlatformRegion(props) {
    const params = {
        orgUuid: props.orgUuid,
        platformId: props.platformId,
        region: props.region,
        regionName: props.regionName,
    }
    return Post({
        url: CloudPlatformRegionApi,
        params: params,
    })
}

export function ListOrgCloudPlatformRegion(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        platformId: props.platformId,
    })
    return Get({
        url: `${CloudPlatformRegionApi}?${params}`,
    })
}


export function DelOrgCloudPlatformRegion(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        regionUuid: props.regionUuid,
    })
    return Del({
        url: `${CloudPlatformRegionApi}?${params}`,
    })
}