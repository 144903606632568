import {Button, ConfigProvider, Input, Layout, List, message} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect} from 'react';
import {useState} from 'react'
import {CreateQuestion, GetQuestionAnswer, GetQuestionHis} from '../../apis/robot/question';
import {OrgUuid, VerifyOrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

export function QuestionAndAnswerRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['robot']} defaultSelectedKeys={['question']}></LeftSider>
                    <IndexRightBody>
                        <QuestionAndAnswer></QuestionAndAnswer>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function QuestionAndAnswer() {
    const [questionList, setQuestionList] = useState([]);
    const [intervalId, setIntervalId] = useState(0)
    const [closeInterval, setCloseInterval] = useState(false)

    useEffect(() => {
        if (closeInterval && intervalId) {
            clearInterval(intervalId)
        }
    }, [closeInterval])

    useEffect(() => {
        if (! VerifyOrgUuid()) {
            return
        }
        GetQuestionHis({
            orgUuid: OrgUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const questions = data.questions
                    if (questions === null) {
                        setQuestionList([]);
                        return;
                    }
                    let tmp = []
                    for (const question of questions) {
                        const result = question.result
                        if (result !== 0) {
                            tmp.push({
                                question: question.question,
                                answer: question.answer,
                                createTime: question.createTime,
                            })
                        }
                    }
                    setQuestionList(tmp);
                    return
                }
                setQuestionList([])
                message.error(`获取历史问答记录失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setQuestionList([])
                message.error(`获取历史问答记录失败`)
                console.log(`获取历史问答记录失败 ${err}`)
            }
        )
    }, [])

    //
    const submitQuestionOnClick = () => {
        const question = document.getElementById('question').value

        if (question === "") {
            message.error('提交的问题内容不能为空！')
            return
        }

        CreateQuestion({
            orgUuid: OrgUuid,
            question: question,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const taskId = data.taskId
                    message.info(`问题提交成功`)

                    setCloseInterval(false)
                    const interId = setInterval(() => {
                        getQuestionAnswer(taskId)
                    }, 3000)
                    setIntervalId(interId)
                    return
                }
                message.error(`问题提交失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`问题提交失败`)
                console.log(`问题提交失败 ${err}`)
            }
        )
    }
    //
    const getQuestionAnswer = (taskId) => {
        GetQuestionAnswer({
            orgUuid: OrgUuid,
            taskId: taskId,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const question = data.question
                const answer = data.answer
                const result = data.result
                const createTime = data.createTime
                if (errCode === 0) {
                    if (result !== 0) {
                        setQuestionList([...questionList, {
                            question: question,
                            answer: answer,
                            createTime: createTime,
                        }])
                        setCloseInterval(true)
                    }
                    return
                }
                console.log(`获取问题答案失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`获取问题答案失败 ${err}`)
            }
        )
    }

    return (
        <>
            <Input.TextArea placeholder={"请输入你想问的问题"} id={"question"} />
            <Button type={'primary'} onClick={submitQuestionOnClick}>
                提交
            </Button>

            <List
                dataSource={questionList}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.question}
                            description={ConvertTimestamp(item.createTime)}
                        />
                        <pre>{item.answer}</pre>
                    </List.Item>
                )}
            />
        </>
    );
}
