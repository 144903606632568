import {Button, ConfigProvider, Input, Layout, Modal, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useState} from 'react';

export function MessageQueueMiddlewareRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['messageQueueMiddleware']}></LeftSider>
                    <IndexRightBody>
                        <MiddlewareContent></MiddlewareContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function MiddlewareContent() {
    const [defaultActiveKey, setDefaultActiveKey] = useState('RocketMQ')

    const messageQueueMiddlewareOnChange = (activeKey) => {
        switch (activeKey) {
            case 'RocketMQ':
                setDefaultActiveKey('RocketMQ')
                listRocketMQInstance()
                break
            case 'RabbitMQ':
                setDefaultActiveKey('RabbitMQ')
                listRabbitMQInstance()
                break
            case 'Kafka':
                setDefaultActiveKey('Kafka')
                listKafkaMQInstance()
                break
            default:
                break
        }
    }

    // rocketmq
    const rocketMQColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [rocketMQDataSource, setRocketMQDataSource] = useState([])

    const [createRocketMQInstanceOpenModal, setCreateRocketMQInstanceOpenModal] = useState(false)

    const createRocketMQInstanceOnClick = () => {
        setCreateRocketMQInstanceOpenModal(true)
    }

    const createRocketMQInstanceOnOk = () => {
        setCreateRocketMQInstanceOpenModal(false)
    }

    const createRocketMQInstanceOnCancel = () => {
        setCreateRocketMQInstanceOpenModal(false)
    }

    const listRocketMQInstance = () => {

    }
    //
    const rabbitMQColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [rabbitMQDataSource, setRabbitMQDataSource] = useState([])


    const [createRabbitMQInstanceOpenModal, setCreateRabbitMQInstanceOpenModal] = useState(false)

    const createRabbitMQInstanceOnClick = () => {
        setCreateRabbitMQInstanceOpenModal(true)
    }

    const createRabbitMQInstanceOnOk = () => {
        setCreateRabbitMQInstanceOpenModal(false)
    }

    const createRabbitMQInstanceOnCancel = () => {
        setCreateRabbitMQInstanceOpenModal(false)
    }

    const listRabbitMQInstance = () => {

    }
    //
    const kafkaMQColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [kafkaMQDataSource, setKafkaMQDataSource] = useState([])


    const [createKafkaMQInstanceOpenModal, setCreateKafkaMQInstanceOpenModal] = useState(false)

    const createKafkaMQInstanceOnClick = () => {
        setCreateKafkaMQInstanceOpenModal(true)
    }

    const createKafkaMQInstanceOnOk = () => {
        setCreateKafkaMQInstanceOpenModal(false)
    }

    const createKafkaMQInstanceOnCancel = () => {
        setCreateKafkaMQInstanceOpenModal(false)
    }

    const listKafkaMQInstance = () => {

    }

    return (<>
        <Tabs
            type="card"
            onChange={messageQueueMiddlewareOnChange}
            defaultActiveKey={defaultActiveKey}
            items={[
                {
                    label: 'RocketMQ',
                    key: 'RocketMQ',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/apache/rocketmq'} target={'_blank'}>Github</a></Button>
                            <Button type={'primary'} onClick={createRocketMQInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={rocketMQColumns}
                               dataSource={rocketMQDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'RabbitMQ',
                    key: 'RabbitMQ',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/rabbitmq/rabbitmq-server'} target={'_blank'}>Github</a></Button>
                            <Button type={'primary'} onClick={createRabbitMQInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={rabbitMQColumns}
                               dataSource={rabbitMQDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'Kafka',
                    key: 'Kafka',
                    children: <>

                        <Space>
                            <Button type={'primary'}><a href={'https://kafka.apache.org/'} target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createKafkaMQInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={kafkaMQColumns}
                               dataSource={kafkaMQDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
            ]}
        />


        <Modal
            title={'添加RocketMQ中间件实例'}
            open={createRocketMQInstanceOpenModal}
            onOk={createRocketMQInstanceOnOk}
            onCancel={createRocketMQInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRocketMQInstanceName'}/>
            <span>主机名</span><Input id={'createRocketMQInstanceHostname'}/>
            <span>端口</span><Input id={'createRocketMQInstancePort'}/>
            <span>密码</span><Input id={'createRocketMQInstancePasswd'}/>
        </Modal>


        <Modal
            title={'添加RabbitMQ中间件实例'}
            open={createRabbitMQInstanceOpenModal}
            onOk={createRabbitMQInstanceOnOk}
            onCancel={createRabbitMQInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRabbitMQInstanceName'}/>
            <span>主机名</span><Input id={'createRabbitMQInstanceHostname'}/>
            <span>端口</span><Input id={'createRabbitMQInstancePort'}/>
            <span>密码</span><Input id={'createRabbitMQInstancePasswd'}/>
        </Modal>


        <Modal
            title={'添加Kafka中间件实例'}
            open={createKafkaMQInstanceOpenModal}
            onOk={createKafkaMQInstanceOnOk}
            onCancel={createKafkaMQInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRocketMQInstanceName'}/>
            <span>主机名</span><Input id={'createRocketMQInstanceHostname'}/>
            <span>端口</span><Input id={'createRocketMQInstancePort'}/>
            <span>密码</span><Input id={'createRocketMQInstancePasswd'}/>
        </Modal>


        </>);
}