import React, {useEffect, useState} from 'react';
import {ConfigProvider, Layout} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {Terminal} from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import {FitAddon} from 'xterm-addon-fit';
import {AttachAddon} from 'xterm-addon-attach';

export function PodTerminalRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['pod']}></LeftSider>
                    <IndexRightBody>
                        <PodTerminal></PodTerminal>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


function PodTerminal() {
    const [terminalInstance, setTerminalInstance] = useState(null);

    useEffect(() => {

        const term = new Terminal();
        const fitAddon = new FitAddon();

        term.loadAddon(fitAddon);
        term.open(document.getElementById('terminalContainer'));
        fitAddon.fit();

        const websocket = new WebSocket('ws://127.0.0.1:8008/logs')
        const attachAddon = new AttachAddon(websocket)
        term.loadAddon(attachAddon)

        // keydown 只有在空格的时候才会监听？
        document.addEventListener('keyup', (event) => {
            console.log('event.key: ', event.key)

            switch (event.key) {
                case 'Enter':
                    //const input = term._core.buffer.translateBufferLineToString(term.buffer.y, true);
                    //console.log("input:", input)
                    term.write('\r\n');
                    break
                case 'Meta':
                    break

                case 'Control':
                    break
                default:
                    term.write(event.key)
                    break
            }

        })

        term.onData((data) => {
            console.log('terminal data: ', data)
            term.write(data)
        })

        setTerminalInstance(term);

        return () => {
            term.dispose();
            setTerminalInstance(null);
        };
    }, [])

    return (<>
        Pod Terminal
        <div
            id={'terminalContainer'}
            style={{
                width: '90%',
            }}
        ></div>
    </>)
}