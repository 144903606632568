import {Button, ConfigProvider, Input, Layout, Modal, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useState} from 'react';

export function StorageMiddlewareRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['storageMiddleware']}></LeftSider>
                    <IndexRightBody>
                        <StorageMiddlewareContent></StorageMiddlewareContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function StorageMiddlewareContent() {

    const [defaultActiveKey, setDefaultActiveKey] = useState('Redis')
    const storageMiddlewareOnChange = (activityKey) => {
        console.log(`activityKey: `, activityKey)
        switch (activityKey) {
            case 'Redis':
                listRedisInstance()
                setDefaultActiveKey('Redis')
                break
            case 'MySQL':
                listMySQLInstance()
                setDefaultActiveKey('MySQL')
                break
            default:
                break
        }
    }


    // redis
    const redisColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            render: function (_, record) {
                return record.hostname + ':' + record.port
            }
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        }
    ]
    const [redisDataSource, setRedisDataSource] = useState([])

    const [createRedisInstanceOpenModal, setCreateRedisInstanceOpenModal] = useState(false)

    const createRedisInstanceOnClick = () => {
        setCreateRedisInstanceOpenModal(true)
    }

    const createRedisInstanceOnOk = () => {
        setCreateRedisInstanceOpenModal(false)
    }

    const createRedisInstanceOnCancel = () => {
        setCreateRedisInstanceOpenModal(false)
    }

    const listRedisInstance = () => {

    }
    //
    const mysqlColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        }
    ]
    const [mysqlDataSource, setMysqlDataSource] = useState([])

    const [createMySQLInstanceOpenModal, setCreateMySQLInstanceOpenModal] = useState(false)

    const createMySQLInstanceOnClick = () => {
        setCreateMySQLInstanceOpenModal(true)
    }

    const createMySQLInstanceOnOk = () => {
        setCreateMySQLInstanceOpenModal(false)
    }

    const createMySQLInstanceOnCancel = () => {
        setCreateMySQLInstanceOpenModal(false)
    }

    const listMySQLInstance = () => {

    }
    //

    return (<>
        <Tabs
            defaultActiveKey={defaultActiveKey}
            type="card"
            onChange={storageMiddlewareOnChange}
            items={[
                {
                    label: 'Redis',
                    key: 'Redis',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://redis.io/'}
                                                        target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createRedisInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={redisColumns}
                               dataSource={redisDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'MySQL',
                    key: 'MySQL',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://www.mysql.com/'}
                                                        target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createMySQLInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={mysqlColumns}
                               dataSource={mysqlDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                }
            ]}
        />

        <Modal
            title={'添加Redis中间件实例'}
            open={createRedisInstanceOpenModal}
            onOk={createRedisInstanceOnOk}
            onCancel={createRedisInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRedisInstanceName'}/>
            <span>主机名</span><Input id={'createRedisInstanceHostname'}/>
            <span>端口</span><Input id={'createRedisInstancePort'}/>
            <span>密码</span><Input id={'createRedisInstancePasswd'}/>
        </Modal>



        <Modal
            title={'添加MySQL中间件实例'}
            open={createMySQLInstanceOpenModal}
            onOk={createMySQLInstanceOnOk}
            onCancel={createMySQLInstanceOnCancel}
        >
            <span>名字</span><Input id={'createMySQLInstanceName'}/>
            <span>主机名</span><Input id={'createMySQLInstanceHostname'}/>
            <span>端口</span><Input id={'createMySQLInstancePort'}/>
            <span>密码</span><Input id={'createMySQLInstancePasswd'}/>
        </Modal>


    </>);
}