import {Button, ConfigProvider, Layout, message, Modal, Select, Space, Table, Popconfirm} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useState} from 'react';
import {
    GetCloudPlatformAccountOptions,
    GetCloudPlatformSubAccountAccessKeyOptions,
    GetCloudPlatformSubAccountOptions, GetOrgProductCloudPlatformOptions
} from './common';
import {ListCloudDomainNameSystem, ListCloudDomainNameSystemRecord} from '../../apis/cloud/dns';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import React from 'react';

export function DNSRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudDNS']}></LeftSider>
                    <IndexRightBody>
                        <DNSContent></DNSContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}


export function DNSContent() {
    //
    const dnsColumns = [
        {
            title: '域名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'dnsServer',
            dataIndex: 'dnsServer',
            key: 'dnsServer'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (<>
                    <Button type={'primary'}>导入</Button>
                    <Button type={'primary'} onClick={() => cloudDNSRecordOnClick(record)}>解析记录</Button>
                </>);
            }
        }
    ]
    const [cloudDNSDataSource, setCloudDNSDataSource] = useState([])
    //
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)

    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState('')
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountValue, setCloudPlatformSubAccountValue] = useState('')
    const cloudPlatformSubAccountOnSelectHandler = (value) => {
        setCloudPlatformSubAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountAccessKeyValue, setCloudPlatformSubAccountAccessKeyValue] = useState('')
    const cloudPlatformSubAccountAccessKeyHandler = (value) => {
        setCloudPlatformSubAccountAccessKeyValue(value)
    }
    // 云平台账号 Select Options
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)
    // 云平台子账户 Select Options
    const cloudPlatformSubAccountOptions = GetCloudPlatformSubAccountOptions(cloudPlatformAccountValue, setCloudPlatformSubAccountValue)
    // 云平台子账户AccessKey Select Options
    const cloudPlatformSubAccountAccessKeyOptions = GetCloudPlatformSubAccountAccessKeyOptions(cloudPlatformSubAccountValue, setCloudPlatformSubAccountAccessKeyValue)

    // 列出云平台DNS
    const listCloudDnsOnClickHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListCloudDomainNameSystem({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const domains = data.domain
                if (errCode === 0) {
                    if (domains === null) {
                        setCloudDNSDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const domain of domains) {
                        tmp.push({
                            key: domain.domainId,
                            name: domain.name,
                            domainId: domain.domainId,
                            resourceGroupId: domain.resourceGroupId,
                            dnsServer: domain.dnsServer
                        })
                    }
                    setCloudDNSDataSource(tmp)
                    return;
                }
                message.error(`列出云平台DNS失败 ${errMsg}`)
                setCloudDNSDataSource([])
            }
        ).catch(
            err => {
                setCloudDNSDataSource([])
                message.error(`列出云平台DNS失败`)
                console.log(`列出云平台DNS失败 ${err}`)
            }
        )
    }
    // 解析记录
    const dnsRecordColumns = [
        {
            title: '记录值',
            dataIndex: 'rr',
            key: 'rr',
            editable: true
        },
        {
            title: '解析类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '解析值',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: '线路',
            dataIndex: 'line',
            key: 'line'
        },
        {
            title: 'TTL',
            dataIndex: 'ttl',
            key: 'ttl'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (<>
                    <Popconfirm
                        title={'更新DNS解析记录'}
                        description={`你确定要更新 ${record.domainName} 的 ${record.rr} 记录？`}
                        onConfirm={() => updateCloudDNSRecordOnClick(record)}
                    >
                        <Button type={'primary'}>更新</Button>
                    </Popconfirm>
                </>);
            }
        }
    ]
    const [dnsRecordOpenModal, setDnsRecordOpenModal] = useState(false)
    const [dnsRecordDataSource, setDnsRecordDataSource] = useState([])
    const dnsRecordModalOnOkHandler = () => {
        setDnsRecordOpenModal(false)
    }
    const dnsRecordModalOnCancelHandler = () => {
        setDnsRecordOpenModal(false)
    }
    const cloudDNSRecordOnClick = (record) => {
        ListCloudDomainNameSystemRecord({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            domainName: record.name
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const records = data.records
                if (errCode === 0) {
                    if (records === null) {
                        message.info(`${record.name} DNS记录为空`)
                        return;
                    }
                    let tmp = []
                    for (const record of records) {
                        tmp.push({
                            key: record.recordId,
                            domainName: record.domainName,
                            rr: record.rr,
                            type: record.type,
                            value: record.value,
                            line: record.line,
                            ttl: record.ttl,
                            status: record.status,
                            lock: record.lock
                        })
                    }
                    setDnsRecordOpenModal(true)
                    setDnsRecordDataSource(tmp)
                    return;
                }
                message.error(`列出 ${record.name} DNS记录失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出 ${record.name} DNS记录失败`)
                console.log(`列出 ${record.name} DNS记录失败, ${err}`)
            }
        )
    }
    const updateCloudDNSRecordOnClick = (record) => {
        message.info(`更新DNS解析记录 ${record.domainName} 的 ${record.rr}`)
    }

    return (
        <>
            <span>云平台</span>
            <Select
                style={{width: 120}}
                options={orgProductCloudPlatformOptions}
                value={cloudPlatformId}
                onSelect={cloudPlatformOnSelectHandler}
            />

            <span>云平台账号</span>
            <Select
                style={{width: 120}}
                options={cloudPlatformAccountOptions}
                value={cloudPlatformAccountValue}
                onSelect={cloudPlatformAccountOnSelectHandler}
            />

            <span>子账号</span>
            <Select
                style={{width: 120}}
                options={cloudPlatformSubAccountOptions}
                value={cloudPlatformSubAccountValue}
                onSelect={cloudPlatformSubAccountOnSelectHandler}
            />

            <span>AccessKey</span>
            <Select
                style={{width: 120}}
                options={cloudPlatformSubAccountAccessKeyOptions}
                value={cloudPlatformSubAccountAccessKeyValue}
                onSelect={cloudPlatformSubAccountAccessKeyHandler}
            />

            <Space size={4}>
                <Button type={'primary'} onClick={listCloudDnsOnClickHandler}>列出</Button>
            </Space>

            <Table
                columns={dnsColumns}
                dataSource={cloudDNSDataSource}
            />

            <Modal
                title={'域名解析记录'}
                keyboard={true}
                scrollable={true}
                open={dnsRecordOpenModal}
                onOk={dnsRecordModalOnOkHandler}
                onCancel={dnsRecordModalOnCancelHandler}
                width={'100%'}
                maskClosable={false}
            >
                <Table
                    scroll={{x: true}}
                    columns={dnsRecordColumns}
                    dataSource={dnsRecordDataSource}
                />
            </Modal>
        </>
    );
}