import {Get, ProductK8SClusterNodeApi} from '../common';

export function ListNode(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
    })
    return Get({
        url: `${ProductK8SClusterNodeApi}?${params}`
    })
}

export function GetNode(props) {
    return Get({
        url: ""
    })
}