import {
    CreateThirdChatSession, CreateThirdChatSessionContexts,
    GetThirdChatSessionContexts,
    GetThirdChatToken,
    ListThirdChatSession
} from '../../apis/robot/chat';
import {Button, ConfigProvider, Input, Layout, List, Menu, message, Modal, Space} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {ProList, WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ConvertTimestamp} from '../common/date';

const {Header, Content, Footer, Sider} = Layout;


export function ThirdChatRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['robot']} defaultSelectedKeys={['thirdChat']}></LeftSider>
                    <IndexRightBody>
                        <ThirdChat></ThirdChat>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}


function ThirdChat() {
    const [createChatSessionOpen, setCreateChatSessionOpen] = useState(false)

    const [thirdOrgUuid, setThirdOrgUuid] = useState('')
    const [thirdAppId, setThirdAppId] = useState('')
    const [thirdUserUuid, setThirdUserUuid] = useState('')
    const [thirdTimestamp, setThirdTimestamp] = useState('')
    const [thirdToken, setThirdToken] = useState('')
    const [thirdChatSessionItems, setThirdChatSessionItems] = useState([
        {
            key: 'newSession',
            label: '创建新会话'
        }
    ])
    const [thirdChatSessionContext, setThirdChatSessionContext] = useState([])

    const [thirdChatSessionSubject, setThirdChatSessionSubject] = useState('')

    const [thirdChatSessionUuid, setThirdChatSessionUuid] = useState('')

    const getThirdChatToken = () => {
        GetThirdChatToken().then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const appId = data.appId
                    const orgUuid = data.orgUuid
                    const timestamp = data.timestamp
                    const token = data.token
                    const userUuid = data.userUuid

                    setThirdOrgUuid(orgUuid)
                    setThirdAppId(appId)
                    setThirdUserUuid(userUuid)
                    setThirdTimestamp(timestamp)
                    setThirdToken(token)
                    return
                }

                setThirdOrgUuid('')
                setThirdAppId('')
                setThirdUserUuid('')
                setThirdTimestamp('')
                setThirdToken('')

                message.error(`获取第三方 chat Token 失败, ${errMsg}`)
            }
        ).catch(
            err => {
                setThirdOrgUuid('')
                setThirdAppId('')
                setThirdUserUuid('')
                setThirdTimestamp('')
                setThirdToken('')

                message.error(`获取第三方 chat Token 失败, ${err}`)
                console.log(`获取第三方 chat Token 失败, ${err}`)
            }
        )
    }


    const getThirdChatSessionContexts = (sessionUuid) => {
        GetThirdChatSessionContexts({
            orgUuid: thirdOrgUuid,
            appId: thirdAppId,
            userUuid: thirdUserUuid,
            timestamp: thirdTimestamp,
            token: thirdToken,
            sessionUuid: sessionUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const contexts = data.contexts

                    let tmp = []
                    for (const context of contexts) {
                        tmp.push({
                            key: context.uuid,
                            sessionUuid: context.sessionUuid,
                            taskId: context.taskId,
                            result: context.result,
                            userContent: context.userContent,
                            assistantContent: context.assistantContent,
                            createTime: context.createTime,
                            updateTime: context.updateTime,
                            //user: context.user,

                        })
                    }
                    setThirdChatSessionContext(tmp)
                    return
                }
                setThirdChatSessionContext([])
                message.error(`列出ThirdChatSessionContext失败， ${errMsg}`)
            }
        ).catch(
            err => {
                setThirdChatSessionContext([])
                message.error(`列出ThirdChatSessionContext失败， ${err}`)
            }
        )
    }


    const listThirdChatSession = () => {

        if (thirdOrgUuid === '' || thirdAppId === '' || thirdUserUuid === '' || thirdTimestamp === '' || thirdToken === '') {
            message.error(`third token not found`)
            return
        }
        console.log(`thirdOrgUuid: ${thirdOrgUuid}, thirdAppId: ${thirdAppId}, thirdUserUuid: ${thirdUserUuid}, thirdTimestamp: ${thirdTimestamp}, thirdToken: ${thirdToken}`)

        ListThirdChatSession({
            orgUuid: thirdOrgUuid,
            appId: thirdAppId,
            userUuid: thirdUserUuid,
            timestamp: thirdTimestamp,
            token: thirdToken
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const sessions = data.sessions
                    if (sessions === null) {
                        setThirdChatSessionItems([
                            {
                                key: 'newSession',
                                label: '创建新会话'
                            }
                        ])
                        return;
                    }

                    let tmp = []
                    tmp.push({
                        key: 'newSession',
                        label: '创建新会话'
                    })
                    for (const session of sessions) {
                        tmp.push({
                            key: session.uuid,
                            label: <pre>{session.sessionName}</pre>,
                            subject: session.subject,
                        })
                    }
                    setThirdChatSessionItems(tmp)
                    return
                }
                setThirdChatSessionItems([
                    {
                        key: 'newSession',
                        label: '创建新会话'
                    }
                ])
                message.error(`列出第三方会话Session失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setThirdChatSessionItems([
                    {
                        key: 'newSession',
                        label: '创建新会话'
                    }
                ])
                message.error(`列出第三方会话Session失败 ${err}`)
            }
        )
    }

    useEffect(() => {
        getThirdChatToken()
    }, [])

    useEffect(() => {
        if (thirdToken === '') {
            return
        }
        listThirdChatSession()
    }, [thirdToken])


    const clickHandler = ({item, key, keyPath, domEvent}) => {
        let sessionKey = keyPath[0]

        console.log(`item: ${key}`)
        if (sessionKey === 'newSession') {
            setCreateChatSessionOpen(true)
            return;
        }

        setThirdChatSessionUuid(sessionKey)
    }

    useEffect(() => {
        if (thirdChatSessionUuid === "") {
            return
        }
        getThirdChatSessionContexts(thirdChatSessionUuid)
    }, [thirdChatSessionUuid])

    const onOpenChangeHandler = () => {

    }

    //
    const createChatSessionOnOk = () => {
        const sessionName = document.getElementById('sessionName').value
        const subject = document.getElementById('subject').value

        CreateThirdChatSession({
            orgUuid: thirdOrgUuid,
            appId: thirdAppId,
            userUuid: thirdUserUuid,
            timestamp: thirdTimestamp,
            token: thirdToken,
            sessionName: sessionName,
            subject: subject
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    listThirdChatSession()
                    setCreateChatSessionOpen(false)
                    return
                }
                message.error(`创建第三方Chat会话失败，${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建第三方Chat会话失败， ${err}`)
            }
        )
    }

    const createChatSessionOnCancel = () => {
        setCreateChatSessionOpen(false)
    }

    //
    const createThirdChatContextOnClick = () => {
        const thirdChatContext = document.getElementById("thirdChatContextInput").value

        CreateThirdChatSessionContexts({
            orgUuid: thirdOrgUuid,
            appId: thirdAppId,
            userUuid: thirdUserUuid,
            timestamp: thirdTimestamp,
            sessionUuid: thirdChatSessionUuid,
            message: thirdChatContext,
            token: thirdToken,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    getThirdChatSessionContexts(thirdChatSessionUuid)
                    return
                }
                message.error(`发送ThirdChatContext失败， ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`发送ThirdChatContext失败， ${err}`)
            }
        )
    }

    return (<>

        <Modal
            title={"创建新Chat会话"}
            onOk={createChatSessionOnOk}
            onCancel={createChatSessionOnCancel}
            open={createChatSessionOpen}
        >
            <span>会话名</span><Input id={"sessionName"}/>
            <span>主题</span><Input id={"subject"}/>
        </Modal>

        <Sider
            width={180}
            style={{
                overflow: 'auto',
                height: '100vh',
                // 位置: fixed 为固定 -> 固定菜单栏
                position: 'fixed',
                marginTop: 60,
                left: 200,
                top: 10,
                bottom: 0
            }}
            collapsed={false} // 设置菜单栏是否收缩
        >
            <Menu
                theme="dark"
                mode="inline"
                defaultOpenKeys={'newSession'}
                defaultSelectedKeys={'newSession'}
                onClick={clickHandler}
                onOpenChange={onOpenChangeHandler}
                items={thirdChatSessionItems}
            />

        </Sider>

        <Content
            style={{
                overflow: 'auto',
                paddingTop: 10,
                paddingLeft: 160
                //padding:24,
            }}>

            <Input.TextArea id={'thirdChatContextInput'}/>
            <Button type={'primary'} onClick={createThirdChatContextOnClick}>提交</Button>
            <pre>Subject: {thirdChatSessionSubject}</pre>
            <List
                dataSource={thirdChatSessionContext}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.userContent}
                            description={ConvertTimestamp(item.createTime)}
                        />
                        <pre>{item.assistantContent}</pre>
                    </List.Item>
                )}
            />

        </Content>
    </>)
}
