import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout, message, Modal, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useEffect, useState} from 'react';
import {GetOrgProductCloudPlatformOptions} from './common';
import {
    AddOrgCloudPlatformRegion,
    DelOrgCloudPlatformRegion,
    ListOrgCloudPlatformRegion
} from '../../apis/cloud/region';
import {OrgUuid, VerifyOrgUuid} from '../../apis/user/org';
import {GetCloudPlatform} from '../../apis/common';
import React from 'react';

const spanStyle = {
    width: 100,
    display: 'block'
}

const inputStyle = {
    width: 300
}

export function CloudRegionRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['region']}></LeftSider>
                    <IndexRightBody>
                        <CloudRegionContent></CloudRegionContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function CloudRegionContent() {
    //
    const [cloudPlatformId, setCloudPlatformId] = useState("")
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [openAddCloudPlatformModal, setOpenAddCloudPlatformModal] = useState(false)
    const addCloudPlatformOnClick = () => {
        setOpenAddCloudPlatformModal(true)
    }
    //
    const [cloudRegionDataSource, setCloudRegionDataSource] = useState([])
    const regionColumns = [
        {
            title: '云平台',
            dataIndex: 'platformId',
            key: 'platformId',
            render: (text) => {
                return GetCloudPlatform(text)
            }
        },
        {
            title: '云区域',
            dataIndex: 'region',
            key: 'region'
        },
        {
            title: '云区域别名',
            dataIndex: 'regionName',
            key: 'regionName'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'} onClick={() => delCloudPlatformOnClickHandler(record)}>删除</Button>
                    </>
                );
            }
        }
    ]
    //
    const listCloudPlatformRegionOnClick = () => {
        if (cloudPlatformId === "") {
            return
        }
        ListOrgCloudPlatformRegion({
            orgUuid: OrgUuid,
            platformId: cloudPlatformId
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const regions = data.region
                    if (regions === null) {
                        setCloudRegionDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const region of regions) {
                        tmp.push({
                            key: region.uuid,
                            platformId: region.platformId,
                            region: region.region,
                            regionName: region.regionName
                        })
                    }
                    setCloudRegionDataSource(tmp)
                    return
                }
                setCloudRegionDataSource([])
                message.error(`列出云平台云地域失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                setCloudRegionDataSource([])
                message.error(`列出云平台云地域失败`)
                console.log(`列出云平台云地域失败 ${err}`)
            }
        )
    }

    useEffect(() => {
        listCloudPlatformRegionOnClick()
    }, [cloudPlatformId])
    //
    const addCloudPlatformOnOkHandler = () => {
        if (! VerifyOrgUuid()) {
            return
        }
        const region = document.getElementById('region').value
        const regionName = document.getElementById('regionName').value

        AddOrgCloudPlatformRegion({
            orgUuid: OrgUuid,
            platformId: cloudPlatformId,
            region: region,
            regionName: regionName,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加云平台地域成功`)
                    setOpenAddCloudPlatformModal(false)
                    return
                }
                message.error(`添加云平台地域失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加云平台地域失败`)
                console.log(`添加云平台地域失败 ${err}`)
            }
        )
    }
    const addCloudPlatformOnCancelHandler = () => {
        setOpenAddCloudPlatformModal(false)
    }
    //
    const delCloudPlatformOnClickHandler = (record) => {
        if (! VerifyOrgUuid() ) {
            return
        }
        DelOrgCloudPlatformRegion({
            orgUuid: OrgUuid,
            regionUuid: record.key,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除云平台地域成功`)
                    return
                }
                message.error(`删除云平台地域失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除云平台地域失败`)
                console.log(`删除云平台地域失败 ${err}`)
            }
        )
    }

    return (
        <>
            <Space size={8}>
                <span>云平台</span>
                <Select style={{width: 120}}
                    //options={CloudPlatformOptions}
                        options={orgProductCloudPlatformOptions}
                        value={cloudPlatformId}
                        onSelect={cloudPlatformOnSelectHandler}/>

                <Button type={'primary'} onClick={listCloudPlatformRegionOnClick}>查询</Button>
                <Button type={'primary'} onClick={addCloudPlatformOnClick}>添加</Button>
            </Space>


            <Table columns={regionColumns} dataSource={cloudRegionDataSource}/>

            <Modal
                title={'添加云区域'}
                open={openAddCloudPlatformModal}
                onOk={addCloudPlatformOnOkHandler}
                onCancel={addCloudPlatformOnCancelHandler}
            >
                <span style={spanStyle}>云平台</span>
                <Select style={{width: 300}}
                        options={orgProductCloudPlatformOptions}
                        value={cloudPlatformId}
                        onSelect={cloudPlatformOnSelectHandler}/>
                <span style={spanStyle}>Region</span>
                <Input id={'region'} style={inputStyle} />
                <span style={spanStyle}>RegionName</span>
                <Input id={'regionName'} style={inputStyle}/>
            </Modal>
        </>
    );
}