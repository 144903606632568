import {Post, Get, Del, ProductK8SClusterDeploymentApi} from '../common';

export function CreateDeployment(props) {
    const params = {}
    return Post({
        url: `${ProductK8SClusterDeploymentApi}`,
        params: params
    })
}

export function ListDeployment(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace
    })
    return Get({
        url: `${ProductK8SClusterDeploymentApi}?${params}`
    })
}

export function GetDeployment(props) {
    const params = new URLSearchParams({})
    return Get({
        url: `${ProductK8SClusterDeploymentApi}?${params}`
    })
}

export function DelDeployment(props) {
    const params = new URLSearchParams({})

    return Del({
        url: `${ProductK8SClusterDeploymentApi}?${params}`
    })
}

export function UpdateDeployment(props) {
    return Post({
        url: '',
        params: props.params
    })
}

export function UpdateDeploymentReplicaSet(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterDeploymentApi}/${props.name}`,
        params: params,
    })
}

export function ListDeploymentRollout(props) {
    return Get({
        url: ''
    })
}