import {Del, Post, Get, ProductK8SClusterIngressApi} from '../common';

export function CreateIngress(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterIngressApi}`,
        params: params,
    })
}

export function UpdateIngress(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterIngressApi}`,
        params: params,
    })
}

export function ListIngress(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
    })
    return Get({
        url: `${ProductK8SClusterIngressApi}?${params}`,
    })
}

export function GetIngress(props) {
    const params = new URLSearchParams({

    })
    return Get({
        url: `${ProductK8SClusterIngressApi}?${params}`,
    })
}

export function DelIngress(props) {
    const params = new URLSearchParams({

    })
    return Del({
        url: `${ProductK8SClusterIngressApi}?${params}`,
    })
}