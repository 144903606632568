// 智能聊天
import {Button, ConfigProvider, DatePicker, Layout} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {ProForm, ProFormText, WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

export function DingTalkRobotRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['robot']} defaultSelectedKeys={['dingTalkRobot']}></LeftSider>
                    <IndexRightBody>
                        <DingTalkRobotContent></DingTalkRobotContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function DingTalkRobotContent() {
    return (
        <WaterMark content={"StartOps"} zIndex={9}>
            <Button type={'primary'}>添加机器人</Button>
        </WaterMark>
    );
}