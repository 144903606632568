import {CiBuildProgramTemplateApi, Del, Get, Post} from '../common';


export function CreateBuildProgramTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        command: `${props.command !== undefined ? props.command : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: CiBuildProgramTemplateApi,
        params: params
    })
}


export function ListBuildProgramTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateType: `${props.templateType !== undefined ? props.templateType : 3}`,
    })
    return Get({
        url: `${CiBuildProgramTemplateApi}?${params}`,
    })
}


export function DelBuildProgramTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateUuid: `${props.templateUuid !== undefined ? props.templateUuid : ""}`,
    })
    return Del({
        url: `${CiBuildProgramTemplateApi}?${params}`,
    })
}
