import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ListKubernetesCluster} from '../../apis/product/k8s';
import {message, Modal, Table} from 'antd';
import {useEffect, useState} from 'react';
import {ListNameSpace} from '../../apis/kubernetes/namespace';
import {GetKubernetesEvent} from '../../apis/hook/kubernetesEvent';
import React from 'react';
import {ConvertTimestamp} from '../common/date';

export function KubernetesClusterSelectOptions(env, setKubernetesCluster) {
    //
    const [kubernetesClusterOptions, setKubernetesClusterOptions] = useState([])

    const listK8SClusterHandler = () => {
        if (env === '') {
            return;
        }
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListKubernetesCluster({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const clusters = data.cluster
                if (errCode === 0) {
                    if (clusters === null) {
                        setKubernetesCluster('')
                        setKubernetesClusterOptions([])
                        return;
                    }
                    let tmp = []
                    for (const c of clusters) {
                        if (c.kubeEdgeGrpc !== "") {
                            tmp.push({
                                value: c.uuid,
                                label: c.name,
                            })
                        }
                    }
                    if (tmp.length !== 0) {
                        setKubernetesCluster(tmp.slice(-1)[0].value)
                    }
                    setKubernetesClusterOptions(tmp)
                    return;
                }
                setKubernetesCluster('')
                setKubernetesClusterOptions([])
                message.error(`列出K8S集群失败, ${errMsg}`)
            }
        ).catch(
            err => {
                setKubernetesCluster('')
                setKubernetesClusterOptions([])
                console.log(`列出K8S集群失败 ${err}`)
                message.error(`列出K8S集群失败`)
            }
        )
    }

    useEffect(() => {
        listK8SClusterHandler()
    }, [env])

    return kubernetesClusterOptions
}



export function KubernetesClusterNameSpaceSelectOptions(env, k8sClusterUuid, setKubernetesClusterNameSpaceOptions) {

    ListNameSpace({
        orgUuid: OrgUuid,
        productUuid: OrgProductUuid,
        productEnvUuid: env,
        k8sClusterUuid: k8sClusterUuid,
    }).then(
        data => {
            const errCode = data.errCode
            const errMsg = data.errMsg
            if (errCode === 0) {
                const namespace = data.namespace
                if (namespace === null) {
                    setKubernetesClusterNameSpaceOptions([])
                    return;
                }

                let tmp = []
                for (const n of namespace) {
                    tmp.push({
                        value: n.name,
                        label: n.name,
                    })
                }
                setKubernetesClusterNameSpaceOptions(tmp)
                return
            }
            message.error(`列出 NameSpace 失败 ${errMsg}`)
            setKubernetesClusterNameSpaceOptions([])
        }
    ).catch(
        err => {
            message.error(`列出 NameSpace 失败`)
            console.log(`列出 NameSpace 失败 ${err}`)
            setKubernetesClusterNameSpaceOptions([])
        }
    )
}

export const KubernetesEventColumns = [
    {
        title: 'namespace',
        dataIndex: 'namespace',
        key: 'namespace'
    },
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'createTime',
        dataIndex: 'createTime',
        key: 'createTime',
        render: (text) => {
            return ConvertTimestamp(text)
        }
    },
    {
        title: 'type',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'reason',
        dataIndex: 'reason',
        key: 'reason'
    },
    {
        title: 'note',
        dataIndex: 'note',
        key: 'note',
    },
    {
        title: 'annotations',
        dataIndex: 'annotations',
        key: 'annotations'
    },
    {
        title: '关联',
        dataIndex: 'regardingKind',
        key: 'regardingKind'
    },
    {
        title: 'regardingName',
        dataIndex: 'regardingName',
        key: 'regardingName'
    },
    {
        title: 'regardingFieldPath',
        dataIndex: 'regardingFieldPath',
        key: 'regardingFieldPath'
    }
]

export function GetKubernetesEventModal(setKubernetesEventOpenModal, setKubernetesEventDataSource, props) {

    GetKubernetesEvent({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        type: props.type,
        createTime: props.createTime,
        regardingKind: props.regardingKind,
        regardingName: props.regardingName,
        regardingUid: props.regardingUid,
    }).then(
        data => {
            const errCode = data.errCode
            const errMsg = data.errMsg
            if (errCode === 0) {
                const events = data.events
                if (events === null) {
                    message.info(`获取 K8S 集群事件为空`)
                    return;
                }

                let tmp = []
                let count = 1
                for (const event of events) {
                    tmp.push({
                        key: count,
                        name: event.name,
                        namespace: event.namespace,
                        type: event.type,
                        reason: event.reason,
                        note: event.note,
                        createTime: event.createTime,
                        annotations: event.annotations,
                        regardingKind: event.regardingKind,
                        regardingName: event.regardingName,
                        regardingFieldPath: event.regardingFieldPath,
                    })
                    count += 1
                }
                setKubernetesEventOpenModal(true)
                setKubernetesEventDataSource(tmp)
                return
            }
            message.error(`获取 K8S 集群事件失败 ${errMsg}`)
        }
    ).catch(
        err => {
            message.error(`获取 K8S 集群事件失败`)
            console.log(`获取 K8S 集群事件失败 ${err}`)
        }
    )

}