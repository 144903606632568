import {Button, ConfigProvider, Input, Layout, message, Modal, Select, Space, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useState} from 'react';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {
    GetCloudPlatformAccountOptions,
    GetCloudPlatformSubAccountOptions,
    GetCloudPlatformSubAccountAccessKeyOptions,
    GetOrgProductCloudPlatformOptions
} from './common';
import {ListCDNDomain, RefreshCDNCache} from '../../apis/cloud/cdn';
import React from 'react';

const spanStyle = {
    width: 80,
    display: 'block'
}

export function CDNRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudCDN']}></LeftSider>
                    <IndexRightBody>
                        <CDNContent></CDNContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function CDNContent() {
    //
    const cdnColumns = [
        {
            title: '域名',
            dataIndex: 'domainName',
            key: 'domainName'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'cname',
            dataIndex: 'cname',
            key: 'cname'
        },
        {
            title: 'origin',
            dataIndex: 'origin',
            key: 'origin',
            render: function (origin) {
                let r = ''
                for (const o of origin) {
                    r += `${o.serverName} => ${o.type}`
                    r += '\n'
                }
                return <pre>{r}</pre>
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (<>
                    <Button type={'primary'}>导入</Button>
                </>);
            }
        }
    ]

    // 列出 cdn 服务列表
    const [cloudCdnDataSource, setCloudCdnDataSource] = useState([])
    //
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState('')
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountValue, setCloudPlatformSubAccountValue] = useState('')
    const cloudPlatformSubAccountOnSelectHandler = (value) => {
        setCloudPlatformSubAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountAccessKeyValue, setCloudPlatformSubAccountAccessKeyValue] = useState('')
    const cloudPlatformSubAccountAccessKeyHandler = (value) => {
        setCloudPlatformSubAccountAccessKeyValue(value)
    }
    // 云平台账号 Select Options
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)
    // 云平台子账户 Select Options
    const cloudPlatformSubAccountOptions = GetCloudPlatformSubAccountOptions(cloudPlatformAccountValue, setCloudPlatformSubAccountValue)
    // 云平台子账户AccessKey Select Options
    const cloudPlatformSubAccountAccessKeyOptions = GetCloudPlatformSubAccountAccessKeyOptions(cloudPlatformSubAccountValue, setCloudPlatformSubAccountAccessKeyValue)

    //
    const listCloudCDNOnClickHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        ListCDNDomain({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const cdns = data.cdn
                if (errCode === 0) {
                    if (cdns === null) {
                        setCloudCdnDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const cdn of cdns) {
                        tmp.push({
                            key: cdn.domainId,
                            domainName: cdn.domainName,
                            status: cdn.status,
                            cname: cdn.cname,
                            origin: cdn.origin
                        })
                    }
                    setCloudCdnDataSource(tmp)
                    return;
                }
                setCloudCdnDataSource([])
                message.error(`列出云cdn列表失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCloudCdnDataSource([])
                message.error(`列出云cdn列表失败 ${err}`)
            }
        )
    }

    // 刷新 cdn 服务缓存
    const [refreshCDNOpenModal, setRefreshCDNOpenModal] = useState(false)
    const [refreshCDNTypeValue, setRefreshCDNTypeValue] = useState(1)
    const refreshCDNTypeOptions = [
        {
            value: 1,
            label: '文件'
        },
        {
            value: 2,
            label: '目录'
        }
    ]
    const refreshCloudCDNOnClickHandler = () => {
        setRefreshCDNOpenModal(true)
    }
    const refreshCloudCDNModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        const paths = document.getElementById('paths').value
        if (cloudPlatformAccountValue === '' || cloudPlatformSubAccountValue === '' || cloudPlatformSubAccountAccessKeyValue === '') {
            message.error(`请正确选择云账号参数`)
            return;
        }
        if (paths === '') {
            message.error(`路径参数为空, 该参数为必填参数`)
            return;
        }

        const pathsArray = paths.split('\n')
        console.log(`pathsArray: ${pathsArray}`)
        RefreshCDNCache({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            type: refreshCDNTypeValue,
            paths: pathsArray
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`刷新缓存任务已提交`)
                    setRefreshCDNOpenModal(false)
                    return
                }
                message.error(`刷新缓存失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`刷新缓存失败`)
                console.log(`刷新缓存失败 ${err}`)
            }
        )
    }

    const refreshCloudCDNModalOnCancel = () => {
        setRefreshCDNOpenModal(false)
    }
    const refreshCDNTypeOnSelect = (value) => {
        setRefreshCDNTypeValue(value)
    }

    // 获取cdn服务状态

    const [cdnServiceStatusModalOpen, setCdnServiceStatusModalOpen] = useState(false)

    const cdnServiceStatusModalOnOk = () => {

    }

    const cdnServiceStatusModalOnCancel = () => {

    }

    const getCdnServiceStatusHandler = () => {

    }



    return (<>
        <span>云平台</span>
        <Select
            style={{width: 120}}
            options={orgProductCloudPlatformOptions}
            value={cloudPlatformId}
            onSelect={cloudPlatformOnSelectHandler}
        />

        <span>云平台账号</span>
        <Select
            style={{width: 120}}
            options={cloudPlatformAccountOptions}
            value={cloudPlatformAccountValue}
            onSelect={cloudPlatformAccountOnSelectHandler}
        />

        <span>子账号</span>
        <Select
            style={{width: 120}}
            options={cloudPlatformSubAccountOptions}
            value={cloudPlatformSubAccountValue}
            onSelect={cloudPlatformSubAccountOnSelectHandler}
        />

        <span>AccessKey</span>
        <Select
            style={{width: 120}}
            options={cloudPlatformSubAccountAccessKeyOptions}
            value={cloudPlatformSubAccountAccessKeyValue}
            onSelect={cloudPlatformSubAccountAccessKeyHandler}
        />

        <Space size={4}>
            <Button type={'primary'} onClick={listCloudCDNOnClickHandler}>列出</Button>
            <Button type={'primary'} onClick={refreshCloudCDNOnClickHandler}>刷新缓存</Button>
            <Button type={'primary'} onClick={getCdnServiceStatusHandler}>服务状态</Button>
        </Space>

        <Table
            columns={cdnColumns}
            dataSource={cloudCdnDataSource}
            scroll={{x: true, y: 400}}
        />


        <Modal
            open={cdnServiceStatusModalOpen}
            onOk={cdnServiceStatusModalOnOk}
            onCancel={cdnServiceStatusModalOnCancel}
            maskClosable={false}
            keyboard={true}
        >

        </Modal>


        <Modal
            open={refreshCDNOpenModal}
            onCancel={refreshCloudCDNModalOnCancel}
            onOk={refreshCloudCDNModalOnOk}
            maskClosable={false}
            keyboard={true}
        >
            <span style={spanStyle}>云平台</span>
            <Select style={{width: 120}}
                    options={orgProductCloudPlatformOptions}
                    value={cloudPlatformId}
                    onSelect={cloudPlatformOnSelectHandler}/>
            <br/>

            <span style={spanStyle}>云平台账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformAccountOptions}
                    value={cloudPlatformAccountValue}
                    onSelect={cloudPlatformAccountOnSelectHandler}/>
            <br/>

            <span style={spanStyle}>子账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountOptions}
                    value={cloudPlatformSubAccountValue}
                    onSelect={cloudPlatformSubAccountOnSelectHandler}/>
            <br/>

            <span style={spanStyle}>AccessKey</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountAccessKeyOptions}
                    value={cloudPlatformSubAccountAccessKeyValue}
                    onSelect={cloudPlatformSubAccountAccessKeyHandler}/>
            <br/>

            <span style={spanStyle}>刷新类型</span>
            <Select style={{width: 120}}
                    options={refreshCDNTypeOptions}
                    value={refreshCDNTypeValue} onSelect={refreshCDNTypeOnSelect}/>

            <br/>
            <span style={spanStyle}>路径</span>
            <Input.TextArea id={'paths'}/>
        </Modal>
    </>);
}