import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Empty, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

// 首页 /home & /
export function Dashboard() {
    let bodyContent = <Empty/>

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['']} defaultSelectedKeys={['']}></LeftSider>
                    <IndexRightBody>
                        {bodyContent}
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>

    </ConfigProvider>
}
