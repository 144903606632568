import {CiUploadProgramTemplateApi, Get, Post} from '../common';

export function CreateUploadProgramTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: `${props.name !== undefined ? props.name : ""}`,
    }
    return Post({
        url: CiUploadProgramTemplateApi,
        params: params
    })
}


export function ListUploadProgramTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateType: `${props.templateType !== undefined ? props.templateType : 3}`,
    })
    return Get({
        url: `${CiUploadProgramTemplateApi}?${params}`,
    })
}


export function DelUploadProgramTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: `${props.name !== undefined ? props.name : ""}`,
    }
    return Post({
        url: CiUploadProgramTemplateApi,
        params: params
    })
}
