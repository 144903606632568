import {Del, Get, HookKubernetesEventReceiverApi, Post} from '../common';


export function CreateKubernetesEventReceiver(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        k8SClusterUuid: props.k8SClusterUuid,
        messageGroupUuid: props.messageGroupUuid,
    }
    return Post({
        url: HookKubernetesEventReceiverApi,
        params: params,
    })
}

export function ListKubernetesEventReceiver(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        k8sClusterUuid: props.k8sClusterUuid,
    });
    return Get({
        url: `${HookKubernetesEventReceiverApi}?${params}`,
    })
}

export function DelKubernetesEventReceiver(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        receiverUuid: props.receiverUuid,
    });
    return Del({
        url: `${HookKubernetesEventReceiverApi}?${params}`,
    })
}