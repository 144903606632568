import {Post, TranslateApi} from '../common';

export function Translate(props) {
    const params = {
        orgUuid: props.orgUuid,
        content: props.content,
        sourceLanguage: props.sourceLanguage,
        destLanguage: props.destLanguage,
    }
    return Post({
        url: TranslateApi,
        params: params,
    })
}