import {useEffect, useState} from 'react';
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';

import {message, Table} from 'antd';
import {Modal, Input, Button} from 'antd'
import {CreateOrg, GetOrganizationMembers, LeaveOrganization, OrgUuid, VerifyOrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';
import React from 'react';

export function GetOrganizationMemberRoute() {


    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['organization']} defaultSelectedKeys={['getOrgMember']}></LeftSider>
                    <IndexRightBody>
                        <GetOrganizationMemberContent></GetOrganizationMemberContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

// 创建组织
export function CreateOrganizationContent() {
    const createOrgModalOkHandler = () => {
        const createOrgName = document.getElementById('createOrgName').value
        if (createOrgName === '') {
            message.error('组织名不允许为空，请输入组织名')
            return;
        }
        const params = {
            'name': createOrgName
        }

        CreateOrg(params).then(
            data => {
                const errCode = data.errCode
                if (errCode !== 0) {
                    message.error('创建失败，Message: ' + data.errMsg)
                    return
                }
                message.success('创建成功')
            }
        )
    }
    const createOrgModalCancelHandler = () => {

    }

    Modal.confirm(
        {
            title: '创建组织',
            keyboard: true,
            content: <><span>组织名:</span><Input id="createOrgName" placeholder="请输入组织名"/></>,
            onOk: createOrgModalOkHandler,
            onCancel: createOrgModalCancelHandler
        }
    )
}

// 退出组织
export function LeaveOrganizationHandler() {

    if (!VerifyOrgUuid()) {
        return
    }
    LeaveOrganization({
        orgUuid: OrgUuid
    }).then(
        data => {
            const errCode = data.errCode
            const errMsg = data.errMsg

            if (errCode === 0) {
                message.success('退出当前组织成功')
                window.location.reload()
            }
            message.error(`离开组织失败，err: ${errMsg}`)
        }
    ).catch(
        err => {
            message.error(`离开组织失败，err: ${err}`)
            console.log(err)
        }
    )
}

// 获取组织成员
export function GetOrganizationMemberContent() {

    // 查询组织用户
    const [orgMembersDataSource, setOrgMembersDataSource] = useState([])
    // 驱逐组织成员
    const evictionMemberHandler = () => {
        // 1. 先调用API
        // 2. 再更新orgUserDataSource, 为了简单方便，再次调用获取渲染结果
        selectOrgMembersHandler()
    }

    const orgUserColumns = [
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '角色',
            dataIndex: 'role',
            render: (role) => {
                let roleName = ''
                if (role === 1) {
                    roleName = '创建者'
                } else if (role === 2) {
                    roleName = '成员'
                } else {
                    roleName = '未知角色'
                }
                return <><span>{roleName}</span></>
            }
        },
        {
            title: '加入时间',
            dataIndex: 'joinDate',
            key: 'joinDate',
            sorter: (a, b) => a.joinDate - b.joinDate,
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            render: (_, record) => {
                if (record.role !== 1) {
                    return (<Button type="primary" onClick={evictionMemberHandler}>驱逐成员</Button>);
                }
            }
        }
    ]

    const selectOrgMembersHandler = () => {
        if (!VerifyOrgUuid()) {
            return
        }
        GetOrganizationMembers({
            orgUuid: OrgUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let orgMembersDataSource = []
                    const orgMembers = data.result.organizationUser
                    for (let orgMember of orgMembers) {
                        const orgMemberUserName = orgMember.userName
                        const orgMemberUserUuid = orgMember.userUuid
                        const orgMemberDateJoined = orgMember.dateJoined
                        const orgMemberRole = orgMember.role
                        //console.log(`orgMemberUserName: ${orgMemberUserName} orgMemberDateJoined: ${orgMemberDateJoined}  orgMemberRole: ${orgMemberRole}`)
                        orgMembersDataSource.push({
                            key: orgMemberUserUuid,
                            username: orgMemberUserName,
                            role: orgMemberRole,
                            joinDate: orgMemberDateJoined
                        })
                    }
                    setOrgMembersDataSource(orgMembersDataSource)
                } else {
                    message.error('获取组织成员列表失败，MSG:' + errMsg)
                }
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    useEffect(
        () => {
            selectOrgMembersHandler()
        }, []
    )

    return (
        <>
            <Table columns={orgUserColumns} dataSource={orgMembersDataSource}>
            </Table>
        </>
    );
}