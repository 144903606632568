import {Button, Input, message, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Modal} from 'antd'
import {
    DelCertificate,
    ImportCertificate,
    ListCertificate,
    ParserSSLCertificate
} from '../../apis/cloud/certificate';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';


export function ListCertificateRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudCertificate']}></LeftSider>
                    <IndexRightBody>
                        <ListCertificateContent></ListCertificateContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function ListCertificateContent() {
    const [sslCertificateDataSource, setSslCertificateDataSource] = useState([])
    const sslCertificateColumns = [
        {
            title: '证书域名',
            dataIndex: 'dns_names',
            key: 'dns_names',
            sorter: (a, b) => a.dns_names.length - b.dns_names.length,
            render: function (dns_names) {
                let r = ''
                for (const domain of JSON.parse(dns_names)) {
                    r += domain
                    r += '\n'
                }
                return <pre>{r}</pre>
            }
        },
        {
            title: '开始时间',
            dataIndex: 'start_date',
            key: 'start_date',
            sorter: (a, b) => a.start_date - b.start_date,
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '结束时间',
            dataIndex: 'end_date',
            key: 'end_date',
            sorter: (a, b) => a.end_date - b.end_date,
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '主题',
            dataIndex: 'subject',
            key: 'subject'
        },
        {
            title: '发行者',
            dataIndex: 'issuer',
            key: 'issuer'
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'}
                                onClick={() => getTlsCertificateDetailOnClickHandler(record)}>查看详情</Button>

                        <Button type={'primary'} danger={true}
                                onClick={() => delCertificateHandler({
                                    certificateUuid: record.uuid,
                                    serialNumber: record.serial_number,
                                })}>
                            删除
                        </Button>
                    </>
                )
            }
        }
    ]
    //
    const getTlsCertificateDetailOnClickHandler = (record) => {
        Modal.confirm({
            title: `${record.dns_names} 证书详情`,
            content: (
                <>
                    <span>证书密钥</span>
                    <Input.TextArea defaultValue={record.key}/>
                    <span>证书内容</span>
                    <Input.TextArea defaultValue={record.pem}/>
                </>
            )
        })
    }

    // 列出证书
    const listCertificateOnClickHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListCertificate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            domainName: '',
            notExpire: ''
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const certificates = data.certificates
                if (errCode === 0) {
                    if (certificates === null) {
                        return;
                    }
                    let tempCertificates = []
                    for (const certificate of certificates) {
                        tempCertificates.push({
                            uuid: certificate.uuid,
                            key: certificate.key,
                            pem: certificate.pem,
                            dns_names: certificate.dnsNames,
                            start_date: certificate.startDate,
                            end_date: certificate.endDate,
                            subject: certificate.subject,
                            serial_number: certificate.serialNumber,
                            issuer: certificate.issuer,
                            version: certificate.version
                        })
                    }
                    setSslCertificateDataSource(tempCertificates)
                    return
                }
                console.log(`列出证书列表失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.warning('列出证书列表失败')
                console.log(`列出证书列表失败 ${err}`)
            }
        )
    }
    // 添加证书
    const importCertificateModalOnOkHandler = () => {
        //const sslCertificateName = document.getElementById("sslCertificateName").value
        const sslCertificateKey = document.getElementById('sslCertificateKey').value
        const sslCertificatePem = document.getElementById('sslCertificatePem').value
        if (sslCertificateKey === '' || sslCertificatePem === '') {
            message.error('请输入对应的参数')
            return
        }
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return;
        }

        ImportCertificate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            key: btoa(sslCertificateKey),
            pem: btoa(sslCertificatePem)
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info('导入证书成功')
                    listCertificateOnClickHandler()
                    return
                }
                message.error(`导入证书失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error('导入证书失败')
                console.log(`导入证书失败, ${err}`)
            }
        )
    }
    const importCertificateModalOnCancelHandler = () => {
        message.warning('取消添加SSL证书')
    }
    const importCertificateOnClickHandler = () => {
        Modal.confirm(
            {
                width: 600,
                title: '手动添加SSL证书',
                content: <>
                    <span>证书密钥</span>
                    <Input.TextArea id={'sslCertificateKey'}/>
                    <span>证书内容</span>
                    <Input.TextArea id={'sslCertificatePem'}/>
                </>,
                onOk: importCertificateModalOnOkHandler,
                onCancel: importCertificateModalOnCancelHandler
            }
        )
    }
    // 解析证书
    const [parseCertificateContentResult, setParseCertificateContentResult] = useState('')
    const [parseCertificateModalOpenStatus, setParseCertificateModalOpenStatus] = useState(false)
    const parseCertificateOnClickHandler = (contentType) => {
        const parseCertificateContent = document.getElementById('parseCertificateContent').value
        if (parseCertificateContent === '') {
            message.warning('请输入证书内容')
            return
        }
        let certificateBase64Content
        if (contentType !== 'base64') {
            certificateBase64Content = btoa(parseCertificateContent)
        } else {
            certificateBase64Content = parseCertificateContent
        }

        ParserSSLCertificate({
            certificate: certificateBase64Content
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let subject = data.subject
                    let issuer = data.Issuer
                    let dnsNames = data.dnsNames
                    let serialNumber = data.serialNumber
                    let version = data.version
                    let notAfter = data.notAfter
                    let notBefore = data.notBefore
                    let content = `
subject: ${subject}
issuer: ${issuer}
dnsNames: ${dnsNames}
serialNumber: ${serialNumber}
version: ${version}
notAfter: ${notAfter}
notBefore: ${notBefore}
notAfterString: ${ConvertTimestamp(notAfter)}
notBeforeString: ${ConvertTimestamp(notBefore)}
`
                    setParseCertificateContentResult(content)
                    return
                }
                message.warning(`解析证书失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.warning('解析证书失败')
            }
        )
    }
    const parseCertificateOpenModalOnClickHandler = () => {
        setParseCertificateModalOpenStatus(true)
    }
    const parseCertificateModalOnCancelHandler = () => {
        setParseCertificateModalOpenStatus(false)
    }
    const parseCertificateModalOnOkHandler = () => {
        setParseCertificateModalOpenStatus(false)
    }
    // 删除证书
    const delCertificateHandler = (props) => {
        if (! VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        DelCertificate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            certificateUuid: props.certificateUuid,
            serialNumber: props.serialNumber,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除证书成功`)
                    listCertificateOnClickHandler()
                    return
                }
                message.error(`删除证书失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除证书失败`)
                console.log(`删除证书失败 ${err}`)
            }
        )
    }
    //

    useEffect(() => {
        listCertificateOnClickHandler()
    }, [])
    return (
        <>
            <Space size={4}>
                <Button type={'primary'} onClick={listCertificateOnClickHandler}>查询证书</Button>
                <Button type={'primary'} onClick={importCertificateOnClickHandler}>导入证书</Button>
                <Button type={'primary'} onClick={parseCertificateOpenModalOnClickHandler}>解析证书</Button>
            </Space>
            <Table columns={sslCertificateColumns} dataSource={sslCertificateDataSource} scroll={{ x: true, y: 400 }}/>
            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={parseCertificateModalOpenStatus}
                onCancel={parseCertificateModalOnCancelHandler}
                onOk={parseCertificateModalOnOkHandler}
            >
                <span>证书内容:</span>
                <Input.TextArea
                    id={'parseCertificateContent'}
                    allowClear={true}
                    showCount={true}
                />
                <Button
                    type={'primary'}
                    onClick={() => parseCertificateOnClickHandler('certificateString')}
                    style={{
                        //position: 'absolute',
                        //right: 0,
                    }}
                >解析</Button>

                <Button
                    type={'primary'}
                    onClick={() =>parseCertificateOnClickHandler('base64')}
                    style={{
                        //position: 'absolute',
                        //right: 0,
                    }}
                >Base64内容解析</Button>
                <br/>
                <pre>{parseCertificateContentResult}</pre>
            </Modal>
        </>);
}

