import zhCN from 'antd/locale/zh_CN';
import {Button, Badge, Calendar, ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

export function DutyRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['workSpace']} defaultSelectedKeys={['duty']}></LeftSider>
                    <IndexRightBody>
                        <DutyContent></DutyContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


export function DutyContent() {

    const cellRender = (current, today, info) => {
        return <>
            <ul>
                <li>
                    <Badge status={'error'} text={'123'}/>
                </li>
            </ul>
        </>
    };

    return (
        <>
            <Button type={'primary'}>
                添加值班
            </Button>
            <Calendar cellRender={cellRender}/>
        </>
    );
}