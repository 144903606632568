import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table, Tabs} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {
    AddCiTemplateStep,
    CreateCiTemplate,
    DelCiTemplate, DelCiTemplateStep,
    ListCiTemplate,
    ListCiTemplateStep
} from '../../apis/ci/template';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';
import {CreateCodeScanTemplate, DelCodeScanTemplate, ListCodeScanTemplate} from '../../apis/ci/codeScan';
import {CreateUnitTestTemplate, DelUnitTestTemplate, ListUnitTestTemplate} from '../../apis/ci/unitTest';
import {
    CreateBuildProgramTemplate,
    DelBuildProgramTemplate,
    ListBuildProgramTemplate
} from '../../apis/ci/buildProgram';
import {
    CreateBuildContainerImageTemplate,
    DelBuildContainerImageTemplate,
    ListBuildContainerImageTemplate
} from '../../apis/ci/buildContainerImage';
import {DelUploadProgramTemplate, ListUploadProgramTemplate} from '../../apis/ci/uploadProgram';
import {ReloadOutlined} from '@ant-design/icons';
import {ListContainerRegistry} from '../../apis/product/containerRegistry';

export function CiPipelineRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['product']} defaultSelectedKeys={['ciPipeline']}></LeftSider>
                    <IndexRightBody>
                        <CiPipelineContent></CiPipelineContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function CiPipelineContent() {

    // 1. CI 模版
    const ciTemplateColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return <>
                    <Button type={'primary'}
                            onClick={() => addCITemplateStepOnClick(record.uuid)}>添加步骤</Button>

                    <Button type={'primary'}
                            onClick={() => listCITemplateStepOnClick(record.uuid)}>查看步骤</Button>
                    <Popconfirm
                        title={'删除 CI 模版'}
                        onConfirm={() => delCustomCITemplateOnClick(record.uuid)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]

    const [ciTemplateDataSource, setCiTemplateDataSource] = useState([])


    // 1.2 CI 模版 Step
    const ciTemplateStepColumns = [
        {
            title: '步骤类型',
            dataIndex: 'stepType',
            key: 'stepType',
            render: (text) => {
                switch (text) {
                    case 1:
                        return "代码扫描"
                    case 2:
                        return "单元测试"
                    case 3:
                        return "构建程序"
                    case 4:
                        return "上传程序"
                    case 5:
                        return "构建容器镜像"
                    default:
                        return "未知类型"
                }
            }
        },
        {
            title: '公共模版',
            dataIndex: 'stepTypeTemplateIsPublic',
            key: 'stepTypeTemplateIsPublic',
            render: (text) => {
                if (text) {
                    return "是"
                } else {
                    return "否"
                }
            }
        },
        {
            title: '步骤模版Uuid',
            dataIndex: 'stepTypeTemplateUuid',
            key: 'stepTypeTemplateUuid'
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            key: 'priority'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Popconfirm
                        title={'删除模版子步骤'}
                        onConfirm={() => delCiTemplateStepOnClick(record.templateUuid, record.uuid)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]

    const [ciTemplateStepDataSource, setCiTemplateStepDataSource] = useState([])


    // 创建 CI 模版
    const [createCITemplateModalOpen, setCreateCITemplateModalOpen] = useState(false)
    const createCITemplateModalOnClick = () => {
        setCreateCITemplateModalOpen(true)
    }
    const createCITemplateModalOnOk = () => {
        const createCITemplateName = document.getElementById('createCITemplateName').value
        if (createCITemplateName === '' || createCITemplateName === undefined) {
            message.error(`请输入模版名字`)
            return
        }

        CreateCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            name: createCITemplateName
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateCITemplateModalOpen(false)
                    listCustomCITemplate()
                    return
                }
                message.error(`创建 CI 模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建 CI 模版失败 ${err}`)

            }
        )
    }
    const createCITemplateModalOnCancel = () => {
        setCreateCITemplateModalOpen(false)
    }

    // 列出 CI 模版
    const listCustomCITemplate = () => {
        ListCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setCiTemplateDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            name: dd.name,
                            createTime: dd.createTime,
                        })
                    }
                    setCiTemplateDataSource(tmp)
                    return
                }
                message.error(`列出 CI 模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCiTemplateDataSource([])
                message.error(`列出 CI 模版失败 ${err}`)
            }
        )
    }


    const delCustomCITemplateOnClick = (templateUuid) => {
        DelCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            uuid: templateUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    listCustomCITemplate()
                    return
                }

                message.error(`删除 CI 模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 CI 模版失败 ${err}`)
            }
        )
    }


    // 添加 CI 模版步骤
    const [addCITemplateStepModalOpen, setAddCITemplateStepModalOpen] = useState(false)
    const [listCITemplateStepModalOpen, setListCITemplateStepModalOpen] = useState(false)

    //
    const [addCITemplateStepTemplateUuid, setAddCITemplateStepTemplateUuid] = useState('')

    const [addCITemplateStepIsPublicOptionsDefaultValue, setAddCITemplateStepIsPublicOptionsDefaultValue] = useState(true)

    const addCITemplateStepIsPublicOptions = [
        {
            value: true,
            label: "公共模版",
        },
        {
            value: false,
            label: "私有模版",
        },
    ]

    const addCITemplateStepIsPublicOptionsOnChange = (value) => {
        setAddCITemplateStepIsPublicOptionsDefaultValue(value)
        setAddCITemplateStepTypeTemplateUuidOptions([])
        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
    }

    //
    const addCITemplateStepTypeOptions = [
        {
            value: 1,
            label: "代码扫描",
        },
        {
            value: 2,
            label: "单元测试",
        },
        {
            value: 3,
            label: "构建程序",
        },
        {
            value: 4,
            label: "上传程序",
        },
        {
            value: 5,
            label: "构建容器镜像",
        },
    ]

    const [addCITemplateStepTypeOptionsDefaultValue, setAddCITemplateStepTypeOptionsDefaultValue] = useState(1)

    const addCITemplateStepTypeOptionsOnChange = (value) => {
        setAddCITemplateStepTypeOptionsDefaultValue(value)
        setAddCITemplateStepTypeTemplateUuidOptions([])
        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
    }

    //
    const reloadAddCITemplateStepTypeTemplateUuidOptionsOnClick = () => {

        let templateType
        switch (addCITemplateStepTypeOptionsDefaultValue) {
            case 1:

                if (addCITemplateStepIsPublicOptionsDefaultValue) {
                    templateType = 1
                } else {
                    templateType = 2
                }

                ListCodeScanTemplate({
                    orgUuid: OrgUuid,
                    productUuid: OrgProductUuid,
                    templateType: templateType,
                }).then(
                    data => {
                        const errCode = data.errCode
                        const errMsg = data.errMsg
                        if (errCode === 0) {
                            const d = data.data
                            if (d === null) {
                                setAddCITemplateStepTypeTemplateUuidOptions([])
                                setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                                return
                            }

                            let tmp = []
                            for (const dd of d) {
                                tmp.push({
                                    value: dd.uuid,
                                    label: dd.describe,
                                })
                            }
                            setAddCITemplateStepTypeTemplateUuidOptions(tmp)
                            setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                            return;
                        }
                        message.error(`列出代码扫描模版失败 ${errMsg}`)
                    }
                ).catch(
                    err => {
                        setAddCITemplateStepTypeTemplateUuidOptions([])
                        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                        message.error(`列出代码扫描模版失败 ${err}`)
                    }
                )

                break
            case 2:

                if (addCITemplateStepIsPublicOptionsDefaultValue) {
                    templateType = 1
                } else {
                    templateType = 2
                }

                ListUnitTestTemplate({
                    orgUuid: OrgUuid,
                    productUuid: OrgProductUuid,
                    templateType: templateType,
                }).then(
                    data => {
                        const errCode = data.errCode
                        const errMsg = data.errMsg
                        if (errCode === 0) {
                            const d = data.data
                            if (d === null) {
                                setAddCITemplateStepTypeTemplateUuidOptions([])
                                setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                                return
                            }

                            let tmp = []
                            for (const dd of d) {
                                tmp.push({
                                    value: dd.uuid,
                                    label: dd.describe,
                                })
                            }
                            setAddCITemplateStepTypeTemplateUuidOptions(tmp)
                            return;
                        }
                        message.error(`列出单元测试模版失败 ${errMsg}`)
                    }
                ).catch(
                    err => {
                        setAddCITemplateStepTypeTemplateUuidOptions([])
                        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                        message.error(`列出单元测试模版失败 ${err}`)
                    }
                )

                break
            case 3:

                if (addCITemplateStepIsPublicOptionsDefaultValue) {
                    templateType = 1
                } else {
                    templateType = 2
                }

                ListBuildProgramTemplate({
                    orgUuid: OrgUuid,
                    productUuid: OrgProductUuid,
                    templateType: templateType,
                }).then(
                    data => {
                        const errCode = data.errCode
                        const errMsg = data.errMsg
                        if (errCode === 0) {
                            const d = data.data
                            if (d === null) {
                                setAddCITemplateStepTypeTemplateUuidOptions([])
                                setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                                return
                            }

                            let tmp = []
                            for (const dd of d) {
                                tmp.push({
                                    value: dd.uuid,
                                    label: dd.describe,
                                })
                            }
                            setAddCITemplateStepTypeTemplateUuidOptions(tmp)
                            return;
                        }
                        message.error(`列出构建程序模版失败 ${errMsg}`)
                    }
                ).catch(
                    err => {
                        setAddCITemplateStepTypeTemplateUuidOptions([])
                        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                        message.error(`列出构建程序模版失败 ${err}`)
                    }
                )

                break
            case 4:

                if (addCITemplateStepIsPublicOptionsDefaultValue) {
                    templateType = 1
                } else {
                    templateType = 2
                }

                ListUploadProgramTemplate({
                    orgUuid: OrgUuid,
                    productUuid: OrgProductUuid,
                    templateType: templateType,
                }).then(
                    data => {
                        const errCode = data.errCode
                        const errMsg = data.errMsg
                        if (errCode === 0) {
                            const d = data.data
                            if (d === null) {
                                setAddCITemplateStepTypeTemplateUuidOptions([])
                                setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                                return
                            }

                            let tmp = []
                            for (const dd of d) {
                                tmp.push({
                                    value: dd.uuid,
                                    label: dd.describe,
                                })
                            }
                            setAddCITemplateStepTypeTemplateUuidOptions(tmp)
                            return;
                        }
                        message.error(`列出上传程序模版失败 ${errMsg}`)
                    }
                ).catch(
                    err => {
                        setAddCITemplateStepTypeTemplateUuidOptions([])
                        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                        message.error(`列出上传程序模版失败 ${err}`)
                    }
                )

                break
            case 5:

                if (addCITemplateStepIsPublicOptionsDefaultValue) {
                    templateType = 1
                } else {
                    templateType = 2
                }

                ListBuildContainerImageTemplate({
                    orgUuid: OrgUuid,
                    productUuid: OrgProductUuid,
                    templateType: templateType,
                }).then(
                    data => {
                        const errCode = data.errCode
                        const errMsg = data.errMsg
                        if (errCode === 0) {
                            const d = data.data
                            if (d === null) {
                                setAddCITemplateStepTypeTemplateUuidOptions([])
                                setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                                return
                            }

                            let tmp = []
                            for (const dd of d) {
                                tmp.push({
                                    value: dd.uuid,
                                    label: dd.describe,
                                })
                            }
                            setAddCITemplateStepTypeTemplateUuidOptions(tmp)
                            return;
                        }
                        message.error(`列出构建容器镜像模版失败 ${errMsg}`)
                    }
                ).catch(
                    err => {
                        setAddCITemplateStepTypeTemplateUuidOptions([])
                        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue('')
                        message.error(`列出构建容器镜像模版失败 ${err}`)
                    }
                )

                break

            default:

                message.error(`未知的模版类型`)
                break
        }
    }

    const [addCITemplateStepTypeTemplateUuidOptions, setAddCITemplateStepTypeTemplateUuidOptions] = useState([])
    const [addCITemplateStepTypeTemplateUuidOptionsDefaultValue, setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue] = useState('')

    const addCITemplateStepTypeTemplateUuidOptionsOnChange = (value) => {
        setAddCITemplateStepTypeTemplateUuidOptionsDefaultValue(value)
    }

    //
    const addCITemplateStepPriorityOptions = [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
        {
            value: 6,
            label: "6",
        },
        {
            value: 7,
            label: "7",
        },
        {
            value: 8,
            label: "8",
        },
        {
            value: 9,
            label: "9",
        },
        {
            value: 10,
            label: "10",
        },
    ]

    const [addCITemplateStepPriorityOptionsDefaultValue, setAddCITemplateStepPriorityOptionsDefaultValue] = useState(1)

    const addCITemplateStepPriorityOptionsOnChange = (value) => {
        setAddCITemplateStepPriorityOptionsDefaultValue(value)
    }


    //

    const addCITemplateStepOnClick = (templateUuid) => {
        setAddCITemplateStepTemplateUuid(templateUuid)
        setAddCITemplateStepModalOpen(true)
    }

    const addCITemplateStepModalOnOk = () => {
        AddCiTemplateStep({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: addCITemplateStepTemplateUuid,
            stepType: addCITemplateStepTypeOptionsDefaultValue,
            stepTypeTemplateIsPublic: addCITemplateStepIsPublicOptionsDefaultValue,
            stepTypeTemplateUuid: addCITemplateStepTypeTemplateUuidOptionsDefaultValue,
            priority: addCITemplateStepPriorityOptionsDefaultValue,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setAddCITemplateStepModalOpen(false)
                    message.info(`添加CI模版子步骤成功`)
                    return
                }

                message.error(`添加CI模版子步骤失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加CI模版子步骤失败 ${err}`)
            }
        )
    }

    const addCITemplateStepModalOnCancel = () => {
        setAddCITemplateStepModalOpen(false)
    }

    const listCITemplateStepOnClick = (templateUuid) => {
        ListCiTemplateStep({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setCiTemplateStepDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            templateUuid: dd.templateUuid,
                            stepType: dd.stepType,
                            stepTypeTemplateIsPublic: dd.stepTypeTemplateIsPublic,
                            stepTypeTemplateUuid: dd.stepTypeTemplateUuid,
                            priority: dd.priority,
                            createTime: dd.createTime,
                            describe: dd.describe,
                        })
                    }
                    setCiTemplateStepDataSource(tmp)
                    setListCITemplateStepModalOpen(true)
                    return
                }
                message.error(`列出CI模版步骤失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出CI模版步骤失败 ${err}`)
            }
        )
    }

    const listCITemplateStepModalOnOk = () => {
        setListCITemplateStepModalOpen(false)
    }

    const listCITemplateStepModalOnCancel = () => {
        setListCITemplateStepModalOpen(false)
    }

    //
    const delCiTemplateStepOnClick = (templateUuid, templateStepUuid) => {
        DelCiTemplateStep({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid,
            templateStepUuid: templateStepUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除Ci模版步骤成功`)
                    listCITemplateStepOnClick(templateUuid)
                    return
                }
                message.error(`删除Ci模版步骤失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除Ci模版步骤失败 ${err}`)
            }
        )
    }

    // 1. 代码扫描
    const codeScanColumns = [
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid'
        },
        {
            title: '公共模块',
            dataIndex: 'public',
            key: 'public',
            render: (text) => {
                if (text) {
                    return '是'
                } else {
                    return '否'
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '命令',
            dataIndex: 'command',
            key: 'command'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                if (record.public) {

                } else {
                    return <>
                        <Popconfirm
                            title={'删除代码扫描模版'}
                            onConfirm={() => delCodeScanTemplateOnClick(record.uuid)}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                }
            }
        }
    ]

    const [codeScanDataSource, setCodeScanDataSource] = useState([])

    const listCodeScanTemplateOnClick = () => {
        ListCodeScanTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setCodeScanDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            public: dd.public,
                            describe: dd.describe,
                            command: dd.command,
                            createTime: dd.createTime
                        })
                    }
                    setCodeScanDataSource(tmp)
                    return;
                }
                message.error(`列出代码扫描模版失败 ${errMsg}`)
                setCodeScanDataSource([])
            }
        ).catch(
            err => {
                setCodeScanDataSource([])
                message.error(`列出代码扫描模版失败 ${err}`)
            }
        )
    }

    const delCodeScanTemplateOnClick = (templateUuid) => {
        DelCodeScanTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除代码扫描模版成功`)
                    listCodeScanTemplateOnClick()
                    return
                }

                message.error(`删除代码扫描模版失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                message.error(`删除代码扫描模版失败 ${err}`)
            }
        )
    }


    const [createCodeScanTemplateModalOpen, setCreateCodeScanTemplateModalOpen] = useState(false)

    const createCodeScanTemplateOnClick = () => {
        setCreateCodeScanTemplateModalOpen(true)
    }

    const createCodeScanTemplateModalOnOk = () => {

        const createCodeScanTemplateCommand = document.getElementById('createCodeScanTemplateCommand').value
        const createCodeScanTemplateDescribe = document.getElementById('createCodeScanTemplateDescribe').value

        CreateCodeScanTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            command: createCodeScanTemplateCommand,
            describe: createCodeScanTemplateDescribe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateCodeScanTemplateModalOpen(false)
                    message.info(`创建代码扫描模版成功`)
                    listCodeScanTemplateOnClick()
                    return
                }

                message.error(`创建代码扫描模版失败 ${errMsg}`)

            }
        ).catch(
            err => {
                message.error(`创建代码扫描模版失败 ${err}`)
            }
        )
    }

    const createCodeScanTemplateModalOnCancel = () => {
        setCreateCodeScanTemplateModalOpen(false)
    }


    // 2. 单元测试
    const unitTestColumns = [
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid'
        },
        {
            title: '公共模块',
            dataIndex: 'public',
            key: 'public',
            render: (text) => {
                if (text) {
                    return '是'
                } else {
                    return '否'
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '命令',
            dataIndex: 'command',
            key: 'command'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                if (record.public) {

                } else {
                    return <>
                        <Popconfirm
                            title={'删除代码扫描模版'}
                            onConfirm={() => delUnitTestTemplateOnClick(record.uuid)}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                }
            }
        }
    ]

    const [unitTestDataSource, setUnitTestDataSource] = useState([])

    const listUnitTestTemplateOnClick = () => {
        ListUnitTestTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setUnitTestDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            public: dd.public,
                            describe: dd.describe,
                            command: dd.command,
                            createTime: dd.createTime
                        })
                    }
                    setUnitTestDataSource(tmp)
                    return;
                }
                message.error(`列出代码扫描模版失败 ${errMsg}`)
                setUnitTestDataSource([])
            }
        ).catch(
            err => {
                setUnitTestDataSource([])
                message.error(`列出代码测试模版失败 ${err}`)
            }
        )
    }

    const delUnitTestTemplateOnClick = (templateUuid) => {
        DelUnitTestTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除单元测试模版成功`)
                    listUnitTestTemplateOnClick()
                    return
                }
                message.error(`删除单元测试模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除单元测试模版失败 ${err}`)
            }
        )
    }


    const [createUnitTestTemplateModalOpen, setCreateUnitTestTemplateModalOpen] = useState(false)

    const createUnitTestTemplateOnClick = () => {
        setCreateUnitTestTemplateModalOpen(true)
    }

    const createUnitTestTemplateModalOnOk = () => {
        const createUnitTestTemplateCommand = document.getElementById('createUnitTestTemplateCommand').value
        const createUnitTestTemplateDescribe = document.getElementById('createUnitTestTemplateDescribe').value

        CreateUnitTestTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            command: createUnitTestTemplateCommand,
            describe: createUnitTestTemplateDescribe

        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateUnitTestTemplateModalOpen(false)
                    message.info(`创建单元测试模版成功`)
                    listUnitTestTemplateOnClick()
                    return
                }
                message.error(`创建单元测试模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建单元测试模版失败 ${err}`)
            }
        )
    }

    const createUnitTestTemplateModalOnCancel = () => {
        setCreateUnitTestTemplateModalOpen(false)
    }


    // 3. 构建程序
    const buildProgramColumns = [
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid'
        },
        {
            title: '公共模块',
            dataIndex: 'public',
            key: 'public',
            render: (text) => {
                if (text) {
                    return '是'
                } else {
                    return '否'
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '命令',
            dataIndex: 'command',
            key: 'command'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                if (record.public) {

                } else {
                    return <>
                        <Popconfirm
                            title={'删除代码扫描模版'}
                            onConfirm={() => delBuildProgramTemplateOnClick(record.uuid)}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                }
            }
        }
    ]

    const [buildProgramDataSource, setBuildProgramDataSource] = useState([])

    const listBuildProgramTemplateOnClick = () => {
        ListBuildProgramTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setBuildProgramDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            public: dd.public,
                            describe: dd.describe,
                            command: dd.command,
                            createTime: dd.createTime
                        })
                    }
                    setBuildProgramDataSource(tmp)
                    return;
                }
                message.error(`列出构建程序模版失败 ${errMsg}`)
                setBuildProgramDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出构建程序模版失败 ${err}`)
            }
        )
    }

    const delBuildProgramTemplateOnClick = (templateUuid) => {
        DelBuildProgramTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid

        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info('删除构建程序模版成功')
                    listBuildProgramTemplateOnClick()
                    return;
                }
                message.error(`删除构建程序模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除构建程序模版失败 ${err}`)
            }
        )
    }

    const [createBuildProgramTemplateModalOpen, setCreateBuildProgramTemplateModalOpen] = useState(false)

    const createBuildProgramTemplateOnClick = () => {
        setCreateBuildProgramTemplateModalOpen(true)
    }

    const createBuildProgramTemplateModalOnOk = () => {

        const createBuildProgramTemplateCommand = document.getElementById('createBuildProgramTemplateCommand').value
        const createBuildProgramTemplateDescribe = document.getElementById('createBuildProgramTemplateDescribe').value

        CreateBuildProgramTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            command: createBuildProgramTemplateCommand,
            describe: createBuildProgramTemplateDescribe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateBuildProgramTemplateModalOpen(false)
                    message.info(`创建构建程序模版成功`)
                    listBuildProgramTemplateOnClick()
                    return
                }
                message.error(`创建构建程序模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建构建程序模版失败 ${err}`)
            }
        )
    }

    const createBuildProgramTemplateModalOnCancel = () => {
        setCreateBuildProgramTemplateModalOpen(false)
    }


    // 4. 构建容器镜像

    const buildContainerImageColumns = [
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid'
        },
        {
            title: '容器镜像仓库Uuid',
            dataIndex: 'containerImageRegisterUuid',
            key: 'containerImageRegisterUuid'
        },
        {
            title: '命名空间',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'dockerfile路径',
            dataIndex: 'dockerFilePath',
            key: 'dockerFilePath'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                if (record.public) {

                } else {
                    return <>
                        <Popconfirm
                            title={'删除代码扫描模版'}
                            onConfirm={() => delBuildContainerImageTemplateOnClick(record.uuid)}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                }
            }
        }
    ]

    const [buildContainerImageDataSource, setBuildContainerImageDataSource] = useState([])

    // 列出构建容器镜像模版
    const listBuildContainerImageTemplateOnClick = () => {
        ListBuildContainerImageTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setBuildContainerImageDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            containerImageRegisterUuid: dd.containerImageRegisterUuid,
                            namespace: dd.namespace,
                            dockerFilePath: dd.dockerFilePath,
                            describe: dd.describe,
                            createTime: dd.createTime
                        })
                    }
                    setBuildContainerImageDataSource(tmp)
                    return;
                }
                message.error(`列出构建容器镜像模版失败 ${errMsg}`)
                setBuildContainerImageDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出构建容器镜像模版失败 ${err}`)
            }
        )
    }

    // 删除构建容器镜像模版
    const delBuildContainerImageTemplateOnClick = (templateUuid) => {
        DelBuildContainerImageTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            templateUuid: templateUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除构建容器镜像模版成功`)
                    listBuildContainerImageTemplateOnClick()
                    return
                }
                message.error(`删除构建容器镜像模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除构建容器镜像模版失败 ${err}`)
            }
        )
    }



    //
    const [containerRegistrySelectOptions, setContainerRegistrySelectOptions] = useState([])
    const [containerRegistrySelectDefaultValue, setContainerRegistrySelectDefaultValue] = useState('')

    const containerRegistrySelectOnChange = (value) => {
        setContainerRegistrySelectDefaultValue(value)
    }

    const listContainerRegistryHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const containerRegistrys = data.containerRegistry
                    if (containerRegistrys === null) {
                        setContainerRegistrySelectOptions([])
                        return;
                    }
                    let tmp = []
                    for (const containerRegistry of containerRegistrys) {
                        tmp.push(
                            {
                                value: containerRegistry.uuid,
                                label: containerRegistry.username + '-' + containerRegistry.url
                            }
                        )
                    }
                    setContainerRegistrySelectOptions(tmp)
                    return
                }
                setContainerRegistrySelectOptions([])
                message.error(`列出容器镜像仓库失败, ${errMsg}`)
            }
        ).catch(
            err => {
                setContainerRegistrySelectOptions([])
                message.error(`列出容器镜像仓库失败 ${err}`)
            }
        )
    }
    //
    const [createBuildContainerImageTemplateModalOpen, setCreateBuildContainerImageTemplateModalOpen] = useState(false)

    const createBuildContainerImageTemplateOnClick = () => {
        listContainerRegistryHandler()
        setCreateBuildContainerImageTemplateModalOpen(true)
    }


    // 创建容器镜像模版
    const createBuildContainerImageTemplateModalOnOk = () => {

        const createBuildContainerImageTemplateNamespace = document.getElementById('createBuildContainerImageTemplateNamespace').value
        const createBuildContainerImageTemplateDockerFilePath = document.getElementById('createBuildContainerImageTemplateDockerFilePath').value
        const createBuildContainerImageTemplateDescribe = document.getElementById('createBuildContainerImageTemplateDescribe').value

        CreateBuildContainerImageTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            containerImageRegisterUuid: containerRegistrySelectDefaultValue,
            namespace: createBuildContainerImageTemplateNamespace,
            dockerFilePath: createBuildContainerImageTemplateDockerFilePath,
            describe: createBuildContainerImageTemplateDescribe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setCreateBuildContainerImageTemplateModalOpen(false)
                    message.info(`创建构建容器镜像模版成功`)
                    listBuildContainerImageTemplateOnClick()
                    return
                }
                message.error(`创建构建容器镜像模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建构建容器镜像模版失败 ${err}`)
            }
        )
    }

    const createBuildContainerImageTemplateModalOnCancel = () => {
        setCreateBuildContainerImageTemplateModalOpen(false)
    }


    // 5. 上传程序


    const uploadProgramColumns = [
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid'
        },
        {
            title: '公共模块',
            dataIndex: 'public',
            key: 'public',
            render: (text) => {
                if (text) {
                    return '是'
                } else {
                    return '否'
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '命令',
            dataIndex: 'command',
            key: 'command'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                if (record.public) {

                } else {
                    return <>
                        <Popconfirm
                            title={'删除代码扫描模版'}
                            onConfirm={() => delUploadProgramTemplateOnClick(record.uuid)}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                }
            }
        }
    ]

    const [uploadProgramDataSource, setUploadProgramDataSource] = useState([])

    const listUploadProgramTemplateOnClick = () => {
        ListUploadProgramTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setUploadProgramDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            public: dd.public,
                            describe: dd.describe,
                            command: dd.command,
                            createTime: dd.createTime
                        })
                    }
                    setUploadProgramDataSource(tmp)
                    return;
                }
                message.error(`列出代码扫描模版失败 ${errMsg}`)
                setUploadProgramDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出构建容器镜像模版失败 ${err}`)
            }
        )
    }

    const delUploadProgramTemplateOnClick = () => {
        DelUploadProgramTemplate({}).then(
            data => {

            }
        ).catch(
            err => {
                message.error(`删除构建容器镜像模版失败 ${err}`)
            }
        )
    }


    const [createUploadProgramTemplateModalOpen, setCreateUploadProgramTemplateModalOpen] = useState(false)

    const createUploadProgramTemplateOnClick = () => {
        setCreateUploadProgramTemplateModalOpen(true)
    }

    const createUploadProgramTemplateModalOnOk = () => {
        setCreateUploadProgramTemplateModalOpen(false)
    }

    const createUploadProgramTemplateModalOnCancel = () => {
        setCreateUploadProgramTemplateModalOpen(false)
    }


    // 9.1
    const [defaultActiveKey, setDefaultActiveKey] = useState('')

    const ciPipelineOnChange = (activeKey) => {
        switch (activeKey) {
            case 'ciTemplate':
                setDefaultActiveKey('ciTemplate')
                break

            case 'codeScan':
                listCodeScanTemplateOnClick()
                setDefaultActiveKey('codeScan')
                break

            case 'unitTest':
                listUnitTestTemplateOnClick()
                setDefaultActiveKey('unitTest')
                break

            case 'buildProgram':
                listBuildProgramTemplateOnClick()
                setDefaultActiveKey('buildProgram')
                break

            case 'buildContainerImage':
                listBuildContainerImageTemplateOnClick()
                setDefaultActiveKey('buildContainerImage')
                break

            case 'uploadProgram':
                listUploadProgramTemplateOnClick()
                setDefaultActiveKey('uploadProgram')
                break

            default:
                break
        }
    }

    //
    useEffect(() => {
        listCustomCITemplate()
    }, [''])


    return (
        <>
            <b><pre style={{
                color: 'red'
            }}>出于安全考虑，暂时不支持单元测试功能</pre>
            </b>

            <b><pre style={{
                color: 'red'
            }}>CI 步骤不支持自定义脚本 (杜绝可能存在的部分安全问题)</pre>
            </b>

            <b><pre style={{
                color: 'red'
            }}>e.g: make build 风险, 在 Makefile 中 build 部分删除 .ssh/authorized_keys 文件</pre>
            </b>


            <Tabs
                type="card"
                onChange={ciPipelineOnChange}
                defaultActiveKey={defaultActiveKey}
                items={[
                    {
                        label: 'Ci模版',
                        key: 'ciTemplate',
                        children: <>
                            <Button type={'primary'} onClick={createCITemplateModalOnClick}>新增模版</Button>

                            <Button type={'primary'} onClick={listCustomCITemplate}>刷新</Button>

                            <Table columns={ciTemplateColumns}
                                   dataSource={ciTemplateDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    },
                    {
                        label: '代码扫描模版',
                        key: 'codeScan',
                        children: <>
                            <Button type={'primary'} onClick={createCodeScanTemplateOnClick}>创建私有模版</Button>
                            <Table columns={codeScanColumns}
                                   dataSource={codeScanDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    },
                    {
                        label: '单元测试模版',
                        key: 'unitTest',
                        children: <>
                            <Button type={'primary'} onClick={createUnitTestTemplateOnClick}>创建私有模版</Button>
                            <Table columns={unitTestColumns}
                                   dataSource={unitTestDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    },
                    {
                        label: '构建程序模版',
                        key: 'buildProgram',
                        children: <>
                            <Button type={'primary'} onClick={createBuildProgramTemplateOnClick}>创建私有模版</Button>
                            <Table columns={buildProgramColumns}
                                   dataSource={buildProgramDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    },
                    {
                        label: '构建容器镜像模版',
                        key: 'buildContainerImage',
                        children: <>
                            <Button type={'primary'}
                                    onClick={createBuildContainerImageTemplateOnClick}>创建私有模版</Button>
                            <Table columns={buildContainerImageColumns}
                                   dataSource={buildContainerImageDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    },
                    {
                        label: '上传程序模版',
                        key: 'uploadProgram',
                        children: <>
                            <Button type={'primary'} onClick={createUploadProgramTemplateOnClick}>创建私有模版</Button>
                            <Table columns={uploadProgramColumns}
                                   dataSource={uploadProgramDataSource}
                                   scroll={{x: true, y: 400}}/>
                        </>
                    }

                ]}
            />


            {/* 创建CI模版 */}
            <Modal
                title={'创建CI模版'}
                open={createCITemplateModalOpen}
                onOk={createCITemplateModalOnOk}
                onCancel={createCITemplateModalOnCancel}
                destroyOnClose={true}
            >
                <Space>name</Space><Input id={'createCITemplateName'}/>
            </Modal>


            {/* 添加CI模版步骤 */}
            <Modal
                title={'添加CI模版步骤'}
                open={addCITemplateStepModalOpen}
                onOk={addCITemplateStepModalOnOk}
                onCancel={addCITemplateStepModalOnCancel}
                destroyOnClose={true}
            >
                <span>模版Uuid</span><Input defaultValue={addCITemplateStepTemplateUuid} disabled={true}/>

                <span>步骤类型</span>
                <br/>
                <Select
                    style={{
                        width: 470,
                    }}
                    options={addCITemplateStepTypeOptions}
                    defaultValue={addCITemplateStepTypeOptionsDefaultValue}
                    onChange={addCITemplateStepTypeOptionsOnChange}
                />
                <br/>

                <span>是否公共模版</span>
                <br/>
                <Select
                    style={{
                        width: 470,
                    }}
                    options={addCITemplateStepIsPublicOptions}
                    defaultValue={addCITemplateStepIsPublicOptionsDefaultValue}
                    onChange={addCITemplateStepIsPublicOptionsOnChange}
                />
                <br/>


                <span>步骤模版Uuid</span>
                <Button
                    icon={<ReloadOutlined />}
                    type={'primary'}
                    onClick={reloadAddCITemplateStepTypeTemplateUuidOptionsOnClick}
                >刷新</Button>

                <br/>
                <Select
                    style={{
                        width: 470,
                    }}
                    options={addCITemplateStepTypeTemplateUuidOptions}
                    defaultValue={addCITemplateStepTypeTemplateUuidOptionsDefaultValue}
                    onChange={addCITemplateStepTypeTemplateUuidOptionsOnChange}
                />


                <br/>

                <span>优先级 (暂时无作用)</span>
                <br/>
                <Select
                    style={{
                        width: 470,
                    }}
                    options={addCITemplateStepPriorityOptions}
                    defaultValue={addCITemplateStepPriorityOptionsDefaultValue}
                    onChange={addCITemplateStepPriorityOptionsOnChange}
                />

            </Modal>


            {/* 列出CI模版步骤 */}
            <Modal
                width={'100%'}
                title={'列出CI模版步骤'}
                open={listCITemplateStepModalOpen}
                onOk={listCITemplateStepModalOnOk}
                onCancel={listCITemplateStepModalOnCancel}
                destroyOnClose={true}
            >
                <Table
                    columns={ciTemplateStepColumns}
                    dataSource={ciTemplateStepDataSource}
                />
            </Modal>


            {/* 1. 添加代码扫描私有模版 */}
            <Modal
                title={'添加代码扫描私有模版'}
                open={createCodeScanTemplateModalOpen}
                onOk={createCodeScanTemplateModalOnOk}
                onCancel={createCodeScanTemplateModalOnCancel}
                destroyOnClose={true}
            >
                <span>命令</span><Input id={'createCodeScanTemplateCommand'}/>
                <span>描述</span><Input id={'createCodeScanTemplateDescribe'}/>
            </Modal>


            {/* 2. 添加单元测试私有模版 */}
            <Modal
                title={'添加单元测试私有模版'}
                open={createUnitTestTemplateModalOpen}
                onOk={createUnitTestTemplateModalOnOk}
                onCancel={createUnitTestTemplateModalOnCancel}
                destroyOnClose={true}
            >

                <span>命令</span><Input id={'createUnitTestTemplateCommand'}/>
                <span>描述</span><Input id={'createUnitTestTemplateDescribe'}/>
            </Modal>


            {/* 添加构建程序私有模版 */}
            <Modal
                title={'添加构建程序私有模版'}
                open={createBuildProgramTemplateModalOpen}
                onOk={createBuildProgramTemplateModalOnOk}
                onCancel={createBuildProgramTemplateModalOnCancel}
                destroyOnClose={true}
            >
                <span>命令</span><Input id={'createBuildProgramTemplateCommand'}/>
                <span>描述</span><Input id={'createBuildProgramTemplateDescribe'}/>
            </Modal>


            {/* 添加构建容器镜像私有模版 */}
            <Modal
                title={'添加构建容器镜像私有模版'}
                open={createBuildContainerImageTemplateModalOpen}
                onOk={createBuildContainerImageTemplateModalOnOk}
                onCancel={createBuildContainerImageTemplateModalOnCancel}
                destroyOnClose={true}
            >
                <span>容器镜像仓库Uuid</span>
                <Select
                    style={{
                        width: 470
                    }}
                    onChange={containerRegistrySelectOnChange}
                    options={containerRegistrySelectOptions}
                    defaultValue={containerRegistrySelectDefaultValue}
                />

                <span>命名空间</span><Input id={'createBuildContainerImageTemplateNamespace'}/>
                <span>dockerfile路径</span><Input id={'createBuildContainerImageTemplateDockerFilePath'}/>
                <span>描述</span><Input id={'createBuildContainerImageTemplateDescribe'}/>
            </Modal>


            {/* 添加上传程序私有模版 */}
            <Modal
                title={'添加上传程序私有模版'}
                open={createUploadProgramTemplateModalOpen}
                onOk={createUploadProgramTemplateModalOnOk}
                onCancel={createUploadProgramTemplateModalOnCancel}
                destroyOnClose={true}
            >

            </Modal>

        </>
    );
}