import {Button, Input, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {AddContainerImage, DelContainerImage, ListContainerImage} from '../../apis/product/containerImage';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';
import {ListContainerRegistry} from '../../apis/product/containerRegistry';
import {CopyContainerImage, CopyContainerImageTaskHis} from '../../apis/ci/containerImage';

export function ListContainerImageRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['product']} defaultSelectedKeys={['containerImage']}></LeftSider>
                    <IndexRightBody>
                        <ListContainerImageContent></ListContainerImageContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export default function ListContainerImageContent() {
    const [containerImageDataSource, setContainerImageDataSource] = useState([])
    const containerImageColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length
        },
        {
            title: '镜像地址',
            dataIndex: 'imageUrl',
            key: 'imageUrl'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Popconfirm
                            title={'删除容器镜像'}
                            onConfirm={() => delContainerImageHandler(record)}
                        >
                            <Button type={'primary'} danger={true}>删除</Button>
                        </Popconfirm>
                    </>
                );
            }
        }
    ]
    //
    const delContainerImageHandler = (record) => {
        DelContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            containerImageUuid: record.key
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除容器镜像失败`)
                    listContainerImageHandler()
                    return
                }
                message.error(`删除容器镜像失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除容器镜像失败`)
            }
        )
    }

    const listContainerImageHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        const keyword = document.getElementById('keyword').value
        ListContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            keyword: keyword
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const containerImages = data.containerImages
                    if (containerImages === null) {
                        setContainerImageDataSource([])
                        return
                    }
                    let temp = []
                    for (const containerImage of containerImages) {
                        temp.push({
                            key: containerImage.uuid,
                            name: containerImage.name,
                            imageUrl: containerImage.imageUrl,
                            describe: containerImage.describe,
                            createTime: containerImage.createTime
                        })
                    }
                    setContainerImageDataSource(temp)
                    return;
                }
                message.error(`列出容器镜像失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出容器镜像失败`)
            }
        )
    }

    useEffect(() => {
        listContainerImageHandler()
    }, [])
    //
    const [createContainerImageOpenModal, setCreateContainerImageOpenModal] = useState(false)

    const createContainerImageOnClick = () => {
        setCreateContainerImageOpenModal(true)
    }

    const createContainerImageModalOnCancel = () => {
        setCreateContainerImageOpenModal(false)

    }
    const createContainerImageModalOnOk = () => {
        const name = document.getElementById('name').value
        const url = document.getElementById('url').value
        const describe = document.getElementById('describe').value
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        AddContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            name: name,
            imageUrl: url,
            describe: describe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    listContainerImageHandler()
                    message.info(`添加容器镜像成功`)
                    setCreateContainerImageOpenModal(false)
                    return
                }
                message.error(`添加容器镜像失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加容器镜像失败`)
            }
        )
    }
    //
    const searchContainerImageHandler = () => {
        listContainerImageHandler()
    }

    // 拷贝镜像
    const [copyContainerImageModalOpen, setCopyContainerImageModalOpen] = useState(false)

    const copyContainerImageOnClick = () => {
        listContainerRegistryHandler()
        setCopyContainerImageModalOpen(true)
    }

    const copyContainerImageModalOnOk = () => {

        const srcImage = document.getElementById('srcImage').value
        const destImage = document.getElementById('destImage').value
        //const timeout = document.getElementById('timeout').value

        if (srcImage === '' || destImage === '') {
            message.error(`srcImage/destImage 不能为空`)
            return
        }
        CopyContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            srcImageRegistryUuid: srcContainerRegistrySelectDefaultValue,
            srcImage: srcImage,
            destImageRegistryUuid: destContainerRegistrySelectDefaultValue,
            destImage: destImage
            //timeout: Number(timeout),
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info('拷贝容器镜像任务已提交')
                    setCopyContainerImageModalOpen(false)
                    return
                }

                message.error(`拷贝容器镜像失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`拷贝容器镜像失败 ${err}`)
            }
        )
    }

    const copyContainerImageModalOnCancel = () => {
        setCopyContainerImageModalOpen(false)
    }

    //
    const listCopyContainerImageTaskColumns = [
        {
            title: 'taskId',
            dataIndex: 'taskId'
        },
        {
            title: 'userUuid',
            dataIndex: 'userUuid'
        },
        {
            title: 'runId',
            dataIndex: 'runId'
        },
        {
            title: 'result',
            dataIndex: 'result',
            render: (text) => {
                let r
                switch (text) {
                    case 1:
                        r = '成功'
                        break
                    case 0:
                        r = '执行中'
                        break
                    case -1:
                        r = '失败'
                        break
                    default:
                        r = '未知'
                        break
                }
                return r
            }
        },
        {
            title: 'srcImage',
            dataIndex: 'srcImage'
        },
        {
            title: 'destImage',
            dataIndex: 'destImage'
        },
        {
            title: 'describe',
            dataIndex: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        }
    ]
    const [listCopyContainerImageTaskDataSource, setListCopyContainerImageTaskDataSource] = useState([])
    const [listCopyContainerImageTaskModalOpen, setListCopyContainerImageTaskModalOpen] = useState(false)

    const listCopyContainerImageTaskOnClick = () => {
        CopyContainerImageTaskHis({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {

                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data

                    if (d === null) {
                        setListCopyContainerImageTaskModalOpen(true)
                        setListCopyContainerImageTaskDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.taskId,
                            taskId: dd.taskId,
                            userUuid: dd.userUuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            runId: dd.runId,
                            srcImage: dd.srcImage,
                            destImage: dd.destImage,
                            result: dd.result,
                            describe: dd.describe,
                            createTime: dd.createTime
                        })
                    }
                    setListCopyContainerImageTaskDataSource(tmp)
                    setListCopyContainerImageTaskModalOpen(true)
                    return
                }

                message.error(`列出 Copy 容器镜像任务失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出 Copy 容器镜像任务失败 ${err}`)
            }
        )
    }

    const listCopyContainerImageTaskModalOnOk = () => {
        setListCopyContainerImageTaskDataSource([])
        setListCopyContainerImageTaskModalOpen(false)
    }

    const listCopyContainerImageTaskModalOnCancel = () => {
        setListCopyContainerImageTaskModalOpen(false)
    }


    //
    const [srcContainerRegistrySelectOptions, setSrcContainerRegistrySelectOptions] = useState([])
    const [destContainerRegistrySelectOptions, setDestContainerRegistrySelectOptions] = useState([])

    const [srcContainerRegistrySelectDefaultValue, setSrcContainerRegistrySelectDefaultValue] = useState('')
    const [destContainerRegistrySelectDefaultValue, setDestContainerRegistrySelectDefaultValue] = useState('')

    const srcContainerRegistrySelectOnChangeHandler = (value) => {
        setSrcContainerRegistrySelectDefaultValue(value)
    }

    const destContainerRegistrySelectOnChangeHandler = (value) => {
        setDestContainerRegistrySelectDefaultValue(value)
    }

    const listContainerRegistryHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const containerRegistrys = data.containerRegistry
                    if (containerRegistrys === null) {
                        setSrcContainerRegistrySelectOptions([])
                        setDestContainerRegistrySelectOptions([])
                        return;
                    }
                    let tmp = []
                    for (const containerRegistry of containerRegistrys) {
                        tmp.push(
                            {
                                value: containerRegistry.uuid,
                                label: containerRegistry.username + '-' + containerRegistry.url
                            }
                        )
                    }
                    setSrcContainerRegistrySelectOptions(tmp)
                    setDestContainerRegistrySelectOptions(tmp)
                    return
                }
                message.error(`列出容器镜像仓库失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出容器镜像仓库失败 ${err}`)
            }
        )
    }

    return (
        <>
            <Space>
                <Input.Search styles={{width: 200}} id={'keyword'} enterButton={true}
                              onSearch={searchContainerImageHandler} placeholder={'请输入关键字'}/>

                <Button type={'primary'} onClick={createContainerImageOnClick}>添加容器镜像</Button>

                <Button type={'primary'} onClick={copyContainerImageOnClick}>拷贝镜像</Button>

                <Button type={'primary'} onClick={listCopyContainerImageTaskOnClick}>拷贝镜像任务</Button>

            </Space>
            <Table
                columns={containerImageColumns}
                dataSource={containerImageDataSource}
                scroll={{x: true, y: 400}}
            />
            <Modal
                title={'添加容器镜像信息'}
                onCancel={createContainerImageModalOnCancel}
                onOk={createContainerImageModalOnOk}
                open={createContainerImageOpenModal}
                destroyOnClose={true}
            >
                <span>名称</span><Input id={'name'}/>
                <span>地址</span><Input id={'url'}/>
                <span>描述</span><Input id={'describe'}/>
            </Modal>


            <Modal
                title={'拷贝容器镜像'}
                open={copyContainerImageModalOpen}
                onOk={copyContainerImageModalOnOk}
                onCancel={copyContainerImageModalOnCancel}
                destroyOnClose={true}
            >

                <span>源仓库</span>
                <br/>

                <Select
                    style={{
                        width: 470
                    }}
                    onChange={srcContainerRegistrySelectOnChangeHandler}
                    options={srcContainerRegistrySelectOptions}
                    defaultValue={srcContainerRegistrySelectDefaultValue}
                />

                <br/>
                <span>源镜像</span><Input id={'srcImage'}/>
                <span>目标仓库</span>

                <br/>

                <Select
                    style={{
                        width: 470
                    }}
                    onChange={destContainerRegistrySelectOnChangeHandler}
                    options={destContainerRegistrySelectOptions}
                    defaultValue={destContainerRegistrySelectDefaultValue}
                />

                <br/>

                <span>目标镜像</span><Input id={'destImage'}/>

            </Modal>


            <Modal
                title={'拷贝容器镜像'}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={listCopyContainerImageTaskModalOpen}
                onOk={listCopyContainerImageTaskModalOnOk}
                onCancel={listCopyContainerImageTaskModalOnCancel}
                destroyOnClose={true}
                width={'100%'}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
            >
                <Table scroll={{x: true}} columns={listCopyContainerImageTaskColumns}
                       dataSource={listCopyContainerImageTaskDataSource}/>
            </Modal>
        </>
    );
}