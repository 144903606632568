import {Del, Get, HookMonitorReceiverApi, Post} from '../common';


export function CreateMonitorReceiver(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        receiver: props.receiver,
        messageGroupUuid: props.messageGroupUuid,
    }
    return Post({
        url: HookMonitorReceiverApi,
        params: params,
    })
}

export function ListMonitorReceiver(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    });
    return Get({
        url: `${HookMonitorReceiverApi}?${params}`,
    })
}

export function DelMonitorReceiver(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        receiverUuid: props.receiverUuid,
    });
    return Del({
        url: `${HookMonitorReceiverApi}?${params}`,
    })
}