import {Button, Input, message, Modal, Table} from 'antd';
import {
    CreateOrganizationProduct, CreateOrganizationProductEnv, DelOrganizationProductEnv,
    ListOrganizationProduct,
    ListOrganizationProductEnv
} from '../../apis/product/product';
import {
    OrgProductUuid, OrgUuid, VerifyOrgUuid, VerifyOrgUuidAndOrgProductUuid
} from '../../apis/user/org';
import {useEffect, useState} from 'react';
import {ConvertTimestamp} from '../common/date';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

export function ListOrganizationProductRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['organization']} defaultSelectedKeys={['listProduct']}></LeftSider>
                    <IndexRightBody>
                        <ListOrganizationProductContent></ListOrganizationProductContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

// 创建组织产品 View
export function CreateOrganizationProductContent() {

    const createOrganizationProductModalOkHandler = () => {
        const organizationProductName = document.getElementById('organizationProductName').value
        const logo = document.getElementById('logo').value
        const describe = document.getElementById('describe').value

        if (organizationProductName === '') {
            message.error('产品名不允许为空，请输入组织名')
            return;
        }

        if (!VerifyOrgUuid()) {
            return;
        }

        const params = {
            'orgUuid': OrgUuid,
            'productName': organizationProductName,
            'logo': logo,
            'describe': describe
        }
        CreateOrganizationProduct(params).then(
            data => {
                const errCode = data.errCode
                if (errCode !== 0) {
                    message.error('创建失败，Message: ' + data.errMsg)
                    return
                }
                message.success('创建成功')
            }
        )
    }
    const createOrganizationProductModalCancelHandler = () => {
    }

    Modal.confirm(
        {
            title: '创建产品',
            keyboard: true,
            content: <>
                <span>产品名:</span><Input id="organizationProductName" placeholder="请输入产品名"/>
                <span>Logo:</span><Input id="logo" placeholder="Logo"/>
                <span>描述:</span><Input id="describe" placeholder="描述"/>
            </>,
            onOk: createOrganizationProductModalOkHandler,
            onCancel: createOrganizationProductModalCancelHandler
        }
    )
}



// 列出组织产品 View
export function ListOrganizationProductContent() {
    //
    const [organizationProduct, setOrganizationProduct] = useState([])

    const organizationProductColumns = [
        {
            title: '产品名',
            dataIndex: 'productName',
            key: 'productName'
        },
        {
            title: 'logo',
            dataIndex: 'logo',
            key: 'logo'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'}>删除</Button>
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        if (!VerifyOrgUuid()) {
            return;
        }

        ListOrganizationProduct({
            orgUuid: OrgUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let tmpProductList = []
                    for (let product of data.result) {
                        tmpProductList.push(
                            {
                                key: product.uuid,
                                orgUuid: product.org_uuid,
                                productName: product.product_name,
                                logo: product.logo,
                                describe: product.describe,
                                createTime: product.createTime
                            }
                        )
                    }
                    setOrganizationProduct(tmpProductList)
                    return
                }
                console.log(`列出组织产品失败, ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }, [])
    return (
        <>
            <Table columns={organizationProductColumns} dataSource={organizationProduct}/>
        </>
    );
}

export function ListOrganizationProductEnvRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['organization']} defaultSelectedKeys={['listProductEnv']}></LeftSider>
                    <IndexRightBody>
                        <ListOrganizationProductEnvContent></ListOrganizationProductEnvContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

// 列出组织产品环境 View
export function ListOrganizationProductEnvContent() {
    //
    const organizationProductEnvColumns = [
        {
            title: '环境名',
            dataIndex: 'envName',
            key: 'envName'
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            sorter: (a, b) => a.createTime - b.createTime,
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Button type={'primary'}
                                onClick={() => deleteOrganizationProductEnvOnClick(record)}>删除</Button>
                    </>
                )
            }
        }
    ]
    //
    const deleteOrganizationProductEnvOnClick = (record) => {
        DelOrganizationProductEnv({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: record.key,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除组织产品环境成功`)
                    listOrganizationProductEnv()
                    return
                }
                message.error(`删除组织产品环境失败， ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除组织产品环境失败`)
            }
        )
    }
    //
    const [organizationProductEnvDataSource, setOrganizationProductEnvDataSource] = useState([])
    const listOrganizationProductEnv = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return;
        }

        ListOrganizationProductEnv({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let envs = data.env
                    let tempEnvs = []
                    for (let env of envs) {
                        tempEnvs.push(
                            {
                                key: env.uuid,
                                envName: env.envName,
                                priority: env.priority,
                                describe: env.describe,
                                createTime: env.createTime
                            }
                        )
                    }
                    setOrganizationProductEnvDataSource(tempEnvs)
                    return
                }
                console.log(`列出组织产品环境失败, ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`列出组织产品环境失败, ${err}`)
            }
        )
    }
    useEffect(() => {
        listOrganizationProductEnv()
        }, []
    )
    //
    const [createProductEnvOpenModal, setCreateProductEnvOpenModal] = useState(false)
    const createProductEnvOnClick = () => {
        setCreateProductEnvOpenModal(true)
    }
    const createProductEnvModalOnCancel = () => {
        setCreateProductEnvOpenModal(false)
    }
    const createProductEnvModalOnOk = () => {
        const envName = document.getElementById('envName').value
        const priority = document.getElementById('priority').value
        const describe = document.getElementById('describe').value
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        CreateOrganizationProductEnv({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            envName: envName,
            priority: parseInt(priority, 10),
            describe: describe
        })
            .then(
                data => {
                    const errCode = data.errCode
                    const errMsg = data.errMsg
                    if (errCode === 0) {
                        message.info(`创建产品环境成功`)
                        listOrganizationProductEnv()
                        setCreateProductEnvOpenModal(false)
                        return
                    }
                    message.error(`创建产品环境失败, ${errMsg}`)
                }
            )
            .catch(
                err => {
                    message.error(`创建产品环境失败`)
                }
            )
    }

    return (
        <>
            <Button type={'primary'} onClick={createProductEnvOnClick}>添加产品环境</Button>
            <Table columns={organizationProductEnvColumns} dataSource={organizationProductEnvDataSource}/>
            <Modal
                title={'添加产品环境'}
                open={createProductEnvOpenModal}
                onCancel={createProductEnvModalOnCancel}
                onOk={createProductEnvModalOnOk}
            >
                <span>环境名</span><Input id={'envName'}/>
                <span>优先级(值越大，优先级越高，最大10)</span><Input type={'number'} id={'priority'}/>
                <span>描述</span><Input id={'describe'}/>
            </Modal>
        </>
    );
}