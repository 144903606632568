import {Get, Post, RobotQuestionApi, RobotQuestionHisApi} from '../common';

export function CreateQuestion(props) {
    const params = {
        orgUuid: props.orgUuid,
        question: props.question,
    }
    return Post({
        url: `${RobotQuestionApi}`,
        params: params,
    })
}

export function GetQuestionAnswer(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        taskId: props.taskId,
    })
    return Get({
        url: `${RobotQuestionApi}?${params}`,
    })
}

export function GetQuestionHis(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
    })
    return Get({
        url: `${RobotQuestionHisApi}?${params}`,
    })
}