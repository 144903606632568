import {Button, DatePicker, Input, Modal, Select, Space, Timeline} from 'antd';
import dayjs from 'dayjs';
import {useState} from 'react';
import {message} from 'antd';
import {Table} from 'antd'
import {ConvertTimestamp, RangePresets} from '../common/date';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {AddFaultStream, CreateFault, DelFaultStream, ListFault, ListFaultStream} from '../../apis/workSpace/fault';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import React from 'react';

const {RangePicker} = DatePicker;
const {TextArea} = Input;

//
const spanStyle = {
    display: 'inline-block',
    width: 80
}

export function FaultRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['workSpace']} defaultSelectedKeys={['fault']}></LeftSider>
                    <IndexRightBody>
                        <FaultContent></FaultContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function FaultContent() {
    // 故障报告表列
    const faultColumns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: '故障等级',
            dataIndex: 'level',
            key: 'level'
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        /*
        {
            title: '概览',
            dataIndex: 'summary',
            key: 'summary'
        },
        {
            title: '原因',
            dataIndex: 'reason',
            key: 'reason'
        },
        {
            title: '优化方案',
            dataIndex: 'optimize',
            key: 'optimize'
        },
         */
        {
            title: '查看详情',
            dataIndex: 'author',
            render: (_, record) => {
                return (
                    <>
                        <Space>
                            <Button type={'primary'} onClick={() => getFaultDetailOnClick(record)}>查看详情</Button>
                            <Button type={'primary'} onClick={() => addFaultStreamOnClick(record)}>添加故障流</Button>
                            <Button type={'primary'} onClick={() => getFaultStreamOnClick(record)}>查看故障流</Button>
                        </Space>
                    </>
                );
            }
        }
    ]
    // 获取故障详情
    const getFaultDetailOnClick = (record) => {
        Modal.confirm({
            title: '故障报告详情',
            content: <>
                <pre>title: {record.title}</pre>
                <pre>level: {record.level}</pre>
                <pre>startTime: {record.startTime}</pre>
                <pre>endTime: {record.endTime}</pre>
                <pre>summary: {record.summary}</pre>
                <pre>reason: {record.reason}</pre>
                <pre>optimize: {record.optimize}</pre>
            </>
        })
    }
    //
    const [addFaultStreamFaultUuid, setAddFaultStreamFaultUuid] = useState()
    const [addFaultStreamOpenModal, setAddFaultStreamOpenModal] = useState(false)
    const addFaultStreamOnClick = (record) => {
        setAddFaultStreamFaultUuid(record.key)
        setAddFaultStreamOpenModal(true)
    }
    const addFaultStreamModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        const doOp = document.getElementById('do').value
        AddFaultStream({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            faultUuid: addFaultStreamFaultUuid,
            startTime: searchStartTimestamp,
            endTime: searchEndTimestamp,
            do: doOp,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加故障报告流成功`)
                    setAddFaultStreamOpenModal(false)
                    return
                }
                message.error(`添加故障报告流失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`添加故障报告流失败 ${err}`)
                message.error(`添加故障报告流失败`)
            }
        )
    }
    const addFaultStreamModalOnCancel = () => {
        setAddFaultStreamOpenModal(false)
    }
    //
    const getFaultStreamOnClick = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListFaultStream({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            faultUuid: record.key,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const faultStreams = data.faultStream
                    if (faultStreams === null) {
                        message.info(`该故障报告目前还未添加故障流`)
                        return;
                    }

                    let items = []
                    for (const faultStream of faultStreams) {
                        items.push({
                            label: `${ConvertTimestamp(faultStream.startTime)} - ${ConvertTimestamp(faultStream.endTime)}`,
                            children: `${faultStream.do}`,
                        })
                        //message.info(`${faultStream.uuid}  ${faultStream.startTime}  ${faultStream.endTime}  `)
                    }

                    Modal.confirm({
                        width: 800,
                        height: 600,
                        title: '故障报告流',
                        content: <>
                            <Timeline mode={'left'} items={items}/>
                        </>
                    })
                    return
                }
                message.error(`列出故障报告流失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`列出故障报告流失败 ${err}`)
                message.error(`列出故障报告流失败`)
            }
        )
    }

    //
    const delFaultStreamOnClickHandler = () => {
        DelFaultStream({

        }).then(
            data => {
                message.error(`删除故障报告流失败`)
            }
        ).catch(
            err => {
                console.log(`删除故障报告流失败 ${err}`)
                message.error(`删除故障报告流失败`)
            }
        )
    }

    //
    const [faultDataSource, setFaultDataSource] = useState([])
    //
    const [searchStartTimestamp, setSearchStartTimestamp] = useState(Math.floor(dayjs().add(-365, 'd').toDate().getTime() / 1000))
    const [searchEndTimestamp, setSearchEndTimestamp] = useState(Math.floor(dayjs().toDate().getTime() / 1000))
    const [openCreateFault, setOpenCreateFault] = useState(false)

    //
    const rangePresets = RangePresets
    const datePickerOnChangeHandler = (date, dateString) => {
        setSearchStartTimestamp(Math.floor(date[0].toDate().getTime() / 1000))
        setSearchEndTimestamp(Math.floor(date[1].toDate().getTime() / 1000))
    }
    // 查询故障报告
    const listFaultButtonOnClick = () => {
        if (searchStartTimestamp <= 0 || searchEndTimestamp <= 0) {
            message.warning(`时间异常，请重新选择. 开始时间: ${searchStartTimestamp} -> 结束时间: ${searchEndTimestamp}`)
            return
        }

        const titleKeyword = document.getElementById('titleKeyword').value
        ListFault({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            titleKeyword: titleKeyword,
            searchStartTime: searchStartTimestamp,
            searchEndTime: searchEndTimestamp
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const faults = data.faults
                    if (faults === null) {
                        setFaultDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const fault of faults) {
                        tmp.push({
                            uuid: fault.uuid,
                            key: fault.uuid,
                            orgUuid: fault.orgUuid,
                            productUuid: fault.productUuid,
                            title: fault.title,
                            level: fault.level,
                            startTime: fault.startTime,
                            endTime: fault.endTime,
                            summary: fault.summary,
                            reason: fault.reason,
                            optimize: fault.optimize
                        })
                    }
                    setFaultDataSource(tmp)
                    return
                }
                message.error(`列出故障报告失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出故障报告失败`)
                console.log(`列出故障报告失败 ${err}`)
            }
        )
    }

    // 创建故障报告调用弹框
    const createFaultButtonOnClick = () => {
        setOpenCreateFault(true)
    }
    //
    const [faultLevel, setFaultLevel] = useState(3)
    const faultLevelOnSelectHandler = (value) => {
        setFaultLevel(value)
    }
    const createFaultModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const title = document.getElementById('title').value
        const summary = document.getElementById('summary').value
        const reason = document.getElementById('reason').value
        const optimize = document.getElementById('optimize').value

        CreateFault({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            title: title,
            level: faultLevel,
            startTime: searchStartTimestamp,
            endTime: searchEndTimestamp,
            summary: summary,
            reason: reason,
            optimize: optimize
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info('创建故障报告成功')
                    setOpenCreateFault(false)
                    listFaultButtonOnClick()
                    return
                }

                message.error(`创建故障报告失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建故障报告失败`)
                console.log(`创建故障报告失败 ${err}`)
            }
        )
    }
    const createFaultModalOnCancel = () => {
        setOpenCreateFault(false)
    }


    return (
        <>
            <Space size={6}>
                <RangePicker
                    placeholder={['请选择开始时间', '请选择结束时间']}
                    presets={rangePresets}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={[dayjs().add(-365, 'd'), dayjs()]}
                    onChange={datePickerOnChangeHandler}
                />

                <span>标题关键字</span>
                <Input id={'titleKeyword'}/>

                <Button
                    onClick={listFaultButtonOnClick}
                    type={'primary'}
                >查询</Button>
                <Button
                    onClick={createFaultButtonOnClick}
                    type={'primary'}
                >
                    添加故障报告</Button>
            </Space>
            <Table columns={faultColumns} dataSource={faultDataSource}/>


            <Modal
                title={'创建故障报告'}
                open={openCreateFault}
                maskClosable={false}
                onOk={createFaultModalOnOk}
                onCancel={createFaultModalOnCancel}
                width={800}
                height={600}
            >

                <div style={{display: 'flex'}}>
                    <span style={spanStyle}>标题</span>
                    <Input
                        placeholder={'标题'}
                        id={'title'}
                    />
                </div>

                <div style={{display: 'flex'}}>
                    <span style={spanStyle}>级别</span>
                    <Select
                        style={{width: '100%'}}
                        value={faultLevel}
                        options={[
                            {
                                value: 1,
                                label: 'P0级重大事故(大面积功能瘫痪)故障'
                            },
                            {
                                value: 2,
                                label: 'P1级影响服务(部分服务异常)故障'
                            },
                            {
                                value: 3,
                                label: 'P2级个别服务异常且影响用户较小'
                            },
                            {
                                value: 4,
                                label: 'P3级一般故障单页面等小范围故障'
                            }
                        ]}
                        onSelect={faultLevelOnSelectHandler}
                    />
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <span style={spanStyle}>故障时间</span>
                    <RangePicker
                        style={{width: '100%'}}
                        placeholder={['请选择开始时间', '请选择结束时间']}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={datePickerOnChangeHandler}
                    />
                </div>


                <span style={spanStyle}>故障概览</span>
                <TextArea
                    row={4}
                    placeholder={'故障概览'}
                    showCount={true}
                    id={'summary'}
                />


                <span style={spanStyle}>故障原因</span>
                <TextArea
                    row={4}
                    placeholder={'故障原因'}
                    showCount={true}
                    id={'reason'}
                />


                <span style={spanStyle}>后期优化</span>
                <TextArea
                    row={4}
                    placeholder={'后期优化'}
                    showCount={true}
                    id={'optimize'}
                />


            </Modal>


            <Modal
                title={'添加故障报告流'}
                open={addFaultStreamOpenModal}
                maskClosable={false}
                onOk={addFaultStreamModalOnOk}
                onCancel={addFaultStreamModalOnCancel}
                width={800}
                height={600}
            >

                <span style={spanStyle}>故障Uuid</span>
                <Input
                    value={addFaultStreamFaultUuid}
                    disabled={true}
                    id={'faultUuid'}
                />

                <div style={{
                    display: 'flex'
                }}>
                    <span style={spanStyle}>故障时间</span>
                    <RangePicker
                        style={{width: '100%'}}
                        placeholder={['请选择开始时间', '请选择结束时间']}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={datePickerOnChangeHandler}
                    />
                </div>

                <span style={spanStyle}>故障流</span>
                <TextArea
                    row={4}
                    placeholder={'故障流'}
                    showCount={true}
                    id={'do'}
                />
            </Modal>
        </>
    );
}