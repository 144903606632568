import {Button, ConfigProvider, Input, Layout, Modal, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useState} from 'react';

export function DiscoveryMiddlewareRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['discoveryMiddleware']}></LeftSider>
                    <IndexRightBody>
                        <DiscoveryMiddlewareContent></DiscoveryMiddlewareContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function DiscoveryMiddlewareContent() {
    const [defaultActiveKey, setDefaultActiveKey] = useState('etcd')

    const discoveryMiddlewareOnChange = (activeKey) => {
        switch (activeKey) {
            case 'Etcd':
                setDefaultActiveKey('Etcd')
                break
            case 'Nacos':
                setDefaultActiveKey('Nacos')
                break
            case 'Consul':
                setDefaultActiveKey('Consul')
                break
            case 'Zookeeper':
                setDefaultActiveKey('Zookeeper')
                break
            default:
                break
        }

    }

    // rocketmq
    const etcdColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [etcdDataSource, setEtcdDataSource] = useState([])

    const [createEtcdInstanceOpenModal, setCreateEtcdInstanceOpenModal] = useState(false)

    const createEtcdInstanceOnClick = () => {
        setCreateEtcdInstanceOpenModal(true)
    }

    const createEtcdInstanceOnOk = () => {
        setCreateEtcdInstanceOpenModal(false)
    }

    const createEtcdInstanceOnCancel = () => {
        setCreateEtcdInstanceOpenModal(false)
    }

    const listEtcdInstance = () => {

    }

    //
    const nacosColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [nacosDataSource, setNacosDataSource] = useState([])

    const [createNacosInstanceOpenModal, setCreateNacosInstanceOpenModal] = useState(false)

    const createNacosInstanceOnClick = () => {
        setCreateNacosInstanceOpenModal(true)
    }

    const createNacosInstanceOnOk = () => {
        setCreateNacosInstanceOpenModal(false)
    }

    const createNacosInstanceOnCancel = () => {
        setCreateNacosInstanceOpenModal(false)
    }

    const listNacosInstance = () => {

    }
    //
    const consulColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [consulDataSource, setConsulDataSource] = useState([])

    const [createConsulInstanceOpenModal, setCreateConsulInstanceOpenModal] = useState(false)

    const createConsulInstanceOnClick = () => {
        setCreateConsulInstanceOpenModal(true)
    }

    const createConsulInstanceOnOk = () => {
        setCreateConsulInstanceOpenModal(false)
    }

    const createConsulInstanceOnCancel = () => {
        setCreateConsulInstanceOpenModal(false)
    }

    const listConsulInstance = () => {

    }
    //
    const zookeeperColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
    ]
    const [zookeeperDataSource, setZookeeperDataSource] = useState([])

    const [createZookeeperInstanceOpenModal, setCreateZookeeperInstanceOpenModal] = useState(false)

    const createZookeeperInstanceOnClick = () => {
        setCreateZookeeperInstanceOpenModal(true)
    }

    const createZookeeperInstanceOnOk = () => {
        setCreateZookeeperInstanceOpenModal(false)
    }

    const createZookeeperInstanceOnCancel = () => {
        setCreateZookeeperInstanceOpenModal(false)
    }

    const listZookeeperInstance = () => {

    }

    return (<>
        <span>自动发现中间件(自动发现实例 & 自动发现配置)</span>
        <Tabs
            type="card"
            onChange={discoveryMiddlewareOnChange}
            defaultActiveKey={defaultActiveKey}
            items={[
                {
                    label: 'Etcd',
                    key: 'Etcd',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/etcd-io/etcd'} target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}><a href={'https://etcd.io/'} target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createEtcdInstanceOnClick}>添加资产</Button>
                        </Space>
                        <Table columns={etcdColumns}
                               dataSource={etcdDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'Nacos',
                    key: 'Nacos',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/alibaba/nacos'} target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}><a href={'https://nacos.io/zh-cn/index.html'} target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createNacosInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={nacosColumns}
                               dataSource={nacosDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'Consul',
                    key: 'Consul',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/hashicorp/consul'} target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}><a href={'https://www.consul.io/'} target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createConsulInstanceOnClick}>添加资产</Button>
                        </Space>
                        <Table columns={consulColumns}
                               dataSource={consulDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'Zookeeper',
                    key: 'Zookeeper',
                    children: <>
                        <Space>
                            <a href={'https://github.com/apache/zookeeper'} target={'_blank'}><Button type={'primary'}>Github</Button></a>
                            <Button type={'primary'}><a href={'https://zookeeper.apache.org/'} target={'_blank'}>Gateway</a></Button>
                            <Button type={'primary'} onClick={createZookeeperInstanceOnClick}>添加资产</Button>
                        </Space>

                        <Table columns={zookeeperColumns}
                               dataSource={zookeeperDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
            ]}
        />



        <Modal
            title={'添加 Etcd 中间件实例'}
            open={createEtcdInstanceOpenModal}
            onOk={createEtcdInstanceOnOk}
            onCancel={createEtcdInstanceOnCancel}
            destroyOnClose={true}
        >
            <span>名字</span><Input id={'createMySQLInstanceName'}/>
            <span>主机名</span><Input id={'createMySQLInstanceHostname'}/>
            <span>端口</span><Input id={'createMySQLInstancePort'}/>
            <span>密码</span><Input id={'createMySQLInstancePasswd'}/>
        </Modal>




        <Modal
            title={'添加 Nacos 中间件实例'}
            open={createNacosInstanceOpenModal}
            onOk={createNacosInstanceOnOk}
            onCancel={createNacosInstanceOnCancel}
            destroyOnClose={true}
        >
            <span>名字</span><Input id={'createMySQLInstanceName'}/>
            <span>主机名</span><Input id={'createMySQLInstanceHostname'}/>
            <span>端口</span><Input id={'createMySQLInstancePort'}/>
            <span>密码</span><Input id={'createMySQLInstancePasswd'}/>
        </Modal>




        <Modal
            title={'添加 Consul 中间件实例'}
            open={createConsulInstanceOpenModal}
            onOk={createConsulInstanceOnOk}
            onCancel={createConsulInstanceOnCancel}
            destroyOnClose={true}
        >
            <span>名字</span><Input id={'createMySQLInstanceName'}/>
            <span>主机名</span><Input id={'createMySQLInstanceHostname'}/>
            <span>端口</span><Input id={'createMySQLInstancePort'}/>
            <span>密码</span><Input id={'createMySQLInstancePasswd'}/>
        </Modal>





        <Modal
            title={'添加 Zookeeper 中间件实例'}
            open={createZookeeperInstanceOpenModal}
            onOk={createZookeeperInstanceOnOk}
            onCancel={createZookeeperInstanceOnCancel}
            destroyOnClose={true}
        >
            <span>名字</span><Input id={'createMySQLInstanceName'}/>
            <span>主机名</span><Input id={'createMySQLInstanceHostname'}/>
            <span>端口</span><Input id={'createMySQLInstancePort'}/>
            <span>密码</span><Input id={'createMySQLInstancePasswd'}/>
        </Modal>

    </>);
}