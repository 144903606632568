import {
    CloudPlatformDNSApi,
    CloudPlatformDNSCacheApi,
    CloudPlatformDNSRecordApi,
    Get,
    ImportCloudPlatformDNSApi,
    Post
} from '../common';

export function ListDomainNameSystem(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${CloudPlatformDNSCacheApi}?${params}`,
    })
}

export function ListCloudDomainNameSystem(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
    })
    return Get({
        url: `${CloudPlatformDNSApi}?${params}`,
    })
}

export function ImportCloudDomainNameSystem(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        domainName: props.domainName,
    }
    return Post({
        url: `${ImportCloudPlatformDNSApi}`,
        params: params,
    })
}


export function ListCloudDomainNameSystemRecord(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        domainName: props.domainName,
    })
    return Get({
        url: `${CloudPlatformDNSRecordApi}?${params}`,
    })
}