import md5 from 'js-md5';
import {message} from 'antd';
import {useState} from 'react';
import React from 'react';

const loginApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/login'
const heartbeatApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/heartbeat'
const logoutApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/logout'
const intervalTimeout = 10000

// 除登录页面，每隔 x 秒访问健康检查页面
export function PageHeartbeat() {
    Heartbeat()
    setInterval(Heartbeat, intervalTimeout)
}

// 心跳检查，当 httpCode 非 200 时，跳转到登录页面
function Heartbeat() {
    let token = window.localStorage.getItem('token')
    if (!token || token === '') {
        window.location.href = '/login'
        return
    }
    //
    fetch(
        heartbeatApi, {
            method: 'GET',
            headers: {
                'token': token
            }
        }
    ).then(response => {
        // 401 - redirect login page
        if (response.status === 401) {
            window.location.href = '/login'
            return
        }
        // not ok - 非 401 & 非 2xx code，展示认定为网络问题，或者服务问题，暂时不进行 redirect login page。
        if (!response.ok) {
            return Promise.reject(new Error(`Heartbeat请求失败, HTTP error ${response.status}`))
        }
        return response.json()
    }).then(
        data => {

            // errCode 不为 0 则跳转到登录页面
            let errCode = data.errCode
            if (errCode === 0) {
                return
            }
            window.location.href = '/login'
        }
    ).catch(
        err => {
            console.log(err.message)
            message.error(err.message)
        }
    )
}

// 登录请求
export function LoginApi() {
    //let username = document.getElementById('username')
    let phone = document.getElementById('phone')
    let password = document.getElementById('password')

    if (!password || password.value === '') {
        message.warning('请输入密码')
        return
    }
    const passwordMD5 = md5(password.value)
    let userLoginParam = {
        //'username': username.value,
        'phone': phone.value,
        'password': passwordMD5
    }

    fetch(
        loginApi, {
            method: 'POST',
            body: JSON.stringify(userLoginParam),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(
            response => {
                if (!response.ok) {
                    return Promise.reject(new Error(`登录失败, HTTP error ${response.status}`))
                }
                return response.json()
            }
        ).then(
        data => {
            let errCode = data.errCode
            let errMsg = data.errMsg


            if (errCode === 0) {

                let token = data.data.token
                let userUuid = data.data.userUuid

                // 登录成功
                window.localStorage.setItem('token', token)
                window.localStorage.setItem('userUuid', userUuid)
                window.location.reload()
                message.success('登录成功')
                window.location.href = '/'

                return
            }

            message.error(`登录失败 ${errMsg}`)
        }
    )
        .catch(
            err => {
                message.error(`登录失败 ${err}`)
                console.log(`请求异常, ${err}`, )
            }
        )
}

// 登录页面获取心跳 - 如果发现 token，且有效，进行跳转到首页
export function LoginPageGetHeartbeat() {
    // token
    let token = window.localStorage.getItem('token')
    if (!token || token === '') {
        console.log('未发现 token, 跳过心跳检查.')
        return
    }
    fetch(
        heartbeatApi, {
            method: 'GET',
            headers: {
                'token': token
            }
        }
    ).then(response => {
            if (!response.ok) {
                throw new Error('请求 HeartBeat 失败')
            }
            return response.json()
        }
    ).then(
        data => {
            let errCode = data.errCode
            // 心跳检查成功 -- 以前登录过，直接跳转到 /
            if (errCode === 0) {
                message.info('用户已登录，正在为您跳转到首页。')
                window.location.href = '/'
                return
            }
            throw new Error('请求 HeartBeat 失败')
        }
    ).catch(
        err => {
            console.log('登录页面访问 HeartBeat 失败, ', err.message)
        }
    )
}

// 登出
export function LogoutApi() {
    // token
    let token = window.localStorage.getItem('token')
    if (!token || token === '') {
        console.log('未发现 token.')
        window.location.href = '/login'
        return
    }
    fetch(
        logoutApi, {
            method: 'GET',
            headers: {
                'token': token
            }
        }
    ).then(response => {
            if (!response.ok) {
                message.error('用户退出失败')
                throw new Error('用户登出失败')
            }
            return response.json()
        }
    ).then(
        data => {
            let errCode = data.errCode
            if (errCode === 0) {
                CleanUserData()
                window.location.href = '/login'
                return
            }
            message.error('用户退出失败')
            throw new Error('用户登出失败')
        }
    ).catch(
        err => {
            message.error('用户退出失败')
            console.log('用户登出失败, ', err.message)
        }
    )
}

// 清理用户状态
function CleanUserData() {
    //window.localStorage.removeItem("token")
    window.localStorage.clear()
}

//
