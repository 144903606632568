import {Button, ConfigProvider, Input, Layout, Modal, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useState} from 'react';

export function OtherMiddlewareRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['otherMiddleware']}></LeftSider>
                    <IndexRightBody>
                        <OtherMiddlewareContent></OtherMiddlewareContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

function OtherMiddlewareContent() {
    const [defaultActiveKey, setDefaultActiveKey] = useState('CoreDNS')

    const otherMiddlewareOnChange = (activeKey) => {
        switch (activeKey) {
            case 'CoreDNS':
                setDefaultActiveKey('CoreDNS')
                break
            case 'XXLJob':
                setDefaultActiveKey('XXLJob')
                break
            case 'Temporal':
                setDefaultActiveKey('Temporal')
                break
            default:
                break
        }
    }

    //
    const coreDNSColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        }
    ]
    const [coreDNSDataSource, setCoreDNSDataSource] = useState([])

    const [createCoreDNSInstanceOpenModal, setCreateCoreDNSInstanceOpenModal] = useState(false)

    const createCoreDNSInstanceOnClick = () => {
        setCreateCoreDNSInstanceOpenModal(true)
    }

    const createCoreDNSInstanceOnOk = () => {
        setCreateCoreDNSInstanceOpenModal(false)
    }

    const createCoreDNSInstanceOnCancel = () => {
        setCreateCoreDNSInstanceOpenModal(false)
    }

    const listCoreDNSInstance = () => {
        setCoreDNSDataSource([])
    }
    //
    const xxlJobColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        }
    ]
    const [xxlJobDataSource, setXxlJobDataSource] = useState([])

    const [createXxlJobInstanceOpenModal, setCreateXxlJobInstanceOpenModal] = useState(false)

    const createXxlJobInstanceOnClick = () => {
        setCreateXxlJobInstanceOpenModal(true)
    }

    const createXxlJobInstanceOnOk = () => {
        setCreateXxlJobInstanceOpenModal(false)
    }

    const createXxlJobInstanceOnCancel = () => {
        setCreateXxlJobInstanceOpenModal(false)
    }

    const listXxlJobInstance = () => {
        setXxlJobDataSource([])
    }
    //
    const temporalColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        }
    ]
    const [temporalDataSource, setTemporalDataSource] = useState([])

    const [createTemporalInstanceOpenModal, setCreateTemporalInstanceOpenModal] = useState(false)

    const createTemporalInstanceOnClick = () => {
        setCreateTemporalInstanceOpenModal(true)
    }

    const createTemporalInstanceOnOk = () => {
        setCreateTemporalInstanceOpenModal(false)
    }

    const createTemporalInstanceOnCancel = () => {
        setCreateTemporalInstanceOpenModal(false)
    }

    const listTemporalInstance = () => {
        setTemporalDataSource([])
    }

    return (<>
        <Tabs
            type="card"
            onChange={otherMiddlewareOnChange}
            defaultActiveKey={defaultActiveKey}
            items={[
                {
                    label: 'CoreDNS',
                    key: 'CoreDNS',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/coredns/coredns'}
                                                        target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}>添加资产</Button>
                        </Space>

                        <Table columns={coreDNSColumns}
                               dataSource={coreDNSDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'XXLJob',
                    key: 'XXLJob',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/xuxueli/xxl-job'}
                                                        target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}>添加资产</Button>
                        </Space>

                        <Table columns={xxlJobColumns}
                               dataSource={xxlJobDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                },
                {
                    label: 'Temporal',
                    key: 'Temporal',
                    children: <>
                        <Space>
                            <Button type={'primary'}><a href={'https://github.com/temporalio/temporal'}
                                                        target={'_blank'}>Github</a></Button>
                            <Button type={'primary'}>添加资产</Button>
                        </Space>

                        <Table columns={temporalColumns}
                               dataSource={temporalDataSource}
                               scroll={{x: true, y: 400}}/>
                    </>
                }
            ]}
        />


        <Modal
            title={'添加 CoreDNS 中间件实例'}
            open={createCoreDNSInstanceOpenModal}
            onOk={createCoreDNSInstanceOnOk}
            onCancel={createCoreDNSInstanceOnCancel}
        >
            <span>名字</span><Input id={'createCoreDNSInstanceName'}/>
            <span>主机名</span><Input id={'createCoreDNSInstanceHostname'}/>
            <span>端口</span><Input id={'createRocketMQInstancePort'}/>
            <span>密码</span><Input id={'createRocketMQInstancePasswd'}/>
        </Modal>


        <Modal
            title={'添加XXL-JOB中间件实例'}
            open={createXxlJobInstanceOpenModal}
            onOk={createXxlJobInstanceOnOk}
            onCancel={createXxlJobInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRocketMQInstanceName'}/>
            <span>主机名</span><Input id={'createRocketMQInstanceHostname'}/>
            <span>端口</span><Input id={'createRocketMQInstancePort'}/>
            <span>密码</span><Input id={'createRocketMQInstancePasswd'}/>
        </Modal>


        <Modal
            title={'添加 Temporal 中间件实例'}
            open={createTemporalInstanceOpenModal}
            onOk={createTemporalInstanceOnOk}
            onCancel={createTemporalInstanceOnCancel}
        >
            <span>名字</span><Input id={'createRocketMQInstanceName'}/>
            <span>主机名</span><Input id={'createRocketMQInstanceHostname'}/>
            <span>端口</span><Input id={'createRocketMQInstancePort'}/>
            <span>密码</span><Input id={'createRocketMQInstancePasswd'}/>
        </Modal>

    </>);
}