import {Del, Get, Post, ProductContainerRegistryApi} from '../common';

export function AddContainerRegistry(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        url: `${props.url !== undefined ? props.url : ""}`,
        username: `${props.username !== undefined ? props.username : ""}`,
        password: `${props.password !== undefined ? props.password : ""}`,
        email: `${props.email !== undefined ? props.email : ""}`,
    }
    return Post({
        url: ProductContainerRegistryApi,
        params: params,
    })
}

export function ListContainerRegistry(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${ProductContainerRegistryApi}?${params}`,
    })
}

export function DelContainerRegistry(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        containerRegistryUuid: `${props.containerRegistryUuid !== undefined ? props.containerRegistryUuid : ""}`,
    })

    return Del({
        url: `${ProductContainerRegistryApi}?${params}`,
    })
}