import {
    CloudPlatformCDNApi,
    CloudPlatformCDNIPApi, CloudPlatformCDNIPDetailApi,
    CloudPlatformCDNQuotaApi,
    CloudPlatformRefreshCDNApi,
    Get, Post
} from '../common';


export function ListCDNDomain(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid
    })
    return Get({
        url: `${CloudPlatformCDNApi}?${params}`
    })
}

export function RefreshCDNCache(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        type: props.type,
        paths: props.paths,
    }
    return Post({
        url: CloudPlatformRefreshCDNApi,
        params: params,
    })
}


export function IsCdnIP() {
    return Get({
        url: CloudPlatformCDNIPApi
    })
}

export function CdnIPDetail() {
    return Get({
        url: CloudPlatformCDNIPDetailApi
    })
}

export function GetCDNDomainQuota() {
    return Get({
        url: CloudPlatformCDNQuotaApi
    })
}