import {
    Del,
    Get,
    ImportTlsCertificateApi,
    ParserTlsCertificateApi,
    Post, TlsCertificateApi
} from '../common';

export function ImportCertificate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        key: `${props.key !== undefined ? props.key : ""}`,
        pem: `${props.pem !== undefined ? props.pem : ""}`,
    }
    return Post({
        url: ImportTlsCertificateApi,
        params: params
    })
}

export function ListCertificate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        domainName: `${props.domainName !== undefined ? props.domainName : ""}`,
        notExpire: `${props.notExpire !== undefined ? props.notExpire : ""}`,
    });

    const listTlsCertificateApiUrl = `${TlsCertificateApi}?${params}`

    return Get({
        url: listTlsCertificateApiUrl
    })
}

export function GenerateCertificate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    });

    return Post({
        url: TlsCertificateApi,
        params: params,
    })
}

export function DelCertificate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        certificateUuid: `${props.certificateUuid !== undefined ? props.certificateUuid : ""}`,
        serialNumber: `${props.serialNumber !== undefined ? props.serialNumber : ""}`,
    });

    const delTlsCertificateApiUrl = `${TlsCertificateApi}?${params}`

    return Del({
        url: delTlsCertificateApiUrl
    })
}

export function ParserSSLCertificate(props) {
    const params = {
        certificate: props.certificate
    }
    return Post({
        url: ParserTlsCertificateApi,
        params: params
    })
}