import {Button, Input, message, Modal, Popconfirm, Select, Space, Table, Tabs} from 'antd';
import {useEffect, useState} from 'react';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {
    AddServiceUrl,
    DelServiceUrl, DelServiceUrlAuthKey, GenerateServiceUrlAuthKey,
    GenerateServiceUrlAuthUrl,
    ListServiceUrl,
    ListServiceUrlAuthKey
} from '../../apis/product/serviceUrl';
import {ConvertTimestamp} from '../common/date';
import {ProductEnvState} from '../../apis/common';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';
import AceEditor from 'react-ace';

export function ListServiceUrlRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['serviceUrl']}></LeftSider>
                    <IndexRightBody>
                        <ListServiceUrlContent></ListServiceUrlContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

const value = `local key = "xxxxxxxx-yyyyyy-zzzz-kkkk-mmmmmmmmmmmmmm"
local dict = ngx.shared
local orgUuid = ngx.var.arg_orgUuid
local productUuid = ngx.var.arg_productUuid
local userUuid = ngx.var.arg_userUuid
local timestamp = ngx.var.arg_timestamp
local rand = ngx.var.arg_rand
local sign = ngx.var.arg_sign
local consoleAuthDict = dict['consoleAuth']

-- lua_shared_dict consoleAuth 10m;


-- cookie
function get_cookies()
    local cookies = ngx.header["Set-Cookie"] or {}
    if type(cookies) == "string" then
        cookies = { cookies }
    end
    return cookies
end

function add_cookie(cookie)
    local cookies = get_cookies()
    table.insert(cookies, cookie)
    ngx.header["Set-Cookie"] = cookies
end


-- 验证 cookie  (记录日志: $cookie_ac999uid)
-- 1. 获取 cookie
-- 2. 验证缓存 cookie 是否存在
local ac999Cookie = ngx.var.cookie_ac999
if ac999Cookie then
    local consoleAuthSessionValue = consoleAuthDict:get(ac999Cookie)
    if consoleAuthSessionValue then
        ngx.log(ngx.INFO, "cookie auth successful, userUuid: " .. consoleAuthSessionValue)
        -- 验证成功
        return
    end
end

if not orgUuid then
    ngx.log(ngx.ERR, "orgUuid parameter not found.")
    return ngx.exit(403)
end

if not productUuid then
    ngx.log(ngx.ERR, "productUuid parameter not found.")
    return ngx.exit(403)
end

if not userUuid then
    ngx.log(ngx.ERR, "userUuid parameter not found.")
    return ngx.exit(403)
end

if not timestamp then
    ngx.log(ngx.ERR, "timestamp parameter not found.")
    return ngx.exit(403)
end

if not rand then
    ngx.log(ngx.ERR, "rand parameter not found.")
    return ngx.exit(403)
end

if not sign then
    ngx.log(ngx.ERR, "sign parameter not found.")
    return ngx.exit(403)
end

local now = ngx.time()
local timeInterval = now - tonumber(timestamp)
if timeInterval >= 60 then
    ngx.log(ngx.ERR, "timestamp: " .. timestamp .. ", now: " .. now .. ", timeInterval: " .. timeInterval .. ", timestamp parameter invalid.")
    return ngx.exit(403)
end

-- 获取认证字符串并验证
local s = orgUuid .. "-" .. productUuid .. "-" .. userUuid .. "-" .. timestamp .. "-" .. rand .. "-" .. key
local localSign = ngx.md5(s)

if sign == localSign then
    consoleAuthDict:set(localSign, userUuid, 3600)
    add_cookie("ac999=" .. localSign)
    add_cookie("ac999uid=" .. userUuid)
else
    ngx.log(ngx.ERR, "sign: " .. sign .. ", localSign: " .. localSign .. ", s: "..s..", sign parameter invalid, ")
    return ngx.exit(403)
end
`

export default function ListServiceUrlContent() {


    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    useEffect(() => {
        if (env === "") {
            return
        }
        getServiceUrlOnClick()
    }, [env])
    //
    const [serviceUrlDataSource, setServiceUrlDataSource] = useState([])
    const serviceUrlColumns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: '服务url',
            dataIndex: 'url',
            key: 'url',
            render: function (text) {
                return (<><a target={'_blank'} href={text}>{text}</a></>);
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '认证跳转',
            dataIndex: 'authUrl',
            key: 'authUrl',
            render: (_, record) => {
                return <Button type={'primary'} onClick={() => generateServiceUrlAuthUrlOnClick(record.key)}>认证跳转</Button>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            sorter: (a, b) => a.createTime - b.createTime,
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Popconfirm
                            title={'是否删除该ServiceUrl'}
                            onConfirm={() => deleteServiceUrl(record)}
                        >
                            <Button
                                type={'primary'}
                                danger={true}
                            >删除</Button>
                        </Popconfirm>

                    </>
                );
            }
        }
    ]
    //
    const deleteServiceUrl = (record) => {
        if (! VerifyOrgUuidAndOrgProductUuid() ) {
            return
        }
        DelServiceUrl({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            serviceUrlUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除ServiceUrl成功`)
                    getServiceUrlOnClick()
                    return
                }
                message.error(`删除ServiceUrl失败, ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                message.error(`删除ServiceUrl失败`)
            }
        )
    }
    //
    const envSelectOnChange = (value) => {
        setEnv(value)
        //getServiceUrlOnClick()
    }
    //
    const getServiceUrlOnClick = () => {
        ListServiceUrl({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let d = data.data
                    if (d === null) {
                        setServiceUrlDataSource([])
                        return;
                    }
                    let tmp = []
                    for (let serviceUrl of d) {
                        tmp.push(
                            {
                                key: serviceUrl.uuid,
                                uuid: serviceUrl.uuid,
                                name: serviceUrl.name,
                                url: serviceUrl.url,
                                describe: serviceUrl.describe,
                                createTime: serviceUrl.createTime
                            }
                        )
                    }
                    setServiceUrlDataSource(tmp)
                    return
                }
                message.error(`获取 ServiceUrl 失败, ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`获取 ServiceUrl 失败`)
            }
        )
    }
    //
    const addServiceUrlOnClick = () => {
        setCreateServiceUrlOpenModal(true)
    }
    //
    const [createServiceUrlOpenModal, setCreateServiceUrlOpenModal] = useState(false)
    const createServiceUrlOnOk = () => {
        const name = document.getElementById("name").value
        const url = document.getElementById("url").value
        const describe = document.getElementById("describe").value
        if (env === "" || name === "" || url === "") {
            message.error("请输入正确的内容")
            return
        }
        if (! VerifyOrgUuidAndOrgProductUuid() ) {
            return;
        }
        AddServiceUrl({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            name: name,
            url: url,
            describe: describe,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加ServiceUrl成功`)
                    getServiceUrlOnClick()
                    setCreateServiceUrlOpenModal(false)
                    return
                }
                message.error(`添加ServiceUrl失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加ServiceUrl失败`)
            }
        )
    }
    const createServiceUrlOnCancel = () => {
        setCreateServiceUrlOpenModal(false)
    }

    //
    const serviceUrlAuthKeyColumns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: '用户uuid',
            dataIndex: 'userUuid',
            key: 'userUuid',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>

                    <Popconfirm
                        title={'确定删除ServiceUrlAuthKey？'}
                        onConfirm={() => delServiceUrlAuthKeyOnClick(record.uuid)}
                    >
                        <Button
                            type={'primary'}
                            danger={true}
                        >删除</Button>
                    </Popconfirm>
                </>
            }
        },
    ]

    const [serviceUrlAuthKeyDataSource, setServiceUrlAuthKeyDataSource] = useState([])
    const [serviceUrlAuthKeyOptions, setServiceUrlAuthKeyOptions] = useState([])
    const [serviceUrlAuthKeyOptionsValue, setServiceUrlAuthKeyOptionsValue] = useState('')

    const [serviceUrlAuthKeyModalOpen, setServiceUrlAuthKeyModalOpen] = useState(false)

    const [createServiceUrlAuthKeyModalOpen, setCreateServiceUrlAuthKeyModalOpen] = useState(false)
    const createServiceUrlAuthKeyOnClick = () => {
        setCreateServiceUrlAuthKeyModalOpen(true)
    }

    const createServiceUrlAuthKeyOnOk = () => {
        const createAuthKeyName = document.getElementById("createAuthKeyName").value
        GenerateServiceUrlAuthKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            name: createAuthKeyName,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`生成ServiceUrlAuthKey成功`)
                    listServiceUrlAuthKeyOnClick()
                    setCreateServiceUrlAuthKeyModalOpen(false)
                    return
                }
                message.error(`生成ServiceUrlAuthKey失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`生成ServiceUrlAuthKey失败 ${err}`)
            }
        )
    }

    const createServiceUrlAuthKeyOnCancel = () => {
        setCreateServiceUrlAuthKeyModalOpen(false)
    }

    const listServiceUrlAuthKeyOnClick = () => {
        ListServiceUrlAuthKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d == null) {
                        setServiceUrlAuthKeyDataSource([])
                        return;
                    }

                    let tmp = []
                    for (let dd of d) {
                        tmp.push({
                            uuid: dd.uuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            productEnvUuid: dd.productEnvUuid,
                            name: dd.name,
                            key: dd.key,
                            userUuid: dd.userUuid,
                            createTime: dd.createTime,
                        })
                    }
                    setServiceUrlAuthKeyDataSource(tmp)
                    return
                }
                setServiceUrlAuthKeyDataSource([])
                message.error(`列出 serviceUrl 认证key 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setServiceUrlAuthKeyDataSource([])
                message.error(`列出 serviceUrl 认证key 失败 ${err}`)
            }
        )
    }

    const delServiceUrlAuthKeyOnClick = (uuid) => {
        DelServiceUrlAuthKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            uuid: uuid,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除 ServiceUrlAuthKey 成功`)
                    listServiceUrlAuthKeyOnClick()
                    return
                }
                message.error(`删除 ServiceUrlAuthKey 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 ServiceUrlAuthKey 失败 ${err}`)
            }
        )
    }


    const getServiceUrlAuthKeyOptionsOnClick = () => {
        ListServiceUrlAuthKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d == null) {
                        setServiceUrlAuthKeyOptions([])
                        return;
                    }

                    let tmp = []
                    for (let dd of d) {
                        tmp.push({
                            value: dd.uuid,
                            label: dd.name,
                        })
                    }
                    setServiceUrlAuthKeyOptions(tmp)
                    return
                }
                setServiceUrlAuthKeyOptions([])
                message.error(`列出 serviceUrl 认证key 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setServiceUrlAuthKeyOptions([])
                message.error(`列出 serviceUrl 认证key 失败 ${err}`)
            }
        )
    }

    const serviceUrlAuthKeyOptionsOnChange = (value) => {
        setServiceUrlAuthKeyOptionsValue(value)
    }

    // 跳转地址
    const [generateAuthUrlServiceUrlUuid, setGenerateAuthUrlServiceUrlUuid] = useState('')
    const generateServiceUrlAuthUrlOnClick = (serviceUrlUuid) => {
        getServiceUrlAuthKeyOptionsOnClick()
        setGenerateAuthUrlServiceUrlUuid(serviceUrlUuid)
        setServiceUrlAuthKeyModalOpen(true)
    }

    const generateServiceUrlAuthUrlOnOCancel = () => {
        setServiceUrlAuthKeyModalOpen(false)
    }

    const generateServiceUrlAuthUrlOnOk = () => {
        if (serviceUrlAuthKeyOptionsValue === "") {
            message.error(`请选择认证Key`)
            return
        }
        GenerateServiceUrlAuthUrl({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            serviceUrlUuid: generateAuthUrlServiceUrlUuid,
            authKeyUuid: serviceUrlAuthKeyOptionsValue,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const url = data.data
                    window.open(url,"_blank")
                    setServiceUrlAuthKeyModalOpen(false)
                    return
                }
                message.error(`生成 serviceUrl 认证Url 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`生成 serviceUrl 认证Url 失败 ${err}`)
            }
        )
    }

    //
    const [defaultActiveKey, setDefaultActiveKey] = useState('serviceUrl')

    const tabsOnChange = (key) => {
        setDefaultActiveKey(key)
        switch (key) {
            case "serviceUrl":
                getServiceUrlOnClick()
                break
            case "serviceUrlAuthKey":
                listServiceUrlAuthKeyOnClick()
                break
            default:
                break
        }
    }
    return (
        <>
            <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChange}/>

            <Tabs
                defaultActiveKey={defaultActiveKey}
                onChange={tabsOnChange}
                items={[
                    {
                        label: '服务Url',
                        key: 'serviceUrl',
                        children:
                        <>

                            <Space size={4}>
                                <Button onClick={getServiceUrlOnClick} type={'primary'}>查询</Button>
                                <Button onClick={addServiceUrlOnClick} type={'primary'}>添加</Button>
                            </Space>

                            <Table
                            columns={serviceUrlColumns}
                            dataSource={serviceUrlDataSource}
                            scroll={{ x: true, y: 400 }}
                        />
                        </>
                    },
                    {
                        label: '服务Url认证Key',
                        key: 'serviceUrlAuthKey',
                        children: <>
                            <Space size={4}>
                                <Button onClick={listServiceUrlAuthKeyOnClick} type={'primary'}>查询</Button>
                                <Button onClick={createServiceUrlAuthKeyOnClick} type={'primary'}>添加</Button>
                            </Space>

                            <Table
                            columns={serviceUrlAuthKeyColumns}
                            dataSource={serviceUrlAuthKeyDataSource}
                            scroll={{ x: true, y: 400 }}
                        />
                            </>
                    },
                    {
                        label: '配置方法',
                        key: 'config',
                        children: <>
                            <pre>
                                <p>nginx 配置文件中对应的 server 中新增以下配置.</p>

                                <p>需要将下面 lua 代码中的 key 替换为 服务Url认证Key.</p>

                                <p>lua_shared_dict consoleAuth 10m;</p>
                                <p>access_by_lua_file      consoleAuth.lua;</p>
                            </pre>

                            <AceEditor
                                mode={'markdown'}
                                theme="terminal"
                                showGutter={true}
                                highlightActiveLine={true}
                                value={value}
                                width={"90%"}
                            />
                            </>
                    }
                ]}

            />


            <Modal
                open={serviceUrlAuthKeyModalOpen}
                title={'跳转认证Url地址'}
                keyboard={true}
                maskClosable={false}
                onOk={generateServiceUrlAuthUrlOnOk}
                onCancel={generateServiceUrlAuthUrlOnOCancel}
                okText={'跳转'}
            >
                <Select
                    style={{width: 460}}
                    options={serviceUrlAuthKeyOptions}
                    onChange={serviceUrlAuthKeyOptionsOnChange}
                />
            </Modal>


            <Modal
                open={createServiceUrlAuthKeyModalOpen}
                title={'创建ServiceUrlAuthKey'}
                keyboard={true}
                maskClosable={false}
                onOk={createServiceUrlAuthKeyOnOk}
                onCancel={createServiceUrlAuthKeyOnCancel}
            >
                <span>名字</span><Input id={'createAuthKeyName'}/>
            </Modal>


            <Modal
                open={createServiceUrlOpenModal}
                title={"创建ServiceUrl"}
                keyboard={true}
                maskClosable={false}
                onOk={createServiceUrlOnOk}
                onCancel={createServiceUrlOnCancel}
            >
                <span>选择产品环境:</span><Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChange}/>
                <br/>
                <span>名字:</span><Input id={"name"}/>
                <span>Url:</span><Input id={"url"}/>
                <span>描述:</span><Input.TextArea id={"describe"}/>
            </Modal>
        </>
    );
}