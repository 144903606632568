import {Post, Get, Del, ProductK8SClusterConfigMapApi} from '../common';

export function CreateConfigMap(props) {
    return Post({
        url: "",
        params: props.params,
    })
}

export function ListConfigMap(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
    })
    return Get({
        url: `${ProductK8SClusterConfigMapApi}?${params}`,
    })
}

export function DelConfigMap(props) {
    return Del({
        url: "",
    })
}

export function UpdateConfigMap(props) {
    return Post({
        url: "",
        params: props.params,
    })
}