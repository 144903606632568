import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {
    AddWebServiceResource,
    DelWebServiceResource,
    ListWebServiceResource
} from '../../apis/product/webServiceResource';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';

export function NodeResourceRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['resource']} defaultSelectedKeys={['nodeResource']}></LeftSider>
                    <IndexRightBody>
                        <NodeResourceContent></NodeResourceContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function NodeResourceContent() {
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)

    const envSelectOnChange = (value) => {
        setEnv(value)
        //getServiceUrlOnClick()
    }
    //
    const [protocolValue, setProtocolValue] = useState(2)
    const protocolOptions = [
        {
            label: 'http',
            value: 1
        },
        {
            label: 'https',
            value: 2
        }
    ]

    const protocolOnChange = (value) => {
        console.log(`value: ${value}`)
        setProtocolValue(value)
    }

    //
    const nodeResourceColumns = [
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: 'operator',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (<>

                    <Popconfirm
                        title={'是否要删除该web资源'}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>)
            }
        }
    ]

    const [nodeResourceDataSource, setNodeResourceDataSource] = useState([])

    useEffect(() => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        if (env === '') {
            return;
        }

    }, [env])


    return (<>

        <br/>
        <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChange}/>
        <Space size={4}>
            <Button  type={'primary'}>查询</Button>
            <Button  type={'primary'}>添加</Button>
        </Space>

        <Table columns={nodeResourceColumns} dataSource={nodeResourceDataSource}/>

        <Modal
            title={'添加Web服务资源'}
            open={false}
            //onOk={}
            //onCancel={}
        >
            <span>主机名</span><Input id={'serverName'}/>
            <span>IP池</span><Input id={'ipPool'} placeholder={'使用英文逗号(,)进行分割'}/>
            <span>健康检查地址</span><Input id={'healthCheckUri'}/>
            <span>描述</span><Input id={'describe'}/>
        </Modal>
    </>)
}