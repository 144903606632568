import {CiBuildContainerImageTemplateApi, Del, Get, Post} from '../common';

export function CreateBuildContainerImageTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        containerImageRegisterUuid: `${props.containerImageRegisterUuid !== undefined ? props.containerImageRegisterUuid : ""}`,
        namespace: `${props.namespace !== undefined ? props.namespace : ""}`,
        dockerFilePath: `${props.dockerFilePath !== undefined ? props.dockerFilePath : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: CiBuildContainerImageTemplateApi,
        params: params
    })
}


export function ListBuildContainerImageTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateType: `${props.templateType !== undefined ? props.templateType : 2}`,
    })
    return Get({
        url: `${CiBuildContainerImageTemplateApi}?${params}`,
    })
}


export function DelBuildContainerImageTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateUuid: `${props.templateUuid !== undefined ? props.templateUuid : ""}`,
    })
    return Del({
        url: `${CiBuildContainerImageTemplateApi}?${params}`,
    })
}
