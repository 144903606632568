// 一问一答
import {ProForm, ProFormText, WaterMark} from '@ant-design/pro-components';
import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout} from 'antd';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

// 智能聊天
export function IntellectChatRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['robot']} defaultSelectedKeys={['smartChat']}></LeftSider>
                    <IndexRightBody>
                        <IntellectChatContent></IntellectChatContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function IntellectChatContent() {
    const submitQuestionOnClick = () => {
        const question = document.getElementById('question').value
        console.log(`question ${question}`)
    }

    return (
        <WaterMark content={'StartOps'} zIndex={9}>
            <Input.TextArea placeholder={'请输入你想问的问题'} id={'question'}/>
            <Button type={'primary'} onClick={submitQuestionOnClick}>
                提交
            </Button>
        </WaterMark>
    );
}