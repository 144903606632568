import {Button, Input, message, Select, Space} from 'antd';
import {useState} from 'react';
import {ConvertTimestamp} from '../common/date';
import {Translate} from '../../apis/message/translate';
import {OrgUuid} from '../../apis/user/org';
import {ParseJwt} from '../../apis/common';
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

export function TranslateRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['tools']} defaultSelectedKeys={['translate']}></LeftSider>
                    <IndexRightBody>
                        <TranslateContent></TranslateContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function TranslateContent() {
    const [defaultSourceLanguage, setDefaultSourceLanguage] = useState('zh')
    const [defaultDestLanguage, setDefaultDestLanguage] = useState('en')
    const [result, setResult] = useState('')

    const selectStyle = {
        width: 120
    }

    let options = [
        {
            value: 'zh',
            label: '中文'
        },
        {
            value: 'en',
            label: '英文'
        }
    ]

    const translateOnClickHandler = () => {
        console.log(`defaultSourceLanguage: ${defaultSourceLanguage}, defaultDestLanguage: ${defaultDestLanguage}`)
        const content = document.getElementById('content').value
        if (content === '') {
            message.warning('请输入需要翻译的内容')
            return
        }
        Translate({
            orgUuid: OrgUuid,
            content: content,
            sourceLanguage: defaultSourceLanguage,
            destLanguage: defaultDestLanguage,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let result = data.result
                    setResult(result)
                    console.log('data: ', data)
                    return
                }
                setResult(`翻译异常,请重试或联系管理员, ${errMsg}`)
            }
        ).catch(
            err => {
                setResult('翻译错误，请重新尝试')
                console.log(`翻译异常，${err}`)
            }
        )
    }
    const setLanguageOnClickHandler = () => {
        setDefaultSourceLanguage(defaultDestLanguage)
        setDefaultDestLanguage(defaultSourceLanguage)
    }
    const sourceLanguageOnChangeHandler = (value) => {
        setDefaultSourceLanguage(value)
    }
    const destLanguageOnChangeHandler = (value) => {
        setDefaultDestLanguage(value)
    }
    return (
        <>
            <br/>
            <strong>需要翻译的内容:</strong>
            <Input.TextArea id={'content'}/>
            <Space>
                <strong>源语言: </strong><Select style={selectStyle} options={options}
                                                 onChange={sourceLanguageOnChangeHandler}
                                                 value={defaultSourceLanguage}
                                                 defaultValue={defaultSourceLanguage}/>
                <strong>目标语言: </strong><Select style={selectStyle} options={options}
                                                   onChange={destLanguageOnChangeHandler}
                                                   value={defaultDestLanguage}
                                                   defaultValue={defaultDestLanguage}/>
            </Space>
            <Button onClick={setLanguageOnClickHandler} type={'primary'}>语言翻转</Button>
            <Button onClick={translateOnClickHandler} type={'primary'}>翻译</Button>
            <br/>
            <br/>
            <p>{result}</p>
        </>
    );
}

export function EnCodeAndDeCodeRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['tools']} defaultSelectedKeys={['encoded']}></LeftSider>
                    <IndexRightBody>
                        <EnCodeAndDeCodeContent></EnCodeAndDeCodeContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>

}

export function EnCodeAndDeCodeContent() {
    //
    let inputString = ''
    const getInputString = () => {
        inputString = document.getElementById('inputString').value
        if (inputString === '') {
            message.warning('输入内容为空，请输入要进行Url解码的内容')
            return false
        }
        return true
    }
    //
    const [encodedResult, setEncodedResult] = useState('')
    const base64encodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        setEncodedResult(btoa(inputString))
    }

    const base64decodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        try {
            setEncodedResult(atob(inputString))
        } catch (e) {
            message.warning('输入源无法进行Base64解码')
        }
    }

    const urlEncodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        setEncodedResult(encodeURIComponent(inputString))
    }
    const urlDecodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        setEncodedResult(decodeURIComponent(inputString))
    }
    const jsonEnCodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        try {
            setEncodedResult(JSON.stringify(inputString))
        } catch (e) {
            message.warning('Json EnCode 异常')
        }
    }
    const jsonDeCodeOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        try {
            setEncodedResult(JSON.parse(inputString))
        } catch (e) {
            message.warning('Json DeCode 异常')
        }
    }
    const timestampToDateOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        setEncodedResult(ConvertTimestamp(inputString))
    }
    const parseJwtOnClickHandler = () => {
        if (!getInputString()) {
            return
        }
        try {
            setEncodedResult(JSON.stringify(ParseJwt(inputString)))
        } catch (e) {
            message.warning('Jwt DeCode 异常')
        }
    }
    return (
        <>
            <span>输入需要编解码的内容:</span><Input.TextArea id={'inputString'}/>
            <br/>
            <br/>
            <Space size={8}>
                <Button type={'primary'} onClick={base64encodeOnClickHandler}>Base64编码</Button>
                <Button type={'primary'} onClick={base64decodeOnClickHandler}>Base64解码</Button>

                <Button type={'primary'} onClick={urlEncodeOnClickHandler}>Url编码</Button>
                <Button type={'primary'} onClick={urlDecodeOnClickHandler}>Url解码</Button>

                <Button type={'primary'} onClick={jsonEnCodeOnClickHandler}>Json编码</Button>
                <Button type={'primary'} onClick={jsonDeCodeOnClickHandler}>Json解码</Button>

                <Button type={'primary'} onClick={parseJwtOnClickHandler}>解析Jwt</Button>

                <Button type={'primary'} onClick={timestampToDateOnClickHandler}>时间戳转换时间</Button>
            </Space>
            <br/>
            <br/>

            <pre>{encodedResult}</pre>

        </>
    );
}

export function IPToolsRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['tools']} defaultSelectedKeys={['ipTool']}></LeftSider>
                    <IndexRightBody>
                        <IPParseContent></IPParseContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function IPParseContent() {
    const [parseResult, setParseResult] = useState('')
    const parseIpOnClickHandler = () => {

    }
    return (
        <>
            <span>IP地址:</span><Input.TextArea id={'inputString'}/>
            <br/>
            <br/>
            <Space size={8}>
                <Button type={'primary'} onClick={parseIpOnClickHandler}>查看IP属性</Button>
            </Space>
            <br/>
            <br/>
            <span>{parseResult}</span>
        </>
    );
}