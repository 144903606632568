import {
    Del,
    Get,
    Post,
    ProductCodeRepoSelfGitlab,
    ProductCodeRepoSelfGitlabBranch,
    ProductCodeRepoSelfGitlabBranchCommits, ProductCodeRepoSelfGitlabBranchMerge,
    ProductCodeRepoSelfGitlabBranchProtect,
    ProductCodeRepoSelfGitlabBranchUnprotect,
    ProductCodeRepoSelfGitlabImport,
    ProductCodeRepoSelfGitlabPlatform, ProductCodeRepoSelfGitlabRepoArchive,
    ProductCodeRepoSelfGitlabToken
} from '../common';

export function AddSelfGitlabPlatform(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        protocol: props.protocol,
        hostname: props.hostname,
        describe: props.describe,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabPlatform,
        params: params,
    })
}

export function ListSelfGitlabPlatform(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlabPlatform}?${params}`,
    })
}

export function DelSelfGitlabPlatform(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        uuid: props.uuid,
    })
    return Del({
        url: `${ProductCodeRepoSelfGitlabPlatform}?${params}`,
    })
}


export function AddSelfGitlabToken(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        name: props.name,
        privateToken: props.privateToken,
        describe: props.describe,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabToken,
        params: params,
    })
}

export function ListSelfGitlabToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlabToken}?${params}`,
    })
}

export function DelSelfGitlabToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        tokenUuid: props.tokenUuid,
    })
    return Del({
        url: `${ProductCodeRepoSelfGitlabToken}?${params}`,
    })
}

export function CreateSelfGitlabRepo(props) {
    return Post({
        url: ProductCodeRepoSelfGitlab,
        params: props.params,
    })
}

export function ImportSelfGitlabRepo(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        platformTokenUuid: props.platformTokenUuid,
        projectId: props.projectId,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabImport,
        params: params,
    })
}

export function GetSelfGitlabRepo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlab}?${params}`,
    })
}

export function ListSelfGitlabRepo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformUuid: props.platformUuid,
        platformTokenUuid: props.platformTokenUuid,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlab}?${params}`,
    })
}

export function DelSelfGitlabRepo(props) {
    return Del({
        url: "",
    })
}

export function ArchiveSelfGitlabRepo(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabRepoArchive,
        params: params,
    })
}


export function CreateSelfGitlabRepoBranch(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
        ref: props.ref,
    }
    return Post({
        url: ProductCodeRepoSelfGitlab,
        params: params,
    })
}

export function ListSelfGitlabRepoBranch(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlabBranch}?${params}`,
    })
}

export function DelSelfGitlabRepoBranch(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
    })
    return Del({
        url: `${ProductCodeRepoSelfGitlabBranch}?${params}`,
    })
}

export function ListSelfGitlabRepoBranchCommits(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
    })

    return Get({
        url: `${ProductCodeRepoSelfGitlabBranchCommits}?${params}`,
    })
}

export function ProtectSelfGitlabRepoBranch(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabBranchProtect,
        params: params,
    })
}

export function UnProtectSelfGitlabRepoBranch(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        branchName: props.branchName,
    }
    return Post({
        url: ProductCodeRepoSelfGitlabBranchUnprotect,
        params: params,
    })
}

export function MergeSelfGitlabRepo(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        sourceBranch: props.sourceBranch,
        targetBranch: props.targetBranch,
        message: props.message,
    }

    return Post({
        url: ProductCodeRepoSelfGitlabBranchMerge,
        params: params,
    })
}

export function SelfGitlabRepoBranchMergeHis(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        sourceBranch: props.sourceBranch,
        targetBranch: props.targetBranch,
    })
    return Get({
        url: `${ProductCodeRepoSelfGitlabBranchMerge}?${params}`,
    })
}