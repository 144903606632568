import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';
import {
    CreateBasicAuthSecret, CreateDockerfileSecret, CreateSSHAuthSecret,
    CreateTLSSecret,
    DelSecret,
    ListSecret,
    UpdateSSHAuthSecret
} from '../../apis/kubernetes/secret';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

export function K8SSecretRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['secret']}></LeftSider>
                    <IndexRightBody>
                        <SecretContent></SecretContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function SecretContent() {
    //
    const secretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>
                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const dockerSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const tlsSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const opaqueSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const sshAuthSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const basicAuthSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const otherSecretColumns = [
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '不可更改',
            dataIndex: 'immutable',
            key: 'immutable'
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>

                    <Popconfirm
                        title={`确定是否删除 Secret ${record.name}`}
                        onConfirm={() => delSecretOnClick(record)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    //
    const [secretDataSource, setSecretDataSource] = useState([])
    const [dockerSecretDataSource, setDockerSecretDataSource] = useState([])
    const [tlsSecretDataSource, setTlsSecretDataSource] = useState([])
    const [opaqueSecretDataSource, setOpaqueSecretDataSource] = useState([])
    const [sshAuthSecretDataSource, setSSHAuthSecretDataSource] = useState([])
    const [basicAuthSecretDataSource, setBasicAuthSecretDataSource] = useState([])
    const [otherSecretDataSource, setOtherSecretDataSource] = useState([])

    // env
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    // cluster
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    // namespace
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === '') {
            return
        }
        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])
    //
    const listKubernetesSecretOnClick = () => {
        ListSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const secrets = data.secrets
                    if (secrets === null) {
                        setSecretDataSource([])
                        return;
                    }

                    let tmpSecretDataSource = []
                    let tmpDockerSecretDataSource = []
                    let tmpTlsSecretDataSource = []
                    let tmpOpaqueSecretDataSource = []
                    let tmpSSHAuthSecretDataSource = []
                    let tmpBasicAuthSecretDataSource = []
                    let tmpOtherSecretDataSource = []
                    let count = 1

                    for (const secret of secrets) {
                        const data = secret.data
                        const immutable = secret.immutable ? '不可更改' : '可更改'

                        tmpSecretDataSource.push({
                            key: count,
                            namespace: secret.namespace,
                            name: secret.name,
                            type: secret.type,
                            immutable: immutable,
                            creationTimestamp: secret.creationTimestamp
                        })
                        count += 1

                        switch (secret.type) {
                            case 'Opaque':
                                tmpOpaqueSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break

                            case 'kubernetes.io/dockerconfigjson':

                                tmpDockerSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break
                            case 'kubernetes.io/basic-auth':
                                tmpBasicAuthSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break
                            case 'kubernetes.io/ssh-auth':
                                tmpSSHAuthSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break
                            case 'kubernetes.io/tls':
                                tmpTlsSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break

                            default:
                                tmpOtherSecretDataSource.push(
                                    {
                                        key: count,
                                        namespace: secret.namespace,
                                        name: secret.name,
                                        type: secret.type,
                                        immutable: immutable,
                                        creationTimestamp: secret.creationTimestamp,
                                        data: data
                                    }
                                )
                                break
                        }
                    }
                    setSecretDataSource(tmpSecretDataSource)
                    setDockerSecretDataSource(tmpDockerSecretDataSource)
                    setTlsSecretDataSource(tmpTlsSecretDataSource)
                    setOpaqueSecretDataSource(tmpOpaqueSecretDataSource)
                    setSSHAuthSecretDataSource(tmpSSHAuthSecretDataSource)
                    setBasicAuthSecretDataSource(tmpBasicAuthSecretDataSource)
                    setOtherSecretDataSource(tmpOtherSecretDataSource)
                    return
                }
                setSecretDataSource([])
                message.error(`列出 Kubernetes Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出 Kubernetes Secret 失败`)
                console.log(`列出 Kubernetes Secret 失败 ${err}`)
                setSecretDataSource([])
            }
        )
    }
    //
    const delSecretOnClick = (record) => {
        DelSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: record.namespace,
            name: record.name
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除 Secret 成功`)
                    listKubernetesSecretOnClick()
                    return
                }
                message.error(`删除 Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 Secret 失败`)
                console.log(`删除 Secret 失败 ${err}`)
            }
        )
    }

    //
    const tabOnChange = (activeKey) => {
        switch (activeKey) {
            case 'bucketStsGenerator':
                console.log(`activeKey: ${activeKey}`)
                break
            default:
                console.log(`activeKey: ${activeKey}`)
                break
        }
    }

    //
    const [createDockerSecretOpenModal, setCreateDockerSecretOpenModal] = useState(false)
    const createDockerSecretModalOnCancelHandler = () => {
        setCreateDockerSecretOpenModal(false)
    }
    const createDockerSecretModalOnOkHandler = () => {
        const name = document.getElementById('dockerSecretName').value
        const username = document.getElementById('dockerSecretUsername').value
        const password = document.getElementById('dockerSecretPassword').value
        const email = document.getElementById('dockerSecretEmail').value
        const url = document.getElementById('dockerSecretUrl').value

        CreateDockerfileSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
            name: name,
            username: username,
            password: password,
            email: email,
            url: url,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建 K8S Dockerfile Secret 成功`)
                    listKubernetesSecretOnClick()
                    setCreateDockerSecretOpenModal(false)
                    return
                }

                message.error(`创建 K8S Dockerfile Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建 K8S Dockerfile Secret 失败`)
                console.log(`创建 K8S Dockerfile Secret 失败 ${err}`)
            }
        )
    }
    const createDockerSecretOnClickHandler = () => {
        setCreateDockerSecretOpenModal(true)
    }
    //
    const [createTlsSecretOpenModal, setCreateTlsSecretOpenModal] = useState(false)
    const createTlsSecretModalOnCancelHandler = () => {
        setCreateTlsSecretOpenModal(false)
    }
    const createTlsSecretModalOnOkHandler = () => {

        const name = document.getElementById('tlsSecretName').value
        const tlsCrt = document.getElementById('tlsCrt').value
        const tlsKey = document.getElementById('tlsKey').value

        CreateTLSSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
            name: name,
            tlsCrt: tlsCrt,
            tlsKey: tlsKey,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建 K8S TLS Secret 成功`)
                    listKubernetesSecretOnClick()
                    setCreateTlsSecretOpenModal(false)
                    return
                }
                message.error(`创建 K8S TLS Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建 K8S TLS Secret 失败`)
                console.log(`创建 K8S TLS Secret 失败 ${err}`)
            }
        )
    }
    const createTlsSecretOnClickHandler = () => {
        setCreateTlsSecretOpenModal(true)
    }
    //
    const [createOpaqueSecretOpenModal, setCreateOpaqueSecretOpenModal] = useState(false)
    const createOpaqueSecretModalOnCancelHandler = () => {
        setCreateOpaqueSecretOpenModal(false)
    }
    const createOpaqueSecretModalOnOkHandler = () => {
        setCreateOpaqueSecretOpenModal(false)
    }
    const createOpaqueSecretOnClickHandler = () => {
        setCreateOpaqueSecretOpenModal(true)
    }
    //
    const [createSSHAuthSecretOpenModal, setCreateSSHAuthSecretOpenModal] = useState(false)
    const createSSHAuthSecretModalOnCancelHandler = () => {
        setCreateSSHAuthSecretOpenModal(false)
    }
    const createSSHAuthSecretModalOnOkHandler = () => {
        const name = document.getElementById('sshAuthName').value
        const privateKey = document.getElementById('privateKey').value
        console.log(`name: ${name}`)
        CreateSSHAuthSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
            name: name,
            privateKey: privateKey,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建 K8S SSH Auth Secret 成功`)
                    listKubernetesSecretOnClick()
                    setCreateSSHAuthSecretOpenModal(false)
                    return
                }
                message.error(`创建 K8S SSH Auth Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建 K8S SSH Auth Secret 失败`)
                console.log(`创建 K8S SSH Auth Secret 失败 ${err}`)
            }
        )
    }
    const createSSHAuthSecretOnClickHandler = () => {
        setCreateSSHAuthSecretOpenModal(true)
    }
    //
    const [createBasicAuthSecretOpenModal, setCreateBasicAuthSecretOpenModal] = useState(false)
    const createBasicAuthSecretModalOnCancelHandler = () => {
        setCreateBasicAuthSecretOpenModal(false)
    }
    const createBasicAuthSecretModalOnOkHandler = () => {
        const name = document.getElementById('basicAuthName').value
        const username = document.getElementById('basicAuthUsername').value
        const password = document.getElementById('basicAuthPassword').value

        CreateBasicAuthSecret({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
            name: name,
            username: username,
            password: password,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建 K8S Basic Auth Secret 成功`)
                    listKubernetesSecretOnClick()
                    setCreateBasicAuthSecretOpenModal(false)
                    return
                }
                message.error(`创建 K8S Basic Auth Secret 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建 K8S Basic Auth Secret 失败`)
                console.log(`创建 K8S Basic Auth Secret 失败 ${err}`)
            }
        )
    }
    const createBasicAuthSecretOnClickHandler = () => {
        setCreateBasicAuthSecretOpenModal(true)
    }
    //

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={listKubernetesSecretOnClick}>查询</Button>

            </Space>
            <br/>
            <br/>
            <Space>
                <Button type={'primary'} onClick={createDockerSecretOnClickHandler}>创建DockerConfigJson</Button>
                <Button type={'primary'} onClick={createTlsSecretOnClickHandler}>创建TLSSecret</Button>
                <Button type={'primary'} onClick={createOpaqueSecretOnClickHandler}>创建OpaqueSecret</Button>
                <Button type={'primary'} onClick={createSSHAuthSecretOnClickHandler}>创建SSHAuthSecret</Button>
                <Button type={'primary'} onClick={createBasicAuthSecretOnClickHandler}>创建BasicAuthSecret</Button>
            </Space>
            <br/>
            <br/>
            <Tabs
                type="card"
                items={[
                    {
                        label: 'secret',
                        key: 'secret',
                        children: <Table columns={secretColumns} dataSource={secretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'dockerSecret',
                        key: 'dockerSecret',
                        children: <Table columns={dockerSecretColumns} dataSource={dockerSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'tlsSecret',
                        key: 'tlsSecret',
                        children: <Table columns={tlsSecretColumns} dataSource={tlsSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'opaqueSecret',
                        key: 'opaqueSecret',
                        children: <Table columns={opaqueSecretColumns} dataSource={opaqueSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'sshSecret',
                        key: 'sshSecret',
                        children: <Table columns={sshAuthSecretColumns} dataSource={sshAuthSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: 'basicSecret',
                        key: 'basicSecret',
                        children: <Table columns={basicAuthSecretColumns} dataSource={basicAuthSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    },
                    {
                        label: '其他',
                        key: 'other',
                        children: <Table columns={otherSecretColumns} dataSource={otherSecretDataSource}
                                         scroll={{x: true, y: 400}}/>
                    }

                ]}
                onChange={tabOnChange}
            />


            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={createDockerSecretOpenModal}
                onCancel={createDockerSecretModalOnCancelHandler}
                onOk={createDockerSecretModalOnOkHandler}
                title={'创建 K8S Docker Secret'}
                destroyOnClose={true}
            >
                <span>name</span><Input id={'dockerSecretName'}/>
                <span>username</span><Input id={'dockerSecretUsername'}/>
                <span>password</span><Input id={'dockerSecretPassword'}/>
                <span>email</span><Input id={'dockerSecretEmail'}/>
                <span>url</span><Input id={'dockerSecretUrl'}/>
            </Modal>

            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={createTlsSecretOpenModal}
                onCancel={createTlsSecretModalOnCancelHandler}
                onOk={createTlsSecretModalOnOkHandler}
                title={'创建 K8S Tls Secret'}
                destroyOnClose={true}
            >
                <span>name</span><Input id={'tlsSecretName'}/>
                <span>tlsCrt</span><Input.TextArea id={'tlsCrt'}/>
                <span>tlsKey</span><Input.TextArea id={'tlsKey'}/>
            </Modal>

            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={createSSHAuthSecretOpenModal}
                onCancel={createSSHAuthSecretModalOnCancelHandler}
                onOk={createSSHAuthSecretModalOnOkHandler}
                title={'创建 K8S SSH Auth Secret'}
                destroyOnClose={true}
            >
                <span>name</span><Input id={'sshAuthName'}/>
                <span>privateKey</span><Input.TextArea id={'privateKey'}/>
            </Modal>

            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={createOpaqueSecretOpenModal}
                onCancel={createOpaqueSecretModalOnCancelHandler}
                onOk={createOpaqueSecretModalOnOkHandler}
                title={'创建 K8S Opaque Secret'}
                destroyOnClose={true}
            >
                <span>name</span><Input id={'opaqueSecretName'}/>
                <span>data</span><Input id={'data'}/>
            </Modal>


            <Modal
                width={600}
                keyboard={true}
                maskClosable={false}
                closable={false}
                open={createBasicAuthSecretOpenModal}
                onCancel={createBasicAuthSecretModalOnCancelHandler}
                onOk={createBasicAuthSecretModalOnOkHandler}
                title={'创建 K8S Basic Auth Secret'}
                destroyOnClose={true}
            >
                <span>name</span><Input id={'basicAuthName'}/>
                <span>username</span><Input id={'basicAuthUsername'}/>
                <span>password</span><Input id={'basicAuthPassword'}/>

            </Modal>
        </>
    );
}