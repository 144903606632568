import {Button, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {ListOrganizationProductEnv} from './product/product';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from './user/org';
import {CopyOutlined} from '@ant-design/icons';
import copy from 'copy-to-clipboard';


export const Token = window.localStorage.getItem('token')

// APIS


// User
export const GetSelfOrganizationApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/org/self'
export const CreateOrganizationApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/org'
export const GetOrganizationMembersApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/org/member'
export const LeaveOrganizationApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/org/leave'

export const CheckUserExistsApiUrl = process.env.REACT_APP_StartOpsApiHost + '/v1/user/info/exists'

export const GetUserRegisterVerifyCodeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/register/verifyCode'
export const UserRegisterApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/register'

export const GetUserLoginVerifyCodeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/login/verifyCode'
export const UserLoginApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/login'

export const UserUpdatePasswdApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/updatePasswd'
export const GetUserUpdatePasswdVerifyCodeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/updatePasswd/verifyCode'

export const UserForgotPasswdApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/forgotPasswd'
export const GetUserForgotPasswdVerifyCodeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/user/forgotPasswd/verifyCode'


// Product
export const OrganizationProductApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product'
export const OrganizationProductEnvApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/env'
export const ProductServiceUrlApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/serviceUrl'

export const ProductServiceUrlAuthKeyApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/serviceUrl/authKey'
export const ProductServiceUrlAuthUrlApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/serviceUrl/authUrl'


export const ProductReliabilityApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/env'
export const ProductContainerImageApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/container/image'
export const ProductContainerRegistryApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/container/registry'

export const ProductK8SPublicTemplateApi =  process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/object/public/template'
export const ProductK8SPrivateTemplateApi =  process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/object/private/template'

export const ProductK8SClusterApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/k8scluster'
export const ProductK8SClusterEdgeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/k8scluster/kubeedge'

export const ProductK8SClusterNameSpaceApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/namespace'
export const ProductK8SClusterNodeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/node'
export const ProductK8SClusterConfigMapApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/configmap'
export const ProductK8SClusterDeploymentApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/deployment'
export const ProductK8SClusterSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/secret'
export const ProductK8SClusterDockerSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/dockerSecret'
export const ProductK8SClusterTlsSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/tlsSecret'
export const ProductK8SClusterOpaqueSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/opaqueSecret'
export const ProductK8SClusterSSHAuthSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/sshAuthSecret'
export const ProductK8SClusterBasicAuthSecretApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/basicAuthSecret'
export const ProductK8SClusterServiceApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/service'
export const ProductK8SClusterIngressApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/ingress'


export const ProductK8SClusterPodsApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/pod'
export const ProductK8SClusterPodApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/pod'
export const ProductK8SClusterSearchPodApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/pod/search'
export const ProductK8SClusterEvictPodApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/pod/evict'
export const ProductK8SClusterCleanEvictPodApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/kubernetes/pod/cleanEvict'


export const ProductCodeRepoSelfGitlabPlatform = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/platform/selfgitlab'
export const ProductCodeRepoSelfGitlabToken = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/platform/token/selfgitlab'
export const ProductCodeRepoSelfGitlab = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab'
export const ProductCodeRepoSelfGitlabImport = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/import'
export const ProductCodeRepoSelfGitlabBranch = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/branch'
export const ProductCodeRepoSelfGitlabBranchCommits = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/branch/commits'
export const ProductCodeRepoSelfGitlabBranchProtect = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/branch/protect'
export const ProductCodeRepoSelfGitlabBranchUnprotect = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/branch/unprotect'
export const ProductCodeRepoSelfGitlabBranchMerge = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/branch/merge'
export const ProductCodeRepoSelfGitlabRepoArchive = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/archive'

export const ProductCodeRepoGithubToken = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/platform/token/github'
export const ProductCodeRepoGithub = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github'
export const ProductCodeRepoGithubImport = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/import'
export const ProductCodeRepoGithubMerge = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/merge'
export const ProductCodeRepoGithubArchive = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/archive'
export const ProductCodeRepoGithubBranch = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/branch'
export const ProductCodeRepoGithubBranchCommits = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/branch/commits'
export const ProductCodeRepoGithubOrganization = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/org'

export const ProductCodeRepoContainerImage = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/container/image'
export const ProductCodeRepoGithubContainerImage = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/container/image'
export const ProductCodeRepoSelfGitlabContainerImage = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/container/image'



export const ProductCodeRepoGithubCiTemplate = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/github/ci'
export const ProductCodeRepoSelfGitlabCiTemplate = process.env.REACT_APP_StartOpsApiHost + '/v1/product/code/repo/selfgitlab/ci'


// product - webServiceResource
export const ProductWebServiceResourceApi = process.env.REACT_APP_StartOpsApiHost + '/v1/product/resource/webservice'


// Message
export const TranslateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/message/translate'


export const MessageGroup = process.env.REACT_APP_StartOpsApiHost + '/v1/message/group'
export const MessageGroupMember = process.env.REACT_APP_StartOpsApiHost + '/v1/message/group/member'


// Cloud
export const GetCloudPlatformApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/platform'
export const GetSupportCloudPlatformApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/platform'

// Cloud - region
export const CloudPlatformRegionApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/region'

// Cloud - certificate
export const ParserTlsCertificateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/certificate/parser'
export const TlsCertificateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/certificate'
export const ImportTlsCertificateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/certificate/import'

// Cloud - account
export const CloudPlatformAccountApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/account'
export const CloudPlatformSubAccountApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/account/subaccount'
export const CloudPlatformSubAccountAccessKeyApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/account/subaccount/accesskey'
export const CloudPlatformAccountBillApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/account/bill'
// Cloud - cdn
export const CloudPlatformCDNApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/cdn'
export const CloudPlatformRefreshCDNApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/cdn/cache/refresh'
export const CloudPlatformCDNQuotaApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/cdn/quota'
export const CloudPlatformCDNIPApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/cdn/ip'
export const CloudPlatformCDNIPDetailApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/cdn/ip/detail'
// Cloud - dns
export const CloudPlatformDNSApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/dns'
export const ImportCloudPlatformDNSApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/dns/import'
export const CloudPlatformDNSCacheApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/dns/cache'
export const CloudPlatformDNSRecordApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/dns/record'
// Cloud - oss
export const CloudPlatformObjectStorageApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/oss'
export const CloudPlatformObjectStorageSecurityTokenGeneratorApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/oss/generator'
export const CloudPlatformObjectStorageSecurityTokenApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/oss/sts'
// Cloud - rds
export const CloudPlatformRDSApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/rds'
export const CloudPlatformRDSSlowLogApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/rds/slowlog'
export const CloudPlatformRDSErrLogApi = process.env.REACT_APP_StartOpsApiHost + '/v1/cloud/rds/errlog'

// WorkSpace
export const WorkSpaceFaultApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/fault'
export const WorkSpaceFaultStreamApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/fault/stream'
export const WorkSpaceOrderTypeApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/workorder/type'
export const WorkSpaceOrderApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/workorder'
export const WorkSpaceOrderStepApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/workorder/step'
export const WorkSpaceTodoApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/todo'
export const WorkSpaceCloseTodoApi = process.env.REACT_APP_StartOpsApiHost + '/v1/workspace/todo/close'

// Hook
export const HookAlertHisApi = process.env.REACT_APP_StartOpsApiHost + '/v1/hook/prometheus/alert/his'
export const HookAlertAckApi = process.env.REACT_APP_StartOpsApiHost + '/v1/hook/prometheus/alert/ack'
export const HookKubernetesEventApi = process.env.REACT_APP_StartOpsApiHost + '/v1/hook/kubernetes/event'


export const HookMonitorReceiverApi = process.env.REACT_APP_StartOpsApiHost + '/v1/hook/monitor/receiver'

export const HookKubernetesEventReceiverApi = process.env.REACT_APP_StartOpsApiHost + '/v1/hook/kubernetes/receiver'


// Ci

export const CiTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template'
export const CiTemplateStepApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/step'

export const CiTemplateTaskApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/task'


export const CiCodeScanTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/codeScan'
export const CiUnitTestTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/unitTest'
export const CiBuildProgramTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/buildProgram'
export const CiBuildContainerImageTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/buildContainerImage'
export const CiUploadProgramTemplateApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/template/uploadProgram'

export const CiCopyContainerImageApi = process.env.REACT_APP_StartOpsApiHost + '/v1/ci/container/image/copy'


// Robot
export const RobotQuestionApi = process.env.REACT_APP_StartOpsApiHost + '/v1/robot/question'
export const RobotQuestionHisApi = process.env.REACT_APP_StartOpsApiHost + '/v1/robot/question/his'
export const RobotChatSessionApi = process.env.REACT_APP_StartOpsApiHost + '/v1/robot/chat/session'

export const ThirdChatApi = process.env.REACT_APP_StartOpsApiHost + '/v1/robot/chat/third/token'
export const ThirdChatSessionApi = 'https://assistant.startops.com.cn/v1/chat/session'
export const ThirdChatSessionContextApi = 'https://assistant.startops.com.cn/v2/chat/context'

// Monitor
export const MonitorTokenApi = process.env.REACT_APP_StartOpsApiHost + '/v1/monitor/token'


export function Post(props) {
    return fetch(
        props.url, {
            method: 'POST',
            body: JSON.stringify(props.params),
            headers: {
                'Content-Type': 'application/json',
                'token': Token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}

export function Put(props) {
    return fetch(
        props.url, {
            method: 'PUT',
            body: JSON.stringify(props.params),
            headers: {
                'Content-Type': 'application/json',
                'token': Token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}

// 需要在调用方组装url及参数
export function Get(props) {
    return fetch(
        props.url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': Token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}

export function Del(props) {
    return fetch(
        props.url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'token': Token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}

export function GetWithToken(props) {
    return fetch(
        props.url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': props.token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}


export function PostWithToken(props) {
    return fetch(
        props.url, {
            method: 'POST',
            body: JSON.stringify(props.params),
            headers: {
                'Content-Type': 'application/json',
                'token': props.token
            }
        }
    ).then(
        response => {
            if (!response.ok) {
                return Promise.reject(new Error(`HTTP error ${response.status}`))
            }
            return response.json()
        }
    )
}

export function ParseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export function GetCloudPlatform(platformId) {
    switch (platformId) {
        case 1:
            return 'IDC'
        case 2:
            return '阿里云'
        case 3:
            return '腾讯云'
        case 4:
            return '华为云'
        case 5:
            return '百度云'
        case 6:
            return '亚马逊云'
        case 7:
            return '七牛云'
        case 8:
            return '网宿云'
        default:
            return '未知'
    }
}


export const CloudPlatformOptions = [
    {
        value: 1,
        label: 'IDC'
    },
    {
        value: 2,
        label: '阿里云'
    },
    {
        value: 3,
        label: '腾讯云'
    },
    {
        value: 4,
        label: '华为云'
    },
    {
        value: 5,
        label: '百度云'
    },
    {
        value: 6,
        label: '亚马逊云'
    },
    {
        value: 7,
        label: '七牛云'
    },
    {
        value: 8,
        label: '网宿云'
    }
]

// 列出产品环境
export function ProductEnvState(setEnv) {

    const [productEnvs, setProductEnvs] = useState([])
    useEffect(() => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListOrganizationProductEnv({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let envs = data.env
                    if (envs === null) {
                        setProductEnvs([])
                        return
                    }

                    let tempEnvs = []
                    for (let env of envs) {
                        tempEnvs.push(
                            {
                                label: env.envName,
                                value: env.uuid
                            }
                        )
                    }
                    setEnv(tempEnvs.slice(-1)[0].value)
                    setProductEnvs(tempEnvs)
                    return [productEnvs, setProductEnvs]
                }
                setProductEnvs([])
                message.error(`获取产品环境失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setProductEnvs([])
                console.log(`获取产品环境失败 ${err}`)
                message.error(`获取产品环境失败`)
            }
        )

    }, [])
    return productEnvs
}


export function StrEncode(data) {
    return unescape(encodeURIComponent(JSON.stringify(data)));
}

export function StrDecode(data) {
    return JSON.parse(decodeURIComponent(escape(data)));
}


export function CopyOnClick(text) {
    const r = copy(text)
    if (r) {
        message.info(`Copy Successful!`)
    } else {
        message.error(`Copy Failed!`)
    }
}