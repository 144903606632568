import md5 from 'js-md5';
import {
    CheckUserExistsApiUrl, GetUserForgotPasswdVerifyCodeApi, GetUserLoginVerifyCodeApi,
    GetUserRegisterVerifyCodeApi, GetUserUpdatePasswdVerifyCodeApi,
    Post, Get, UserForgotPasswdApi,
    UserRegisterApi, UserUpdatePasswdApi
} from '../common';


function CheckUserExistsApi(props) {
    const params = {
        username: props.username,
        phone: props.phone,
    }

    return Post(
        {
            url: CheckUserExistsApiUrl,
            params: params,
        }
    )
}

// 用户注册

export function GetUserRegisterVerifyCode(props) {
    const params = {
        phoneNumber: props.phoneNumber,
    }
    return Post({
        url: GetUserRegisterVerifyCodeApi,
        params: params
    })
}

export function UserRegister(props) {
    const params = {
        username: props.username,
        nickname: props.nickname,
        phoneRegion: props.phoneRegion,
        phone: props.phone,
        verifyCode: props.verifyCode,
        password: `${props.password !== undefined ? props.password : ""}`,
        email: `${props.email !== undefined ? props.email : ""}`,
    }

    return Post({
        url: UserRegisterApi,
        params: params
    })
}

//
export function GetLoginVerifyCode(props) {
    const params = new URLSearchParams({
        phoneNumber: props.phoneNumber,
    })
    return Get({
        url: `${GetUserLoginVerifyCodeApi}?${params}`,
    })
}

export function VerifyCodeLogin(props) {
    const params = {
        phoneNumber: props.phoneNumber,
        verifyCode: props.verifyCode,
    }

    return Post({
        url: GetUserLoginVerifyCodeApi,
        params: params,
    })
}

// 更新用户密码

export function GetUserUpdatePasswdVerifyCode(props) {
    const params = {
    }
    return Post({
        url: GetUserUpdatePasswdVerifyCodeApi,
        params: params,
    })
}

export function UserVerifyCodeUpdatePasswd(props) {
    const params = {
    }
    return Post({
        url: GetUserUpdatePasswdVerifyCodeApi,
        params: params,
    })
}

export function UserUpdatePassword(props) {
    //const passwordMD5 = md5(password)
    const params = {
        password: props.password,
        verifyCode: props.verifyCode,
    }
    return Post({
        url: UserUpdatePasswdApi,
        params: params,
    })
}

// 忘记密码

export function GetUserForgotPasswdVerifyCode(props) {
    const params = new URLSearchParams({
        phoneNumber: props.phoneNumber,
    })
    return Post({
        url: `${GetUserForgotPasswdVerifyCodeApi}?${params}`,
    })
}

export function UserForgotPasswd(props) {
    const params = {
        phoneNumber: props.phoneNumber,
        password: props.password,
        verifyCode: props.verifyCode,
    }
    return Post({
        url: UserForgotPasswdApi,
        params: params,
    })
}

//

