import {Post, Get, Del, WorkSpaceTodoApi, WorkSpaceCloseTodoApi} from '../common';

export function CreateTodo(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        priority: props.priority,
        overTime: props.overTime,
        content: props.content,
    }
    return Post({
        url: `${WorkSpaceTodoApi}`,
        params: params,
    })
}

export function ListTodo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        status: props.status,
    })
    return Get({
        url: `${WorkSpaceTodoApi}?${params}`
    })
}

export function DelTodo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        todoUuid: props.todoUuid,
    })
    return Del({
        url: `${WorkSpaceTodoApi}?${params}`
    })
}

export function CloseTodo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        todoUuid: props.todoUuid,
        describe: props.describe,
    })
    return Del({
        url: `${WorkSpaceCloseTodoApi}?${params}`
    })
}
