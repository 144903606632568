import {Post, Get, Del, ProductK8SClusterApi, ProductK8SClusterEdgeApi, ProductK8SClusterPodApi} from '../common';

export function AddKubernetesCluster(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        name: props.name,
        apiService: props.apiService,
        describe: props.describe
    }
    return Post({
        url: ProductK8SClusterApi,
        params: params
    })
}

export function ListKubernetesCluster(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid
    })
    return Get({
        url: `${ProductK8SClusterApi}?${params}`
    })
}

export function DelKubernetesCluster(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        clusterUuid: props.clusterUuid
    })
    return Del({
        url: `${ProductK8SClusterApi}?${params}`
    })
}


export function AddKubernetesEdge(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        kubeEdgeGrpc: props.kubeEdgeGrpc,
        token: props.token
    }
    return Post({
        url: ProductK8SClusterEdgeApi,
        params: params
    })
}

export function GetKubernetesEdge(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
    })
    return Get({
        url: `${ProductK8SClusterEdgeApi}?${params}`
    })
}

export function DelKubernetesEdge(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
    })
    return Del({
        url: `${ProductK8SClusterEdgeApi}?${params}`
    })
}

