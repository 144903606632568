import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';


import {
    AddMessageGroupMember,
    CreateMessageGroup,
    DelMessageGroup, DelMessageGroupMember, GetMessageGroupMember,
    ListMessageGroup
} from '../../apis/message/messageGroup';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';


export function MessageGroupRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['monitor']} defaultSelectedKeys={['messageGroup']}></LeftSider>
                    <IndexRightBody>
                        <MessageGroupContent></MessageGroupContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function MessageGroupContent() {
    //
    const [defaultActiveKey, setDefaultActiveKey] = useState('messageGroup')

    //
    const MessageGroupTabsOnChange = (activeKey) => {

        switch (activeKey) {
            case 'messageGroup':
                listMessageGroupOnClick()
                setDefaultActiveKey(activeKey)
                break
            case 'messageGroupMembers':
                setDefaultActiveKey(activeKey)
                break
            default:
                return
        }
    }

    //
    const messageGroupColumns = [
        {
            title: '消息组名',
            dataIndex: 'groupName'
        },
        {
            title: 'uuid',
            dataIndex: 'uuid'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            render: (_, record) => {
                return (<>
                    <Space>
                        <Popconfirm
                            title={'您是否要删除该消息组'}
                            onConfirm={() => {
                                delMessageGroupOnClick(record.uuid)
                            }}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </Space>

                    <br/>

                    <Space>
                        <Button type={'primary'}
                                onClick={() => addMessageGroupMemberOnClick(record.uuid)}>添加成员</Button>
                        <Button type={'primary'}
                                onClick={() => getMessageGroupMemberOnClick(record.uuid)}>查看成员</Button>
                    </Space>


                </>)
            }
        }
    ]

    const [messageGroupDataSource, setMessageGroupDataSource] = useState([])
    const [createMessageGroupModalOpen, setCreateMessageGroupModalOpen] = useState(false)

    const createMessageGroupOnClick = () => {
        setCreateMessageGroupModalOpen(true)
    }

    const createMessageGroupModalOnOk = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const groupName = document.getElementById('createGroupName').value

        if (groupName === '' || groupName === undefined) {
            message.error(`请输入组名`)
            return;
        }

        CreateMessageGroup({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            groupName: groupName
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建消息组成功`)
                    listMessageGroupOnClick()
                    setCreateMessageGroupModalOpen(false)
                    return
                }
                message.error(`创建消息组失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建消息组失败 ${err}`)
            }
        )
    }

    const createMessageGroupModalOnCancel = () => {
        setCreateMessageGroupModalOpen(false)
    }

    const listMessageGroupOnClick = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        ListMessageGroup({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setMessageGroupDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const g of d) {
                        tmp.push({
                            key: g.uuid,
                            uuid: g.uuid,
                            orgUuid: g.orgUuid,
                            productUuid: g.productUuid,
                            groupName: g.groupName,
                            createTime: g.createTime
                        })
                    }

                    setMessageGroupDataSource(tmp)
                    return
                }

                message.error(`列出消息组失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出消息组失败 ${err}`)
            }
        )
    }

    const delMessageGroupOnClick = (groupUuid) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        DelMessageGroup({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            groupUuid: groupUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除消息组成功`)
                    listMessageGroupOnClick()
                    return
                }
                message.error(`删除消息组失败 ${errMsg}`)

            }
        ).catch(
            err => {
                message.error(`删除消息组失败 ${err}`)
            }
        )
    }

    //
    const messageGroupMemberColumns = [
        {
            title: 'groupUuid',
            dataIndex: 'groupUuid'
        },
        {
            title: '通道',
            dataIndex: 'channel',
            render: (text) => {
                let name
                switch (text) {
                    case 1:
                        name = '钉钉'
                        break
                    case 2:
                        name = '飞书'
                        break
                    case 3:
                        name = 'Slack'
                        break
                    default:
                        name = 'UnKnow'
                }
                return name
            }
        },
        {
            title: 'url',
            dataIndex: 'url'
        },
        {
            title: '关键字',
            dataIndex: 'keyword'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Popconfirm
                            title={'删除组织组成员'}
                            description={'您确认要删除该组织组成员？'}
                            onConfirm={() => {
                                delMessageGroupMemberOnClick(record.groupUuid, record.uuid)
                            }}
                        >
                            <Button danger={true} type={'primary'}>删除</Button>
                        </Popconfirm>
                    </>
                )
            }
        }
    ]

    const [groupMemberChannel, setGroupMemberChannel] = useState(1)
    const groupMemberChannelOptions = [
        {
            value: 1,
            label: '钉钉'
        },
        {
            value: 2,
            label: '飞书'
        },
        {
            value: 3,
            label: 'Slack'
        }
    ]

    const groupMemberChannelSelectOnSelectHandler = (value) => {
        setGroupMemberChannel(value)
    }

    const [messageGroupMemberDataSource, setMessageGroupMemberDataSource] = useState([])
    const [addMessageGroupMemberModalOpen, setAddMessageGroupMemberModalOpen] = useState(false)
    const [addGroupMemberDefaultGroupUuid, setAddGroupMemberDefaultGroupUuid] = useState('')

    const addMessageGroupMemberOnClick = (groupUuid) => {
        setAddGroupMemberDefaultGroupUuid(groupUuid)
        setAddMessageGroupMemberModalOpen(true)
    }

    const addMessageGroupMemberModalOnOk = () => {

        const url = document.getElementById('url').value
        const keyword = document.getElementById('keyword').value
        if (url === '' || url === undefined) {
            message.error(`请输入 url 信息`)
            return
        }

        AddMessageGroupMember({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            groupUuid: addGroupMemberDefaultGroupUuid,
            channel: groupMemberChannel,
            url: url,
            keyword: keyword
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加消息组成员成功`)
                    setAddMessageGroupMemberModalOpen(false)
                    return
                }

                message.error(`添加消息组成员失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加消息组成员失败 ${err}`)
            }
        )

    }

    const addMessageGroupMemberModalOnCancel = () => {
        setAddMessageGroupMemberModalOpen(false)
    }


    const getMessageGroupMemberOnClick = (groupUuid) => {

        GetMessageGroupMember({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            groupUuid: groupUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setMessageGroupMemberDataSource([])
                        setDefaultActiveKey('messageGroupMembers')
                        return;
                    }

                    let tmp = []
                    for (const m of d) {
                        tmp.push({
                            key: m.uuid,
                            uuid: m.uuid,
                            groupUuid: m.groupUuid,
                            channel: m.channel,
                            url: m.url,
                            createTime: m.createTime
                        })
                    }

                    setDefaultActiveKey('messageGroupMembers')
                    setMessageGroupMemberDataSource(tmp)
                    return
                }
                message.error(`获取消息组成员失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取消息组成员失败 ${err}`)
            }
        )
    }

    const delMessageGroupMemberOnClick = (groupUuid, memberUuid) => {
        DelMessageGroupMember({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            groupUuid: groupUuid,
            memberUuid: memberUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除组织组成员成功`)
                    getMessageGroupMemberOnClick(groupUuid)
                    return
                }
                message.error(`删除组织组成员失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除组织组成员失败 ${err}`)
            }
        )
    }

    //
    useEffect(() => {
        listMessageGroupOnClick()
    }, [])

    return (<>
        <Tabs
            activeKey={defaultActiveKey}
            type={'card'}
            items={
                [
                    {
                        key: 'messageGroup',
                        label: `消息组`,
                        children: <>
                            <Button type={'primary'} onClick={createMessageGroupOnClick}>添加消息组</Button>
                            <Table columns={messageGroupColumns} dataSource={messageGroupDataSource}/>
                        </>
                    },
                    {
                        key: 'messageGroupMembers',
                        label: `消息组成员`,
                        children: <>
                            <Table columns={messageGroupMemberColumns} dataSource={messageGroupMemberDataSource}/>
                        </>
                    }
                ]
            }
            onChange={MessageGroupTabsOnChange}
        />

        <Modal
            open={createMessageGroupModalOpen}
            onOk={createMessageGroupModalOnOk}
            onCancel={createMessageGroupModalOnCancel}
            destroyOnClose={true}
        >
            <span>组名</span><Input id={'createGroupName'}/>

        </Modal>


        <Modal
            open={addMessageGroupMemberModalOpen}
            onOk={addMessageGroupMemberModalOnOk}
            onCancel={addMessageGroupMemberModalOnCancel}
            destroyOnClose={true}
        >
            <span>通道</span>
            <Select
                style={{width: 120}}
                value={groupMemberChannel}
                options={groupMemberChannelOptions}
                onSelect={groupMemberChannelSelectOnSelectHandler}
            />


            <br/>
            <span>Url</span><Input id={'url'}/>
            <span>关键字</span><Input id={'keyword'}/>
        </Modal>
    </>);
}


export function GetMessageGroupOptions() {
    const [messageGroupOptions, setMessageGroupOptions] = useState([])

    useEffect(
        () => {

            if (!VerifyOrgUuidAndOrgProductUuid()) {
                return
            }

            ListMessageGroup({
                orgUuid: OrgUuid,
                productUuid: OrgProductUuid
            }).then(
                data => {
                    let errCode = data.errCode
                    let errMsg = data.errMsg
                    if (errCode === 0) {
                        const d = data.data
                        if (d === null) {
                            setMessageGroupOptions([])
                            return;
                        }

                        let tmp = []
                        for (const g of d) {
                            tmp.push({
                                value: g.uuid,
                                label: g.groupName,
                            })
                        }

                        setMessageGroupOptions(tmp)
                        return
                    }

                    setMessageGroupOptions([])
                    message.error(`列出消息组失败 ${errMsg}`)
                }
            ).catch(
                err => {
                    setMessageGroupOptions([])
                    message.error(`列出消息组失败 ${err}`)
                }
            )


        }, []
    )

    return messageGroupOptions
}