import {ConfigProvider, Layout, Space} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useState} from 'react';
import AceEditor from 'react-ace';


export function ConvertRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['tools']} defaultSelectedKeys={['convert']}></LeftSider>
                    <IndexRightBody>
                        <ConvertContent></ConvertContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


export function ConvertContent() {

    const [srcValue, setSrcValue] = useState('')
    const [destValue, setDestValue] = useState('')

    return <>

        <h6>源格式</h6>
            <AceEditor
                mode={'markdown'}
                theme="terminal"
                showGutter={true}
                highlightActiveLine={true}
                value={srcValue}
                width={"40%"}
            />

        <h6>目标格式</h6>
            <AceEditor
                mode={'markdown'}
                theme="terminal"
                showGutter={true}
                highlightActiveLine={true}
                value={destValue}
                width={"40%"}
            />



    </>
}