import {CloudPlatformRDSApi, CloudPlatformRDSSlowLogApi, CloudPlatformRDSErrLogApi, Get} from '../common';

export function ListRDSInstance(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        region: props.region,
    })
    return Get({
        url: `${CloudPlatformRDSApi}?${params}`,
    })
}

export function GetRDSSlowLog(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        region: props.region,
        instanceId: props.instanceId,
        startTime: props.startTime,
        endTime: props.endTime,
    })
    return Get({
        url: `${CloudPlatformRDSSlowLogApi}?${params}`,
    })
}

export function GetRDSErrLog(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        region: props.region,
        instanceId: props.instanceId,
        startTime: props.startTime,
        endTime: props.endTime,
    })
    return Get({
        url: `${CloudPlatformRDSErrLogApi}?${params}`,
    })
}