import {
    Button,
    ConfigProvider,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Select,
    Space,
    Table,
    Tabs
} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {
    AddSelfGitlabPlatform,
    AddSelfGitlabToken,
    ArchiveSelfGitlabRepo,
    CreateSelfGitlabRepoBranch,
    DelSelfGitlabPlatform, DelSelfGitlabRepoBranch,
    DelSelfGitlabToken,
    ImportSelfGitlabRepo,
    ListSelfGitlabPlatform,
    ListSelfGitlabRepo,
    ListSelfGitlabRepoBranch,
    ListSelfGitlabRepoBranchCommits,
    ListSelfGitlabToken, MergeSelfGitlabRepo,
    ProtectSelfGitlabRepoBranch, SelfGitlabRepoBranchMergeHis,
    UnProtectSelfGitlabRepoBranch
} from '../../apis/product/selfGitlab';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';
import {CopyOutlined, LinkOutlined} from '@ant-design/icons';
import {
    AddGithubToken, ArchiveGithubRepo, DelGithubRepoBranch,
    DelGithubToken,
    ImportGithubRepo, ListGithubOrganization,
    ListGithubRepo, ListGithubRepoBranch, ListGithubRepoBranchCommits,
    ListGithubToken, MergeGithubRepoBranch, ProtectGithubRepoBranch, UnProtectGithubRepoBranch
} from '../../apis/product/github';
import {
    GithubCodeRepoContainerImage, GithubCodeRepoExecCiTemplate,
    ListCodeRepoContainerImage,
    SelfGitlabCodeRepoContainerImage, SelfGitlabCodeRepoExecCiTemplate
} from '../../apis/product/ci';
import {ListContainerRegistry} from '../../apis/product/containerRegistry';
import {CopyOnClick} from '../../apis/common';
import {ListCiTemplate, ListCiTemplateTask} from '../../apis/ci/template';

export function CodeRepoRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['product']} defaultSelectedKeys={['codeRepo']}></LeftSider>
                    <IndexRightBody>
                        <CodeRepoContent></CodeRepoContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function CodeRepoContent() {
    // ---- 自建Gitlab
    // 自建Gitlab平台Columns
    const selfGitlabCodePlatformColumns = [
        {
            title: '访问协议',
            dataIndex: 'protocol',
            key: 'protocol'
        },
        {
            title: '主机名',
            dataIndex: 'hostname',
            key: 'hostname'
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Space>
                        <Button
                            type={'primary'}
                            danger={true}
                            onClick={() => delSelfGitlabCodePlatform(record.key)}>删除</Button>
                    </Space>

                    <br/>

                    <Space>
                        <Button type={'primary'}
                                onClick={() => addSelfGitlabCodePlatformTokenOnClick(record.key)}>添加Token</Button>
                        <Button type={'primary'}
                                onClick={() => listSelfGitlabCodePlatformToken(record.key)}>查看Token</Button>
                    </Space>

                    <br/>

                    <Space>
                        <Button type={'primary'}
                                onClick={() => listSelfGitlabCodePlatformRepo(record.key, '')}>查看仓库</Button>
                    </Space>

                </>
            }
        }
    ]

    const [selfGitlabCodePlatformDataSource, setSelfGitlabCodePlatformDataSource] = useState([])

    const [addSelfGitlabCodePlatformOpenModal, setAddSelfGitlabCodePlatformOpenModal] = useState(false)

    const [addSelfGitlabCodePlatformProtocol, setAddSelfGitlabCodePlatformProtocol] = useState(2)
    const addSelfGitlabCodePlatformProtocolOnChange = (value) => {
        setAddSelfGitlabCodePlatformProtocol(value)
    }
    // 自建Gitlab平台列表
    const [selfGitlabCodePlatformSelectOptions, setSelfGitlabCodePlatformSelectOptions] = useState([])
    // 添加自建GitlabToken的PlatformUuid值
    const [addSelfGitlabCodePlatformTokenPlatform, setAddSelfGitlabCodePlatformTokenPlatform] = useState('')
    const selfGitlabCodePlatformSelectOptionsOnChange = (value) => {
        setAddSelfGitlabCodePlatformTokenPlatform(value)
    }

    // 添加自建Gitlab平台
    const addSelfGitlabCodePlatform = (selfGitlabCodePlatformUuid) => {

        setAddSelfGitlabCodePlatformOpenModal(true)
    }

    const addSelfGitlabCodePlatformOnCancel = () => {
        setAddSelfGitlabCodePlatformOpenModal(false)
    }

    const addSelfGitlabCodePlatformOnOk = () => {
        const addSelfGitlabCodePlatformHostname = document.getElementById('addSelfGitlabCodePlatformHostname').value
        const addSelfGitlabCodePlatformDescribe = document.getElementById('addSelfGitlabCodePlatformDescribe').value

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        AddSelfGitlabPlatform({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            protocol: addSelfGitlabCodePlatformProtocol,
            hostname: addSelfGitlabCodePlatformHostname,
            describe: addSelfGitlabCodePlatformDescribe
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加自建Gitlab平台成功`)
                    listSelfGitlabCodePlatform()
                    setAddSelfGitlabCodePlatformOpenModal(false)
                    return
                }
                message.error(`添加自建Gitlab平台失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加自建Gitlab平台失败, ${err}`)
            }
        )
    }

    // 列出自建Gitlab平台
    const listSelfGitlabCodePlatform = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        ListSelfGitlabPlatform({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const gitlab = data.gitlab
                    if (gitlab === null) {
                        setSelfGitlabCodePlatformDataSource([])
                        setSelfGitlabCodePlatformSelectOptions([])

                        return;
                    }

                    let tmp = []
                    let tmpSelectOptions = []
                    for (const g of gitlab) {
                        tmp.push({
                            key: g.uuid,
                            protocol: g.protocol,
                            hostname: g.hostname,
                            describe: g.describe,
                            createTime: g.createTime
                        })

                        tmpSelectOptions.push({
                            value: g.uuid,
                            label: g.hostname
                        })
                    }
                    setSelfGitlabCodePlatformDataSource(tmp)
                    setSelfGitlabCodePlatformSelectOptions(tmpSelectOptions)
                    return
                }
                message.error(`列出自建Gitlab平台失败 ${errMsg}`)
                setSelfGitlabCodePlatformDataSource([])
                setSelfGitlabCodePlatformSelectOptions([])
            }
        ).catch(
            err => {
                message.error(`列出自建Gitlab平台失败`)
                setSelfGitlabCodePlatformDataSource([])
                setSelfGitlabCodePlatformSelectOptions([])
                console.log(`列出自建Gitlab平台失败 ${err}`)
            }
        )
    }
    // 删除自建Gitlab平台
    const delSelfGitlabCodePlatform = (uuid) => {
        DelSelfGitlabPlatform({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            uuid: uuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除自建Gitlab平台成功`)
                    return
                }
                message.error(`删除自建Gitlab平台失败 ${errMsg}`)
                console.log(`删除自建Gitlab平台失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除自建Gitlab平台失败 ${err}`)
                console.log(`删除自建Gitlab平台失败 ${err}`)
            }
        )
    }


    // 自建Gitlab平台Token表Columns
    const selfGitlabCodePlatformTokenColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'privateToken',
            dataIndex: 'privateToken',
            key: 'privateToken'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Popconfirm
                        title={'删除自建Gitlab平台Token'}
                        description={'请问您是否需要删除该自建Gitlab平台Token'}
                        onConfirm={() => delSelfGitlabCodePlatformToken(record.platformUuid, record.key)}
                    >
                        <Button
                            type={'primary'}
                            danger={true}>删除</Button>
                    </Popconfirm>

                    <Button type={'primary'}
                            onClick={() => importSelfGitlabCodePlatform(record.platformUuid, record.key)}>倒入仓库</Button>

                    <Button type={'primary'}
                            onClick={() => listSelfGitlabCodePlatformRepo(record.platformUuid, record.key)}>列出仓库</Button>
                </>
            }
        }
    ]
    const [selfGitlabCodePlatformTokenDataSource, setSelfGitlabCodePlatformTokenDataSource] = useState([])

    // 添加自建Gitlab平台Token

    const [addSelfGitlabCodePlatformTokenOpenModal, setAddSelfGitlabCodePlatformTokenOpenModal] = useState(false)

    const addSelfGitlabCodePlatformTokenOnClick = (platformUuid) => {
        setAddSelfGitlabCodePlatformTokenPlatform(platformUuid)
        setAddSelfGitlabCodePlatformTokenOpenModal(true)
    }

    const addSelfGitlabCodePlatformTokenOnOk = () => {

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const name = document.getElementById('addSelfGitlabCodePlatformTokenName').value
        const privateToken = document.getElementById('addSelfGitlabCodePlatformTokenPrivateToken').value
        const describe = document.getElementById('addSelfGitlabCodePlatformTokenDescribe').value

        AddSelfGitlabToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: addSelfGitlabCodePlatformTokenPlatform,
            name: name,
            privateToken: privateToken,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加自建Gitlab平台Token成功`)
                    setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformToken')
                    listSelfGitlabCodePlatformToken(addSelfGitlabCodePlatformTokenPlatform)
                    return
                }
                message.error(`添加自建Gitlab平台Token失败 ${errMsg}`)
                console.log(`添加自建Gitlab平台Token失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加自建Gitlab平台Token失败 ${err}`)
                console.log(`添加自建Gitlab平台Token失败 ${err}`)
            }
        )

        setAddSelfGitlabCodePlatformTokenOpenModal(false)
    }
    const addSelfGitlabCodePlatformTokenOnCancel = () => {
        setAddSelfGitlabCodePlatformTokenOpenModal(false)
    }

    // 列出自建Gitlab平台Token
    const [selfGitlabPlatformDefaultActiveKey, setSelfGitlabPlatformDefaultActiveKey] = useState('selfGitlabCodePlatform')

    const listSelfGitlabCodePlatformToken = (platformUuid) => {
        ListSelfGitlabToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: platformUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const token = data.token

                    if (token === null) {
                        message.warning(`该自建Gitlab平台还没有添加Token`)
                        setSelfGitlabCodePlatformTokenDataSource([])
                        return
                    }

                    let tmp = []
                    for (const t of token) {
                        tmp.push({
                            key: t.uuid,
                            uuid: t.uuid,
                            platformUuid: t.platformUuid,
                            name: t.name,
                            privateToken: t.privateToken,
                            describe: t.describe,
                            createTime: t.createTime
                        })
                    }
                    setSelfGitlabCodePlatformTokenDataSource(tmp)
                    setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformToken')
                    return
                }
                setSelfGitlabCodePlatformTokenDataSource([])
                message.error(`列出自建Gitlab平台Token失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setSelfGitlabCodePlatformTokenDataSource([])
                message.error(`列出自建Gitlab平台Token失败`)
                console.log(`列出自建Gitlab平台Token失败 ${err}`)
            }
        )
    }

    const delSelfGitlabCodePlatformToken = (platformUuid, tokenUuid) => {
        DelSelfGitlabToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: platformUuid,
            tokenUuid: tokenUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除自建Gitlab平台Token成功`)
                    listSelfGitlabCodePlatformToken(platformUuid)
                    return
                }
                message.error(`删除自建Gitlab平台Token失败  ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除自建Gitlab平台Token失败 ${err}`)
                console.log(`删除自建Gitlab平台Token失败 ${err}`)
            }
        )
    }

    // 添加自建Gitlab平台仓库
    const [addSelfGitlabCodePlatformRepoOpenModal, setAddSelfGitlabCodePlatformRepoOpenModal] = useState(false)
    const [selfGitlabCodePlatformTokenOptions, setSelfGitlabCodePlatformTokenOptions] = useState([])

    const addSelfGitlabCodePlatformRepoOnClick = () => {
        setAddSelfGitlabCodePlatformRepoOpenModal(true)
    }

    const addSelfGitlabCodePlatformRepoOnOk = () => {
        setAddSelfGitlabCodePlatformRepoOpenModal(false)
    }

    const addSelfGitlabCodePlatformRepoOnCancel = () => {
        setAddSelfGitlabCodePlatformRepoOpenModal(false)
    }

    const selfGitlabCodePlatformRepoColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            render: function (_, record) {
                return <>
                    <a href={'#'} target={'_blank'}>{record.name}</a>
                </>
            }
        },
        {
            title: '已归档',
            dataIndex: 'archived',
            key: 'archived',
            render: function (archived) {
                return archived ? '*' : ''
            }
        },
        {
            title: 'webUrl',
            dataIndex: 'webUrl',
            key: 'webUrl',
            render: function (_, record) {
                return <>
                    <a href={record.webUrl} target={'_blank'}>
                        <Button
                            type={'primary'}
                            icon={<LinkOutlined />}
                        >跳转</Button>
                    </a>
                </>
            }
        },
        {
            title: 'ssh地址',
            dataIndex: 'sshCloneUrl',
            key: 'sshCloneUrl',
            render: function (_, record) {
                return <>
                    <Space>
                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => CopyOnClick(record.sshCloneUrl)}>复制</Button>
                    </Space>
                </>
            }
        },
        {
            title: 'httpClone地址',
            dataIndex: 'httpCloneUrl',
            key: 'httpCloneUrl',
            render: function (_, record) {
                return <>
                    <Space>
                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => CopyOnClick(record.httpCloneUrl)}>复制</Button>
                    </Space>
                </>
            }
        },
        {
            title: '默认分支',
            dataIndex: 'defaultBranch',
            key: 'defaultBranch'
        },
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>

                    <Space>
                        {record.archived ? '' :
                            <Button type={'primary'}
                                    onClick={() => createSelfGitlabRepoBranch(record.key)}>新建分支</Button>
                        }

                        <Button type={'primary'}
                                onClick={() => listSelfGitlabRepoBranch(record.platformUuid, record.key, record.archived)}>列出分支</Button>
                    </Space>
                    <br/>
                    <Space>
                        {record.archived ? '' :
                            <Button
                                type={'primary'}
                                danger={true}
                                onClick={() => archiveSelfGitlabRepo(record.key)}>归档</Button>
                        }

                        <Button
                            type={'primary'}
                            onClick={() => getSelfGitlabRepoBranchMergeHis(record.key, '')}>合并历史</Button>
                    </Space>

                    <br/>

                    <Space>
                        <Button type={'primary'} onClick={() => listCodeRepoContainerImageOnClick(record.key)}>列出镜像</Button>
                    </Space>

                </>
            }
        }
    ]
    const [selfGitlabCodePlatformRepoDataSource, setSelfGitlabCodePlatformRepoDataSource] = useState([])

    //
    const selfGitlabCodePlatformTabOnChange = (activeKey) => {
        switch (activeKey) {
            case 'selfGitlabCodePlatform':
                setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatform')
                listSelfGitlabCodePlatform()
                break

            case 'selfGitlabCodePlatformToken':
                //listSelfGitlabCodePlatformToken()
                setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformToken')
                break

            case 'selfGitlabCodePlatformRepo':
                setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformRepo')
                break
        }
    }

    useEffect(() => {
        listSelfGitlabCodePlatform()
    }, [])


    // 倒入自建Gitlab仓库
    const [importSelfGitlabCodePlatformOpenModal, setImportSelfGitlabCodePlatformOpenModal] = useState(false)
    const [importSelfGitlabCodePlatformPlatformUuid, setImportSelfGitlabCodePlatformPlatformUuid] = useState('')
    const [importSelfGitlabCodePlatformTokenUuid, setImportSelfGitlabCodePlatformTokenUuid] = useState('')

    const importSelfGitlabCodePlatform = (platformUuid, tokenUuid) => {
        setImportSelfGitlabCodePlatformPlatformUuid(platformUuid)
        setImportSelfGitlabCodePlatformTokenUuid(tokenUuid)
        setImportSelfGitlabCodePlatformOpenModal(true)
    }

    const importSelfGitlabCodePlatformOnOk = () => {
        const importSelfGitlabCodePlatformProjectId = document.getElementById('importSelfGitlabCodePlatformProjectId').value

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const projectId = parseInt(importSelfGitlabCodePlatformProjectId, 10)
        if (isNaN(projectId)) {
            message.error(`输入projectId必须是一个整数类型`)
            return;
        }

        ImportSelfGitlabRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: importSelfGitlabCodePlatformPlatformUuid,
            platformTokenUuid: importSelfGitlabCodePlatformTokenUuid,
            projectId: projectId
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg

                if (errCode === 0) {
                    setImportSelfGitlabCodePlatformOpenModal(false)
                    setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformRepo')
                    listSelfGitlabCodePlatformRepo(importSelfGitlabCodePlatformPlatformUuid, importSelfGitlabCodePlatformTokenUuid)
                    message.info(`倒入自建Gitlab代码平台成功`)
                    return
                }

                message.error(`倒入自建Gitlab代码平台失败 ${errMsg}`)
                console.log((`倒入自建Gitlab代码平台失败 ${errMsg}`))
            }
        ).catch(
            err => {
                message.error(`倒入自建Gitlab代码平台失败 ${err}`)
                console.log(`倒入自建Gitlab代码平台失败 ${err}`)
            }
        )
    }

    const importSelfGitlabCodePlatformOnCancel = () => {
        setImportSelfGitlabCodePlatformOpenModal(false)
    }

    // 列出自建Gitlab仓库
    const listSelfGitlabCodePlatformRepo = (platformUuid, platformTokenUuid) => {
        ListSelfGitlabRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: platformUuid,
            platformTokenUuid: platformTokenUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg

                if (errCode === 0) {
                    const repo = data.repo
                    if (repo === null) {
                        message.warning(`未查询到对应的仓库`)
                        setSelfGitlabCodePlatformRepoDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const r of repo) {
                        tmp.push({
                            key: r.uuid,
                            platformUuid: platformUuid,
                            name: r.name,
                            webUrl: r.webUrl,
                            httpCloneUrl: r.httpCloneUrl,
                            sshCloneUrl: r.sshCloneUrl,
                            defaultBranch: r.defaultBranch,
                            namespace: r.namespace,
                            archived: r.archived,
                            createTime: r.createTime
                        })
                    }

                    setSelfGitlabCodePlatformRepoDataSource(tmp)
                    setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatformRepo')
                    return
                }
                setSelfGitlabCodePlatformRepoDataSource([])
                message.error(`列出自建Gitlab平台仓库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setSelfGitlabCodePlatformRepoDataSource([])
                message.error(`列出自建Gitlab平台仓库失败 ${err}`)
                console.log(`列出自建Gitlab平台仓库失败 ${err}`)
            }
        )
    }
    // 创建自建 Gitlab 仓库分支
    const createSelfGitlabRepoBranch = (repoUuid) => {
        //const repoUuid = document.getElementById('createSelfGitlabRepoBranchRepoUuid').value
        const branchName = document.getElementById('createSelfGitlabRepoBranchName').value
        const ref = document.getElementById('createSelfGitlabRepoBranchRef').value

        CreateSelfGitlabRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            branchName: branchName,
            ref: ref
        }).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }
    // 列出自建 Gitlab 仓库分支
    const selfGitlabRepoBranchColumns = [
        {
            title: '分支名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '是否保护',
            dataIndex: 'protected',
            key: 'protected',
            render: function (text) {
                if (text) {
                    return '*'
                } else {
                    return ''
                }
            }
        },
        {
            title: '默认',
            dataIndex: 'isDefault',
            key: 'isDefault',
            render: function (text) {
                if (text) {
                    return '*'
                } else {
                    return ''
                }
            }
        },
        {
            title: '已合并',
            dataIndex: 'merged',
            key: 'merged',
            render: function (text) {
                if (text) {
                    return '*'
                } else {
                    return ''
                }
            }
        },
        {
            title: '能Push',
            dataIndex: 'canPush',
            key: 'canPush',
            render: function (text) {
                if (text) {
                    return '*'
                } else {
                    return ''
                }
            }
        },
        {
            title: 'Url',
            dataIndex: 'webURL',
            key: 'webURL',
            render: function (text) {
                return <a href={text} target={'_blank'}>
                    <Button
                        type={'primary'}
                        icon={<LinkOutlined />}
                    >跳转</Button>
                </a>
            }
        },
        {
            title: '最后提交Id',
            dataIndex: 'commitSha',
            key: 'commitSha'
        },
        {
            title: '最后提交消息',
            dataIndex: 'commitMessage',
            key: 'commitMessage'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return <>

                    {record.protected
                        ?
                        <Button
                            type={'primary'}
                            danger={true}
                            onClick={() => unProtectSelfGitlabRepoBranch(record.repoUuid, record.name)}>取消保护</Button>
                        :
                        <Button
                            type={'primary'}
                            danger={true}
                            onClick={() => protectSelfGitlabRepoBranch(record.repoUuid, record.name)}>设置保护</Button>}

                    <Button
                        type={'primary'}
                        onClick={() => listSelfGitlabRepoBranchCommits(record.repoUuid, record.name)}>提交记录</Button>

                    {record.repoArchived
                        ?
                        '' :
                        <Button
                            type={'primary'}
                            onClick={() => mergeSelfGitlabRepo(record.repoUuid, record.name, '', '')}>合并至主干</Button>
                    }

                    <Button
                        type={'primary'}
                        onClick={() => getSelfGitlabRepoBranchMergeHis(record.repoUuid, record.name)}>合并历史</Button>

                    {record.repoArchived
                        ?
                        '' :
                        <Popconfirm
                            title={'删除自建Gitlab仓库分支'}
                            description={'您确定要删除自建Gitlab仓库分支？'}
                            onConfirm={() => delSelfGitlabRepoBranch(record.repoUuid, record.name)}
                        >
                            <Button type={'primary'} danger={true}>删除分支</Button>
                        </Popconfirm>
                    }


                    <Button type={'primary'} onClick={() =>
                        selfGitlabCodeRepoBuildContainerImageOnClick(record.platformUuid, record.repoUuid, record.name)}>构建镜像</Button>

                    <Button type={'primary'} onClick={() => selfGitlabCodeRepoExecCiTaskOnClick(record.platformUuid, record.repoUuid, record.name)}>执行CI模版任务</Button>

                    <Button type={'primary'} onClick={() => listCiTemplateTaskOnClick(record.repoUuid, record.name)}>列出CI任务</Button>

                </>
            }
        }
    ]
    const [selfGitlabRepoBranchDataSource, setSelfGitlabRepoBranchDataSource] = useState([])
    const [listSelfGitlabRepoBranchOpenModal, setListSelfGitlabRepoBranchOpenModal] = useState(false)

    const listSelfGitlabRepoBranchOnOk = () => {
        setListSelfGitlabRepoBranchOpenModal(false)
    }
    const listSelfGitlabRepoBranchOnCancel = () => {
        setListSelfGitlabRepoBranchOpenModal(false)
    }

    const listSelfGitlabRepoBranch = (platformUuid, repoUuid, archived) => {
        ListSelfGitlabRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const branch = data.branch
                    if (branch === null) {
                        message.info(`未发现该仓库分支，请联系开发者排查`)
                        return
                    }

                    let tmp = []
                    for (const b of branch) {
                        tmp.push({
                            key: b.name,
                            platformUuid: platformUuid,
                            repoUuid: repoUuid,
                            repoArchived: archived,
                            commitSha: b.commitSha,
                            name: b.name,
                            protected: b.protected,
                            commitMessage: b.commitMessage,
                            merged: b.merged,
                            isDefault: b.isDefault,
                            canPush: b.canPush,
                            developersCanPush: b.developersCanPush,
                            developersCanMerge: b.developersCanMerge,
                            webURL: b.webURL
                        })
                    }

                    setSelfGitlabRepoBranchDataSource(tmp)
                    setListSelfGitlabRepoBranchOpenModal(true)
                    return;
                }
                message.error(`列出仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`列出仓库分支失败 ${err}`)
                message.error(`列出仓库分支失败 ${err}`)
            }
        )
    }

    const delSelfGitlabRepoBranch = (repoUuid, name) => {
        DelSelfGitlabRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            branchName: name
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    console.log(`删除仓库分支成功`)
                    message.info(`删除仓库分支成功`)
                    return
                }

                console.log(`删除仓库分支失败 ${errMsg}`)
                message.error(`删除仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`删除仓库分支失败 ${err}`)
                message.error(`删除仓库分支失败 ${err}`)
            }
        )
    }

    // 列出自建 Gitlab 仓库分支 Commits
    const listSelfGitlabRepoBranchCommitsColumns = [
        {
            title: '分支名',
            dataIndex: 'branchName',
            key: 'branchName'
        },
        {
            title: 'message',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'committer',
            dataIndex: 'committer',
            key: 'committer'
        },
        {
            title: 'author',
            dataIndex: 'author',
            key: 'author'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: 'sha',
            dataIndex: 'sha',
            key: 'sha',
            render: function (text) {
                return text.slice(0, 10)
            }
        }
    ]
    const [listSelfGitlabRepoBranchCommitsDataSource, setListSelfGitlabRepoBranchCommitsDataSource] = useState([])
    const [listSelfGitlabRepoBranchCommitsOpenModal, setListSelfGitlabRepoBranchCommitsOpenModal] = useState(false)
    const listRepoBranchCommitsOnOk = () => {
        setListSelfGitlabRepoBranchCommitsOpenModal(false)
    }
    const listRepoBranchCommitsOnCancel = () => {
        setListSelfGitlabRepoBranchCommitsOpenModal(false)
    }
    const listSelfGitlabRepoBranchCommits = (repoUuid, branchName) => {
        ListSelfGitlabRepoBranchCommits({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            branchName: branchName
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const commits = data.commits
                    if (commits === null) {
                        setListSelfGitlabRepoBranchCommitsDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const c of commits) {
                        tmp.push({
                            key: c.sha,
                            branchName: branchName,
                            message: c.message,
                            committer: c.committer,
                            author: c.author,
                            createTime: c.createTime,
                            sha: c.sha
                        })
                    }
                    setListSelfGitlabRepoBranchCommitsDataSource(tmp)
                    setListSelfGitlabRepoBranchCommitsOpenModal(true)
                    return
                }
                message.error(`列出自建 Gitlab 仓库分支 Commits 失败 ${errMsg}`)
                console.log(`列出自建 Gitlab 仓库分支 Commits 失败 ${errMsg}`)
                setListSelfGitlabRepoBranchCommitsDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出自建 Gitlab 仓库分支 Commits 失败 ${err}`)
                console.log(`列出自建 Gitlab 仓库分支 Commits 失败 ${err}`)
                setListSelfGitlabRepoBranchCommitsDataSource([])
            }
        )
    }
    // 保护自建 Gitlab 仓库分支
    const protectSelfGitlabRepoBranch = (repoUuid, branchName) => {
        ProtectSelfGitlabRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            branchName: branchName
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`保护自建Gitlab仓库分支成功`)
                    return
                }
                message.error(`保护自建Gitlab仓库分支失败 ${errMsg}`)
                console.log(`保护自建Gitlab仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`保护自建Gitlab仓库分支失败 ${err}`)
                message.error(`保护自建Gitlab仓库分支失败 ${err}`)
            }
        )
    }
    // 不保护自建 Gitlab 仓库分支
    const unProtectSelfGitlabRepoBranch = (repoUuid, branchName) => {
        UnProtectSelfGitlabRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            branchName: branchName
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`释放保护自建Gitlab仓库分支成功`)
                    return
                }
                message.error(`释放保护自建Gitlab仓库分支失败 ${errMsg}`)
                console.log(`释放保护自建Gitlab仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`释放保护自建Gitlab仓库分支失败 ${err}`)
                message.error(`释放保护自建Gitlab仓库分支失败 ${err}`)
            }
        )
    }
    // 自建Gitlab平台仓库分支合并
    const mergeSelfGitlabRepo = (repoUuid, sourceBranch, targetBranch, message) => {
        MergeSelfGitlabRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            sourceBranch: sourceBranch,
            targetBranch: targetBranch,
            message: message
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`合并自建Gitlab仓库分支成功`)
                    return
                }
                message.error(`合并自建Gitlab仓库分支失败 ${errMsg}`)
                console.log(`合并自建Gitlab仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`合并自建Gitlab仓库分支失败 ${err}`)
                console.log(`合并自建Gitlab仓库分支失败 ${err}`)
            }
        )
    }

    // 自建Gitlab平台仓库分支合并历史
    const codeRepoBranchMergeHisColumns = [
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'author',
            dataIndex: 'author',
            key: 'author'
        },
        {
            title: 'mergedBy',
            dataIndex: 'mergedBy',
            key: 'mergedBy'
        },
        {
            title: 'closedBy',
            dataIndex: 'closedBy',
            key: 'closedBy'
        },
        {
            title: 'mergeCommitSHA',
            dataIndex: 'mergeCommitSHA',
            key: 'mergeCommitSHA'
        },
        {
            title: 'message',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'sourceBranch',
            dataIndex: 'sourceBranch',
            key: 'sourceBranch'
        },
        {
            title: 'targetBranch',
            dataIndex: 'targetBranch',
            key: 'targetBranch'
        },
        {
            title: 'mergedAt',
            dataIndex: 'mergedAt',
            key: 'mergedAt'
        },
        {
            title: 'closedAt',
            dataIndex: 'closedAt',
            key: 'closedAt'
        },
        {
            title: 'mergeStatus',
            dataIndex: 'mergeStatus',
            key: 'mergeStatus'
        }
    ]
    const [getSelfGitlabRepoBranchMergeHisOpenModal, setGetSelfGitlabRepoBranchMergeHisOpenModal] = useState(false)
    const [codeRepoBranchMergeHisDataSource, setCodeRepoBranchMergeHisDataSource] = useState([])

    const getSelfGitlabRepoBranchMergeHisOnOk = () => {
        setGetSelfGitlabRepoBranchMergeHisOpenModal(false)
    }
    const getSelfGitlabRepoBranchMergeHisOnCancel = () => {
        setGetSelfGitlabRepoBranchMergeHisOpenModal(false)
    }
    const getSelfGitlabRepoBranchMergeHis = (repoUuid, sourceBranch) => {
        SelfGitlabRepoBranchMergeHis({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            sourceBranch: sourceBranch,
            targetBranch: ''
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const merges = data.merges
                    if (merges === null) {
                        message.info(`未获取到该自建Gitlab平台分支合并信息`)
                        return;
                    }

                    let tmp = []
                    let count = 0
                    for (const m of merges) {
                        count += 1
                        tmp.push({
                            key: count,
                            title: m.title,
                            author: m.author,
                            mergedBy: m.mergedBy,
                            closedBy: m.closedBy,
                            mergeCommitSHA: m.mergeCommitSHA,
                            message: m.message,
                            sourceBranch: m.sourceBranch,
                            targetBranch: m.targetBranch,
                            mergedAt: m.mergedAt,
                            closedAt: m.closedAt,
                            mergeStatus: m.mergeStatus
                        })
                    }
                    setCodeRepoBranchMergeHisDataSource(tmp)
                    setGetSelfGitlabRepoBranchMergeHisOpenModal(true)
                    return
                }

                message.error(`获取自建Gitlab分支合并历史失败 ${errMsg}`)
                console.log(`获取自建Gitlab分支合并历史失败 ${errMsg}`)

            }
        ).catch(
            err => {
                message.error(`获取自建Gitlab分支合并历史失败 ${err}`)
                console.log(`获取自建Gitlab分支合并历史失败 ${err}`)
            }
        )
    }
    // 归档自建 Gitlab 仓库
    const archiveSelfGitlabRepo = (repoUuid) => {
        ArchiveSelfGitlabRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid
        })
            .then(
                data => {

                }
            ).catch(
            err => {
                message.error(`归档自建 Gitlab 仓库失败 ${err}`)
                console.log(`归档自建 Gitlab 仓库失败 ${err}`)
            }
        )
    }

    // ->->->->->->->->->->->->->->->->->->->->-><-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-<-
    // --- Github

    const [githubDefaultActiveKey, setGithubDefaultActiveKey] = useState('github')

    const githubCodePlatformTabOnChange = (activeKey) => {
        console.log('github activeKey:', activeKey)
        switch (activeKey) {
            case 'githubCodePlatformRepo':
                listGithubRepo('')
                setGithubDefaultActiveKey('githubCodePlatformRepo')
                break
            case 'githubCodePlatformToken':
                listGithubToken()
                setGithubDefaultActiveKey('githubCodePlatformToken')
                break
            default:
                break
        }
    }

    const githubRepoTokenColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'privateToken',
            dataIndex: 'privateToken',
            key: 'privateToken'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        },
        {
            title: 'operator',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return <>
                    <Button type={'primary'} onClick={() => importGithubRepoOnClick(record.key)}>倒入仓库</Button>
                    <Button type={'primary'} onClick={() => listGithubRepo(record.key)}>列出仓库</Button>
                    <Popconfirm
                        title={'删除Github仓库Token'}
                        description={'您确定要删除该Github仓库Token吗？'}
                        onConfirm={() => delGithubRepoTokenOnClick(record.key)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                    <Button type={'primary'} onClick={() => listGithubOrganization(record.key)}>列出组织</Button>

                </>


            }
        }
    ]
    const [githubRepoTokenDataSource, setGithubRepoTokenDataSource] = useState([])

    const [addGithubRepoTokenOpenModal, setAddGithubRepoTokenOpenModal] = useState(false)
    // 添加 Github Token
    const addGithubRepoTokenOnOk = () => {

        const name = document.getElementById('addGithubRepoPrivateTokenName').value
        const token = document.getElementById('addGithubRepoPrivateToken').value
        const describe = document.getElementById('addGithubRepoPrivateTokenDescribe').value

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        AddGithubToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            name: name,
            token: token,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setAddGithubRepoTokenOpenModal(false)
                    listGithubToken()
                    message.info(`添加 Github 仓库 Token 成功`)
                    return
                }

                message.error(`添加 Github 仓库 Token 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加 Github 仓库 Token 失败 ${err}`)
            }
        )
    }
    const addGithubRepoTokenOnCancel = () => {
        setAddGithubRepoTokenOpenModal(false)
    }
    const addGithubRepoTokenOnClick = () => {
        setAddGithubRepoTokenOpenModal(true)
    }

    // 列出 Github Token

    const listGithubToken = () => {
        ListGithubToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const token = data.token
                    if (token === null) {
                        setGithubRepoTokenDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const t of token) {
                        tmp.push({
                            key: t.uuid,
                            name: t.name,
                            describe: t.describe,
                            createTime: t.createTime,
                            privateToken: t.privateToken
                        })
                    }
                    setGithubRepoTokenDataSource(tmp)
                    return
                }
                setGithubRepoTokenDataSource([])
                message.error(`列出 Github 仓库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setGithubRepoTokenDataSource([])
                message.error(`列出 Github 仓库失败 ${err}`)
            }
        )
    }

    // 删除 Github Token

    const delGithubRepoTokenOnClick = (tokenUuid) => {
        DelGithubToken({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            uuid: tokenUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除 Github 仓库 Token 成功`)
                    listGithubToken()
                    return
                }
                message.error(`删除 Github 仓库 Token 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 Github 仓库 Token 失败 ${err}`)
            }
        )
    }


    //
    const githubRepoColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            render: function (_, record) {
                return <>
                    <a href={'#'} target={'_blank'}>{record.name}</a>
                </>
            }
        },
        {
            title: '已归档',
            dataIndex: 'archived',
            key: 'archived',
            render: function (archived) {
                return archived ? '*' : ''
            }
        },
        {
            title: 'sshUrl',
            dataIndex: 'sshCloneUrl',
            key: 'sshCloneUrl',
            render: function (_, record) {
                return <>
                    <Space>
                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => CopyOnClick(record.sshCloneUrl)}>复制</Button>
                    </Space>
                </>
            }
        },
        {
            title: 'httpClone',
            dataIndex: 'httpCloneUrl',
            key: 'httpCloneUrl',
            render: function (_, record) {
                return <>

                        <Button type={'primary'} icon={<CopyOutlined/>}
                                onClick={() => CopyOnClick(record.httpCloneUrl)}>复制</Button>
                        <br/>

                        <a href={record.httpCloneUrl} target={'_blank'}>
                            <Button
                                type={'primary'}
                                icon={<LinkOutlined />}
                            >跳转</Button>
                        </a>

                </>
            }
        },
        {
            title: '默认分支',
            dataIndex: 'defaultBranch',
            key: 'defaultBranch'
        },
        {
            title: 'fullName',
            dataIndex: 'fullName',
            key: 'fullName'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Popconfirm
                        title={'从系统内移除仓库'}
                        description={'您确定要从系统中移除该仓库吗？仅从系统移除不进行仓库真实删除操作。'}
                    >
                        <Button type={'primary'} danger={true}>移除</Button>
                    </Popconfirm>

                    <br/>

                    <Button type={'primary'} onClick={() => listGithubRepoBranchOnClick(record.key)}>列出分支</Button>

                    {
                        //<Button type={'primary'} danger={true}>归档</Button>
                    }
                    <Button type={'primary'} onClick={() => listGithubRepoBranchOnClick(record.key)}>合并历史</Button>

                    <br/>

                    <Button type={'primary'} onClick={() => listCodeRepoContainerImageOnClick(record.uuid)}>列出镜像</Button>

                </>
            }
        }
    ]
    const [githubRepoDataSource, setGithubRepoDataSource] = useState([])

    //
    const listGithubOrganizationColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'operator',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return <>
                    <Button type={'primary'}>查询仓库</Button>
                </>
            }
        }
    ]
    const [listGithubOrganizationDataSource, setListGithubOrganizationDataSource] = useState([])
    const [listGithubOrganizationOpenModal, setListGithubOrganizationOpenModal] = useState(false)
    const listGithubOrganizationOnOk = () => {
        setListGithubOrganizationOpenModal(false)
    }
    const listGithubOrganizationOnCancel = () => {
        setListGithubOrganizationOpenModal(false)
    }
    const listGithubOrganization = (tokenUuid) => {
        ListGithubOrganization({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            tokenUuid: tokenUuid
        }).then(
            data => {

                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const orgs = data.orgs
                    if (orgs === null) {
                        setListGithubOrganizationDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const org of orgs) {
                        tmp.push({
                            id: org.id,
                            name: org.name
                        })
                    }
                    setListGithubOrganizationDataSource(tmp)
                    setListGithubOrganizationOpenModal(true)
                    return
                }

                message.error(`列出Github组织失败 ${errMsg}`)
                setListGithubOrganizationDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出Github组织失败 ${err}`)
                setListGithubOrganizationDataSource([])
            }
        )
    }

    // 倒入 Github 仓库
    const [importGithubRepoOpenModal, setImportGithubRepoOpenModal] = useState(false)
    const [importGithubRepoToken, setImportGithubRepoToken] = useState('')

    const importGithubRepoOnClick = (githubToken) => {
        setImportGithubRepoToken(githubToken)
        setImportGithubRepoOpenModal(true)
    }

    const importGithubRepoOnOk = () => {

        const importGithubRepoOrgName = document.getElementById('importGithubRepoOrgName').value
        const importGithubRepoName = document.getElementById('importGithubRepoName').value

        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        ImportGithubRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            tokenUuid: importGithubRepoToken,
            githubOrgName: importGithubRepoOrgName,
            repoName: importGithubRepoName
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setImportGithubRepoOpenModal(false)
                    message.info(`倒入Github仓库成功`)
                    return
                }
                message.error(`倒入Github仓库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`倒入Github仓库失败 ${err}`)
                console.log(`倒入Github仓库失败 ${err}`)
            }
        )
    }

    const importGithubRepoOnCancel = () => {
        setImportGithubRepoOpenModal(false)
    }

    // 获取 Github 仓库
    const listGithubRepo = (tokenUuid) => {
        ListGithubRepo({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            token: tokenUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const repo = data.repo
                    if (repo === null) {
                        setGithubRepoDataSource([])
                        message.info(`未查询到该Token下存在仓库，请倒入仓库`)
                        return;
                    }

                    let tmp = []
                    for (const r of repo) {
                        tmp.push({
                            key: r.uuid,
                            uuid: r.uuid,
                            orgUuid: r.orgUuid,
                            productUuid: r.productUuid,
                            name: r.name,
                            fullName: r.fullName,
                            defaultBranch: r.defaultBranch,
                            httpCloneUrl: r.httpCloneUrl,
                            sshCloneUrl: r.sshCloneUrl,
                            webUrl: r.webUrl,
                            describe: r.describe,
                            createTime: r.createTime
                        })
                    }
                    setGithubDefaultActiveKey('githubCodePlatformRepo')
                    setGithubRepoDataSource(tmp)
                    return
                }
                setGithubRepoDataSource([])
                message.error(`列出 Github 仓库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setGithubRepoDataSource([])
                message.error(`列出 Github 仓库失败 ${err}`)
            }
        )
    }

    // 归档 Github 仓库
    const archiveGithubRepoOnClick = () => {
        ArchiveGithubRepo({}).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`归档 Github 仓库成功`)
                    return
                }
                message.error(`归档 Github 仓库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`归档 Github 仓库失败 ${err}`)
            }
        )
    }


    //
    const githubRepoBranchColumns = [
        {
            title: '分支名',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '是否保护',
            dataIndex: 'protected',
            key: 'protected',
            render: function (text) {
                if (text) {
                    return '*'
                } else {
                    return ''
                }
            }
        },
        {
            title: '最后提交Id',
            dataIndex: 'commitSha',
            key: 'commitSha'
        },
        {
            title: '最后提交消息',
            dataIndex: 'commitMessage',
            key: 'commitMessage'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return <>

                    <Button type={'primary'} onClick={() => githubCodeRepoBuildContainerImageOnClick(record.repoUuid, record.name)}>构建镜像</Button>
                    <Button type={'primary'} onClick={() => githubCodeRepoExecCiTaskOnClick(record.repoUuid, record.name)}>执行CI模版任务</Button>
                    <Button type={'primary'} onClick={() => listCiTemplateTaskOnClick(record.repoUuid, record.name)}>列出CI任务</Button>
                </>
            }
        }
    ]
    const [githubRepoBranchDataSource, setGithubRepoBranchDataSource] = useState([])
    const [listGithubRepoBranchOpenModal, setListGithubRepoBranchOpenModal] = useState(false)
    const listGithubRepoBranchOnOk = () => {
        setListGithubRepoBranchOpenModal(false)
    }
    const listGithubRepoBranchOnCancel = () => {
        setListGithubRepoBranchOpenModal(false)
    }
    // 列出 Github 仓库分支
    const listGithubRepoBranchOnClick = (repoUuid) => {
        ListGithubRepoBranch({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const branch = data.branch
                    if (branch === null) {
                        return;
                    }

                    let tmp = []
                    let count = 0
                    for (const b of branch) {
                        count += 1
                        tmp.push({
                            key: count,
                            repoUuid: repoUuid,
                            name: b.name,
                            commitSha: b.commitSha
                        })
                    }
                    setGithubRepoBranchDataSource(tmp)
                    setListGithubRepoBranchOpenModal(true)
                    return
                }
                message.error(`列出 Github 仓库分支失败 ${errMsg}`)
                setGithubRepoBranchDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出 Github 仓库分支失败 ${err}`)
                setGithubRepoBranchDataSource([])
            }
        )
    }

    // 删除 Github 仓库分支
    const delGithubRepoBranchOnClick = (repoUuid, branchName) => {
        DelGithubRepoBranch({}).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除 Github 仓库分支成功`)
                    return
                }
                message.error(`删除 Github 仓库分支失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除 Github 仓库分支失败 ${err}`)
            }
        )
    }

    // 保护 github 仓库分支
    const protectGithubRepoBranchOnClick = () => {
        ProtectGithubRepoBranch({}).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }
    // 释放保护 github 仓库分支
    const unProtectGithubRepoBranchOnClick = () => {
        UnProtectGithubRepoBranch({}).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }
    // 合并 github 仓库分支
    const mergeGithubRepoBranchOnClick = () => {
        MergeGithubRepoBranch({}).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }

    const githubRepoCommitColumns = [
        {
            title: '分支名',
            dataIndex: 'branchName',
            key: 'branchName'
        },
        {
            title: 'message',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'committer',
            dataIndex: 'committer',
            key: 'committer'
        },
        {
            title: 'author',
            dataIndex: 'author',
            key: 'author'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (text) {
                return ConvertTimestamp(text)
            }
        },
        {
            title: 'sha',
            dataIndex: 'sha',
            key: 'sha',
            render: function (text) {
                return text.slice(0, 10)
            }
        }
    ]
    const [githubRepoCommitDataSource, setGithubRepoCommitDataSource] = useState([])
    // 列出 Github 仓库分支 Commits
    const [listGithubRepoBranchCommitsOpenModal, setListGithubRepoBranchCommitsOpenModal] = useState(false)
    const listGithubRepoBranchCommitsOnOk = () => {
        setListGithubRepoBranchCommitsOpenModal(false)
    }

    const listGithubRepoBranchCommitsOnCancel = () => {
        setListGithubRepoBranchCommitsOpenModal(false)
    }
    const listGithubRepoBranchCommitsOnClick = () => {
        ListGithubRepoBranchCommits({}).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setListGithubRepoBranchCommitsOpenModal(true)

                    let tmp = []

                    setGithubRepoCommitDataSource(tmp)
                    return
                }

                message.error(`列出 Github 仓库分支 commits 失败 ${errMsg}`)
                setGithubRepoCommitDataSource([])
            }
        ).catch(
            err => {
                message.error(`列出 Github 仓库分支 commits 失败 ${err}`)
                setGithubRepoCommitDataSource([])
            }
        )
    }


    // ----- gitlab
    const gitlabCodePlatformTokenColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        }
    ]
    const gitlabCodePlatformRepoColumns = [
        {}
    ]
    const [gitlabRepoDataSource, setGitlabRepoDataSource] = useState([])

    const [importGitlabCodePlatformOpenModal, setImportGitlabCodePlatformOpenModal] = useState(false)

    const importGitlabCodePlatformOnOk = () => {

    }

    const importGitlabCodePlatformOnCancel = () => {

    }

    const gitlabCodePlatformTabOnChange = (activeKey) => {

    }

    // ---- gitee
    // gitee 代码平台Token
    const giteeCodePlatformTokenColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        }
    ]
    const [giteeCodePlatformTokenDataSource, setGiteeCodePlatformTokenDataSource] = useState([])
    // gitee 代码平台仓库
    const giteeCodePlatformRepoColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'describe',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: 'createTime',
            dataIndex: 'createTime',
            key: 'createTime'
        }
    ]
    const [giteeCodePlatformRepoDataSource, setGiteeCodePlatformRepoDataSource] = useState([])

    // 倒入 gitee 代码平台仓库
    const [importGiteeCodePlatformRepoOpenModal, setImportGiteeCodePlatformRepoOpenModal] = useState(false)

    const importGiteeCodePlatformOnOk = () => {
        setImportGiteeCodePlatformRepoOpenModal(false)
    }

    const importGiteeCodePlatformOnCancel = () => {
        setImportGiteeCodePlatformRepoOpenModal(false)
    }
    // 列出 gitee 代码平台仓库
    const getGiteeCodePlatformRepo = () => {
        setGiteeCodePlatformRepoDataSource([])
    }
    // gitee 代码平台 Tabs 切换
    const giteeCodePlatformTabOnChange = (activeKey) => {

    }

    //

    const tabOnChange = (activeKey) => {
        switch (activeKey) {
            case 'selfGitlabCode':
                setSelfGitlabPlatformDefaultActiveKey('selfGitlabCodePlatform')
                listSelfGitlabCodePlatform()
                console.log(`selfGitlabCode activeKey: ${activeKey}`)
                break
            case 'github':
                setGithubDefaultActiveKey('githubCodePlatformToken')
                listGithubToken()
                console.log(`github activeKey: ${activeKey}`)
                break
            case 'gitee':
                console.log(`gitee activeKey: ${activeKey}`)
                break
            case 'gitlab':
                console.log(`gitlab activeKey: ${activeKey}`)
                break
            default:
                console.log(`default activeKey: ${activeKey}`)
                break
        }
    }

    // 列出代码仓库容器镜像
    const codeRepoContainerImageColumns = [
        {
            title: 'taskId',
            dataIndex: 'taskId',
            key: 'taskId'
        },
        {
            title: '仓库Uuid',
            dataIndex: 'repoUuid',
            key: 'repoUuid'
        },
        {
            title: '仓库地址',
            dataIndex: 'repoUrl',
            key: 'repoUrl',
            render: (text) => {
                return <>
                    <Button type={'primary'} icon={<CopyOutlined/>}
                               onClick={() => CopyOnClick(text)}>复制</Button>

                    <a href={text} target={'_blank'}>
                        <Button
                            type={'primary'}
                            icon={<LinkOutlined />}
                        >跳转</Button>
                    </a>
                </>
            }
        },
        {
            title: '分支名',
            dataIndex: 'branch',
            key: 'branch'
        },
        {
            title: '提交Id',
            dataIndex: 'commitId',
            key: 'commitId'
        },
        {
            title: 'dockerfilePath',
            dataIndex: 'dockerfilePath',
            key: 'dockerfilePath'
        },
        {
            title: '镜像地址',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (text) => {

                return <Button type={'primary'} icon={<CopyOutlined/>}
                               onClick={() => CopyOnClick(text)}>复制</Button>
            }
        },
        {
            title: '结果',
            dataIndex: 'result',
            key: 'result',
            render: (text) => {
                switch (text) {
                    case 1:
                        return "成功"
                    case -1:
                        return "失败"
                    default:
                        return "执行中"
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe',
            render: (text) => {
                return <pre>{text}</pre>
            }
        },
        {
            title: '开始执行时间',
            dataIndex: 'startExecTime',
            key: 'startExecTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: 'endExecTime',
            dataIndex: 'endExecTime',
            key: 'endExecTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '耗时',
            dataIndex: 'costTime',
            key: 'costTime',
            render: (text) => {
                return `${text} 秒`
            }
        }
    ]
    const [codeRepoContainerImageDataSource, setCodeRepoContainerImageDataSource] = useState([])

    const [codeRepoContainerImageModalOpen, setCodeRepoContainerImageModalOpen] = useState(false)

    const codeRepoContainerImageOnOk = () => {
        setCodeRepoContainerImageModalOpen(false)
    }

    const codeRepoContainerImageOnCancel = () => {
        setCodeRepoContainerImageModalOpen(false)
    }

    const listCodeRepoContainerImageOnClick = (repoUuid) => {
        ListCodeRepoContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setCodeRepoContainerImageModalOpen(true)
                        setCodeRepoContainerImageDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key:  dd.taskId,
                            taskId: dd.taskId,
                            runId: dd.runId,
                            repoUuid: dd.repoUuid,
                            repoUrl: dd.repoUrl,
                            branch: dd.branch,
                            commitId: dd.commitId,
                            dockerfilePath: dd.dockerfilePath,
                            imageUrl: dd.imageUrl,
                            result: dd.result,
                            describe: dd.describe,
                            startExecTime: dd.startExecTime,
                            endExecTime: dd.endExecTime,
                            createTime: dd.createTime,
                            costTime: dd.costTime,
                        })
                    }
                    setCodeRepoContainerImageModalOpen(true)
                    setCodeRepoContainerImageDataSource(tmp)
                    return
                }
                message.error(`列出代码仓库容器镜像失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出代码仓库容器镜像失败 ${err}`)
            }
        )
    }

    //
    const [containerRegistrySelectOptions, setContainerRegistrySelectOptions] = useState([])
    const [containerRegistrySelectDefaultValue, setContainerRegistrySelectDefaultValue] = useState('')

    const containerRegistrySelectOnChangeHandler = (value) => {
        setContainerRegistrySelectDefaultValue(value)
    }

    const listContainerRegistryHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const containerRegistrys = data.containerRegistry
                    if (containerRegistrys === null) {
                        setContainerRegistrySelectOptions([])
                        return;
                    }
                    let tmp = []
                    for (const containerRegistry of containerRegistrys) {
                        tmp.push(
                            {
                                value: containerRegistry.uuid,
                                label: containerRegistry.username + '-' + containerRegistry.url
                            }
                        )
                    }
                    setContainerRegistrySelectOptions(tmp)
                    return
                }
                message.error(`列出容器镜像仓库失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出容器镜像仓库失败 ${err}`)
            }
        )
    }


    // 自建Gitlab平台仓库构建容器镜像
    const [selfGitlabCodeRepoBuildContainerImagePlatformUuid, setSelfGitlabCodeRepoBuildContainerImagePlatformUuid] = useState('')
    const [selfGitlabCodeRepoBuildContainerImageRepoUuid, setSelfGitlabCodeRepoBuildContainerImageRepoUuid] = useState('')
    const [selfGitlabCodeRepoBuildContainerImageBranchName, setSelfGitlabCodeRepoBuildContainerImageBranchName] = useState('')

    const [selfGitlabCodeRepoBuildContainerImageModalOpen, setSelfGitlabCodeRepoBuildContainerImageModalOpen] = useState(false)

    const selfGitlabCodeRepoBuildContainerImageOnClick = (platformUuid, repoUuid, branchName) => {
        listContainerRegistryHandler()
        setSelfGitlabCodeRepoBuildContainerImagePlatformUuid(platformUuid)
        setSelfGitlabCodeRepoBuildContainerImageRepoUuid(repoUuid)
        setSelfGitlabCodeRepoBuildContainerImageBranchName(branchName)
        setSelfGitlabCodeRepoBuildContainerImageModalOpen(true)
    }

    const selfGitlabCodeRepoBuildContainerImageOnOk = () => {
        SelfGitlabCodeRepoContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: selfGitlabCodeRepoBuildContainerImagePlatformUuid,
            repoUuid: selfGitlabCodeRepoBuildContainerImageRepoUuid,
            branchName: selfGitlabCodeRepoBuildContainerImageBranchName,
            registryUuid: containerRegistrySelectDefaultValue,
            dockerfile: "Dockerfile",
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`Github平台仓库构建容器镜像任务已提交`)
                    setSelfGitlabCodeRepoBuildContainerImageModalOpen(false)
                    return
                }
                message.error(`来自自建Gitlab平台仓库构建容器镜像失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`来自自建Gitlab平台仓库构建容器镜像失败 ${err}`)
            }
        )
    }

    const selfGitlabCodeRepoBuildContainerImageOnCancel = () => {
        setSelfGitlabCodeRepoBuildContainerImageModalOpen(false)
    }



    // Github 代码仓库构建容器镜像
    const [githubCodeRepoBuildContainerImageModalOpen, setGithubCodeRepoBuildContainerImageModalOpen] = useState(false)

    const [githubCodeRepoBuildContainerImageRepoUuid, setGithubCodeRepoBuildContainerImageRepoUuid] = useState('')
    const [githubCodeRepoBuildContainerImageBranchName, setGithubCodeRepoBuildContainerImageBranchName] = useState('')


    const githubCodeRepoBuildContainerImageOnClick = (repoUuid, branchName) => {
        listContainerRegistryHandler()
        setGithubCodeRepoBuildContainerImageRepoUuid(repoUuid)
        setGithubCodeRepoBuildContainerImageBranchName(branchName)
        setGithubCodeRepoBuildContainerImageModalOpen(true)
    }

    const githubCodeRepoBuildContainerImageModalOnOk = () => {
        GithubCodeRepoContainerImage({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: githubCodeRepoBuildContainerImageRepoUuid,
            branchName: githubCodeRepoBuildContainerImageBranchName,
            registryUuid: containerRegistrySelectDefaultValue,
            dockerfile: "Dockerfile",
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`Github平台仓库构建容器镜像任务已提交`)
                    setGithubCodeRepoBuildContainerImageModalOpen(false)
                    return
                }
                message.error(`Github平台仓库构建容器镜像失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`Github平台仓库构建容器镜像失败 ${err}`)
            }
        )
    }

    const githubCodeRepoBuildContainerImageModalOnCancel = () => {
        setGithubCodeRepoBuildContainerImageModalOpen(false)
    }

    // ----------------------------------------------------------------------------------------------------
    // 执行 ci 模版任务

    const [execCITaskRepoUuid, setExecCITaskRepoUuid] = useState('')
    const [execCITaskRepoBranch, setExecCITaskRepoBranch] = useState('')
    const [execCITaskPlatformUuid, setExecCITaskPlatformUuid] = useState('')

    const [selfGitlabCodeRepoExecCiTaskModalOpen, setSelfGitlabCodeRepoExecCiTaskModalOpen] = useState(false)
    const selfGitlabCodeRepoExecCiTaskOnClick = (platformUuid, repoUuid, branchName) => {
        getCiTemplateSelectOptions()
        setExecCITaskRepoUuid(repoUuid)
        setExecCITaskRepoBranch(branchName)
        setExecCITaskPlatformUuid(platformUuid)
        setSelfGitlabCodeRepoExecCiTaskModalOpen(true)
    }

    const [githubCodeRepoExecCiTaskModalOpen, setGithubCodeRepoExecCiTaskModalOpen] = useState(false)
    const githubCodeRepoExecCiTaskOnClick = (repoUuid, branchName) => {
        getCiTemplateSelectOptions()
        setExecCITaskRepoUuid(repoUuid)
        setExecCITaskRepoBranch(branchName)
        setGithubCodeRepoExecCiTaskModalOpen(true)
    }

    // 获取 ci 模版Options
    const [ciTemplateSelectOptions, setCiTemplateSelectOptions] = useState([])
    const getCiTemplateSelectOptions = () => {
        ListCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setCiTemplateSelectOptions([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            value: dd.uuid,
                            label: dd.name,
                        })
                    }
                    setCiTemplateSelectOptions(tmp)
                    return
                }
                message.error(`列出 CI 模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCiTemplateSelectOptions([])
                message.error(`列出 CI 模版失败 ${err}`)
            }
        )
    }

    const [ciTemplateUuid, setCiTemplateUuid] = useState('')
    const ciTemplateSelectOptionsOnChange = (value) => {
        setCiTemplateUuid(value)
    }

    // github仓库执行ci任务

    const githubCodeRepoExecCiTaskModalOk = () => {
        GithubCodeRepoExecCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: execCITaskRepoUuid,
            branchName: execCITaskRepoBranch,
            templateUuid: ciTemplateUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setGithubCodeRepoExecCiTaskModalOpen(false)
                    return
                }
                message.error(`Github代码仓库执行CI模版任务失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`Github代码仓库执行CI模版任务失败 ${err}`)
            }
        )
    }
    
    const githubCodeRepoExecCiTaskModalOnCancel = () => {
        setGithubCodeRepoExecCiTaskModalOpen(false)
    }



    // 自建gitlab仓库执行ci任务
    const selfGitlabCodeRepoExecCiTaskOnModalOnOK = () => {
        SelfGitlabCodeRepoExecCiTemplate({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformUuid: execCITaskPlatformUuid,
            repoUuid: execCITaskRepoUuid,
            branchName: execCITaskRepoBranch,
            templateUuid: ciTemplateUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setSelfGitlabCodeRepoExecCiTaskModalOpen(false)
                    return
                }
                message.error(`自建Gitlab代码仓库执行CI模版任务失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`自建Gitlab代码仓库执行CI模版任务失败 ${err}`)
            }
        )
    }

    const selfGitlabCodeRepoExecCiTaskOnModalOnCancel = () => {
        setSelfGitlabCodeRepoExecCiTaskModalOpen(false)
    }

    // 列出 CI 任务
    const ciTemplateTaskColumns = [
        {
            title: 'taskId',
            dataIndex: 'taskId',
            key: 'taskId',
        },
        {
            title: 'runId',
            dataIndex: 'runId',
            key: 'runId',
            render: (text) => {
                if (text !== undefined && text !== null && typeof text === 'string') {
                    return text.substring(0, 8)
                } else {
                    return text
                }
            }
        },
        {
            title: '模版id',
            dataIndex: 'templateUuid',
            key: 'templateUuid',
            render: (text) => {
                if (text !== undefined && text !== null && typeof text === 'string') {
                    return text.substring(0, 8)
                } else {
                    return text
                }
            }
        },
        {
            title: '提交Id',
            dataIndex: 'repoCommitId',
            key: 'repoCommitId',
            render: (text) => {
                if (text !== undefined && text !== null && typeof text === 'string') {
                    return text.substring(0, 8)
                } else {
                    return text
                }
            }
        },
        {
            title: '提交消息',
            dataIndex: 'repoCommitMsg',
            key: 'repoCommitMsg'
        },
        {
            title: '结果',
            dataIndex: 'result',
            key: 'result',
            render: (text) => {

                switch (text) {
                    case 1:
                        return "成功"
                    case -1:
                        return "失败"
                    case 0:
                        return "执行中"
                }
            }
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe'
        },
        {
            title: '开始执行时间',
            dataIndex: 'startExecTime',
            key: 'startExecTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '结束执行时间',
            dataIndex: 'endExecTime',
            key: 'endExecTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        }
    ]

    const [ciTemplateDataSource, setCiTemplateDataSource] = useState([])

    const [ciTemplateTaskModalOpen, setCiTemplateTaskModalOpen] = useState(false)

    const ciTemplateTaskModalOnOk = () => {
        setCiTemplateTaskModalOpen(false)
    }

    const ciTemplateTaskModalOnCancel = () => {
        setCiTemplateTaskModalOpen(false)
    }

    const listCiTemplateTaskOnClick = (repoUuid, repoBranch) => {
        ListCiTemplateTask({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            repoUuid: repoUuid,
            repoBranch: repoBranch,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        message.info(`该仓库分支无任务`)
                        setCiTemplateDataSource([])
                        return
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.taskId,
                            taskId: dd.taskId,
                            runId: dd.runId,
                            userUuid: dd.userUuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            templateUuid: dd.templateUuid,

                            repoUuid: dd.repoUuid,
                            repoUrl: dd.repoUrl,
                            repoBranch: dd.repoBranch,
                            repoCommitId: dd.repoCommitId,
                            repoCommitMsg: dd.repoCommitMsg,
                            result: dd.result,
                            describe: dd.describe,
                            startExecTime: dd.startExecTime,
                            endExecTime: dd.endExecTime,
                            createTime: dd.createTime,
                        })
                    }
                    setCiTemplateDataSource(tmp)

                    setCiTemplateTaskModalOpen(true)
                    return
                }
                setCiTemplateDataSource([])
                message.error(`列出CI任务失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCiTemplateDataSource([])
                message.error(`列出CI任务失败 ${err}`)
            }
        )
    }

    return (
        <>

            <Tabs
                type="card"
                onChange={tabOnChange}
                items={[
                    {
                        label: '自建Gitlab',
                        key: 'selfGitlabCode',
                        children: <>

                            <Tabs
                                activeKey={selfGitlabPlatformDefaultActiveKey}
                                type="card"
                                onChange={selfGitlabCodePlatformTabOnChange}
                                items={[
                                    {
                                        label: '自建Gitlab代码仓库平台',
                                        key: 'selfGitlabCodePlatform',
                                        children: <>
                                            <Space>
                                                <Button type={'primary'}
                                                        onClick={addSelfGitlabCodePlatform}>添加自建Gitlab代码平台</Button>
                                            </Space>
                                            <Table columns={selfGitlabCodePlatformColumns}
                                                   dataSource={selfGitlabCodePlatformDataSource}
                                                   scroll={{x: true, y: 400}}
                                            />
                                        </>
                                    },
                                    {
                                        label: '自建Gitlab代码仓库平台Token',
                                        key: 'selfGitlabCodePlatformToken',
                                        children: <>
                                            <Table columns={selfGitlabCodePlatformTokenColumns}
                                                   dataSource={selfGitlabCodePlatformTokenDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    },
                                    {
                                        label: '自建gitlab仓库',
                                        key: 'selfGitlabCodePlatformRepo',
                                        children: <>
                                            <Table columns={selfGitlabCodePlatformRepoColumns}
                                                   dataSource={selfGitlabCodePlatformRepoDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    }
                                ]}
                            />
                        </>
                    },

                    {
                        label: 'Github',
                        key: 'github',
                        children: <>
                            <Tabs
                                activeKey={githubDefaultActiveKey}
                                type="card"
                                onChange={githubCodePlatformTabOnChange}
                                items={[
                                    {
                                        label: 'github平台Token',
                                        key: 'githubCodePlatformToken',
                                        children: <>
                                            <Button type={'primary'}
                                                    onClick={addGithubRepoTokenOnClick}>添加Github平台Token</Button>
                                            <Table columns={githubRepoTokenColumns}
                                                   dataSource={githubRepoTokenDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    },
                                    {
                                        label: 'github平台仓库',
                                        key: 'githubCodePlatformRepo',
                                        children: <>
                                            <Table columns={githubRepoColumns}
                                                   dataSource={githubRepoDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    }
                                ]}
                            />
                        </>
                    },


                    {
                        label: 'Gitee',
                        key: 'gitee',
                        children: <>
                            <Tabs
                                type="card"
                                onChange={giteeCodePlatformTabOnChange}
                                items={[
                                    {
                                        label: 'gitee平台Token',
                                        key: 'giteeCodePlatformToken',
                                        children: <>
                                            <Table columns={giteeCodePlatformTokenColumns}
                                                   dataSource={giteeCodePlatformTokenDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    },
                                    {
                                        label: 'gitee平台仓库',
                                        key: 'giteeCodePlatformRepo',
                                        children: <>
                                            <Table columns={giteeCodePlatformTokenColumns}
                                                   dataSource={giteeCodePlatformRepoDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    }
                                ]}
                            />
                        </>
                    },


                    {
                        label: 'Gitlab',
                        key: 'gitlab',
                        children: <>

                            <Tabs
                                type="card"
                                onChange={gitlabCodePlatformTabOnChange}
                                items={[
                                    {
                                        label: 'gitlab平台Token',
                                        key: 'gitlabCodePlatformToken',
                                        children: <>
                                            <Table columns={gitlabCodePlatformRepoColumns}
                                                   dataSource={gitlabRepoDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    },
                                    {
                                        label: 'gitlab平台仓库',
                                        key: 'gitlabCodePlatformRepo',
                                        children: <>
                                            <Table columns={gitlabCodePlatformRepoColumns}
                                                   dataSource={gitlabRepoDataSource}
                                                   scroll={{x: true, y: 400}}/>
                                        </>
                                    }
                                ]}
                            />
                        </>
                    }
                ]}
            />

            {
                // ----------------- 自建 Gitlab
            }

            <Modal
                title={'添加自建Gitlab代码平台 (PS: 平台需要能够访问该Gitlab平台)'}
                open={addSelfGitlabCodePlatformOpenModal}
                onOk={addSelfGitlabCodePlatformOnOk}
                onCancel={addSelfGitlabCodePlatformOnCancel}
                destroyOnClose={true}
            >

                <span>访问协议</span>
                <br/>
                <Select
                    style={{
                        width: 300
                    }}
                    defaultValue={addSelfGitlabCodePlatformProtocol}
                    options={[
                        {
                            value: 1,
                            label: 'http'
                        },
                        {
                            value: 2,
                            label: 'https'
                        }
                    ]}
                    onChange={addSelfGitlabCodePlatformProtocolOnChange}

                />
                <br/>
                <span>主机名</span>
                <Input id={'addSelfGitlabCodePlatformHostname'}/>
                <span>描述</span>
                <Input.TextArea id={'addSelfGitlabCodePlatformDescribe'}/>
            </Modal>

            <Modal
                title={'添加自建Gitlab代码平台Token'}
                open={addSelfGitlabCodePlatformTokenOpenModal}
                onOk={addSelfGitlabCodePlatformTokenOnOk}
                onCancel={addSelfGitlabCodePlatformTokenOnCancel}
                destroyOnClose={true}
            >
                <span>platform</span><Input disabled={true} value={addSelfGitlabCodePlatformTokenPlatform}/>
                <span>name</span><Input id={'addSelfGitlabCodePlatformTokenName'}/>
                <span>privateToken</span><Input.Password id={'addSelfGitlabCodePlatformTokenPrivateToken'}/>
                <span>describe</span><Input.TextArea id={'addSelfGitlabCodePlatformTokenDescribe'}/>

            </Modal>


            <Modal
                title={'倒入自建Gitlab代码平台仓库'}
                open={addSelfGitlabCodePlatformRepoOpenModal}
                onOk={addSelfGitlabCodePlatformRepoOnOk}
                onCancel={addSelfGitlabCodePlatformRepoOnCancel}
                destroyOnClose={true}
            >
            </Modal>

            <Modal
                title={'倒入自建Gitlab代码平台仓库'}
                open={importSelfGitlabCodePlatformOpenModal}
                onOk={importSelfGitlabCodePlatformOnOk}
                onCancel={importSelfGitlabCodePlatformOnCancel}
                destroyOnClose={true}
            >
                <span>自建Gitlab平台</span><Input disabled={true} value={importSelfGitlabCodePlatformPlatformUuid}/>
                <span>自建Gitlab平台Token</span><Input disabled={true} value={importSelfGitlabCodePlatformTokenUuid}/>
                <span>项目ID</span><Input type={'number'} id={'importSelfGitlabCodePlatformProjectId'}/>
            </Modal>

            <Modal
                title={'列出自建Gitlab仓库分支 (部分信息接口有延迟)'}
                open={listSelfGitlabRepoBranchOpenModal}
                onOk={listSelfGitlabRepoBranchOnOk}
                onCancel={listSelfGitlabRepoBranchOnCancel}
                width={'auto'}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
                destroyOnClose={true}
            >
                <Table
                    scroll={{x: true}}
                    columns={selfGitlabRepoBranchColumns}
                    dataSource={selfGitlabRepoBranchDataSource}/>
            </Modal>

            <Modal
                title={'列出自建Gitlab仓库分支提交记录'}
                open={listSelfGitlabRepoBranchCommitsOpenModal}
                onOk={listRepoBranchCommitsOnOk}
                onCancel={listRepoBranchCommitsOnCancel}
                width={'auto'}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
                destroyOnClose={true}
            >
                <Table columns={listSelfGitlabRepoBranchCommitsColumns}
                       dataSource={listSelfGitlabRepoBranchCommitsDataSource}/>
            </Modal>

            <Modal
                title={'列出自建Gitlab仓库分支合并记录'}
                open={getSelfGitlabRepoBranchMergeHisOpenModal}
                onOk={getSelfGitlabRepoBranchMergeHisOnOk}
                onCancel={getSelfGitlabRepoBranchMergeHisOnCancel}
                width={'auto'}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
                destroyOnClose={true}
            >
                <Table
                    scroll={{x: true}}
                    columns={codeRepoBranchMergeHisColumns}
                    dataSource={codeRepoBranchMergeHisDataSource}/>
            </Modal>


            {
                // ----------------- Github
            }

            <Modal
                title={'添加Github平台Token'}
                open={addGithubRepoTokenOpenModal}
                onOk={addGithubRepoTokenOnOk}
                onCancel={addGithubRepoTokenOnCancel}
                destroyOnClose={true}
            >
                <span>名字</span><Input id={'addGithubRepoPrivateTokenName'}/>
                <span>PrivateToken</span><Input id={'addGithubRepoPrivateToken'}/>
                <span>描述</span><Input.TextArea id={'addGithubRepoPrivateTokenDescribe'}/>
            </Modal>


            <Modal
                title={'倒入Github代码平台仓库'}
                open={importGithubRepoOpenModal}
                onOk={importGithubRepoOnOk}
                onCancel={importGithubRepoOnCancel}
                destroyOnClose={true}
            >

                <span>Token</span><Input id={'importGithubRepoToken'} value={importGithubRepoToken} disabled={true}/>
                <span>组织</span><Input id={'importGithubRepoOrgName'}/>
                <span>仓库</span><Input id={'importGithubRepoName'}/>

            </Modal>

            <Modal
                title={'列出Github组织'}
                open={listGithubOrganizationOpenModal}
                onOk={listGithubOrganizationOnOk}
                onCancel={listGithubOrganizationOnCancel}
                destroyOnClose={true}
            >
                <Table columns={listGithubOrganizationColumns} dataSource={listGithubOrganizationDataSource}/>
            </Modal>


            <Modal
                title={'列出Github仓库分支'}
                open={listGithubRepoBranchOpenModal}
                onOk={listGithubRepoBranchOnOk}
                onCancel={listGithubRepoBranchOnCancel}
                destroyOnClose={true}
                width={'100%'}
            >
                <Table
                    columns={githubRepoBranchColumns}
                    dataSource={githubRepoBranchDataSource}
                />
            </Modal>


            <Modal
                title={'列出Github仓库分支Commits'}
                open={listGithubRepoBranchCommitsOpenModal}
                onOk={listGithubRepoBranchCommitsOnOk}
                onCancel={listGithubRepoBranchCommitsOnCancel}
                destroyOnClose={true}
            >
                <Table columns={githubRepoCommitColumns} dataSource={githubRepoCommitDataSource}/>
            </Modal>


            {
                // ----------------- Gitlab
            }
            <Modal
                title={'倒入Gitlab代码平台仓库'}
                open={importGitlabCodePlatformOpenModal}
                onOk={importGitlabCodePlatformOnOk}
                onCancel={importGitlabCodePlatformOnCancel}
                destroyOnClose={true}
            >

                <span>类型</span><select/>

            </Modal>


            {
                // ----------------- Gitee
            }

            <Modal
                title={'倒入Gitee代码平台仓库'}
                open={importGiteeCodePlatformRepoOpenModal}
                onOk={importGiteeCodePlatformOnOk}
                onCancel={importGiteeCodePlatformOnCancel}
                destroyOnClose={true}
            >

                <span>类型</span><select/>

            </Modal>


            {
                // -----------------------------------
            }
            <Modal
                title={'仓库容器镜像'}
                open={codeRepoContainerImageModalOpen}
                onOk={codeRepoContainerImageOnOk}
                onCancel={codeRepoContainerImageOnCancel}
                destroyOnClose={true}
                width={'100%'}
            >
                <Table
                    columns={codeRepoContainerImageColumns}
                    dataSource={codeRepoContainerImageDataSource}
                    scroll={{x: true, y: 400}}
                />
            </Modal>




            <Modal
                title={'自建Gitlab仓库构建容器镜像'}
                open={selfGitlabCodeRepoBuildContainerImageModalOpen}
                onOk={selfGitlabCodeRepoBuildContainerImageOnOk}
                onCancel={selfGitlabCodeRepoBuildContainerImageOnCancel}
            >
                <span>容器仓库</span><Select
                style={{
                    width: 470
                }}
                options={containerRegistrySelectOptions}
                onChange={containerRegistrySelectOnChangeHandler}
            />
            </Modal>


            <Modal
                title={'Github仓库构建容器镜像'}
                open={githubCodeRepoBuildContainerImageModalOpen}
                onOk={githubCodeRepoBuildContainerImageModalOnOk}
                onCancel={githubCodeRepoBuildContainerImageModalOnCancel}
            >
                <span>容器仓库</span><Select
                style={{
                    width: 470
                }}
                options={containerRegistrySelectOptions}
                onChange={containerRegistrySelectOnChangeHandler}
            />

            </Modal>


            {
                // -----------------------------------
            }

            <Modal
                title={'自建Gitlab仓库执行CI任务'}
                open={selfGitlabCodeRepoExecCiTaskModalOpen}
                onOk={selfGitlabCodeRepoExecCiTaskOnModalOnOK}
                onCancel={selfGitlabCodeRepoExecCiTaskOnModalOnCancel}
            >
                <span>CI模版</span><Select
                style={{
                    width: 470
                }}
                options={ciTemplateSelectOptions}
                onChange={ciTemplateSelectOptionsOnChange} />
            </Modal>


            <Modal
                title={'Github仓库执行CI任务'}
                open={githubCodeRepoExecCiTaskModalOpen}
                onOk={githubCodeRepoExecCiTaskModalOk}
                onCancel={githubCodeRepoExecCiTaskModalOnCancel}
            >
                <span>CI模版</span><Select
                style={{
                    width: 470
                }}
                options={ciTemplateSelectOptions}
                onChange={ciTemplateSelectOptionsOnChange} />
            </Modal>


            <Modal
                title={'CI任务'}
                open={ciTemplateTaskModalOpen}
                onOk={ciTemplateTaskModalOnOk}
                onCancel={ciTemplateTaskModalOnCancel}
                destroyOnClose={true}
                width={'100%'}
            >
                <Table
                    columns={ciTemplateTaskColumns}
                    dataSource={ciTemplateDataSource}
                    scroll={{x: true, y: 400}}
                />
            </Modal>
        </>
    );
}