import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout, message, Modal, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useEffect, useState} from 'react';
import {CreateWorkOrder, CreateWorkOrderType, ListWorkOrder, ListWorkOrderType} from '../../apis/workSpace/workOrder';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';
import React from 'react';

export function WorkOrderRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['workSpace']} defaultSelectedKeys={['workOrder']}></LeftSider>
                    <IndexRightBody>
                        <WorkOrderContent></WorkOrderContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

function ListWorkOrderTypeSelectOptions(setWorkOrderType, setWorkOrderTypeSelectOptions) {

    useEffect(() => {
        ListWorkOrderType({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const workOrderTypes = data.workOrderType
                    if (workOrderTypes === null) {
                        setWorkOrderTypeSelectOptions([])
                        return;
                    }

                    let tmp = []
                    for (const workOrderType of workOrderTypes) {
                        tmp.push({
                            value: workOrderType.uuid,
                            label: workOrderType.name
                        })
                    }
                    setWorkOrderType(tmp.slice(-1)[0].value)
                    setWorkOrderTypeSelectOptions(tmp)
                    return
                }
                setWorkOrderTypeSelectOptions([])
                message.error(`列出工单类型失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setWorkOrderTypeSelectOptions([])
                message.error(`列出工单类型失败`)
            }
        )
    }, [])
}

export function WorkOrderContent() {
    //
    const workOrderColumns = [
        {
            title: '工单内容',
            dataIndex: 'content',
            key: 'content'
        },
        {
            title: '提交者',
            dataIndex: 'author',
            key: 'author'
        },
        {
            title: '提交时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>查看详情</Button>
                    <Button type={'primary'}>添加步骤</Button>
                </>
            }
        },
    ]
    const [workOrderDataSource, setWorkOrderDataSource] = useState([])

    //
    const [workOrderStatus, setWorkOrderStatus] = useState(-1)
    const workOrderStatusOptions = [
        {
            value: 0,
            label: '所有状态'
        },
        {
            value: 1,
            label: '已完成'
        },
        {
            value: -1,
            label: '执行中'
        }
    ]
    const workOrderStatusOnSelectHandler = (value) => {
        setWorkOrderStatus(value)
    }
    //
    const [workOrderTypeSelectOptions, setWorkOrderTypeSelectOptions] = useState([])
    const [workOrderType, setWorkOrderType] = useState('')
    const workOrderTypeOnSelectHandler = (value) => {
        setWorkOrderType(value)
    }
    ListWorkOrderTypeSelectOptions(setWorkOrderType, setWorkOrderTypeSelectOptions)

    // 添加工单类型
    const [createWorkTypeOpenModal, setCreateWorkTypeOpenModal] = useState(false)
    const createWorkTypeOnClickHandler = () => {
        setCreateWorkTypeOpenModal(true)
    }
    const createWorkTypeModalOnOkHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const name = document.getElementById('name').value
        const describe = document.getElementById('describe').value

        CreateWorkOrderType({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            name: name,
            describe: describe
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`创建工单类型成功`)
                    setCreateWorkTypeOpenModal(false)
                    return
                }
                message.error(`创建工单类型失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`创建工单类型失败 ${err}`)
                message.error(`创建工单类型失败`)
            }
        )
    }
    const createWorkTypeModalOnCancelHandler = () => {
        setCreateWorkTypeOpenModal(false)
    }
    // 提交工单
    const [submitWorkOrderOpenModal, setSubmitWorkOrderOpenModal] = useState(false)
    const submitWorkOrderOnClickHandler = () => {
        setSubmitWorkOrderOpenModal(true)
    }
    const submitWorkOrderOnOk = () => {

        CreateWorkOrder({

        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setSubmitWorkOrderOpenModal(false)
                    listWorkOrderOnClickHandler()
                    message.error(`提交工单成功`)
                    return
                }
                setSubmitWorkOrderOpenModal(false)
                message.error(`提交工单失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`提交工单失败`)
                console.log(`提交工单失败 ${err}`)
            }
        )
    }
    const submitWorkOrderOnCancel = () => {
        setSubmitWorkOrderOpenModal(false)
    }

    //
    const listWorkOrderOnClickHandler = () => {
        ListWorkOrder({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            status: workOrderStatus,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const workOrders = data.workOrders
                    if (workOrders === null) {
                        setWorkOrderDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const workOrder of workOrders) {
                        tmp.push({
                            key: workOrder.uuid,
                            content: workOrder.content,
                            author: workOrder.author,
                            createTime: workOrder.createTime,
                        })
                    }
                    setWorkOrderDataSource(tmp)
                    return
                }
                setWorkOrderDataSource([])
                message.error(`列出工单失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setWorkOrderDataSource([])
                console.log(`列出工单失败 ${err}`)
                message.error(`列出工单失败`)
            }
        )
    }
    //
    useEffect(() => {
        listWorkOrderOnClickHandler()
    }, [])

    return (
        <>
            <Space>
                <span>工单状态</span>
                <Select
                    options={workOrderStatusOptions}
                    value={workOrderStatus}
                    onSelect={workOrderStatusOnSelectHandler}
                />

                <Button type={'primary'} onClick={listWorkOrderOnClickHandler}>
                    查询
                </Button>

                <Button type={'primary'} onClick={submitWorkOrderOnClickHandler}>
                    提交工单
                </Button>

                <Button type={'primary'} onClick={createWorkTypeOnClickHandler}>
                    添加工单类型
                </Button>
            </Space>

            <Table columns={workOrderColumns} dataSource={workOrderDataSource}/>

            <Modal
                open={createWorkTypeOpenModal}
                title={'添加工单类型'}
                onOk={createWorkTypeModalOnOkHandler}
                onCancel={createWorkTypeModalOnCancelHandler}
            >
                <span>名字</span><Input id={'name'}/>
                <span>描述</span><Input id={'describe'}/>
            </Modal>


            <Modal
                title={'提交工单'}
                open={submitWorkOrderOpenModal}
                onOk={submitWorkOrderOnOk}
                onCancel={submitWorkOrderOnCancel}
            >
                <span>工单类型</span>
                <Select
                    options={workOrderTypeSelectOptions}
                    value={workOrderType}
                    onSelect={workOrderTypeOnSelectHandler}
                />
            </Modal>
        </>
    );
}