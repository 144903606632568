import {useEffect, useState} from 'react';
import {ListCloudAccount, ListCloudSubAccount, ListCloudSubAccountAccessKey} from '../../apis/cloud/account';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import {message} from 'antd';
import {Get, GetCloudPlatformApi} from '../../apis/common';
import {ListOrgCloudPlatformRegion} from '../../apis/cloud/region';


export function GetOrgProductCloudPlatformOptions(setCloudPlatformId) {
    const [orgProductCloudPlatformOptions, setOrgProductCloudPlatformOptions] = useState([])

    useEffect(() => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        const params = new URLSearchParams({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        });

        Get({
            url: `${GetCloudPlatformApi}?${params}`
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const platforms = data.platform
                if (errCode === 0) {
                    if (platforms === null) {
                        message.info(`未发现配置云平台账户，请先配置云平台账户`)
                        console.log(`未发现配置云平台账户，请先配置云平台账户`)
                        setOrgProductCloudPlatformOptions([])
                        return;
                    }
                    let tmp = []
                    for (const platform of platforms) {
                        tmp.push({
                            value: platform.id,
                            label: platform.name
                        })
                    }
                    setCloudPlatformId(tmp.slice(-1)[0].value)
                    setOrgProductCloudPlatformOptions(tmp)
                    return;
                }
                message.info(`获取云平台失败 ${errMsg}`)
                setOrgProductCloudPlatformOptions([])
                return;
            }
        ).catch(
            err => {
                setOrgProductCloudPlatformOptions([])
                message.info(`获取云平台失败`)
                console.log(`获取云平台失败 ${err}`)
            }
        )
    }, [])
    return orgProductCloudPlatformOptions
}

// Select Options - CloudPlatformAccountOptions

export function GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue) {
    const [cloudPlatformAccountOptions, setCloudPlatformAccountOptions] = useState([])

    useEffect(() => {
        if (cloudPlatformId === '' || cloudPlatformId === undefined) {
            return
        }
        console.log(`云平台ID发生变化, 执行云平台账户变化 cloudPlatformId: ${cloudPlatformId}`)
        ListCloudAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const accounts = data.account
                if (errCode === 0) {
                    if (accounts === null) {
                        console.log(`清空云平台账户值`)
                        setCloudPlatformAccountValue('')
                        setCloudPlatformAccountOptions([])
                        return;
                    }
                    let tmp = []
                    for (const account of accounts) {
                        tmp.push({
                            value: account.uuid,
                            //label: account.accountName
                            label: account.name
                        })
                    }
                    setCloudPlatformAccountOptions(tmp)
                    setCloudPlatformAccountValue(tmp.slice(-1)[0].value)
                    return
                }
                setCloudPlatformAccountOptions([])
                setCloudPlatformAccountValue('')
                message.error(`列出云平台账户失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCloudPlatformAccountOptions([])
                setCloudPlatformAccountValue('')
                message.error(`列出云平台账户失败`)
                console.log(`列出云平台账户失败 ${err}`)
            }
        )
    }, [cloudPlatformId])
    return cloudPlatformAccountOptions
}

// Select Options - CloudPlatformSubAccountOptions

export function GetCloudPlatformSubAccountOptions(accountUuid, setCloudPlatformSubAccountValue) {
    const [cloudPlatformSubAccountOptions, setCloudPlatformSubAccountOptions] = useState([])

    useEffect(() => {
        if (accountUuid === '' || accountUuid === undefined) {
            return
        }
        ListCloudSubAccount({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            accountUuid: accountUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const subAccounts = data.subAccount
                    if (subAccounts === null) {
                        setCloudPlatformSubAccountValue('')
                        setCloudPlatformSubAccountOptions([])
                        message.info(`该账户下暂时未添加子账户`)
                        return
                    }
                    let tmp = []
                    for (const subAccount of subAccounts) {
                        tmp.push({
                            value: subAccount.uuid,
                            label: subAccount.username
                        })
                    }
                    setCloudPlatformSubAccountValue(tmp.slice(-1)[0].value)
                    setCloudPlatformSubAccountOptions(tmp)
                    return;
                }
                setCloudPlatformSubAccountValue('')
                setCloudPlatformSubAccountOptions([])
                message.error(`获取子账户列表失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCloudPlatformSubAccountValue('')
                setCloudPlatformSubAccountOptions([])
                message.error('获取子账户列表失败')
                console.log(`获取子账户列表失败, ${err}`)
            }
        )
    }, [accountUuid])

    return cloudPlatformSubAccountOptions
}

// Select Options - CloudPlatformSubAccountAccessKeyOptions

export function GetCloudPlatformSubAccountAccessKeyOptions(subAccountUuid, setCloudPlatformSubAccountAccessKeyValue) {
    const [cloudPlatformSubAccountAccessKeyOptions, setCloudPlatformSubAccountAccessKeyOptions] = useState([])

    useEffect(() => {
        console.log(`GetCloudPlatformSubAccountAccessKeyOptions subAccountUuid: ${subAccountUuid}`)
        if (subAccountUuid === '' || subAccountUuid === undefined) {
            setCloudPlatformSubAccountAccessKeyValue('')
            setCloudPlatformSubAccountAccessKeyOptions([])
            return
        }
        //console.log(`GetCloudPlatformSubAccountAccessKeyOptions subAccountUuid: ${subAccountUuid}`)
        ListCloudSubAccountAccessKey({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            subAccountUuid: subAccountUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const accessKeys = data.accessKeys
                if (errCode === 0) {
                    if (accessKeys === null) {
                        setCloudPlatformSubAccountAccessKeyValue('')
                        setCloudPlatformSubAccountAccessKeyOptions([])
                        return
                    }
                    let temp = []
                    for (const a of accessKeys) {
                        temp.push({
                            //accessKey: a.accessKey,
                            //describe: a.describe,
                            value: a.uuid,
                            label: a.describe
                        })
                    }
                    setCloudPlatformSubAccountAccessKeyValue(temp.slice(-1)[0].value)
                    setCloudPlatformSubAccountAccessKeyOptions(temp)
                    return
                }
                message.error(`列出云平台子账户AccessKey失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出云平台子账户AccessKey失败`)
                console.log(`列出云平台子账户AccessKey失败, ${err}`)
            }
        )
    }, [subAccountUuid])

    return cloudPlatformSubAccountAccessKeyOptions
}

export function GetCloudPlatformRegionOptions(cloudPlatformId, setCloudPlatformRegionValue) {
    const [cloudPlatformRegionOptions, setCloudPlatformRegionOptions] = useState([])

    useEffect(() => {

        if (cloudPlatformId === '' || cloudPlatformId === undefined) {
            return
        }

        ListOrgCloudPlatformRegion({
            orgUuid: OrgUuid,
            platformId: cloudPlatformId
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const regions = data.region
                    if (regions === null) {
                        setCloudPlatformRegionOptions([])
                        return;
                    }

                    let tmp = []
                    for (const region of regions) {
                        tmp.push({
                            value: region.region,
                            label: region.regionName
                        })
                    }
                    setCloudPlatformRegionOptions(tmp)
                    setCloudPlatformRegionValue(tmp.slice(-1)[0].value)
                    return
                }
                setCloudPlatformRegionOptions([])
                message.error(`列出云平台云地域失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                setCloudPlatformRegionOptions([])
                message.error(`列出云平台云地域失败`)
                console.log(`列出云平台云地域失败 ${err}`)
            }
        )
    }, [cloudPlatformId])

    return cloudPlatformRegionOptions
}

// ------------------------------------Table DataSource 没必要做单独的模块------------------------------------------

// Table DataSource - 传入 云账户Uuid, 设置子账户数据源函数

export function SetCloudPlatformSubAccountDataSource(accountUuid, setSubAccountDataSource) {
    if (accountUuid === null) {
        return
    }
    ListCloudSubAccount({
        orgUuid: OrgUuid,
        productUuid: OrgProductUuid,
        accountUuid: accountUuid
    }).then(
        data => {
            const errCode = data.errCode
            const errMsg = data.errMsg
            if (errCode === 0) {
                const subAccounts = data.subAccount
                if (subAccounts === null) {
                    setSubAccountDataSource([])
                    message.info(`该账户下暂时未添加子账户`)
                    return
                }
                let tempSubAccountDataSource = []
                for (const subAccount of subAccounts) {
                    tempSubAccountDataSource.push(
                        {
                            key: subAccount.uuid,
                            username: subAccount.username,
                            email: subAccount.email,
                            phoneNumber: subAccount.phoneNumber,
                            createTime: subAccount.createTime
                        }
                    )
                }
                setSubAccountDataSource(tempSubAccountDataSource)
                return;
            }
            message.error(`获取子账户列表失败 ${errMsg}`)
        }
    ).catch(
        err => {
            message.error('获取子账户列表失败')
            console.log(`获取子账户列表失败, ${err}`)
        }
    )
}

// Table DataSource列出云平台子账户AccessKey
export function SetListSubAccountAccessKeyDataSource(subAccountUuid, setListSubAccountAccessKeyDataSource) {
    ListCloudSubAccountAccessKey({
        orgUuid: OrgUuid,
        productUuid: OrgProductUuid,
        subAccountUuid: subAccountUuid
    }).then(
        data => {
            const errCode = data.errCode
            const errMsg = data.errMsg
            const accessKeys = data.accessKeys
            if (errCode === 0) {
                if (accessKeys === null) {
                    setListSubAccountAccessKeyDataSource([])
                    return
                }
                let temp = []
                for (const a of accessKeys) {
                    temp.push({
                        key: a.uuid,
                        subAccountUuid: a.subAccountUuid,
                        accessKey: a.accessKey,
                        describe: a.describe,
                        createTime: a.createTime
                    })
                }
                setListSubAccountAccessKeyDataSource(temp)
                return
            }
            message.error(`列出云平台子账户AccessKey失败 ${errMsg}`)
        }
    ).catch(
        err => {
            message.error(`列出云平台子账户AccessKey失败`)
            console.log(`列出云平台子账户AccessKey失败, ${err}`)
        }
    )
}