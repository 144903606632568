import {Button, ConfigProvider, Layout, Select, Space, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useState} from 'react';
import {
    GetCloudPlatformAccountOptions, GetCloudPlatformRegionOptions, GetCloudPlatformSubAccountAccessKeyOptions,
    GetCloudPlatformSubAccountOptions,
    GetOrgProductCloudPlatformOptions
} from './common';
import {ConvertTimestamp} from '../common/date';
import React from 'react';

export function ECSRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>
            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudElasticCompute']}></LeftSider>
                    <IndexRightBody>
                        <ECSContent></ECSContent>
                    </IndexRightBody>
                </Layout>
            </Layout>
        </WaterMark>
    </ConfigProvider>
}

export function ECSContent() {
    //
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState('')
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountValue, setCloudPlatformSubAccountValue] = useState('')
    const cloudPlatformSubAccountOptions = GetCloudPlatformSubAccountOptions(cloudPlatformAccountValue, setCloudPlatformSubAccountValue)
    const cloudPlatformSubAccountOnSelectHandler = (value) => {
        setCloudPlatformSubAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountAccessKeyValue, setCloudPlatformSubAccountAccessKeyValue] = useState('')
    const cloudPlatformSubAccountAccessKeyOptions = GetCloudPlatformSubAccountAccessKeyOptions(cloudPlatformSubAccountValue, setCloudPlatformSubAccountAccessKeyValue)
    const cloudPlatformSubAccountAccessKeyHandler = (value) => {
        setCloudPlatformSubAccountAccessKeyValue(value)
    }
    //
    const [cloudPlatformRegionValue, setCloudPlatformRegionValue] = useState('')
    const cloudPlatformRegionOptions = GetCloudPlatformRegionOptions(cloudPlatformId, setCloudPlatformRegionValue)
    const cloudPlatformRegionOptionsOnSelectHandler = (value) => {
        setCloudPlatformRegionValue(value)
    }
    //
    const [cloudECSDataSource, setCloudECSDataSource] = useState([])
    const ecsColumns = [
        {
            title: '实例名',
            dataIndex: 'instanceName',
            key: 'instanceName'
        },
        {
            title: '实例ID',
            dataIndex: 'instanceId',
            key: 'instanceId'
        },
        {
            title: '公用网络',
            dataIndex: 'publicNet',
            key: 'publicNet'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '过期时间',
            dataIndex: 'deadlineTime',
            key: 'deadlineTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'} >慢查日志(3天)</Button>
                        <Button type={'primary'} >错误日志(半月)</Button>
                    </>
                );
            }
        }
    ]

    return (
        <>
            <span>云平台</span>
            <Select style={{width: 120}}
                //options={CloudPlatformOptions}
                    options={orgProductCloudPlatformOptions}
                    value={cloudPlatformId}
                    onSelect={cloudPlatformOnSelectHandler}/>
            <span>云平台账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformAccountOptions}
                    value={cloudPlatformAccountValue}
                    onSelect={cloudPlatformAccountOnSelectHandler}/>

            <span>子账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountOptions}
                    value={cloudPlatformSubAccountValue}
                    onSelect={cloudPlatformSubAccountOnSelectHandler}/>

            <span>AccessKey</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountAccessKeyOptions}
                    value={cloudPlatformSubAccountAccessKeyValue}
                    onSelect={cloudPlatformSubAccountAccessKeyHandler}/>

            <span>Region</span>
            <Select style={{width: 160}}
                    options={cloudPlatformRegionOptions}
                    value={cloudPlatformRegionValue}
                    onSelect={cloudPlatformRegionOptionsOnSelectHandler}/>

            <Space size={4}>
                <Button type={'primary'} >列出</Button>
            </Space>
            <Table columns={ecsColumns} dataSource={cloudECSDataSource}/>
        </>
    );
}