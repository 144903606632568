import {CiTemplateApi, CiTemplateStepApi, CiTemplateTaskApi, CloudPlatformAccountApi, Del, Get, Post} from '../common';


export function CreateCiTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: `${props.name !== undefined ? props.name : ""}`,
    }
    return Post({
        url: CiTemplateApi,
        params: params
    })
}


export function ListCiTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${CiTemplateApi}?${params}`,
    })
}


export function DelCiTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        uuid: props.uuid,
    })
    return Del({
        url: `${CiTemplateApi}?${params}`,
    })
}


export function AddCiTemplateStep(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateUuid: props.templateUuid,
        stepType: props.stepType,
        stepTypeTemplateIsPublic: props.stepTypeTemplateIsPublic,
        stepTypeTemplateUuid: props.stepTypeTemplateUuid,
        priority: props.priority,
    }
    return Post({
        url: CiTemplateStepApi,
        params: params
    })
}


export function ListCiTemplateStep(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateUuid: props.templateUuid,
    })
    return Get({
        url: `${CiTemplateStepApi}?${params}`,
    })
}


export function DelCiTemplateStep(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        templateUuid: props.templateUuid,
        templateStepUuid: props.templateStepUuid,
    })
    return Del({
        url: `${CiTemplateStepApi}?${params}`,
    })
}


export function ListCiTemplateTask(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
        repoBranch: props.repoBranch,
    })
    return Get({
        url: `${CiTemplateTaskApi}?${params}`,
    })
}
