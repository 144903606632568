import {
    Post,
    Get,
    Del,
    ProductCodeRepoGithubToken,
    ProductCodeRepoGithub,
    ProductCodeRepoGithubImport,
    ProductCodeRepoGithubBranch,
    ProductCodeRepoGithubBranchCommits,
    ProductCodeRepoGithubMerge, ProductCodeRepoGithubOrganization
} from '../common';

export function AddGithubToken(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: props.name,
        token: props.token,
        describe: props.describe,
    }
    return Post({
        url: ProductCodeRepoGithubToken,
        params: params,
    })
}

export function ListGithubToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${ProductCodeRepoGithubToken}?${params}`,
    })
}

export function DelGithubToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        uuid: props.uuid,
    })
    return Del({
        url: `${ProductCodeRepoGithubToken}?${params}`,
    })
}

export function ListGithubOrganization(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        tokenUuid: props.tokenUuid,
    })
    return Get({
        url: `${ProductCodeRepoGithubOrganization}?${params}`
    })
}

export function ImportGithubRepo(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        tokenUuid: props.tokenUuid,
        githubOrgName: props.githubOrgName,
        repoName: props.repoName,
    }
    return Post({
        url: ProductCodeRepoGithubImport,
        params: params,
    })
}

export function CreateGithubRepo(props) {
    return Post({
        url: ProductCodeRepoGithub,
        params: props.params,
    })
}

export function ListGithubRepo(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        token: props.token,
    })
    return Get({
        url: `${ProductCodeRepoGithub}?${params}`
    })
}

export function ArchiveGithubRepo(props) {
    return Del({
        url: ""
    })
}

export function CreateGithubRepoBranch(props) {
    const params = {

    }
    return Post({
        url: ProductCodeRepoGithubBranch,
        params: params,
    })
}

export function ListGithubRepoBranch(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        repoUuid: props.repoUuid,
    })
    return Get({
        url: `${ProductCodeRepoGithubBranch}?${params}`
    })
}

export function DelGithubRepoBranch(props) {
    const params = new URLSearchParams({

    })
    return Del({
        url: `${ProductCodeRepoGithubBranch}?${params}`
    })
}

export function ProtectGithubRepoBranch(props) {
    return Post({
        url: "",
        params: props.params,
    })
}

export function UnProtectGithubRepoBranch(props) {
    return Post({
        url: "",
        params: props.params,
    })
}

export function MergeGithubRepoBranch(props) {
    const params = {

    }
    return Post({
        url: `${ProductCodeRepoGithubMerge}`,
        params: params,
    })
}


export function ListGithubRepoBranchCommits(props) {
    const params = new URLSearchParams({

    })
    return Get({
        url: `${ProductCodeRepoGithubBranchCommits}?${params}`,
    })
}