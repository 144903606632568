import {
    Get,
    GetWithToken,
    PostWithToken,
    ThirdChatApi,
    ThirdChatSessionApi,
    ThirdChatSessionContextApi
} from '../common';

export function CreateChatSession() {
    
}

export function ListChatSession() {

}

export function SendChatSessionMsg() {

}

export function GetChatSessionMsg() {

}

export function GetThirdChatToken() {
    return Get({
        url: `${ThirdChatApi}`,
    })
}

export function ListThirdChatSession(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        appId: props.appId,
        userUuid: props.userUuid,
        timestamp: props.timestamp,
    })

    return GetWithToken({
        url: `${ThirdChatSessionApi}?${params}`,
        token: props.token,
    })
}


export function CreateThirdChatSession(props) {
    const params = {
        orgUuid: props.orgUuid,
        appId: props.appId,
        userUuid: props.userUuid,
        timestamp: props.timestamp,
        sessionName: props.sessionName,
        subject: props.subject,
    }

    return PostWithToken({
        url: `${ThirdChatSessionApi}`,
        params: params,
        token: props.token,
    })
}

export function GetThirdChatSessionContexts(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        appId: props.appId,
        userUuid: props.userUuid,
        timestamp: props.timestamp,
        sessionUuid: props.sessionUuid,
    })

    return GetWithToken({
        url: `${ThirdChatSessionContextApi}?${params}`,
        token: props.token,
    })
}



export function CreateThirdChatSessionContexts(props) {
    const params = {
        orgUuid: props.orgUuid,
        appId: props.appId,
        userUuid: props.userUuid,
        timestamp: props.timestamp,
        sessionUuid: props.sessionUuid,
        message: props.message,
    }

    return PostWithToken({
        url: `${ThirdChatSessionContextApi}`,
        params: params,
        token: props.token,
    })
}

