import {Avatar, Button, Layout, Menu, message, Popconfirm, Select} from 'antd';
import {LogoutApi, PageHeartbeat} from '../../apis/user/login';
import {GetLocalStorageOrg, GetLocalStorageOrgProduct, GetSelfOrg, OrgUuid, VerifyOrgUuid} from '../../apis/user/org';
import {useEffect, useState} from 'react';
import {ListOrganizationProduct} from '../../apis/product/product';
import {CreateOrganizationContent, LeaveOrganizationHandler} from '../../views/organization/organization';
import {CreateOrganizationProductContent} from '../../views/product/product';
import React from 'react';
import {DiscoveryMiddlewareRoute} from '../../views/product/discoveryMiddleware';
import {Token} from '../../apis/common';
// const {Divider} = StatisticCard;
const {Header, Content, Footer, Sider} = Layout;

// 获取设置的 orgUuid 缓存
let [orgUuidLocalCache, orgNameLocalCache] = GetLocalStorageOrg()
// 获取设置的 orgProductUuid 缓存
let [orgProductUuidLocalCache, orgProductNameLocalCache] = GetLocalStorageOrgProduct()

export function HeaderSider(props) {
    if (Token === '' || Token === undefined) {
        window.location.href = '/login'
        return
    }

    PageHeartbeat()

    /*
    useEffect(() => {
        PageHeartbeat()
    }, [])
     */

    // 组织列表
    const [orgList, setOrgList] = useState([])
    const [orgProductList, setOrgProductList] = useState([])

    // 刷新组织列表 - 刷新按钮 Handler
    const refreshOrgButtonHandler = () => {
        GetSelfOrg().then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let result = data.result
                    let orgs = []
                    for (let org of result.selfOrgList) {
                        orgs.push({
                            'value': org.orgUuid,
                            'label': org.orgName
                        })
                    }
                    setOrgList(orgs)
                    //message.success('刷新组织列表成功')
                    // 获取组织产品列表
                    if (orgUuidLocalCache !== '') {
                        refreshOrgProductButtonHandler()
                    }

                } else {
                    message.warning('刷新组织列表失败')
                    console.log('fetch org list failed. err: ', errMsg)
                }
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    // 刷新组织列表 - 刷新按钮 Handler

    const refreshOrgProductButtonHandler = () => {
        if (!VerifyOrgUuid()) {
            return
        }
        ListOrganizationProduct({
            orgUuid: OrgUuid
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                let result = data.result

                if (errCode === 0) {
                    if (result === null) {
                        message.error('该组织目前还没有创建产品，请先创建产品')
                        return
                    }
                    let tmpOrgProducts = []
                    for (let p of result) {
                        tmpOrgProducts.push({
                            'value': p.uuid,
                            'label': p.product_name
                        })
                    }
                    setOrgProductList(tmpOrgProducts)
                    console.log('刷新组织产品列表成功')
                    return;
                }
                message.warning('刷新组织产品列表失败')
                console.log('fetch org list failed. err: ', errMsg)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    // useEffect
    useEffect(() => {
            refreshOrgButtonHandler()
        }, []
    )

    // 更改组织(切换组织) - 当选中 Select 元素时，set组织到本地缓存
    const changeOrgSelectHandler = (value, option) => {
        const orgMapString = JSON.stringify(option)
        const label = option.label
        window.localStorage.setItem('org', orgMapString)
        window.localStorage.removeItem('orgProduct')
        window.location.reload()
        message.success('切换组织到 [ ' + label + ' ]')
        console.log('切换组织到 [ ' + label + ' ]')
        // 需要放在 reload 之后，因为之前 orgUuid 获取有缓存
        refreshOrgProductButtonHandler()
    }
    // 更改产品(切换产品)
    const changeOrgProductSelectHandler = (value, option) => {
        const orgMapString = JSON.stringify(option)
        const label = option.label
        window.localStorage.setItem('orgProduct', orgMapString)
        message.success('切换组织到 [ ' + label + ' ]')
        window.location.reload()
    }

    // 创建组织 handler
    const createOrganizationHandler = () => {
        CreateOrganizationContent()
        refreshOrgButtonHandler()
    }
    // 创建组织产品 handler
    const createOrganizationProductHandler = () => {
        CreateOrganizationProductContent()
        refreshOrgProductButtonHandler()
    }
    //
    return (<>
        <Header style={{
            // marginLeft 左边边界
            //marginLeft: 200,
            position: 'fixed',
            overflow: 'auto',
            left: 0,
            top: 0,
            // height 高度 60
            height: 60,
            bottom: 10,
            right: 0
        }}>
            <div>
                <a href={'/'}><img
                    src={'https://startops-static.oss-cn-hangzhou.aliyuncs.com/logo/个人记录.png'}
                    //src={'https://startops-static.oss-cn-hangzhou.aliyuncs.com/logo/startops-logo.png'}
                    alt={'logo'} style={{
                    width: 100,
                    height: 40,
                    top: 10,
                    // position: 'absolute' 自动填充 div 中
                    position: 'absolute'
                }}/></a>

                <span style={{
                    //top: 0,
                    left: 220,
                    bottom: 1,
                    position: 'absolute',
                    color: 'white'
                }}>请选择组织</span>

                <Select style={{
                    width: 120,
                    height: 40,
                    top: 10,
                    left: 300,
                    overflow: 'auto',
                    position: 'absolute'
                }}
                        defaultValue={orgNameLocalCache}
                        onChange={changeOrgSelectHandler}
                        options={
                            orgList
                        }
                />

                <Button
                    type="primary"
                    size={'small'}
                    style={{
                        width: 50,
                        height: 30,
                        top: 10,
                        left: 430,
                        overflow: 'auto',
                        position: 'absolute'
                    }}
                    onClick={refreshOrgButtonHandler}
                >
                    刷新</Button>

                <span style={{
                    //top: 0,
                    left: 520,
                    bottom: 1,
                    position: 'absolute',
                    color: 'white'
                }}>请选择产品</span>

                <Select style={{
                    width: 120,
                    height: 40,
                    top: 10,
                    left: 600,
                    overflow: 'auto',
                    position: 'absolute'
                }}
                        defaultValue={orgProductNameLocalCache}
                        onChange={changeOrgProductSelectHandler}
                        options={
                            orgProductList
                        }
                />
                <Button
                    type="primary"
                    size={'small'}
                    style={{
                        width: 50,
                        height: 30,
                        top: 10,
                        left: 730,
                        overflow: 'auto',
                        position: 'absolute'
                    }}
                    onClick={refreshOrgProductButtonHandler}
                >
                    刷新</Button>

                <Button
                    type="primary"
                    size={'small'}
                    style={{
                        width: 70,
                        height: 30,
                        top: 10,
                        left: 800,
                        overflow: 'auto',
                        position: 'absolute'
                    }}
                    onClick={createOrganizationHandler}
                >
                    创建组织</Button>

                <Button
                    type="primary"
                    size={'small'}
                    style={{
                        width: 70,
                        height: 30,
                        top: 10,
                        left: 880,
                        overflow: 'auto',
                        position: 'absolute'
                    }}
                    onClick={createOrganizationProductHandler}
                >
                    创建产品</Button>

                <Popconfirm
                    title={'主动离开该组织'}
                    description={'您是否确定主动离开该组织？'}
                    onConfirm={LeaveOrganizationHandler}
                >
                    <Button
                        type="primary"
                        size={'small'}
                        style={{
                            width: 70,
                            height: 30,
                            top: 10,
                            left: 980,
                            overflow: 'auto',
                            position: 'absolute'
                        }}
                        danger={true}
                    >
                        离开组织</Button>
                </Popconfirm>


                <a href={'/user/info'}>
                    <Avatar src={'https://startops-static.oss-cn-hangzhou.aliyuncs.com/startops/icon/startops.png'}
                            size={'large'} alt={'用户头像'} style={{
                        top: 10,
                        height: 40,
                        float: 'right',
                        right: 140
                    }}></Avatar>
                </a>


                <Button ghost={true} style={{
                    top: 10,
                    height: 40,
                    overflow: 'auto',
                    float: 'right'
                }}
                        onClick={LogoutApi}
                >登出</Button>
            </div>
        </Header>
    </>);
}

export function LeftSider(props) {
    //const [defaultOpenKeys, setDefaultOpenKeys] = useState([])
    //const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])

    const leftMenu = [
        {
            key: 'organization',
            label: '组织管理',
            children: [
                {
                    key: 'getOrgMember',
                    label: '组织成员'
                },
                {
                    key: 'listProduct',
                    label: '列出产品'
                },
                {
                    key: 'listProductEnv',
                    label: '产品环境'
                }
            ]
        },
        {
            key: 'product',
            label: '程序管理',
            children: [
                {
                    key: 'codeRepo',
                    label: '代码仓库'
                },
                {
                    key: 'ciPipeline',
                    label: 'CI流水配置'
                },
                {
                    key: 'cdPipeline',
                    label: 'CD流水配置'
                },
                {
                    key: 'reliability',
                    label: '服务可用性'
                },
                {
                    key: 'containerImage',
                    label: '容器镜像'
                },
                {
                    key: 'containerRegistry',
                    label: '容器镜像仓库'
                }
            ]
        },
        {
            key: 'resource',
            label: '资源管理',
            children: [

                {
                    key: 'messageQueueMiddleware',
                    label: '消息中间件'
                },
                {
                    key: 'storageMiddleware',
                    label: '存储中间件'
                },
                {
                    key: 'discoveryMiddleware',
                    label: '自动发现中间件'
                },
                {
                    key: 'otherMiddleware',
                    label: '其他中间件'
                },
                {
                    key: 'webServiceResource',
                    label: 'web服务资源'
                },
                {
                    key: 'serviceUrl',
                    label: '服务Url'
                },
                {
                    key: 'nodeResource',
                    label: '主机资源'
                }
            ]
        },
        {
            key: 'kubernetes',
            label: 'K8S管理',
            children: [
                {
                    key: 'template',
                    label: '模版管理'
                },
                {
                    key: 'cluster',
                    label: '集群管理'
                },
                {
                    key: 'node',
                    label: '节点'
                },
                {
                    key: 'configMap',
                    label: 'ConfigMap'
                },
                {
                    key: 'secret',
                    label: 'Secret'
                },
                {
                    key: 'deployment',
                    label: 'Deployment'
                },
                {
                    key: 'service',
                    label: 'Service'
                },
                {
                    key: 'ingress',
                    label: 'Ingress'
                },
                {
                    key: 'pod',
                    label: 'Pod'
                },
                {
                    key: 'event',
                    label: '事件管理'
                }
            ]
        },
        {
            key: 'cloud',
            label: '云服务管理',
            children: [
                {
                    key: 'region',
                    label: '云区域'
                },
                {
                    key: 'cloudAccount',
                    label: '云账号'
                },
                {
                    key: 'cloudSubAccount',
                    label: '云平台子账号'
                },
                {
                    key: 'cloudCertificate',
                    label: 'SSL证书'
                },
                {
                    key: 'cloudCDN',
                    label: 'CDN内容加速'
                },
                {
                    key: 'cloudRDS',
                    label: 'RDS云数据库'
                },
                {
                    key: 'cloudOSS',
                    label: 'OSS云对象存储'
                },
                {
                    key: 'cloudDNS',
                    label: 'DNS云解析'
                },
                {
                    key: 'cloudElasticCompute',
                    label: '云主机管理'
                }
            ]
        },
        {
            key: 'workSpace',
            label: '工作管理',
            children: [
                {
                    key: 'fault',
                    label: '故障报告'
                },
                {
                    key: 'workOrder',
                    label: '工单管理'
                },
                {
                    key: 'duty',
                    label: '值班表'
                },
                {
                    key: 'todo',
                    label: '待办事宜'
                },
                {
                    key: 'taskDist',
                    label: '任务分配'
                }
            ]
        },
        {
            key: 'robot',
            label: 'AI',
            children: [
                {
                    key: 'dingTalkRobot',
                    label: '钉钉机器人管理'
                },
                {
                    key: 'smartChat',
                    label: '聊天模式'
                },
                {
                    key: 'question',
                    label: '一问一答'
                },
                {
                    key: 'thirdChat',
                    label: '智能聊天2'
                }
            ]
        }, {
            key: 'monitor',
            label: '监控和消息',
            children: [
                {
                    key: 'messageGroup',
                    label: '消息组'
                },
                {
                    key: 'monitorToken',
                    label: '监控Token'
                },
                {
                    key: 'monitorReceiver',
                    label: '监控接收者'
                },
                {
                    key: 'httpDiscovery',
                    label: 'HTTP自动发现'
                },
                {
                    key: 'alarms',
                    label: '历史告警'
                }
            ]
        },

        {
            key: 'tools',
            label: '工具管理',
            children: [
                {
                    key: 'translate',
                    label: '翻译'
                },
                {
                    key: 'encoded',
                    label: '编解码'
                },
                {
                    key: 'convert',
                    label: '格式转换'
                },
                {
                    key: 'ipTool',
                    label: 'Ip查询'
                }
            ]
        }
    ]
    // 点击左菜单 Handler
    const clickHandler = ({item, key, keyPath, domEvent}) => {
        let secondMenu = keyPath[0]
        let firstMenu = keyPath[1]

        if (firstMenu === undefined || secondMenu === undefined) {
            console.log('firstMenu: ' + firstMenu + '. secondMenu: ', secondMenu)
            return
        }
        //setDefaultOpenKeys([firstMenu])
        //setDefaultSelectedKeys([secondMenu])

        // 若无 orgUuid，呼出选择 orgUuid，后续拼接 url 部分需要 orgUuid
        if (orgUuidLocalCache === null || orgUuidLocalCache === undefined) {
            // 本来应该加一个弹框的，后续添加
            message.warning('系统未检测到你的组织，请选择您需要操作的组织')
            return
        }

        //let href = `/?orgUuid=${encodeURIComponent(orgUuidLocalCache)}&orgName=${encodeURIComponent(orgNameLocalCache)}&orgProductUuid=${encodeURIComponent(orgProductUuidLocalCache)}&orgProductName=${encodeURIComponent(orgProductNameLocalCache)}&firstMenu=${encodeURIComponent(firstMenu)}&secondMenu=${encodeURIComponent(secondMenu)}`
        let href = `/${encodeURIComponent(firstMenu)}/${encodeURIComponent(secondMenu)}?orgUuid=${encodeURIComponent(orgUuidLocalCache)}&orgName=${encodeURIComponent(orgNameLocalCache)}&orgProductUuid=${encodeURIComponent(orgProductUuidLocalCache)}&orgProductName=${encodeURIComponent(orgProductNameLocalCache)}`
        window.location.assign(href)
    }
    //
    const onOpenChangeHandler = (openKeys) => {
        //console.log("openKeys: ", openKeys)
    }
    return (<>
        {/* div 空出 header 内容*/}
        <div style={{height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)'}}/>
        {/* 左菜单 */}

        <Sider
            width={180}
            style={{
                overflow: 'auto',
                height: '100vh',
                // 位置: fixed 为固定 -> 固定菜单栏
                position: 'fixed',
                marginTop: 60,
                left: 0,
                top: 0,
                bottom: 0
            }}
            collapsed={false} // 设置菜单栏是否收缩
        >
            {/* 菜单 */}
            <Menu
                theme="dark"
                mode="inline"
                defaultOpenKeys={props.defaultOpenKeys}
                defaultSelectedKeys={props.defaultSelectedKeys}
                onClick={clickHandler}
                onOpenChange={onOpenChangeHandler}
                //inlineCollapsed={true} // inlineCollapsed` not control Menu under Sider
                //triggerSubMenuAction={'hover'}
                items={leftMenu}
            >
            </Menu>
        </Sider>

    </>);
}

export function IndexRightBody(props) {
    return (
        <>
            {/* 右部分主体 */}
            <Layout className="site-layout">
                {/* 小导航 */}

                {/* 主体内容 */}
                <Content style={{
                    overflow: 'auto',
                    paddingTop: 24
                    //padding:24,
                }}>
                    {props.children}
                </Content>

                {/* Footer*/}
                <Footer style={{left: 180, textAlign: 'center'}}>
                    <text>该服务由 @StartOps 提供</text>
                    <br/>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备15041721号-2</a>

                </Footer>
            </Layout>

            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7808012575924886"
                    crossOrigin="anonymous"></script>
        </>
    );
}