import {LoginApi, LoginPageGetHeartbeat} from '../../apis/user/login';
import React from 'react';
import {LoginPageCarousel} from './login';
import {Button, Input, Layout, Row, Space} from 'antd';
import {InfoCircleOutlined, MobileOutlined} from '@ant-design/icons';
const {Sider, Content} = Layout;

export function ForgotPasswdRoute() {
    LoginPageGetHeartbeat()
    return (
        <>
            <Content width={'180'} style={{
                position: 'fixed',
                //overflow: 'auto',
                height: '100vh',
                left: 0,
                backgroundColor: 'gray',
                right: 400
            }}>
                <LoginPageCarousel></LoginPageCarousel>
            </Content>
            <ForgotPasswdContent></ForgotPasswdContent>
        </>
    );
}


export function ForgotPasswdContent() {
    //
    const getForgotPasswdVerifyCodeOnClick = () => {

    }

    //
    const forgotPasswdOnClick = () => {

    }

    return (
        <Sider style={{
            position: 'fixed',
            overflow: 'auto',
            height: '100vh',
            //width: '30vh',
            //left: 180,
            right: 100,
            marginTop: 120
            //top: 30,
            //backgroundColor: "black",
        }}>





            <Row justify={'center'}>
                <Space>
                    <Input
                        placeholder={'手机号码'}
                        prefix={<MobileOutlined/>}
                        id={'phone'}
                    />

                </Space>
            </Row>
            <Row justify={'center'}>
                <Space>
                    <Input
                        placeholder={'注册验证码'}
                        prefix={<MobileOutlined/>}
                        id={'forgotPasswdVerifyCode'}
                    />

                    <Button type={'primary'} onClick={getForgotPasswdVerifyCodeOnClick}>验证码</Button>
                </Space>
            </Row>



            <Row justify={'center'}>
                <Space>
                    <Button onClick={forgotPasswdOnClick}  type={'primary'}>重置密码</Button>
                </Space>

                <Space>
                    <Button href={"/verify/login"}>验证码登录</Button>
                    <Button href={'/login'}>密码登录</Button>
                </Space>
            </Row>
        </Sider>);
}