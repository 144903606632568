import {Get, Post, WorkSpaceOrderApi, WorkSpaceOrderStepApi, WorkSpaceOrderTypeApi} from '../common';

export function CreateWorkOrderType(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        name: props.name,
        describe: props.describe,
    }
    return Post({
        url: `${WorkSpaceOrderTypeApi}`,
        params: params,
    })
}

export function ListWorkOrderType(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${WorkSpaceOrderTypeApi}?${params}`,
    })
}

export function CreateWorkOrder(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        workOrderTypeUuid: props.workOrderTypeUuid,
        content: props.content,
    }
    return Post({
        url: `${WorkSpaceOrderApi}`,
        params: params,
    })
}


export function ListWorkOrder(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        status: props.status,
    })
    return Get({
        url: `${WorkSpaceOrderApi}?${params}`,
    })
}

export function CloseWorkOrder(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        workOrderUuid: props.workOrderUuid,
    })
    return Get({
        url: `${WorkSpaceOrderApi}?${params}`,
    })
}


export function AddWorkOrderStep(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        workOrderUuid: props.workOrderUuid,
        content: props.content,
    }
    return Post({
        url: `${WorkSpaceOrderStepApi}`,
        params: params,
    })
}

export function ListWorkOrderStep(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        workOrderUuid: props.workOrderUuid,
    })
    return Get({
        url: `${WorkSpaceOrderStepApi}?${params}`,
    })
}