import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {LoginRoute, VerifyCodeLoginRoute} from './views/user/login'
import {Dashboard} from './views/common/home';
import {About} from './views/common/about';
import {UserRegisterRoute} from './views/user/user';

import 'bootstrap/dist/css/bootstrap.min.css';
import {GetOrganizationMemberRoute} from './views/organization/organization';
import {
    ListOrganizationProductEnvRoute,
    ListOrganizationProductRoute
} from './views/product/product';
import {CodeRepoRoute} from './views/product/codeRepo';
import { OtherMiddlewareRoute} from './views/product/otherMiddleware';
import {CiPipelineRoute} from './views/product/ci';
import {CdPipelineRoute} from './views/product/cd';
import {ListServiceUrlRoute} from './views/product/serviceUrl';
import ListReliabilityRoute from './views/product/reliability';
import {ListContainerImageRoute} from './views/product/containerImage';
import ListContainerRegistryRoute from './views/product/containerRegistry';
import {CloudAccountRoute, SubAccountRoute} from './views/cloud/account';
import {ListCertificateRoute} from './views/cloud/certificate';
import {CDNRoute} from './views/cloud/cdn';
import {RDSRoute} from './views/cloud/rds';
import {ObjectStorageServiceRoute} from './views/cloud/oss';
import {DNSRoute} from './views/cloud/dns';
import {ECSRoute} from './views/cloud/ecs';
import {FaultRoute} from './views/workSpace/fault';
import {WorkOrderRoute} from './views/workSpace/workOrder';
import {DutyRoute} from './views/workSpace/duty';
import TodoListRoute from './views/workSpace/todo';
import {TaskDistRoute} from './views/workSpace/taskDist';
import {IntellectChatRoute} from './views/robot/chat';
import {QuestionAndAnswerRoute} from './views/robot/question';
import {HttpDiscoveryRoute} from './views/monitor/httpDiscovery';
import {AlarmsRoute} from './views/monitor/alarms';
import {K8SNodeRoute} from './views/kubernetes/node';
import {K8SClusterRoute} from './views/kubernetes/cluster';
import {K8SConfigMapRoute} from './views/kubernetes/configMap';
import {K8SDeploymentRoute} from './views/kubernetes/deployment';
import {K8SServiceRoute} from './views/kubernetes/service';
import {K8SIngressRoute} from './views/kubernetes/ingress';
import {K8SPodRoute} from './views/kubernetes/pod';
import {K8SEventRoute} from './views/kubernetes/event';
import {EnCodeAndDeCodeRoute, IPToolsRoute, TranslateRoute} from './views/tools/code';
import {CloudRegionRoute} from './views/cloud/region';
import {WebTerminal} from './views/tools/term';
import {DingTalkRobotRoute} from './views/robot/dingtalk';
import {K8SSecretRoute} from './views/kubernetes/secret';
import {ThirdChatRoute} from './views/robot/thirdChat';
import {MessageQueueMiddlewareRoute} from './views/product/messageQueueMiddleware';
import {StorageMiddlewareRoute} from './views/product/storageMiddleware';
import {DiscoveryMiddlewareRoute} from './views/product/discoveryMiddleware';
import {PodTerminalRoute} from './views/kubernetes/terminal';
import {WebServiceResourceRoute} from './views/product/webServiceResource';
import {MonitorTokenRoute} from './views/monitor/monitorToken';
import {MonitorReceiverRoute} from './views/monitor/monitorReceiver';
import {ForgotPasswdRoute} from './views/user/passwd';
import {MessageGroupRoute} from './views/message/messageGroup';
import {NodeResourceRoute} from './views/product/nodeResource';
import {K8STemplateRoute} from './views/product/k8sTemplate';
import {ConvertRoute} from './views/tools/convert';


const root = ReactDOM.createRoot(
    document.getElementById('root')
);

// 验证 Url

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/login" element={<LoginRoute />}/>
            <Route path="/verify/login" element={<VerifyCodeLoginRoute />}/>
            <Route path="/register" element={<UserRegisterRoute/>}/>

            <Route path="/forgot" element={<ForgotPasswdRoute/>}/>

            <Route path="/about" element={<About/>}/>

            <Route path="/organization/getOrgMember" element={<GetOrganizationMemberRoute/>}/>
            <Route path="/organization/listProduct" element={<ListOrganizationProductRoute/>}/>
            <Route path="/organization/listProductEnv" element={<ListOrganizationProductEnvRoute/>}/>

            <Route path="/product/codeRepo" element={<CodeRepoRoute/>}/>

            <Route path="/product/ciPipeline" element={<CiPipelineRoute/>}/>
            <Route path="/product/cdPipeline" element={<CdPipelineRoute/>}/>
            <Route path="/product/reliability" element={<ListReliabilityRoute/>}/>
            <Route path="/product/containerImage" element={<ListContainerImageRoute/>}/>
            <Route path="/product/containerRegistry" element={<ListContainerRegistryRoute/>}/>


            <Route path="/resource/serviceUrl" element={<ListServiceUrlRoute/>}/>
            <Route path="/resource/nodeResource" element={<NodeResourceRoute/>}/>

            <Route path="/resource/otherMiddleware" element={<OtherMiddlewareRoute/>}/>
            <Route path="/resource/messageQueueMiddleware" element={<MessageQueueMiddlewareRoute/>}/>
            <Route path="/resource/storageMiddleware" element={<StorageMiddlewareRoute/>}/>
            <Route path="/resource/discoveryMiddleware" element={<DiscoveryMiddlewareRoute/>}/>

            <Route path="/resource/webServiceResource" element={<WebServiceResourceRoute/>}/>


            <Route path="/cloud/region" element={<CloudRegionRoute/>}/>
            <Route path="/cloud/cloudAccount" element={<CloudAccountRoute/>}/>
            <Route path="/cloud/cloudSubAccount" element={<SubAccountRoute/>}/>
            <Route path="/cloud/cloudCertificate" element={<ListCertificateRoute/>}/>
            <Route path="/cloud/cloudCDN" element={<CDNRoute/>}/>
            <Route path="/cloud/cloudRDS" element={<RDSRoute/>}/>
            <Route path="/cloud/cloudOSS" element={<ObjectStorageServiceRoute/>}/>
            <Route path="/cloud/cloudDNS" element={<DNSRoute/>}/>
            <Route path="/cloud/cloudElasticCompute" element={<ECSRoute/>}/>

            <Route path="/workSpace/fault" element={<FaultRoute/>}/>
            <Route path="/workSpace/workOrder" element={<WorkOrderRoute/>}/>
            <Route path="/workSpace/duty" element={<DutyRoute/>}/>
            <Route path="/workSpace/todo" element={<TodoListRoute/>}/>
            <Route path="/workSpace/taskDist" element={<TaskDistRoute/>}/>

            <Route path="/robot/dingTalkRobot" element={<DingTalkRobotRoute />}/>
            <Route path="/robot/question" element={<QuestionAndAnswerRoute/>}/>
            <Route path="/robot/smartChat" element={<IntellectChatRoute/>}/>
            <Route path="/robot/thirdChat" element={<ThirdChatRoute/>}/>

            <Route path="/monitor/httpDiscovery" element={<HttpDiscoveryRoute/>}/>
            <Route path="/monitor/alarms" element={<AlarmsRoute/>}/>
            <Route path="/monitor/monitorToken" element={<MonitorTokenRoute />}/>
            <Route path="/monitor/monitorReceiver" element={<MonitorReceiverRoute />}/>

            <Route path="/monitor/messageGroup" element={<MessageGroupRoute/>}/>



            <Route path="/kubernetes/template" element={<K8STemplateRoute/>}/>
            <Route path="/kubernetes/cluster" element={<K8SClusterRoute/>}/>
            <Route path="/kubernetes/node" element={<K8SNodeRoute/>}/>
            <Route path="/kubernetes/configMap" element={<K8SConfigMapRoute/>}/>
            <Route path="/kubernetes/deployment" element={<K8SDeploymentRoute/>}/>
            <Route path="/kubernetes/service" element={<K8SServiceRoute/>}/>
            <Route path="/kubernetes/ingress" element={<K8SIngressRoute/>}/>
            <Route path="/kubernetes/pod" element={<K8SPodRoute/>}/>
            <Route path="/kubernetes/event" element={<K8SEventRoute/>}/>
            <Route path="/kubernetes/secret" element={<K8SSecretRoute/>}/>
            <Route path="/kubernetes/pod/term" element={<PodTerminalRoute />}/>


            <Route path="/tools/translate" element={<TranslateRoute/>}/>
            <Route path="/tools/encoded" element={<EnCodeAndDeCodeRoute/>}/>
            <Route path="/tools/ipTool" element={<IPToolsRoute/>}/>
            <Route path="/tools/convert" element={<ConvertRoute />}/>


            <Route path="/tools/term" element={<WebTerminal/>}/>

        </Routes>
    </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
