import {StatisticCard} from '@ant-design/pro-card';
import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Input, Layout, message, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';
import {Terminal} from 'xterm';
import {FitAddon} from 'xterm-addon-fit';
import {AttachAddon} from 'xterm-addon-attach';
import {GetKubernetesEvent} from '../../apis/hook/kubernetesEvent';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

export function K8SEventRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['event']}></LeftSider>
                    <IndexRightBody>
                        <EventContent></EventContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function EventContent() {
    //

    const [regardingKind, setRegardingKind] = useState('')

    const regardingKindOptionsOnChange = (value) => {
        setRegardingKind(value)
    }

    const regardingKindOptions = [
        {
            value: "",
            label: "所有Kind",
        },
        {
            value: "cluster",
            label: "cluster",
        },
        {
            value: "pod",
            label: "pod",
        },
        {
            value: "deployment",
            label: "deployment",
        },
        {
            value: "secret",
            label: "secret",
        },
        {
            value: "service",
            label: "service",
        },
        {
            value: "ingress",
            label: "ingress",
        },
        {
            value: "node",
            label: "node",
        },
        {
            value: "replicaset",
            label: "replicaset",
        },
        {
            value: "ingresslist",
            label: "ingresslist",
        },
        {
            value: "endpoints",
            label: "endpoints",
        },
        {
            value: "cronjob",
            label: "cronjob",
        },
        {
            value: "job",
            label: "job",
        },
    ]


    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    //
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    //
    const kubernetesEventColumns = [
        {
            title: 'Kind',
            dataIndex: 'kind',
        },
        {
            title: '事件名',
            dataIndex: 'name',
        },
        {
            title: 'namespace',
            dataIndex: 'namespace'
        },
        {
            title: '类型',
            dataIndex: 'type'
        },
//        {
//            title: 'annotations',
//            dataIndex: 'annotations'
//        },
        {
            title: '关联Kind',
            dataIndex: 'regardingKind'
        },
        {
            title: '关联名',
            dataIndex: 'regardingName'
        },
        {
            title: '原因',
            dataIndex: 'reason'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: 'note',
            dataIndex: 'note'
        },
/*        {
            title: 'regardingFieldPath',
            dataIndex: 'regardingFieldPath'
        }
 */
    ]
    const [kubernetesEventDataSource, setKubernetesEventDataSource] = useState([])
    //
    const getKubernetesEventOnClick = () => {
        const regardingName = document.getElementById('regardingName').value
        GetKubernetesEvent({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: '',
            type: '',
            createTime: '',
            regardingKind: regardingKind,
            regardingName: regardingName,
            regardingUid: ''
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setKubernetesEventDataSource([])
                        return;
                    }

                    let tmp = []
                    let count = 1
                    for (const event of d) {
                        tmp.push({
                            key: count,
                            k8sClusterUuid: event.k8sClusterUuid,
                            apiVersion: event.apiVersion,
                            kind: event.kind,
                            name: event.name,
                            namespace: event.namespace,
                            type: event.type,
                            reason: event.reason,
                            note: event.note,
                            createTime: event.createTime,
                            annotations: event.annotations,
                            resourceVersion: event.resourceVersion,
                            regardingKind: event.regardingKind,
                            regardingNamespace: event.regardingNamespace,
                            regardingName: event.regardingName,
                            regardingUid: event.regardingUid,
                            regardingAPIVersion: event.regardingAPIVersion,
                            regardingResourceVersion: event.regardingResourceVersion,
                            regardingFieldPath: event.regardingFieldPath
                        })

                        count += 1
                    }
                    setKubernetesEventDataSource(tmp)
                    return
                }

                message.error(`获取 Kubernetes Event 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取 Kubernetes Event 失败 ${err}`)
            }
        )
    }



    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select
                    style={{width: 180}}
                    value={kubernetesCluster}
                    options={kubernetesClusterOptions}
                    onChange={kubernetesClusterSelectOnChangeHandler}
                />

                <span>RegardingKind</span>
                <Select
                    style={{width: 180}}
                    value={regardingKind}
                    options={regardingKindOptions}
                    onChange={regardingKindOptionsOnChange}
                />
                <span>RegardingName</span>
                <Input id={'regardingName'}/>

                <Button type={'primary'} onClick={getKubernetesEventOnClick}>查询</Button>

            </Space>


            <Table
                columns={kubernetesEventColumns}
                dataSource={kubernetesEventDataSource}
                style={{
                    width: "100%",
                }}
            />
        </>
    );
}