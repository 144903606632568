import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, message, Modal, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';
import React from 'react';
import {ListConfigMap} from '../../apis/kubernetes/configMap';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';

export function K8SConfigMapRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['configMap']}></LeftSider>
                    <IndexRightBody>
                        <ConfigMapContent></ConfigMapContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


export function ConfigMapContent() {
    //
    const k8sConfigMapColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: '配置项',
            dataIndex: 'data',
            key: 'data',
            render: (text) => {
                console.log(`text ${text}`)
                if (text === null || text === undefined) {
                    return
                }
                const keys = Object.keys(text)
                return <>
                    <ul>
                        {keys.map((key) => (
                            <li key={key}>{key}</li>
                        ))}
                    </ul>
                </>
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: () => {
                return <>
                    <Button type={'primary'}>查看</Button>
                <Button type={'primary'}>更新</Button>
                </>
            }
        }
    ]
    const [k8sConfigMapDataSource, setK8sConfigMapDataSource] = useState([])
    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }


    //
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }
    //
    const getKubernetesConfigMapOnClick = () => {
        ListConfigMap({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const configMaps = data.configMap
                    if (configMaps === null) {
                        setK8sConfigMapDataSource([])
                        return;
                    }

                    let tmp = []
                    let count = 1
                    for (const c of configMaps) {
                        tmp.push({
                            key: count,
                            namespace: c.namespace,
                            name: c.name,
                            data: c.data
                        })
                        count += 1
                    }
                    setK8sConfigMapDataSource(tmp)
                    return
                }
                setK8sConfigMapDataSource([])
                message.error(`列出ConfigMap失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setK8sConfigMapDataSource([])
                message.error(`列出ConfigMap失败`)
                console.log(`列出ConfigMap失败 ${err}`)
            }
        )
    }

    //
    const [createConfigMapOpenModal, setCreateConfigMapOpenModal] = useState(false)
    const createConfigMapOnClick = () => {
        setCreateConfigMapOpenModal(true)
    }
    const createConfigMapOnOk = () => {
        setCreateConfigMapOpenModal(false)
    }
    const createConfigMapOnCancel = () => {
        setCreateConfigMapOpenModal(false)
    }

    //
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === "") {
            return
        }

        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])



    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={getKubernetesConfigMapOnClick}>查询</Button>
            </Space>

            <Table columns={k8sConfigMapColumns} dataSource={k8sConfigMapDataSource}/>

            <Modal
                title={'添加 ConfigMap'}
                open={createConfigMapOpenModal}
                onOk={createConfigMapOnOk}
                onCancel={createConfigMapOnCancel}
                destroyOnClose={true}
            >
            </Modal>
        </>
    );
}