import dayjs from 'dayjs';

export const RangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()]
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()]
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()]
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()]
    }
]

// timestamp 转 LocaleString
export function ConvertTimestamp(timestamp) {
    let options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }

    //return new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp * 1000))
    return new Date(timestamp * 1000).toLocaleString()
}