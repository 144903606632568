import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, message, Table, Select, Space} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import React from 'react';
import {KubernetesClusterSelectOptions} from './common';
import {ListNode} from '../../apis/kubernetes/node';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

export function K8SNodeRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['node']}></LeftSider>
                    <IndexRightBody>
                        <NodeContent></NodeContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function NodeContent() {
    //
    const k8sNodeColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '创建时间',
            dataIndex: 'creationTimestamp',
            key: 'creationTimestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '组件信息',
            dataIndex: 'module',
            key: 'module',
            render: (_, record) => {
                return (
                    <>
                        <pre>ContainerRuntime: {record.containerRuntimeVersion}</pre>
                        <pre>KernelVersion: {record.kernelVersion}</pre>
                        <pre>KubeletVersion: {record.kubeletVersion}</pre>
                        <pre>osImage: {record.osImage}</pre>
                    </>
                )
            }
        },
        {
            title: '容量',
            dataIndex: 'capacity',
            key: 'capacity',
            render: (text) => {
                return <>
                    <pre>Cpu: {text.cpu}</pre>
                    <pre>Memory: {text.memory}</pre>
                    <pre>Storage: {text.storage}</pre>
                    <pre>StorageEphemeral: {text.storageEphemeral}</pre>
                </>
            }
        },
        {
            title: '可分配容量',
            dataIndex: 'allocatable',
            key: 'allocatable',
            render: (text) => {
                return <>
                    <pre>Cpu: {text.cpu}</pre>
                    <pre>Memory: {text.memory}</pre>
                    <pre>Storage: {text.storage}</pre>
                    <pre>StorageEphemeral: {text.storageEphemeral}</pre>
                </>
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: () => {
                return <>
                    <Button type={'primary'}>详情</Button>
                    <Button type={'primary'}>容器组</Button>
                </>
            }
        }
    ]
    //
    const [k8sNodeDataSource, setK8sNodeDataSource] = useState([])

    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }
    //
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }
    //
    const getKubernetesNodeOnClick = () => {
        ListNode({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const nodes = data.node
                    if (nodes === null) {
                        setK8sNodeDataSource([])
                        return;
                    }

                    let tmp = []
                    let count = 0
                    for (const n of nodes) {
                        count += 1
                        tmp.push({
                            key: count,
                            name: n.name,
                            creationTimestamp: n.creationTimestamp,
                            containerRuntimeVersion: n.containerRuntimeVersion,
                            kernelVersion: n.kernelVersion,
                            kubeletVersion: n.kubeletVersion,
                            kubeletEndpoint: n.kubeletEndpoint,
                            osImage: n.osImage,
                            labels: n.labels,
                            annotations: n.annotations,
                            capacity: n.capacity,
                            allocatable: n.allocatable
                        })
                    }
                    setK8sNodeDataSource(tmp)
                    return
                }
                setK8sNodeDataSource([])
                message.error(`列出Kubernetes节点失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setK8sNodeDataSource([])
                message.error(`列出Kubernetes节点失败`)
                console.log(`列出Kubernetes节点失败 ${err}`)
            }
        )
    }

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}} value={kubernetesCluster} options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>
                <Button type={'primary'} onClick={getKubernetesNodeOnClick}>查询</Button>
            </Space>
            <Table columns={k8sNodeColumns} dataSource={k8sNodeDataSource}/>
        </>
    );
}