import {Get, HookAlertAckApi, HookAlertHisApi, Post} from '../common';

export function GetAlarmsHis(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        monitorToken: props.monitorToken,
        receiver: props.receiver,
        status: props.status,
        fingerprint: props.fingerprint,
        startSearchTime: props.startSearchTime,
        endSearchTime: props.endSearchTime,
    });
    return Get({
        url: `${HookAlertHisApi}?${params}`,
    })
}

export function AckAlarm(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        fingerprint: props.fingerprint,
        ackMessage: props.ackMessage,
    }
    return Post({
        url: HookAlertAckApi,
        params: params,
    })
}

