import {Get, MonitorTokenApi, Post, Del} from '../common';
import {OrgProductUuid, OrgUuid} from '../user/org';

export function ListMonitorToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
    });
    return Get({
        url: `${MonitorTokenApi}?${params}`,
    })
}


export function GenerateMonitorToken(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        describe: props.describe,
    }
    return Post({
        url: MonitorTokenApi,
        params: params,
    })
}

export function DelMonitorToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        uuid: props.uuid,
    });
    return Del({
        url: `${MonitorTokenApi}?${params}`,
    })
}