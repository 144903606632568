import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Space, Table, Tabs} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-github';
import "ace-builds/src-noconflict/theme-terminal";

import {
    CreateK8SPrivateTemplate, DelK8SPrivateTemplate, GetK8SPrivateTemplate,
    GetK8SPublicTemplate,
    ListK8SPrivateTemplate,
    ListK8SPublicTemplate
} from '../../apis/kubernetes/template';
import {OrgUuid} from '../../apis/user/org';
import {ConvertTimestamp} from '../common/date';

export function K8STemplateRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['template']}></LeftSider>
                    <IndexRightBody>
                        <K8STemplateContent></K8STemplateContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function K8STemplateContent() {

    //
    const [defaultActiveKey, setDefaultActiveKey] = useState('publicTemplate')

    const tabsOnChange = (value) => {
        setDefaultActiveKey(value)
        switch (value) {
            case 'publicTemplate':
                listPublicTemplateOnClick()
                break
            case 'privateTemplate':
                listPrivateTemplateOnClick()
                break
            case 'templateContent':
                break
        }
    }

    //
    const publicTemplateColumns = [
        {
            title: '模版名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                <Button type={'primary'} onClick={() => getPublicTemplateOnClick(record.uuid)}>查看</Button>
                </>
            }
        }
    ]
    const [publicTemplateDataSource, setPublicTemplateDataSource] = useState([])

    const listPublicTemplateOnClick = () => {
        ListK8SPublicTemplate({

        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setPublicTemplateDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            name: dd.name,
                            describe: dd.describe,
                            createTime: dd.createTime,
                        })
                    }
                    setPublicTemplateDataSource(tmp)
                    return
                }
                setPublicTemplateDataSource([])
                message.error(`列出公共模版失败失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setPublicTemplateDataSource([])
                message.error(`列出公共模版失败失败 ${err}`)
            }
        )
    }

    const getPublicTemplateOnClick = (uuid) => {
        GetK8SPublicTemplate(uuid).
        then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    //data.createTime
                    //data.name
                    setTemplateContent(data.template)
                    setDefaultActiveKey('templateContent')
                    return
                }
                message.error(`获取公共模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取公共模版失败 ${err}`)
            }
        )
    }

    //
    const privateTemplateColumns = [
        {
            title: '模版名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '描述',
            dataIndex: 'describe',
            key: 'describe',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'} onClick={() => getPrivateTemplateOnClick(record.uuid)}>查看</Button>

                    <Popconfirm
                        title={'是否要删除该私有模版'}
                        onConfirm={() => delPrivateTemplateOnClick(record.uuid)}
                    >
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>

                </>
            }
        }
    ]

    const [privateTemplateDataSource, setPrivateTemplateDataSource] = useState([])

    const [createPrivateTemplateOnOpen, setCreatePrivateTemplateOnOpen] = useState(false)
    const createPrivateTemplateOnClick = () => {
        setCreatePrivateTemplateOnOpen(true)
    }

    const [createPrivateTemplate, setCreatePrivateTemplate] = useState('')

    const aceEditorOnChange = (value) => {
        setCreatePrivateTemplate(value)
    }

    const createPrivateTemplateOnOk = () => {
        const createPrivateTemplateName = document.getElementById('createPrivateTemplateName').value
        const createPrivateTemplateDescribe = document.getElementById('createPrivateTemplateDescribe').value

        CreateK8SPrivateTemplate({
            orgUuid: OrgUuid,
            name: createPrivateTemplateName,
            template: createPrivateTemplate,
            describe: createPrivateTemplateDescribe,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    setCreatePrivateTemplateOnOpen(false)
                    listPrivateTemplateOnClick()
                    message.info(`创建私有模版成功`)
                    return
                }

                message.error(`创建私有模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`创建私有模版失败 ${err}`)
            }
        )
    }

    const createPrivateTemplateOnCancel = () => {
        setCreatePrivateTemplateOnOpen(false)
    }

    const listPrivateTemplateOnClick = () => {
        ListK8SPrivateTemplate({
            orgUuid: OrgUuid,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setPrivateTemplateDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            name: dd.name,
                            describe: dd.describe,
                            createTime: dd.createTime,
                        })
                    }
                    setPrivateTemplateDataSource(tmp)
                    return
                }
                setPrivateTemplateDataSource([])
                message.error(`列出私有模版失败失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setPrivateTemplateDataSource([])
                message.error(`列出私有模版失败失败 ${err}`)
            }
        )
    }

    const getPrivateTemplateOnClick = (uuid) => {
        GetK8SPrivateTemplate({
            uuid: uuid,
            orgUuid: OrgUuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    setTemplateContent(data.template)
                    setDefaultActiveKey('templateContent')
                    return
                }
                message.error(`获取私有模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取私有模版失败 ${err}`)
            }
        )
    }

    const delPrivateTemplateOnClick = (uuid) => {
        DelK8SPrivateTemplate({
            orgUuid: OrgUuid,
            uuid: uuid,
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除私有模版成功`)
                    listPrivateTemplateOnClick()
                    return
                }
                message.error(`删除私有模版失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除私有模版失败 ${err}`)
            }
        )
    }

    const [templateContent, setTemplateContent] = useState('')


    //
    useEffect(() => {
        listPublicTemplateOnClick()
    }, [''])

    return <>

        <Tabs

            defaultActiveKey={defaultActiveKey}
            activeKey={defaultActiveKey}
            onChange={tabsOnChange}
            items={[
                {
                    label: '公共模版',
                    key: 'publicTemplate',
                    children:
                        <>
                            <Table
                                columns={publicTemplateColumns}
                                dataSource={publicTemplateDataSource}
                                scroll={{ x: true, y: 400 }}
                            />
                        </>
                },
                {
                    label: '私有模版',
                    key: 'privateTemplate',
                    children:
                        <>
                            <Button type={'primary'} onClick={createPrivateTemplateOnClick}>添加</Button>
                            <Table
                                columns={privateTemplateColumns}
                                dataSource={privateTemplateDataSource}
                                scroll={{ x: true, y: 400 }}
                            />
                        </>
                },
                {
                    label: '模版内容',
                    key: 'templateContent',
                    disabled: true,
                    children:
                        <>
                            <AceEditor
                                mode={'yaml'}
                                theme="terminal"
                                showGutter={true}
                                highlightActiveLine={true}
                                value={templateContent}
                                width={"90%"}
                                readOnly={true}
                            />
                        </>
                },
                ]
            }
        />

        <Modal
            open={createPrivateTemplateOnOpen}
            title={"创建私有模版"}
            keyboard={true}
            maskClosable={false}
            width={'auto'}
            style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
            destroyOnClose={true}
            onOk={createPrivateTemplateOnOk}
            onCancel={createPrivateTemplateOnCancel}
        >

            <span>名字</span>
            <Input id={'createPrivateTemplateName'}/>
            <span>描述</span>
            <Input id={'createPrivateTemplateDescribe'}/>
            <span>模版内容</span>
            <AceEditor
                mode={'yaml'}
                theme="terminal"
                showGutter={true}
                highlightActiveLine={true}
                value={createPrivateTemplate}
                width={"100%"}
                onChange={aceEditorOnChange}
            />
        </Modal>
    </>
}