import {
    Del,
    Get,
    Post,
    ProductK8SClusterServiceApi,
    ProductK8SPrivateTemplateApi,
    ProductK8SPublicTemplateApi
} from '../common';


export function ListK8SPublicTemplate(props) {
    return Get({
        url: `${ProductK8SPublicTemplateApi}`,
    })
}

export function GetK8SPublicTemplate(uuid) {
    return Get({
        url: `${ProductK8SPublicTemplateApi}/${uuid}`,
    })
}

export function CreateK8SPrivateTemplate(props) {
    const params = {
        orgUuid: props.orgUuid,
        name: props.name,
        template: props.template,
        describe: props.describe,
    }
    return Post({
        url: `${ProductK8SPrivateTemplateApi}`,
        params: params,
    })
}

export function ListK8SPrivateTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
    })
    return Get({
        url: `${ProductK8SPrivateTemplateApi}?${params}`,
    })
}

export function GetK8SPrivateTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
    })
    return Get({
        url: `${ProductK8SPrivateTemplateApi}/${props.uuid}?${params}`,
    })
}



export function DelK8SPrivateTemplate(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        uuid: props.uuid,
    })
    return Del({
        url: `${ProductK8SPrivateTemplateApi}?${params}`,
    })
}