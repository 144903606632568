import {Button, Input, message, Modal, Popconfirm, Table} from 'antd';
import {useEffect, useState} from 'react';
import {AddContainerRegistry, DelContainerRegistry, ListContainerRegistry} from '../../apis/product/containerRegistry';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';

import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';

export default function ListContainerRegistryRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['product']} defaultSelectedKeys={['containerRegistry']}></LeftSider>
                    <IndexRightBody>
                        <ListContainerRegistryContent></ListContainerRegistryContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function ListContainerRegistryContent() {
    const [containerRegistryDataSource, setContainerRegistryDataSource] = useState([])
    const containerRegistryColumns = [
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: '地址',
            dataIndex: 'url',
            key: 'url'
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (
                    <>
                        <Popconfirm
                            title={"删除容器镜像仓库"}
                            onConfirm={() => delContainerRegistryHandler(record)}
                        >
                            <Button type={'primary'} danger={true}>删除</Button>
                        </Popconfirm>
                    </>
                );
            }
        }
    ]
    // 列出 ContainerRegistry
    const listContainerRegistryHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const containerRegistrys = data.containerRegistry
                    if (containerRegistrys === null) {
                        setContainerRegistryDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const containerRegistry of containerRegistrys) {
                        tmp.push(
                            {
                                key: containerRegistry.uuid,
                                username: containerRegistry.username,
                                url: containerRegistry.url,
                                email: containerRegistry.email,
                                createTime: containerRegistry.createTime
                            }
                        )
                    }
                    setContainerRegistryDataSource(tmp)
                    return
                }
                message.error(`列出容器镜像仓库失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出容器镜像仓库失败`)
            }
        )
    }

    useEffect(() => {
        listContainerRegistryHandler()
    }, [])
    // 添加 ContainerRegistry
    const addContainerRegistryHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }

        const url = document.getElementById('url').value
        const username = document.getElementById('username').value
        const password = document.getElementById('password').value
        const email = document.getElementById('email').value

        AddContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            url: url,
            username: username,
            password: password,
            email: email
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`添加ContainerRegistry成功`)
                    listContainerRegistryHandler()
                    setCreateContainerRegistryOpenModal(false)
                    return
                }
                message.error(`添加ContainerRegistry失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`添加ContainerRegistry失败`)
            }
        )
    }
    //
    const delContainerRegistryHandler = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        DelContainerRegistry({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            containerRegistryUuid: record.key
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除ContainerRegistry成功`)
                    listContainerRegistryHandler()
                    return
                }
                message.error(`删除ContainerRegistry失败, ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除ContainerRegistry失败`)
            }
        )
    }

    //
    const [createContainerRegistryOpenModal, setCreateContainerRegistryOpenModal] = useState(false)
    const createContainerRegistryOnClick = () => {
        setCreateContainerRegistryOpenModal(true)
    }
    const createContainerRegistryOnCancel = () => {
        setCreateContainerRegistryOpenModal(false)
    }
    const createContainerRegistryOnOk = () => {
        addContainerRegistryHandler()
    }

    return (
        <>
            <pre style={{color: 'red'}}>dockerhub:  <a target={'_blank'} href={'https://hub.docker.com/'}>https://hub.docker.com/</a></pre>

            <pre style={{color: 'red'}}>阿里云:  <a target={'_blank'} href={'https://cr.console.aliyun.com/'}>https://cr.console.aliyun.com/</a></pre>

            <pre style={{color: 'red'}}>RedHat:  <a target={'_blank'} href={'https://quay.io/'}>https://quay.io/</a></pre>


            <Button type={'primary'} onClick={createContainerRegistryOnClick}>添加容器镜像仓库</Button>
            <Table columns={containerRegistryColumns} dataSource={containerRegistryDataSource}/>
            <Modal
                maskClosable={false}
                title={"添加容器镜像仓库"}
                open={createContainerRegistryOpenModal}
                onCancel={createContainerRegistryOnCancel}
                onOk={createContainerRegistryOnOk}
                destroyOnClose={true}
            >
                <span>Url</span><Input id={'url'} required={true} />
                <span>用户名</span><Input id={'username'}/>
                <span>密码</span><Input.Password id={'password'}/>
                <span>Email</span><Input id={'email'}/>
            </Modal>
        </>
    );
}