import {Del, Get, Post, ProductWebServiceResourceApi} from '../common';

export function AddWebServiceResource(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        protocol: parseInt(props.protocol),
        serverName: `${props.serverName !== undefined ? props.serverName : ""}`,
        ipPool: props.ipPool,
        healthCheckUri: `${props.healthCheckUri !== undefined ? props.healthCheckUri : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: ProductWebServiceResourceApi,
        params: params
    })
}


export function ListWebServiceResource(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
    })
    return Get({
        url: `${ProductWebServiceResourceApi}?${params}`,
    })
}


export function DelWebServiceResource(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        uuid: props.uuid,
    })
    return Del({
        url: `${ProductWebServiceResourceApi}?${params}`,
    })
}

