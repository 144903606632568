import {Button, ConfigProvider, Input, Layout, message, Modal, Popconfirm, Select, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {CreateMonitorReceiver, DelMonitorReceiver, ListMonitorReceiver} from '../../apis/monitor/monitorReceiver';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {GetMessageGroupOptions} from '../message/messageGroup';
import {ConvertTimestamp} from '../common/date';


export function MonitorReceiverRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['monitor']} defaultSelectedKeys={['monitorReceiver']}></LeftSider>
                    <IndexRightBody>
                        <MonitorReceiverContent></MonitorReceiverContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function MonitorReceiverContent() {
    const monitorReceiverColumns = [
        {
            title: '接收者',
            dataIndex: 'receiver',
            key: 'receiver'
        },
        {
            title: '消息组uuid',
            dataIndex: 'messageGroupUuid',
            key: 'messageGroupUuid'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Popconfirm
                            title={'是否要删除该消息组'}
                            onConfirm={() => delMonitorReceiverOnClick(record.uuid)}
                        >
                            <Button type={'primary'} danger={true}>删除</Button>
                        </Popconfirm>
                    </>
                );
            }
        }
    ]

    const [monitorReceiverDataSource, setMonitorReceiverDataSource] = useState([])

    //
    const [createMonitorReceiverModalOpen, setCreateMonitorReceiverModalOpen] = useState(false)
    const [messageGroupDefaultValue, setMessageGroupDefaultValue] = useState('')
    //const [messageGroupOptions, setMessageGroupOptions] = useState([])
    const messageGroupOptions = GetMessageGroupOptions()

    const messageGroupOptionsSelectOnHandler = (value) => {
        setMessageGroupDefaultValue(value)
    }

    const createMonitorReceiverModalOnCancel = () => {
        setCreateMonitorReceiverModalOpen(false)
    }

    const createMonitorReceiverOnClick = () => {
        setCreateMonitorReceiverModalOpen(true)
    }
    const createMonitorReceiverModalOnOk = () => {

        const receiver = document.getElementById('receiver').value
        if (receiver === '') {
            message.error(`receiver 值不能为空`)
            return
        }

        if (messageGroupDefaultValue === '') {
            message.error(`消息组不能为空`)
            return;
        }

        CreateMonitorReceiver({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            receiver: receiver,
            messageGroupUuid: messageGroupDefaultValue
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    listMonitorReceiver()
                    setCreateMonitorReceiverModalOpen(false)
                    message.info(`创建监控接收者成功`)
                    return
                }
                message.error(`创建监控接收者失败 ${errMsg}`)

            }
        ).catch(
            err => {
                message.error(`创建监控接收者失败 ${err}`)
            }
        )
    }

    //
    const listMonitorReceiver = () => {
        ListMonitorReceiver({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === null) {
                        setMonitorReceiverDataSource([])
                        return;
                    }

                    let tmp = []
                    for (const dd of d) {
                        tmp.push({
                            key: dd.uuid,
                            uuid: dd.uuid,
                            orgUuid: dd.orgUuid,
                            productUuid: dd.productUuid,
                            receiver: dd.receiver,
                            messageGroupUuid: dd.messageGroupUuid,
                            createTime: dd.createTime,
                        })
                    }
                    setMonitorReceiverDataSource(tmp)
                    return
                }

                setMonitorReceiverDataSource([])
                message.error(`列出监控接收者失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setMonitorReceiverDataSource([])
                message.error(`列出监控接收者失败 ${err}`)
            }
        )
    }

    //
    const delMonitorReceiverOnClick = (receiverUuid) => {
        DelMonitorReceiver({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            receiverUuid: receiverUuid
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`删除监控接收者成功`)
                    listMonitorReceiver()
                    return
                }
                message.error(`删除监控接收者失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`删除监控接收者失败 ${err}`)
            }
        )
    }

    useEffect(() => {
        listMonitorReceiver()
    }, [])

    return (<>

        <Button onClick={createMonitorReceiverOnClick} type={'primary'}>创建监控接收者</Button>
        <Button onClick={listMonitorReceiver} type={'primary'}>刷新</Button>
        <Table columns={monitorReceiverColumns} dataSource={monitorReceiverDataSource}/>

        <Modal
            title={'创建监控接收者'}
            open={createMonitorReceiverModalOpen}
            onOk={createMonitorReceiverModalOnOk}
            onCancel={createMonitorReceiverModalOnCancel}
            destroyOnClose={true}
        >
            <span>接收者<b>(需要完全匹配告警中接收者值)</b></span><Input id={'receiver'}/>
            <span>消息组</span>
            <br/>

            <Select
                style={{
                    width: '100%',
            }}
                value={messageGroupDefaultValue}
                options={messageGroupOptions}
                onSelect={messageGroupOptionsSelectOnHandler}
            />

        </Modal>
    </>)
}