import {LoginPageGetHeartbeat} from '../../apis/user/login';
import {GetUserRegisterVerifyCode, UserRegister} from '../../apis/user/user'
import {LoginPageCarousel} from './login';
import {Button, Input, Layout, message, Row, Space} from 'antd';
import {InfoCircleOutlined, MobileOutlined, UserOutlined, MailOutlined} from '@ant-design/icons';
import React from 'react';
import md5 from 'js-md5';

const {Sider, Content} = Layout;

export function UserRegisterRoute() {

    LoginPageGetHeartbeat()

    return (
        <>
            <Content width={'180'} style={{
                position: 'fixed',
                //overflow: 'auto',
                height: '100vh',
                left: 0,
                backgroundColor: 'gray',
                right: 400
            }}>
                <LoginPageCarousel></LoginPageCarousel>
            </Content>
            <RegisterPageSiderContent></RegisterPageSiderContent>
        </>
    );
}


function RegisterPageSiderContent() {
    // 注册用户
    const registerUserOnClick = () => {
        const username = document.getElementById('username').value
        const nickname = document.getElementById('nickname').value
        const phoneRegion = document.getElementById('phoneRegion').value // number
        const verifyCode = document.getElementById('registerVerifyCode').value
        const phone = document.getElementById('phone').value
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value
        //
        if (verifyCode === '' || verifyCode === undefined) {
            message.error('请输入验证码')
            return
        }
        //
        if (password === '' || password === undefined) {
            message.error('请输入密码')
            return
        }
        const passwordMD5 = md5(password)
        //
        UserRegister({
            username: username,
            nickname: nickname,
            password: passwordMD5,
            phoneRegion: phoneRegion,
            phone: phone,
            verifyCode: verifyCode,
            email: `${email !== true ? email : ''}`
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    const d = data.data
                    if (d === true) {
                        message.info(`注册用户成功`)
                    } else {
                        message.error(`注册用户失败`)
                    }
                    return
                }
                message.error(`注册用户失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`注册用户失败 ${err}`)
            }
        )
    }

    // 获取注册短信验证码
    const getMessageRegisterVerifyCodeOnClick = () => {
        const phoneNumber = document.getElementById('phone').value;
        if (phoneNumber === undefined || phoneNumber === "") {
            message.error(`手机号码不能为空`)
            return
        }
        GetUserRegisterVerifyCode({
            phoneNumber: phoneNumber,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {

                    const d = data.data
                    if (d === true) {
                        message.info(`获取验证码成功`)
                    } else {
                        message.error(`获取验证码失败`)
                    }
                    return

                }
                message.error(`获取验证码失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`获取验证码失败 ${err}`)
            }
        )
    }

    return (
        <Sider style={{
            position: 'fixed',
            overflow: 'auto',
            height: '100vh',
            //width: '30vh',
            //left: 180,
            right: 100,
            marginTop: 120
            //top: 30,
            //backgroundColor: "black",
        }}>
            <Row justify={'center'}>
                <Input
                    placeholder={'名字'}
                    prefix={<UserOutlined/>}
                    id={'username'}
                />
            </Row>
            <Row justify={'center'}>
                <Input
                    placeholder={'别名'}
                    prefix={<UserOutlined/>}
                    id={'nickname'}
                />
            </Row>
            <Row justify={'center'}>
                <Input
                    placeholder={'手机号码区域'}
                    prefix={<MobileOutlined/>}
                    id={'phoneRegion'}
                    defaultValue={'86'}
                />
            </Row>
            <Row justify={'center'}>
                <Space>
                    <Input
                        placeholder={'手机号码'}
                        prefix={<MobileOutlined/>}
                        id={'phone'}
                    />

                </Space>
            </Row>
            <Row justify={'center'}>
                <Space>
                    <Input
                        placeholder={'注册验证码'}
                        prefix={<MobileOutlined/>}
                        id={'registerVerifyCode'}
                    />

                    <Button type={'primary'} onClick={getMessageRegisterVerifyCodeOnClick}>验证码</Button>
                </Space>
            </Row>
            <Row justify={'center'}>
                <Input
                    placeholder={'邮箱'}
                    prefix={<MailOutlined/>}
                    id={'email'}
                />
            </Row>
            <Row justify={'center'}>
                <Input.Password
                    placeholder={'密码'}
                    prefix={<InfoCircleOutlined/>}
                    id={'password'}
                />
            </Row>
            <Row justify={'center'}>
                <Button onClick={registerUserOnClick}>注册</Button>
                <Button href={'/login'}>密码登录</Button>
                <Button href={'/verify/login'}>验证码登录</Button>
            </Row>
        </Sider>);
}
