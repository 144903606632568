import {Button, DatePicker, Select, Space, Table, Modal, Input} from 'antd';
import {useEffect, useState} from 'react';
import {AckAlarm, GetAlarmsHis} from '../../apis/monitor/alarm';
import {message} from 'antd'
import dayjs from 'dayjs';
import {ConvertTimestamp, RangePresets} from '../common/date';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider, Layout} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React from 'react';
import {ProductEnvState} from '../../apis/common';

const {RangePicker} = DatePicker;
//const dateFormat = 'YYYY/MM/DD';

export function AlarmsRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['monitor']} defaultSelectedKeys={['alarms']}></LeftSider>
                    <IndexRightBody>
                        <AlarmsContent></AlarmsContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function AlarmsContent() {
    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)

    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    //
    const alarmColumns = [
        {
            title: '告警名称',
            dataIndex: 'alertName',
            key: 'alertName',
        },
        {
            title: '实例',
            dataIndex: 'instance',
            key: 'instance',
            width: "10%"
        },
        {
            title: '任务',
            dataIndex: 'job',
            key: 'job',
            width: "10%"
        },
        {
            title: '等级',
            dataIndex: 'level',
            key: 'level',
            width: "10%",
            sorter: {
                compare: (a, b) => a.level - b.level
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: "10%",
            render: function (text) {
                if (text === 'firing') {
                    return '告警中'
                } else {
                    return '已恢复'
                }
            }
        },
        {
            title: '告警时间',
            dataIndex: 'startAt',
            key: 'startAt',
            sorter: {
                compare: (a, b) => a.startAt - b.startAt
            },
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '是否确认',
            dataIndex: 'ack',
            key: 'ack',
            width: "10%",
            render: function (text) {
                if (text === 1) {
                    return '确认'
                } else {
                    return '未确认'
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: function (_, record) {
                return (<>

                    {
                        record.status === 'firing' ?
                            <Button
                                type={'primary'}
                                onClick={() => ackAlarmModalOnClock(record.fingerprint)}
                                danger={true}
                            >确认</Button> : ""
                    }



                    <Button
                        type={'primary'}
                        onClick={getAlarmDetailOnClick}
                    >详情</Button>
                </>);
            }
        }
    ]
    //
    //const [searchStartTimestamp, setSearchStartTimestamp] = useState(dayjs().add(-90, 'd').toDate().getTime())
    //const [searchEndTimestamp, setSearchEndTimestamp] = useState(dayjs().toDate().getTime())
    const [alarmStatus, setAlarmStatus] = useState('firing')

    const rangePresets = RangePresets

    const [defaultStartTime, setDefaultStartTime] = useState(dayjs().add(-90, 'd'))
    const [defaultEndTime, setDefaultEndTime] = useState(dayjs())


    const datePickerOnChangeHandler = (date, dateString) => {
        //setSearchStartTimestamp(date[0].toDate().getTime())
        //setSearchEndTimestamp(date[1].toDate().getTime())

        setDefaultStartTime(date[0])
        setDefaultEndTime(date[1])
    }

    //
    const alarmStatusOnChangeHandler = (value) => {
        setAlarmStatus(value)
    }

    // 确认告警 (ack)
    const ackAlarmModalOnClock = (fingerprint) => {
        Modal.confirm({
            title: `确认告警`,
            content: (<>
                <span>确认消息</span>
                <Input id={'ackMessage'}/>
            </>),
            onOk: () => ackAlarmModalOnOk(fingerprint),
        })
    }
    const ackAlarmModalOnOk = (fingerprint) => {
        const ackMessage = document.getElementById('ackMessage').value
        if (fingerprint === "" || ackMessage === "") {
            message.error(`确认告警消息失败 请检查请求参数`)
            return;
        }
        AckAlarm({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            fingerprint: fingerprint,
            ackMessage: ackMessage,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    message.info(`确认告警成功`)
                    getAlarmsHisHandler({
                        startSearchTime: Math.floor(dayjs().add(-365, 'd').toDate().getTime() / 1000),
                        endSearchTime: Math.floor(dayjs().toDate().getTime() / 1000),
                        receiver: "",
                        fingerprint: "",
                        status: alarmStatus,
                    })
                    return
                }
                message.error(`确认告警失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`确认告警失败`)
                console.log(`确认告警失败 ${err}`)
            }
        )
    }
    // 获取告警详情
    const getAlarmDetailOnClick = () => {

        Modal.confirm({
            title: "查看告警详情",
            content: <>

            </>
        })
    }

    // 获取告警历史
    const [alarmDataSource, setAlarmDataSource] = useState([])
    const getAlarmsHisHandler = (props) => {
        if (! VerifyOrgUuidAndOrgProductUuid() ) {
            return;
        }
        if (env === "") {
            return;
        }
        GetAlarmsHis({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            monitorToken: "",
            receiver: props.receiver,
            fingerprint: props.fingerprint,
            status: alarmStatus,
            startSearchTime: props.startSearchTime,
            endSearchTime: props.endSearchTime,
        }).then(
            data => {
                let errCode = data.errCode
                let errMsg = data.errMsg
                if (errCode === 0) {
                    let alerts = data.alert
                    if (alerts === null) {
                        setAlarmDataSource([])
                        return;
                        
                        
                    }
                    let tempAlarms = []
                    for (let alert of alerts) {
                        tempAlarms.push({
                            alertName: alert.alertName,
                            instance: alert.instance,
                            job: alert.job,
                            level: alert.level,
                            status: alert.status,
                            startAt: alert.startAt,
                            fingerprint: alert.fingerprint,
                            ack: alert.ack,
                            ackUserUuid: alert.ackUserUuid,
                        })
                    }
                    setAlarmDataSource(tempAlarms)
                    return;
                }
                message.error(`列出告警列表失败 ${errMsg}`)
                return;
            }
        ).catch(
            err => {
                message.error(`列出告警列表失败`)
                console.log(`列出告警列表失败 ${err}`)
            }
        )
    }
    const getAlarmsHisOnClick = () => {
        const searchStartTimestamp = defaultStartTime.toDate().getTime()
        const searchEndTimestamp = defaultEndTime.toDate().getTime()

        if (searchStartTimestamp <= 0 || searchEndTimestamp <= 0 || searchEndTimestamp < searchStartTimestamp) {
            message.error(`选择查询开始时间 [${searchStartTimestamp}] 和结束时间 [ ${searchEndTimestamp} ] 错误`)
            return;
        }
        getAlarmsHisHandler({
            startSearchTime: Math.floor(searchStartTimestamp / 1000),
            endSearchTime: Math.floor(searchEndTimestamp / 1000),
            receiver: "",
            fingerprint: "",
            status: alarmStatus,
        })
    }

    useEffect(()=> {
        getAlarmsHisHandler({
            startSearchTime: Math.floor(dayjs().add(-365, 'd').toDate().getTime() / 1000),
            endSearchTime: Math.floor(dayjs().toDate().getTime() / 1000),
            receiver: "",
            fingerprint: "",
            status: alarmStatus,
        })
    }, [env])



    return (
        <>
            <Space size={6}>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>

                <RangePicker
                    placeholder={['请选择开始时间', '请选择结束时间']}
                    presets={rangePresets}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={[defaultStartTime, defaultEndTime]}
                    onChange={datePickerOnChangeHandler}
                />

                <Select
                    onChange={alarmStatusOnChangeHandler}
                    defaultValue={alarmStatus}
                    options={[
                        {value: 'firing', label: '告警中'},
                        {value: 'resolved', label: '已恢复'}
                    ]}/>

                <Button
                    onClick={getAlarmsHisOnClick}
                    type={'primary'}
                >查询</Button>
            </Space>
            <Table columns={alarmColumns} dataSource={alarmDataSource} rowKey={record => record.fingerprint}>

            </Table>
        </>
    );
}