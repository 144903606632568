import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, message, Popconfirm, Select, Space, Table, Modal} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';
import {DelDeployment, ListDeployment, UpdateDeploymentReplicaSet} from '../../apis/kubernetes/deployment';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';
import {GetKubernetesEventModal, KubernetesEventColumns} from './common';


export function K8SDeploymentRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['deployment']}></LeftSider>
                    <IndexRightBody>
                        <DeploymentContent></DeploymentContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}


export function DeploymentContent() {
    //
    const [kubernetesEventDataSource, setKubernetesEventDataSource] = useState([])
    const [kubernetesEventOpenModal, setKubernetesEventOpenModal] = useState(false)

    // deployment table
    const k8sDeploymentColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: '副本信息',
            dataIndex: 'replicas',
            key: 'replicas',
            render: (_, record) => {
                return <>
                    <pre>replicas: {record.replicas}</pre>
                    <pre>availableReplicas: {record.availableReplicas}</pre>
                    <pre>updatedReplicas: {record.updatedReplicas}</pre>
                    <pre>readyReplicas: {record.readyReplicas}</pre>
                </>
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>查看</Button>
                    <br/>
                    <Button type={'primary'}>更新</Button>
                    <br/>
                    <Popconfirm title={'删除Deployment'} onConfirm={() => deleteDeploymentOnConfirmHandler()}>
                        <Button type={'primary'} danger={true}>删除</Button>
                    </Popconfirm>
                    <br/>
                    <Button type={'primary'} onClick={() => GetKubernetesEventModal(setKubernetesEventOpenModal, setKubernetesEventDataSource, {
                        orgUuid: OrgUuid,
                        productUuid: OrgProductUuid,
                        k8sClusterUuid: kubernetesCluster,
                        namespace: record.namespace,
                        type: "",
                        createTime: "",
                        regardingKind: "deployment",
                        regardingName: record.name,
                        regardingUid: "",
                    })}
                    >事件</Button>
                </>
            }
        }
    ]
    const [k8sDeploymentDataSource, setK8sDeploymentDataSource] = useState([])

    // env
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    // cluster
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    // namespace
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === '') {
            return
        }
        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])

    //
    const getKubernetesDeploymentOnClick = () => {
        ListDeployment({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const deployments = data.deployment
                    if (deployments === null) {
                        setK8sDeploymentDataSource([])
                        return;
                    }
                    let tmp = []
                    let count = 1
                    for (const d of deployments) {
                        tmp.push({
                            key: count,
                            name: d.name,
                            namespace: d.namespace,
                            replicas: d.replicas,
                            availableReplicas: d.availableReplicas,
                            updatedReplicas: d.updatedReplicas,
                            readyReplicas: d.readyReplicas
                        })
                        count += 1
                    }
                    setK8sDeploymentDataSource(tmp)
                    return
                }
                setK8sDeploymentDataSource([])
                message.error(`列出 Deployment 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setK8sDeploymentDataSource([])
                message.error(`列出 Deployment 失败`)
                console.log(`列出 Deployment 失败 ${err}`)
            }
        )
    }
    //
    const deleteDeploymentOnConfirmHandler = () => {
        DelDeployment({}).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }

    //
    const updateDeploymentReplicaset = () => {
        UpdateDeploymentReplicaSet({}).then(
            data => {

            }
        ).catch(
            err => {

            }
        )
    }

    //
    const kubernetesEventModalOnOk = () => {
        setKubernetesEventOpenModal(false)
    }
    const kubernetesEventModalOnCancel = () => {
        setKubernetesEventOpenModal(false)
    }

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={getKubernetesDeploymentOnClick}>查询</Button>
            </Space>

            <Table columns={k8sDeploymentColumns} dataSource={k8sDeploymentDataSource}/>

            <Modal
                open={kubernetesEventOpenModal}
                onOk={kubernetesEventModalOnOk}
                onCancel={kubernetesEventModalOnCancel}
                title={'K8S集群Events'}
                width={'100%'}
                destroyOnClose={true}
            >
                <div style={{height: '400px', overflow: 'auto'}}>
                    <Table columns={KubernetesEventColumns} dataSource={kubernetesEventDataSource}/>
                </div>
            </Modal>
        </>
    );
}