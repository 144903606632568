import {Post, Get, Del, ProductK8SClusterServiceApi} from '../common';

export function CreateServiceCluster(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterServiceApi}`,
        params: params,
    })
}

export function CreateServiceNodePort(props) {
    const params = {

    }
    return Post({
        url: `${ProductK8SClusterServiceApi}`,
        params: params,
    })
}

export function ListService(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
    })
    return Get({
        url: `${ProductK8SClusterServiceApi}?${params}`,
    })
}

export function GetService(props) {
    const params = new URLSearchParams({

    })
    return Get({
        url: `${ProductK8SClusterServiceApi}?${params}`,
    })
}

export function DelService(props) {
    const params = new URLSearchParams({

    })
    return Del({
        url: `${ProductK8SClusterServiceApi}?${params}`,
    })
}