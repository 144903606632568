import {Button, ConfigProvider, Layout, message, Modal, Select, Space, Table} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import {useState} from 'react';
import {
    GetCloudPlatformAccountOptions,
    GetCloudPlatformSubAccountAccessKeyOptions,
    GetCloudPlatformSubAccountOptions,
    GetOrgProductCloudPlatformOptions,
    GetCloudPlatformRegionOptions
} from './common';
import {GetRDSErrLog, GetRDSSlowLog, ListRDSInstance} from '../../apis/cloud/rds';
import {OrgProductUuid, OrgUuid, VerifyOrgUuidAndOrgProductUuid} from '../../apis/user/org';
import dayjs from 'dayjs';
import {ConvertTimestamp} from '../common/date';
import React from 'react';

export function RDSRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['cloud']} defaultSelectedKeys={['cloudRDS']}></LeftSider>
                    <IndexRightBody>
                        <RDSContent></RDSContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function RDSContent() {
    //
    const [cloudPlatformId, setCloudPlatformId] = useState()
    const orgProductCloudPlatformOptions = GetOrgProductCloudPlatformOptions(setCloudPlatformId)
    const cloudPlatformOnSelectHandler = (value) => {
        setCloudPlatformId(value)
    }
    //
    const [cloudPlatformAccountValue, setCloudPlatformAccountValue] = useState('')
    const cloudPlatformAccountOptions = GetCloudPlatformAccountOptions(cloudPlatformId, setCloudPlatformAccountValue)
    const cloudPlatformAccountOnSelectHandler = (value) => {
        setCloudPlatformAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountValue, setCloudPlatformSubAccountValue] = useState('')
    const cloudPlatformSubAccountOptions = GetCloudPlatformSubAccountOptions(cloudPlatformAccountValue, setCloudPlatformSubAccountValue)
    const cloudPlatformSubAccountOnSelectHandler = (value) => {
        setCloudPlatformSubAccountValue(value)
    }
    //
    const [cloudPlatformSubAccountAccessKeyValue, setCloudPlatformSubAccountAccessKeyValue] = useState('')
    const cloudPlatformSubAccountAccessKeyOptions = GetCloudPlatformSubAccountAccessKeyOptions(cloudPlatformSubAccountValue, setCloudPlatformSubAccountAccessKeyValue)
    const cloudPlatformSubAccountAccessKeyHandler = (value) => {
        setCloudPlatformSubAccountAccessKeyValue(value)
    }
    //
    const [cloudPlatformRegionValue, setCloudPlatformRegionValue] = useState('')

    const cloudPlatformRegionOptions = GetCloudPlatformRegionOptions(cloudPlatformId, setCloudPlatformRegionValue)
    const cloudPlatformRegionOptionsOnSelectHandler = (value) => {
        setCloudPlatformRegionValue(value)
    }
    //
    const [cloudRDSDataSource, setCloudRDSDataSource] = useState([])
    const rdsColumns = [
        {
            title: '实例名',
            dataIndex: 'instanceName',
            key: 'instanceName'
        },
        {
            title: '实例ID',
            dataIndex: 'instanceId',
            key: 'instanceId'
        },
        {
            title: '公用网络',
            dataIndex: 'publicNet',
            key: 'publicNet'
        },
        {
            title: 'Cpu',
            dataIndex: 'cpu',
            key: 'cpu'
        },
        {
            title: 'Memory(MB)',
            dataIndex: 'memory',
            key: 'memory'
        },
        {
            title: 'Qps',
            dataIndex: 'qps',
            key: 'qps'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: '自动续费',
            dataIndex: 'autoRenew',
            key: 'autoRenew'
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '过期时间',
            dataIndex: 'deadlineTime',
            key: 'deadlineTime',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return (
                    <>
                        <Button type={'primary'}
                                onClick={() => listCloudRdsSlowLogOnClickHandler(record)}>慢查日志(3天)</Button>
                        <Button type={'primary'}
                                onClick={() => listCloudRdsErrLogOnClickHandler(record)}>错误日志(半月)</Button>
                    </>
                );
            }
        }
    ]
    //
    const listCloudRdsOnClickHandler = () => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        ListRDSInstance({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            region: cloudPlatformRegionValue
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                const rds = data.rds
                if (errCode === 0) {
                    if (rds === null) {
                        setCloudRDSDataSource([])
                        return;
                    }
                    let tmp = []
                    for (const r of rds) {
                        tmp.push({
                            key: r.instanceId,
                            instanceId: r.instanceId,
                            instanceName: r.instanceName,
                            publicNet: r.publicNet,
                            cpu: r.cpu,
                            memory: r.memory,
                            qps: r.qps,
                            status: r.status,
                            autoRenew: r.autoRenew,
                            instanceType: r.instanceType,
                            createTime: r.createTime,
                            deadlineTime: r.deadlineTime,
                            cdbError: r.cdbError,
                            region: r.region
                        })
                    }
                    setCloudRDSDataSource(tmp)
                    return;
                }
                setCloudRDSDataSource([])
                message.error(`列出云数据库失败 ${errMsg}`)
            }
        ).catch(
            err => {
                setCloudRDSDataSource([])
                message.error(`列出云数据库失败`)
                console.log(`列出云数据库失败 ${err}`)
            }
        )
    }
    //
    const cloudRdsSlowLogColumns = [
        {
            title: 'timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            },
        },
        {
            title: 'userHost',
            dataIndex: 'userHost',
            key: 'userHost',
        },
        {
            title: 'userName',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'database',
            dataIndex: 'database',
            key: 'database',
        },
        {
            title: 'sqlQuery',
            dataIndex: 'sqlQuery',
            key: 'sqlQuery',
        },
        {
            title: 'queryTime',
            dataIndex: 'queryTime',
            key: 'queryTime',
        },
        {
            title: 'lockTime',
            dataIndex: 'lockTime',
            key: 'lockTime'
        },
        {
            title: 'scanRow',
            dataIndex: 'scanRow',
            key: 'scanRow'
        }
    ]
    const [openCloudRdsSlowLogModal, setOpenCloudRdsSlowLogModal] = useState(false)
    const [cloudRdsSlowLogDataSource, setCloudRdsSlowLogDataSource] = useState([])
    const cloudRdsSlowLogOnOk = () => {
        setOpenCloudRdsSlowLogModal(false)
    }
    const cloudRdsSlowLogOnCancel = () => {
        setOpenCloudRdsSlowLogModal(false)
    }
    const listCloudRdsSlowLogOnClickHandler = (record) => {
        GetRDSSlowLog({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            region: record.region,
            instanceId: record.instanceId,
            startTime: Math.floor(dayjs().add(-3, 'd').toDate().getTime() / 1000),
            endTime: Math.floor(dayjs().toDate().getTime() / 1000)
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const slowLogs = data.slowlogs
                    if (slowLogs === null) {
                        setCloudRdsSlowLogDataSource([])
                        setOpenCloudRdsSlowLogModal(true)
                        return
                    }
                    let tmp = []
                    let index = 1
                    for (const slowLog of slowLogs) {
                        tmp.push(
                            {
                                key: index,
                                timestamp: slowLog.timestamp,
                                userHost: slowLog.userHost,
                                userName: slowLog.userName,
                                database: slowLog.database,
                                sqlQuery: slowLog.sqlQuery,
                                queryTime: slowLog.queryTime,
                                lockTime: slowLog.lockTime,
                                scanRow: slowLog.scanRow
                            }
                        )
                        index += 1
                    }
                    setCloudRdsSlowLogDataSource(tmp)
                    setOpenCloudRdsSlowLogModal(true)
                    return
                }
                console.log(`列出云数据库慢查日志失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`列出云数据库慢查日志失败 ${err}`)
                message.error(`列出云数据库慢查日志失败`)
            }
        )
    }
    //
    const cloudRdsErrLogColumns = [
        {
            title: 'timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => {
                return ConvertTimestamp(text)
            }
        },
        {
            title: 'content',
            dataIndex: 'content',
            key: 'content'
        }
    ]
    const [openCloudRdsErrLogModal, setOpenCloudRdsErrLogModal] = useState(false)
    const [cloudRdsErrLogDataSource, setCloudRdsErrLogDataSource] = useState([])
    const cloudRdsErrLogOnOk = () => {
        setOpenCloudRdsErrLogModal(false)
    }
    const cloudRdsErrLogOnCancel = () => {
        setOpenCloudRdsErrLogModal(false)
    }
    const listCloudRdsErrLogOnClickHandler = (record) => {
        if (!VerifyOrgUuidAndOrgProductUuid()) {
            return
        }
        GetRDSErrLog({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            platformId: cloudPlatformId,
            accountUuid: cloudPlatformAccountValue,
            subAccountUuid: cloudPlatformSubAccountValue,
            subAccountAccessKeyUuid: cloudPlatformSubAccountAccessKeyValue,
            region: record.region,
            instanceId: record.instanceId,
            startTime: Math.floor(dayjs().add(-15, 'd').toDate().getTime() / 1000),
            endTime: Math.floor(dayjs().toDate().getTime() / 1000)
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const errlogs = data.errlogs
                    if (errlogs === null) {
                        setCloudRdsErrLogDataSource([])
                        setOpenCloudRdsErrLogModal(true)
                        return
                    }
                    let tmp = []
                    let index = 1
                    for (const errlog of errlogs) {
                        tmp.push(
                            {
                                key: index,
                                timestamp: errlog.timestamp,
                                content: errlog.content,
                            }
                        )
                        index += 1
                    }
                    setCloudRdsErrLogDataSource(tmp)
                    setOpenCloudRdsErrLogModal(true)
                    return
                }
                message.error(`列出云数据库错误日志失败 ${errMsg}`)
            }
        ).catch(
            err => {
                console.log(`列出云数据库错误日志失败 ${err}`)
                message.error(`列出云数据库错误日志失败`)
            }
        )
    }

    return (
        <>
            <span>云平台</span>
            <Select style={{width: 120}}
                //options={CloudPlatformOptions}
                    options={orgProductCloudPlatformOptions}
                    value={cloudPlatformId}
                    onSelect={cloudPlatformOnSelectHandler}/>
            <span>云平台账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformAccountOptions}
                    value={cloudPlatformAccountValue}
                    onSelect={cloudPlatformAccountOnSelectHandler}/>

            <span>子账号</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountOptions}
                    value={cloudPlatformSubAccountValue}
                    onSelect={cloudPlatformSubAccountOnSelectHandler}/>

            <span>AccessKey</span>
            <Select style={{width: 120}}
                    options={cloudPlatformSubAccountAccessKeyOptions}
                    value={cloudPlatformSubAccountAccessKeyValue}
                    onSelect={cloudPlatformSubAccountAccessKeyHandler}/>

            <span>Region</span>
            <Select style={{width: 160}}
                    options={cloudPlatformRegionOptions}
                    value={cloudPlatformRegionValue}
                    onSelect={cloudPlatformRegionOptionsOnSelectHandler}/>

            <Space size={4}>
                <Button type={'primary'} onClick={listCloudRdsOnClickHandler}>列出</Button>
            </Space>
            <Table columns={rdsColumns} dataSource={cloudRDSDataSource}/>


            <Modal
                title={'云数据库慢查日志列表'}
                open={openCloudRdsSlowLogModal}
                onOk={cloudRdsSlowLogOnOk}
                onCancel={cloudRdsSlowLogOnCancel}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
                width={'100%'}
                maskClosable={false}
            >
                <Table scroll={{ x: true }} columns={cloudRdsSlowLogColumns} dataSource={cloudRdsSlowLogDataSource}/>
            </Modal>


            <Modal
                title={'云数据库错误日志列表'}
                open={openCloudRdsErrLogModal}
                onOk={cloudRdsErrLogOnOk}
                onCancel={cloudRdsErrLogOnCancel}
                style={{maxHeight: '100%', maxWidth: '100%', height: '100%', width: 'auto'}}
            >
                <Table scroll={{ x: true }} columns={cloudRdsErrLogColumns} dataSource={cloudRdsErrLogDataSource}/>
            </Modal>


        </>
    );
}