import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, message, Select, Space, Table, Modal} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {
    GetKubernetesEventModal,
    KubernetesClusterNameSpaceSelectOptions,
    KubernetesClusterSelectOptions, KubernetesEventColumns
} from './common';
import {ListIngress} from '../../apis/kubernetes/ingress';
import {OrgProductUuid, OrgUuid} from '../../apis/user/org';

export function K8SIngressRoute() {

    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['ingress']}></LeftSider>
                    <IndexRightBody>
                        <IngressContent></IngressContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function IngressContent() {
    //
    const [kubernetesEventDataSource, setKubernetesEventDataSource] = useState([])
    const [kubernetesEventOpenModal, setKubernetesEventOpenModal] = useState(false)
    //
    const kubernetesEventModalOnOk = () => {
        setKubernetesEventOpenModal(false)
    }
    const kubernetesEventModalOnCancel = () => {
        setKubernetesEventOpenModal(false)
    }
    //
    const k8sIngressColumns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'namespace',
            dataIndex: 'namespace',
            key: 'namespace'
        },
        {
            title: 'service',
            dataIndex: 'service',
            key: 'service',
            render: (text) => {
                const s = Object.entries(text).map(([k, v]) => (
                    <>
                        <pre>host: {k}</pre>
                        <pre>tlsSecret: {v.tlsSecret}</pre>
                    </>
                ))
                return <>
                    {s}
                </>
            }
        },
        {
            title: '操作',
            dataIndex: 'operator',
            key: 'operator',
            render: (_, record) => {
                return <>
                    <Button type={'primary'}>更新</Button>
                    <br/>
                    <Button type={'primary'} danger={true}>删除</Button>
                    <br/>
                    <Button type={'primary'} onClick={() => GetKubernetesEventModal(setKubernetesEventOpenModal, setKubernetesEventDataSource, {
                        orgUuid: OrgUuid,
                        productUuid: OrgProductUuid,
                        k8sClusterUuid: kubernetesCluster,
                        namespace: record.namespace,
                        type: "",
                        createTime: "",
                        regardingKind: "ingress",
                        regardingName: record.name,
                        regardingUid: "",
                    })}
                    >事件</Button>
                </>
            }
        }
    ]
    const [k8sIngressDataSource, setK8sIngressDataSource] = useState([])

    // env
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    // cluster
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    // namespace
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === '') {
            return
        }
        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])


    //
    const getKubernetesIngressOnClick = () => {

        ListIngress({
            orgUuid: OrgUuid,
            productUuid: OrgProductUuid,
            productEnvUuid: env,
            k8sClusterUuid: kubernetesCluster,
            namespace: kubernetesClusterNameSpace
        }).then(
            data => {
                const errCode = data.errCode
                const errMsg = data.errMsg
                if (errCode === 0) {
                    const ingress = data.ingress
                    if (ingress === null) {
                        setK8sIngressDataSource([])
                        return;
                    }

                    let tmp = []
                    let count = 1
                    for (const i of ingress) {
                        tmp.push({
                            key: count,
                            name: i.name,
                            namespace: i.namespace,
                            service: i.service
                        })
                        count += 1
                    }
                    setK8sIngressDataSource(tmp)
                    return
                }
                setK8sIngressDataSource([])
                message.error(`列出 K8S 集群 Ingress 失败 ${errMsg}`)
            }
        ).catch(
            err => {
                message.error(`列出 K8S 集群 Ingress 失败`)
                console.log(`列出 K8S 集群 Ingress 失败  ${err}`)
                setK8sIngressDataSource([])
            }
        )
    }

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={getKubernetesIngressOnClick}>查询</Button>
            </Space>

            <Table columns={k8sIngressColumns} dataSource={k8sIngressDataSource}/>

            <Modal
                open={kubernetesEventOpenModal}
                onOk={kubernetesEventModalOnOk}
                onCancel={kubernetesEventModalOnCancel}
                title={'K8S集群Events'}
                width={"100%"}
                destroyOnClose={true}
            >
                <div style={{ height: '400px', overflow: 'auto' }}>
                    <Table columns={KubernetesEventColumns} dataSource={kubernetesEventDataSource}/>
                </div>
            </Modal>

        </>
    );
}