import {useEffect, useRef, useState} from 'react';
import {Terminal} from 'xterm';
import React from 'react';
import 'xterm/css/xterm.css';

function useTerminal() {
    const termRef = useRef(null);
    const [command, setCommand] = useState(''); // 当前输入命令
    const [term, setTerm] = useState(null); // 终端实例

    useEffect(() => {
        if (termRef.current) {
            const newTerm = new Terminal();
            newTerm.open(termRef.current);

            // 设置一些常见配置，例如字体大小、背景色等
            // ...

            newTerm.onData((data) => {
                setCommand((prev) => prev + data);
                newTerm.write(data);
            });

            setTerm(newTerm);
        }

        return () => {
            if (term) {
                term.dispose();
            }
        };
    }, []);

    return [termRef, command, setCommand];
}

export function WebTerminal() {
    const [termRef, command, setCommand] = useTerminal();
    const term = new Terminal();
    function handleKeyDown(e) {
        if (e.keyCode === 13) { // 按下回车键
            const cmd = command.trim(); // 去除首尾空格
            if (cmd !== '') {
                term.write('\r\n');
                // 处理命令并发送到终端实例中
                // ...
                setCommand('');
            }
        }
    }

    return (
        <div>
            <div ref={termRef}></div>
            <input type="text" value={command} onChange={(e) => setCommand(e.target.value)} onKeyDown={handleKeyDown} />
        </div>
    );
}