import {
    Get,
    Post,
    Del,
    OrganizationProductApi,
    OrganizationProductEnvApi
} from '../common';

// 获取组织产品列表API
export function ListOrganizationProduct(props) {
    const params = new URLSearchParams({ orgUuid: props.orgUuid });
    const getOrganizationProductUrl = `${OrganizationProductApi}?${params}`

    return Get({
        url: getOrganizationProductUrl,
    })
}


// 创建组织产品API
export function CreateOrganizationProduct(props) {
    const params = {
        orgUuid: props.orgUuid,
        productName: props.productName,
        logo: `${props.logo !== undefined ? props.logo : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    };
    const getOrganizationProductUrl = `${OrganizationProductApi}?${params}`
    return Post({
            url: getOrganizationProductUrl,
            params: params,
        })
}

// 列出组织产品环境
export function ListOrganizationProductEnv(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    });
    const getOrganizationProductEnvUrl = `${OrganizationProductEnvApi}?${params}`
    return Get({
        url: getOrganizationProductEnvUrl,
        params: params,
    })
}

// 创建组织产品API
export function CreateOrganizationProductEnv(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        envName: `${props.envName !== undefined ? props.envName : ""}`,
        priority: props.priority,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    };
    const createOrganizationProductEnvUrl = `${OrganizationProductEnvApi}`
    return Post({
        url: createOrganizationProductEnvUrl,
        params: params,
    })
}

export function DelOrganizationProductEnv(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
    });
    const getOrganizationProductEnvUrl = `${OrganizationProductEnvApi}?${params}`
    return Del({
        url: getOrganizationProductEnvUrl,
        params: params,
    })
}