import {Del, Get, MessageGroup, MessageGroupMember, Post} from '../common';

export function CreateMessageGroup(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        groupName: props.groupName
    }
    return Post({
        url: MessageGroup,
        params: params
    })
}

export function ListMessageGroup(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid
    })
    return Get({
        url: `${MessageGroup}?${params}`
    })
}


export function DelMessageGroup(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        groupUuid: props.groupUuid
    })
    return Del({
        url: `${MessageGroup}?${params}`
    })
}


export function AddMessageGroupMember(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        groupUuid: props.groupUuid,
        channel: props.channel,
        url: props.url,
        keyword: props.keyword
    }
    return Post({
        url: MessageGroupMember,
        params: params
    })
}

export function GetMessageGroupMember(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        groupUuid: props.groupUuid
    })
    return Get({
        url: `${MessageGroupMember}?${params}`
    })
}


export function DelMessageGroupMember(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        groupUuid: props.groupUuid,
        memberUuid: props.memberUuid,
    })
    return Del({
        url: `${MessageGroupMember}?${params}`
    })
}