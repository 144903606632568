import {Get, HookKubernetesEventApi} from '../common';

export function GetKubernetesEvent(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: `${props.productEnvUuid !== undefined ? props.productEnvUuid : ""}`,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        type: props.type,
        createTime: props.createTime,
        regardingKind: props.regardingKind,
        regardingName: props.regardingName,
        regardingUid: props.regardingUid,
    })
    return Get({
        url: `${HookKubernetesEventApi}?${params}`,
    })
}