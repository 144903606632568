import {
    Del,
    Get,
    Post,
    ProductServiceUrlApi,
    ProductServiceUrlAuthKeyApi,
    ProductServiceUrlAuthUrlApi
} from '../common';

export function AddServiceUrl(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        name: `${props.name !== undefined ? props.name : ""}`,
        url: `${props.url !== undefined ? props.url : ""}`,
        describe: `${props.describe !== undefined ? props.describe : ""}`,
    }
    return Post({
        url: ProductServiceUrlApi,
        params: params
    })
}

export function ListServiceUrl(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: `${props.productEnvUuid !== undefined ? props.productEnvUuid : ""}`,
    })
    return Get({
        url: `${ProductServiceUrlApi}?${params}`
    })
}

export function DelServiceUrl(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        serviceUrlUuid: `${props.serviceUrlUuid !== undefined ? props.serviceUrlUuid : ""}`,
    })
    return Del({
        url: `${ProductServiceUrlApi}?${params}`
    })
}



export function GenerateServiceUrlAuthKey(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        name: `${props.name !== undefined ? props.name : ""}`,
    }
    return Post({
        url: ProductServiceUrlAuthKeyApi,
        params: params
    })
}

export function ListServiceUrlAuthKey(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: `${props.productEnvUuid !== undefined ? props.productEnvUuid : ""}`,
    })
    return Get({
        url: `${ProductServiceUrlAuthKeyApi}?${params}`
    })
}

export function DelServiceUrlAuthKey(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        uuid: `${props.uuid !== undefined ? props.uuid : ""}`,
    })
    return Del({
        url: `${ProductServiceUrlAuthKeyApi}?${params}`
    })
}

export function GenerateServiceUrlAuthUrl(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        serviceUrlUuid: `${props.serviceUrlUuid !== undefined ? props.serviceUrlUuid : ""}`,
        authKeyUuid: `${props.authKeyUuid !== undefined ? props.authKeyUuid : ""}`,
    })
    return Get({
        url: `${ProductServiceUrlAuthUrlApi}?${params}`
    })
}
