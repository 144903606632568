import React from 'react';

export function About() {
    return <>
        <p>关于我们</p>
        <p></p>
        <script async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7808012575924886"
                crossOrigin="anonymous"></script>
    </>
}

