import zhCN from 'antd/locale/zh_CN';
import {Button, ConfigProvider, Layout, Modal, Select, Space, Table} from 'antd';
import {WaterMark} from '@ant-design/pro-components';
import {HeaderSider, IndexRightBody, LeftSider} from '../../components/navbar/navbar';
import React, {useEffect, useState} from 'react';
import {ProductEnvState} from '../../apis/common';
import {KubernetesClusterNameSpaceSelectOptions, KubernetesClusterSelectOptions} from './common';

export function K8SPodRoute() {
    return <ConfigProvider locale={zhCN}>
        <WaterMark content={'StartOps'}>

            <Layout>
                {/* 头部菜单 - 页面上部分内容 */}
                <HeaderSider></HeaderSider>
                {/* 页面下部分内容 */}
                <Layout className="site-layout" style={{
                    marginLeft: 200,
                    marginTop: 60
                }}>
                    <LeftSider defaultOpenKeys={['kubernetes']} defaultSelectedKeys={['pod']}></LeftSider>
                    <IndexRightBody>
                        <PodContent></PodContent>
                    </IndexRightBody>
                </Layout>
            </Layout>

        </WaterMark>
    </ConfigProvider>
}

export function PodContent() {
    //
    const k8sPodColumns = [
        {

        }
    ]
    const [k8sPodDataSource, setK8sPodDataSource] = useState([])

    //
    const [env, setEnv] = useState('')
    const productEnvs = ProductEnvState(setEnv)
    const envSelectOnChangeHandler = (value) => {
        setEnv(value)
    }

    //
    const [kubernetesCluster, setKubernetesCluster] = useState('')
    const kubernetesClusterOptions = KubernetesClusterSelectOptions(env, setKubernetesCluster)
    const kubernetesClusterSelectOnChangeHandler = (value) => {
        setKubernetesCluster(value)
    }

    //
    const [kubernetesClusterNameSpaceOptions, setKubernetesClusterNameSpaceOptions] = useState([])
    const [kubernetesClusterNameSpace, setKubernetesClusterNameSpace] = useState('default')

    const kubernetesClusterNamespaceSelectOnChangeHandler = (value) => {
        setKubernetesClusterNameSpace(value)
    }

    useEffect(() => {
        if (kubernetesCluster === "") {
            return
        }

        KubernetesClusterNameSpaceSelectOptions(env, kubernetesCluster, setKubernetesClusterNameSpaceOptions)
    }, [kubernetesCluster])

    //
    const getKubernetesPodOnClick = () => {

    }

    return (
        <>
            <Space>
                <span>环境</span>
                <Select style={{width: 120}} value={env} options={productEnvs} onChange={envSelectOnChangeHandler}/>
                <span>K8S集群</span>
                <Select style={{width: 180}}
                        value={kubernetesCluster}
                        options={kubernetesClusterOptions}
                        onChange={kubernetesClusterSelectOnChangeHandler}/>

                <Select style={{width: 180}}
                        value={kubernetesClusterNameSpace}
                        options={kubernetesClusterNameSpaceOptions}
                        onChange={kubernetesClusterNamespaceSelectOnChangeHandler}/>

                <Button type={'primary'} onClick={getKubernetesPodOnClick}>查询</Button>
            </Space>

            <Table columns={k8sPodColumns} dataSource={k8sPodDataSource}/>

        </>
    );
}