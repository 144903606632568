import {
    Post,
    Get,
    Del,
    ProductK8SClusterSecretApi,
    ProductK8SClusterDockerSecretApi,
    ProductK8SClusterTlsSecretApi,
    Put,
    ProductK8SClusterSSHAuthSecretApi,
    ProductK8SClusterOpaqueSecretApi,
    ProductK8SClusterBasicAuthSecretApi
} from '../common';

export function ListSecret(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
    })
    return Get({
        url: `${ProductK8SClusterSecretApi}?${params}`,
    })
}

export function GetSecret(props) {
    return Get({
        url: "",
    })
}

export function DelSecret(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
    })
    return Del({
        url: `${ProductK8SClusterSecretApi}?${params}`,
    })
}

export function CreateDockerfileSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        username: props.username,
        password: props.password,
        email: props.email,
        url: props.url,
    }
    return Post({
        url: `${ProductK8SClusterDockerSecretApi}`,
        params: params,
    })
}

export function UpdateDockerfileSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        username: props.username,
        password: props.password,
        email: props.email,
        url: props.url,
    }
    return Put({
        url: `${ProductK8SClusterDockerSecretApi}`,
        params: params,
    })
}


export function CreateTLSSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        tlsCrt: props.tlsCrt,
        tlsKey: props.tlsKey,
    }

    return Post({
        url: `${ProductK8SClusterTlsSecretApi}`,
        params: params,
    })
}

export function UpdateTLSSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        tlsCrt: props.tlsCrt,
        tlsKey: props.tlsKey,
    }
    return Put({
        url: `${ProductK8SClusterTlsSecretApi}`,
        params: params,
    })
}


export function CreateSSHAuthSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        privateKey: props.privateKey,
    }

    return Post({
        url: `${ProductK8SClusterSSHAuthSecretApi}`,
        params: params,
    })
}

export function UpdateSSHAuthSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        privateKey: props.privateKey,
    }

    return Put({
        url: `${ProductK8SClusterSSHAuthSecretApi}`,
        params: params,
    })
}

export function CreateOpaqueSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        data: props.data, // map
    }
    return Post({
        url: `${ProductK8SClusterOpaqueSecretApi}`,
        params: params,
    })
}

export function UpdateOpaqueSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        data: props.data, // map
    }
    return Put({
        url: `${ProductK8SClusterOpaqueSecretApi}`,
        params: params,
    })
}


export function CreateBasicAuthSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        username: props.username,
        password: props.password,
    }

    return Post({
        url: `${ProductK8SClusterBasicAuthSecretApi}`,
        params: params,
    })
}

export function UpdateBasicAuthSecret(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        productEnvUuid: props.productEnvUuid,
        k8sClusterUuid: props.k8sClusterUuid,
        namespace: props.namespace,
        name: props.name,
        username: props.username,
        password: props.password,
    }

    return Put({
        url: `${ProductK8SClusterBasicAuthSecretApi}`,
        params: params,
    })
}
