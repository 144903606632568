import {
    CloudPlatformObjectStorageApi,
    CloudPlatformObjectStorageSecurityTokenApi,
    CloudPlatformObjectStorageSecurityTokenGeneratorApi,
    Get,
    Del,
    Post
} from '../common';

export function ListObjectStorageBucket(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
    })
    return Get({
        url: `${CloudPlatformObjectStorageApi}?${params}`,
    })
}

export function CreateObjectStorageGenerator(props) {
    const params = {
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        platformId: props.platformId,
        accountUuid: props.accountUuid,
        subAccountUuid: props.subAccountUuid,
        subAccountAccessKeyUuid: props.subAccountAccessKeyUuid,
        bucket: props.bucket,
        endpoint: props.endpoint,
        arn: props.arn,
    }
    return Post({
        url: `${CloudPlatformObjectStorageSecurityTokenGeneratorApi}`,
        params: params,
    })
}

export function ListObjectStorageGenerator(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
    })
    return Get({
        url: `${CloudPlatformObjectStorageSecurityTokenGeneratorApi}?${params}`,
    })
}

export function DelObjectStorageGenerator(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        generatorUuid: props.generatorUuid,
    })
    return Del({
        url: `${CloudPlatformObjectStorageSecurityTokenGeneratorApi}?${params}`,
    })
}

export function GetObjectStorageGenerator(props) {
    const params = new URLSearchParams({

    })
    return Get({
        url: `${CloudPlatformObjectStorageSecurityTokenGeneratorApi}?${params}`,
    })
}

export function GetObjectStorageBucketSecurityToken(props) {
    const params = new URLSearchParams({
        orgUuid: props.orgUuid,
        productUuid: props.productUuid,
        generatorUuid: props.generatorUuid,
        relativeId: props.relativeId,
    })
    return Get({
        url: `${CloudPlatformObjectStorageSecurityTokenApi}?${params}`
    })
}